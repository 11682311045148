<template>
	<div v-if="!loadingCohorts && cohortCards" class="cohort-row-cards">
		<div
			v-for="cohort in cohortCards"
			class="full-width margin-2 flex-row overflow-hidden border-radius-pt25 cursor-pointer shadow white-background"
			:key="cohort.es_cohortid"
			@click="changeCohort(cohort, $event)"
		>
			<div class="width-28-percent min-width-15">
				<cohort-image :cohort="cohort" />
			</div>

			<div class="flex-column justify-center padding-left-3 v-padding-2 width-72-percent">
				<p class="title is-4">{{ cohort.es_name }}</p>
				<div class="flex-row align-center">
					<p class="padding-1-1-1-0">Cohort progress:</p>

					<vue-circle
						:progress="cohort.progress * 100"
						:size="36"
						:fill="{ color: '#2da8df' }"
						:start-angle="Math.PI * 1.5"
						:thickness="3"
						:show-percent="true"
					></vue-circle>

					<!-- working completion button  -->
					<div v-if="cohort.progress * 100 === 100 && cohort.es_completioncertificate === 'True'" class="pl-md">
						<b-button
							:disabled="downloadingCertificate"
							:loading="downloadingCertificate"
							@click="downloadCertificate(cohort)"
						>
							Download Certificate
						</b-button>
					</div>
					<!-- disabled completion button  -->
					<div v-else-if="cohort.es_completioncertificate === 'True'" class="pl-md">
						<b-tooltip type="is-info" label="Available after 100% completion" position="is-right">
							<b-button disabled>
								Download Certificate
							</b-button>
						</b-tooltip>
					</div>
				</div>
			</div>
		</div>
	</div>
	<Loader v-else />
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import router from "../router/router.js"
import CohortImage from "../components/CohortImage.vue"
import ArchivedCohortImage from "../components/ArchivedCohortImage.vue"
import thumbnail from "../assets/academy-image-min.jpg"
import Loader from "../components/Loader"
// import VueCircle from "vue2-circle-progress";
import VueCircle from "vue2-circle-progress/src/index.vue"
import { openSnackBar } from "../utils/openSnackBar"
import EventBus from "../event-bus"

export default {
	name: "CohortRowCards",
	components: {
		CohortImage,
		Loader,
		VueCircle,
		ArchivedCohortImage
	},
	props: ["tab"],
	data() {
		return {
			thumbnail: thumbnail
		}
	},
	computed: {
		...mapGetters([
			"baseUrl",
			"currentTopic",
			"currentModule",
			"contact",
			"cohortsArray",
			"currentCohort",
			"freeCohorts",
			"currentEnrollment",
			"contact",
			"loadingCohorts",
			"cohortCards",
			"downloadingCertificate"
		])
	},
	mounted() {
		// this.getFreeCohorts();
	},
	methods: {
		...mapActions([
			"changeCurrentPage",
			"changeCurrentCohort",
			"getFreeCohorts",
			"setLoadingCohort",
			"readCohortData",
			"fetchCohortData",
			"getCohortCertificate"
		]),
		changeCohort(cohort, event) {
			if (event.target.innerText != "Download Certificate") {
				this.fetchCohortData(cohort)
			}
		},
		downloadCertificate(cohort) {
			openSnackBar(this, `Your certificate will be downloaded soon.`)

			EventBus.$emit("print-certificate", cohort)
		}
	}
}
</script>

<style lang="scss">
#app .cohort-row-cards {
	display: flex;
	flex-direction: column;
	align-items: center;

	.full-width {
		// width: 100%;
		width: 79%;
		max-width: 90rem;
	}

	.cursor-pointer {
		cursor: pointer;
	}

	.margin-2 {
		margin: 1rem 2rem;
	}

	// .margin-2:hover {
	//     margin: 1.95rem 2rem 2.05rem 2rem;
	// }

	.flex-row {
		display: flex;
		flex-direction: row;
	}

	.align-center {
		align-items: center;
	}

	.flex-column {
		display: flex;
		flex-direction: column;
	}

	.width-25-percent {
		width: 25%;
	}

	.width-28-percent {
		width: 28%;
	}

	.width-75-percent {
		width: 75%;
	}

	.width-72-percent {
		width: 72%;
	}

	.min-width-15 {
		min-width: 15rem;
		max-width: 20rem;
	}

	.padding-1 {
		padding: 1rem;
	}

	.has-border {
		border: 1px solid lightgray;
	}

	.border-radius-pt25 {
		border-radius: 0.25rem;
	}

	.overflow-hidden {
		overflow-x: hidden;
		overflow-y: hidden;
	}

	// .shadow {
	//     box-shadow: 0rem 1.5rem 3.5rem rgb(103, 192, 255);
	//     transition: box-shadow 150ms ease-out;
	// }

	// .shadow:hover {
	//     box-shadow: 0rem 1rem 4rem rgba(255, 255, 50, 0.3);
	//     transition: box-shadow 150ms ease-in;
	// }

	.white-background {
		background-color: white;
	}

	.padding-2 {
		padding: 2rem;
	}

	.flex-row .circle {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.padding-1-1-1-0 {
		padding: 1rem 0.5rem 1rem 0rem;
	}

	.percent-text {
		font-size: 0.75rem !important;
	}

	.justify-center {
		justify-content: center;
	}

	.v-padding-2 {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}

	.padding-left-3 {
		padding-left: 3rem;
	}
}
</style>
