<template>
    <div class="on-time-percent-component">
        <b-tooltip label="Topics submitted before the due date" type="is-info">
            <vue-fontawesome :icon="['fas', 'info-circle']" size="sm" color="#0077b5" />
        </b-tooltip>
        <p v-if="percent" class="font-size-2 flex-center">{{percent}}</p>
        <Loader v-else />
        <p class="text-center">On-time Submissions</p>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Loader from "./Loader";
import moment from "moment";

export default {
    name: "OnTimePercent",
    components: { Loader },
    computed: {
        ...mapGetters(["attempts", "currentCohort", "currentEnrollment"])
    },
    data() {
        return {
            percent: null
        };
    },
    mounted() {
        if (this.attempts) {
            this.percent = this.calculatePercent();
        }
    },
    watch: {
        attempts: function() {
            this.percent = this.calculatePercent();
        }
    },
    methods: {
        calculatePercent() {
            //make array of earliest unique submitted attempts on topic
            let attemptsAndTopics = this.buildArrayOfAttempts();

            //if no attempts, 100%
            if (attemptsAndTopics.length == 0) {
                return "100%";
            }

            //total unique attempts
            let total = attemptsAndTopics.length;

            //check how many attempts were on time
            let onTimeAttempts = this.filterOnTimeAttempts(attemptsAndTopics);

            //return how many were on time out of total unique attempts
            if (onTimeAttempts.length == 0) {
                return "0%";
            } else {
                return Math.round((onTimeAttempts.length / total) * 100) + "%";
            }
        },
        filterOnTimeAttempts(attemptsAndTopics) {
            return attemptsAndTopics.filter(attempt => {
                let dueTime =
                    attempt.topic.es_duedate || attempt.topic.es_enddate;

                let attemptTime =
                    attempt.es_timeend ||
                    attempt.es_timestart ||
                    attempt.ceatedon;

                //convert times to moments
                dueTime = new moment(dueTime).startOf("day");
                attemptTime = new moment(attemptTime).startOf("day");

                //compare
                var diff = dueTime.diff(attemptTime, "days");

                //discard if overdue
                if (diff < 0) {
                    return false;
                }

                //otherwise keep in array
                return true;
            });
        },
        buildArrayOfAttempts() {
            //filter attempts for current enrollment
            let arrayOfAttempts = this.filterCurrentAttempts();

            //filter out unsubmitted attempts
            arrayOfAttempts = this.filterSubmittedAttempts(arrayOfAttempts);

            //filter for earliest unique attempts
            arrayOfAttempts = this.filterEarliestAttempts(arrayOfAttempts);

            //add a topic field
            arrayOfAttempts = this.expandAttemptTopics(arrayOfAttempts);

            return arrayOfAttempts;
        },
        filterCurrentAttempts() {
            //fitler for attempts in current enrollment
            return this.attempts.filter(attempt => {
                return (
                    attempt.es_enrollment.es_enrollmentid ==
                    this.currentEnrollment.es_enrollmentid
                );
            });
        },
        filterEarliestAttempts(arrayOfAttempts) {
            //filters for the earliest unique attempts for a topic
            let uniqueAttempts = [];

            arrayOfAttempts.forEach(attempt => {
                //check if it is in uniqueAttempts
                let duplicate = uniqueAttempts.find(uniqueAttempt => {
                    return (
                        uniqueAttempt._es_topic_value ===
                        attempt._es_topic_value
                    );
                });

                if (!duplicate) {
                    //if it isn't, push it
                    uniqueAttempts.push(attempt);
                } else {
                    //if it is, compare it, and replace if necessary
                    let earlierAttempt = this.returnEarlierAttempt(
                        duplicate,
                        attempt
                    );

                    //if the new one is earlier
                    if (earlierAttempt == attempt) {
                        //get index of the duplciate
                        let duplicateIndex = uniqueAttempts.findIndex(
                            unique => {
                                return (
                                    unique.es_attemptid ==
                                    duplicate.es_attemptid
                                );
                            }
                        );
                        //splice at that index with the new value
                        uniqueAttempts.splice(duplicateIndex, 1, attempt);
                    }
                }
            });

            return uniqueAttempts;
        },
        expandAttemptTopics(arrayOfAttempts) {
            //adds a topic property on the attempt with more data about the topic
            let arrayOfTopics = this.buildArrayOfCurrentTopics();

            return arrayOfAttempts.map(attempt => {
                //find matching topic
                let matchingTopic = arrayOfTopics.find(topic => {
                    return topic.es_topicid == attempt._es_topic_value;
                });

                //append it to the attempt
                attempt.topic = matchingTopic;
                return attempt;
            });
        },
        buildArrayOfCurrentTopics() {
            //makes array of topics in the current cohort
            let arr = [];
            this.currentCohort.modules.forEach(module => {
                module.topics.forEach(topic => {
                    arr.push({
                        es_topicid: topic.es_topicid,
                        es_duedate: topic.es_duedate,
                        es_enddate: topic.es_enddate
                    });
                });
            });
            return arr;
        },
        filterSubmittedAttempts(arrayOfAttempts) {
            //filter attempts for only those submitted
            return arrayOfAttempts.filter(attempt => {
                return attempt.es_issubmitted;
            });
        },
        returnEarlierAttempt(duplicate, attempt) {
            //returns the earlier of two attempts
            let duplicateTime = duplicate.es_timeend || duplicate.es_timestart;
            let attemptTime = attempt.es_timeend || attempt.es_timestart;

            //convert times to moments
            duplicateTime = new moment(duplicateTime).startOf("day");
            attemptTime = new moment(attemptTime).startOf("day");

            //compare
            var diff = duplicateTime.diff(attemptTime, "days");

            //return earlier one
            if (diff < 0) {
                return duplicate;
            }

            return attempt;
        }
    }
};
</script>

<style lang="scss">
#app .on-time-percent-component {
    position: relative;

    .flex-center {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .font-size-2 {
        font-size: 2rem;
    }

    .text-center {
        text-align: center;
    }

    svg {
        cursor: help;
    }

    .b-tooltip {
        position: absolute;
        top: 4px;
        right: 4px;
    }
}
</style>
