<template>
	<div v-if="loadedQuestion" class="upload-quiz-question-component" :key="questionId">
		<!-- Upload -->
		<!-- question text -->
		<p class="question-margin-bottom">{{ this.loadedQuestion.es_text }}</p>

		<div class="flex-center">
			<!-- file box -->
			<div
				class="margin-top-1 flex-center upload-light-border height-10 max-width-30 width-90-percent alice-blue-bg-color border-radius-2 box-shadow-4"
			>
				<div class="test-div">
					<div v-if="file" class="flex-row center-self">
						<vue-fontawesome :icon="['far', 'file']" size="2x" color="black" />

						<!-- file name -->
						<span class="file-name">{{ file.name }}</span>

						<vue-fontawesome v-if="correctFileType" :icon="['fas', 'check-circle']" size="2x" color="#4be66d" />
						<vue-fontawesome v-else :icon="['fas', 'times-circle']" size="2x" color="#e94542" />
					</div>
					<div v-else class="flex-row">
						<vue-fontawesome :icon="['far', 'file']" size="2x" color="black" />
						<span class="file-name">No file chosen</span>
					</div>

					<p class="error-p" v-if="file && !correctFileType">
						Only files with following extensions are allowed:
						<em>pdf</em>.
					</p>
				</div>
			</div>
		</div>

		<b-field class="file flex-center vertical-margin-2">
			<b-upload v-model="file" @input="handleChange(file)">
				<a class="button is-info clear-markdown">
					<vue-fontawesome :icon="['fas', 'upload']" size="sm" color="white" />
					<span>Choose File</span>
				</a>
			</b-upload>
		</b-field>

		<!-- selections
            <p class="content">
                <b>Selection:</b>
                {{ checkboxChoices }}
        </p>-->
	</div>
	<Loader v-else />
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import axios from "axios"
import EventBus from "../event-bus"
import Loader from "./Loader"
import { openSnackBar } from "../utils/openSnackBar"

export default {
	name: "UploadQuizQuestion",
	props: ["questionId"],
	components: { Loader },
	computed: {
		...mapGetters(["questionChoices", "currentCohort", "currentQuestionIndex", "previousSelections", "continuingQuiz"])
	},
	data() {
		return {
			loadedQuestion: null,
			radio: null,
			correctAnswer: false,
			answer: null,
			filteredChoices: null,
			shuffledAnswerChoices: null,
			checkboxChoices: [],
			file: null,
			correctFileType: false
		}
	},
	mounted() {
		this.getQuestion()
		this.checkPrevious()
	},
	methods: {
		...mapActions(["answerQuizQuestion"]),
		checkPrevious() {
			if (this.previousSelections && this.continuingQuiz) {
				let selection = this.findSelection()

				//save choice
				let choice = selection.es_choice_text

				//set answer to choice
				if (choice && choice != "No answer selected" && choice != "Invalid file type") {
					this.correctFileType = true
					this.file = { name: choice }
					this.handlePrevious()
					// this.handleChange(this.file);
				} else if (choice && choice == "Invalid file type") {
					this.correctFileType = false
					this.file = { name: choice }
					this.handlePrevious()
				}
			}
		},
		findSelection() {
			if (this.currentQuestion) {
				return this.previousSelections.find(selection => {
					return this.currentQuestion.es_question_id == selection.es_question_id
				})
			} else if (this.questionId) {
				return this.previousSelections.find(selection => {
					return this.questionId == selection.es_question_id
				})
			} else {
				return null
			}
		},
		getQuestion() {
			//find an answer choice from this topic with the same questionid
			let questionChoice = this.questionChoices.find(choice => {
				return choice.es_topicquestion.es_topicquestionid == this.questionId
			})

			//set question in data equal to that choices question (it is expanded)
			this.loadedQuestion = questionChoice.es_topicquestion

			//submit an unanswered question (placeholder selection in case student chooses no answer)
			this.submitUnansweredQuestion()
		},
		submitUnansweredQuestion() {
			//sends a "no choice selected/answered" default selection to quizbutton component

			this.answerQuizQuestion({
				es_topicquestionid: this.loadedQuestion.es_topicquestionid,
				choices: null,
				es_text: this.loadedQuestion.es_text,
				es_type: this.loadedQuestion.es_type,
				es_sort: this.currentQuestionIndex
			})
		},
		handleChange(file) {
			//emits to quizbutton and adds object to array this.correctQuestions which is passed to the server
			//when the quiz is submitted

			let reader = new FileReader()
			let correctFileType = this.checkFileType(file)

			if (!correctFileType) {
				this.alertWrongType(file)
				this.clearPreviousFile()
			} else {
				reader.readAsDataURL(file)

				let handler = this
				reader.onload = e => {
					this.sendChoiceToQuizButton(handler, e)
				}
			}
		},
		sendChoiceToQuizButton(handler, e) {
			//splice reader.result

			let reader = e.target.result

			let resultString = reader
			let idx = resultString.indexOf("base64,") + "base64,".length

			let choices = [
				{
					es_topicquestionchoiceid: null,
					es_topicquestionid: handler.loadedQuestion.es_topicquestionid,
					es_text: this.file.name,
					file: resultString.slice(idx)
				}
			]

			this.answerQuizQuestion({
				es_topicquestionid: handler.loadedQuestion.es_topicquestionid,
				choices: choices,
				es_type: handler.loadedQuestion.es_type,
				es_text: handler.loadedQuestion.es_text,
				es_sort: handler.currentQuestionIndex
			})
		},
		checkFileType(file) {
			let correctType = file.type == "application/pdf"
			this.correctFileType = correctType

			return correctType
		},
		alertWrongType(file) {
			openSnackBar(this, `Error: ${file.type} is not supported.`)
		},
		clearPreviousFile() {
			let choices = [
				{
					es_topicquestionchoiceid: null,
					es_topicquestionid: this.loadedQuestion.es_topicquestionid,
					es_text: "Invalid file type",
					file: null
				}
			]

			this.answerQuizQuestion({
				es_topicquestionid: this.loadedQuestion.es_topicquestionid,
				choices: choices,
				es_type: this.loadedQuestion.es_type,
				es_text: this.loadedQuestion.es_text,
				es_sort: this.currentQuestionIndex
			})
		},
		handlePrevious() {
			let choices = [
				{
					es_topicquestionchoiceid: null,
					es_topicquestionid: this.loadedQuestion.es_topicquestionid,
					es_text: this.file.name,
					file: null
				}
			]

			this.answerQuizQuestion({
				es_topicquestionid: this.loadedQuestion.es_topicquestionid,
				choices: choices,
				es_type: this.loadedQuestion.es_type,
				es_text: this.loadedQuestion.es_text,
				es_sort: this.currentQuestionIndex
			})
		}
	}
}
</script>

<style lang="scss">
#app .upload-quiz-question-component {
	-webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
	padding: 0.5rem;
	margin-left: 1rem;
	background-color: #dde4fb;
	margin-right: 5rem;
	margin-top: 2rem;
	margin-bottom: 1rem;

	.message-box {
		margin: 1rem;
		border: 1px solid #2ca8df;
		height: 6rem;
		overflow-y: auto;
		padding: 0.5rem 1rem;
		background-color: #ced9fd;
	}

	.flex {
		display: flex;
	}

	.align-center {
		align-items: center;
	}

	.choice-container {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.validity-icon {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 24px;
		margin-top: 0px;
		padding-left: 12px;
	}

	.empty {
		padding: 12px;
	}

	.b-radio.radio {
		margin-bottom: 0.5rem;
	}

	.button {
		margin-left: 1rem;
	}

	.choices-block .b-tooltip {
		line-height: 1.5rem;
	}

	.checkbox {
		margin-bottom: 0.5rem;
		line-height: 1.5rem;
	}

	.b-checkbox.checkbox + .checkbox {
		margin-left: 0rem;
	}

	.upload-light-border {
		border: 1px solid lightblue;
	}

	.flex-center {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.file-name {
		border: none;
		max-width: 12rem;
	}

	a.clear-markdown {
		text-decoration: none;
		color: white;
	}

	.upload span {
		padding-left: 0.5rem;
	}

	.vertical-margin-2 {
		margin-bottom: 2rem;
		margin-top: 2rem;
	}

	.square {
		width: 50%;
		min-width: 20rem;
		max-width: 25rem;
		max-height: 8rem;
	}

	.square:after {
		content: "";
		display: block;
		padding-bottom: 100%;
	}

	.margin-top-1 {
		margin-top: 1rem;
	}

	.alice-blue-bg-color {
		background-color: aliceblue;
	}

	.flex-row {
		display: flex;
		flex-direction: row;
	}

	.box-shadow-4 {
		box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
	}

	.border-radius-2 {
		border-radius: 1rem;
	}

	.column {
		flex-direction: column;
	}

	.height-10 {
		height: 10rem;
	}

	.width-30 {
		width: 30rem;
	}

	.max-width-30 {
		max-width: 30rem;
	}

	.width-90-percent {
		width: 90%;
	}

	.test-div {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: relative;
		height: inherit;
		width: inherit;
	}

	.center-self {
		justify-self: center;
		align-self: center;
	}

	.test-div .error-p {
		margin: 0;
		padding: 0;
		position: absolute;
		font-weight: bold;
		bottom: 1px;
		padding-bottom: 1rem;
		color: #ea4542;
	}
}

#app .markdown-renderer-component .markdown .upload-quiz-question-component {
	.vertical-margin-2 {
		margin-bottom: 2rem;
		margin-top: 2rem;
	}

	a {
		text-decoration: none;
		color: white;
	}
}
</style>
