<template>
    <div v-if="imageUrl">
        <img
            :src="imageUrl"
            class="has-left-padding has-right-padding"
            alt
            @click="isImageModalActive = true"
        />
        <p
            v-if="this.image.description && this.image.description != 'null'"
            class="is-small image-caption"
        >{{caption}}</p>
        <b-modal :active.sync="isImageModalActive">
            <p class="image-modal" @click="isImageModalActive = false">
                <img :src="imageUrl" />
            </p>
        </b-modal>
    </div>
    <Loader v-else />
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import Loader from "./Loader.vue";

export default {
    name: "ContentImage",
    props: ["image"],
    computed: {
        ...mapGetters(["loadedImages", "baseUrl", "currentTopic"]),
        caption: function() {
            if (!this.image.description || this.image.description == "null") {
                return `Fig. ${this.image.index}`;
            } else {
                return `Fig. ${this.image.index}: ${this.image.description}`;
            }
        }
    },
    components: { Loader },
    data() {
        return {
            imageUrl: null,
            isImageModalActive: false
        };
    },
    watch: {
        //watch prop in case component gets reused
        image: function() {
            this.getImageUrl(this.image.annotationid);
        }
    },
    mounted() {
        this.getImageUrl(this.image.annotationid);
    },
    methods: {
        ...mapActions(["updateLoadedImages"]),
        getImageUrl(id) {
            //check if image is in loadedImages
            if (this.alreadyLoaded(id)) {
                let loadedImage = this.loadedImages.find(
                    image => image.annotationid === id
                );
                this.imageUrl = loadedImage.documentbody;
            } else {
                //if not, get request for the documentbody
                let url =
                    this.baseUrl +
                    `api/documentbody?annotationid=${id}&topicid=${this.currentTopic.es_topicid}`;
                axios
                    .get(url)
                    .then(response => {
                        //add the image to loadedImages
                        this.updateLoadedImages(response.data);
                        this.imageUrl = response.data.documentbody;
                    })
                    .catch(err => {
                        console.log("api/documentbody error", err);
                        router.push({ path: "/" });
                        commit("setAuthentication", false);
                    });
            }
        },
        alreadyLoaded(id) {
            //check if any images have been loaded
            if (this.loadedImages.length > 0) {
                return this.loadedImages.some(
                    image => image.annotationid === id
                );
            } else {
                return false;
            }
        }
    }
};
</script>
