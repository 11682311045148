<template>
	<!--content + video-->
	<span class="content-topic-component">
		<div id="single-col-topic" class="overflow-y-scroll hundo-height">
			<markdown-renderer />
			<submit-button />
		</div>
	</span>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import SubmitButton from "./SubmitButton.vue"
import MarkdownRenderer from "./MarkdownRenderer.vue"

export default {
	name: "ContentTopic",
	computed: {
		...mapGetters(["currentCohort"])
	},
	data() {
		return {}
	},
	components: {
		MarkdownRenderer,
		SubmitButton
	},
	mounted() {},
	methods: {}
}
</script>

<style lang="scss">
.content-topic-component {
	.overflow-y-scroll {
		overflow-y: scroll;
	}

	.hundo-height {
		height: 100%;
	}
}
</style>
