<template>
	<div class="submit-button-component">
		<!--button when submitted (no click event)-->
		<div v-if="submitted.status" class="flex align-center max-width-920">
			<b-button type="is-success" :disabled="!canSubmit || attemptSaving" :loading="attemptSaving">Done!</b-button>
			<div class="flex-col-center min-width-110 text-center pr-md">
				<span class="small">{{ `Completed on ${submitted.date}` }}</span>
				<a @click="handleFeedbackClick" class="small">Leave feedback</a>
			</div>

			<!-- print version -->
			<print-button v-if="printable" />
		</div>

		<!--button when not submitted -->
		<div v-else-if="!submitted.status" class="flex align-center max-width-920 resp-flex-col">
			<b-tooltip label="Must answer all questions correctly" position="is-right" type="is-info" :active="!canSubmit">
				<b-button v-if="!canSubmit || attemptSaving" type="is-info" :loading="attemptSaving" disabled>Mark Done</b-button>
				<b-button v-else @click="attemptToSubmitAttempt()" type="is-info" :loading="attemptSaving">Mark Done</b-button>
			</b-tooltip>

			<div class="flex-col-center min-width-110 pr-md">
				<a @click="handleFeedbackClick" class="small">Leave feedback</a>
			</div>

			<!-- print version -->
			<print-button v-if="printable" />
		</div>

		<div class="end-white-space"></div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import EventBus from "../event-bus"
import moment from "moment"
import PrintButton from "./PrintButton"
import timezone from "moment-timezone"

export default {
	name: "SubmitButton",
	components: {
		PrintButton
	},
	computed: {
		...mapGetters([
			"currentTopic",
			"attempts",
			"currentAttempt",
			"attemptSaving",
			"rootTimer",
			"timeZone",
			"totalQuestions",
			"pdfDownloading",
			"currentCohort"
		]),
		printable: function() {
			if (
				[717580001, 717580000].includes(this.currentTopic.es_type) &&
				this.currentTopic.es_content &&
				[717580000, 717580004, 717580007].includes(this.currentCohort.es_type)
			) {
				return true
			}
			return false
		}
	},
	data() {
		return {
			correctQuestions: [],
			submitted: { status: false },
			agreedToAttempt: false,
			canSubmit: false
		}
	},
	updated() {},
	mounted() {
		this.checkSubmittedStatus()

		EventBus.$on("answered", data => {
			this.updateCorrectQuestions(data)
		})

		this.checkCanSubmit()
	},
	watch: {
		attempts: function() {
			this.checkSubmittedStatus()
		}
	},
	beforeDestroy() {},
	methods: {
		...mapActions(["submitAttempt", "setAttemptSaving", "generatePdf", "updatePdfDownloading"]),
		checkSubmittedStatus() {
			//checks if this topic was submitted before
			let contactAttempts = this.attempts
			let completedAttempts = contactAttempts.filter(attempt => {
				return attempt._es_topic_value === this.currentTopic.es_topicid && attempt.es_issubmitted
			})
			this.setSubmitted(completedAttempts)
		},
		setSubmitted(completedAttempts) {
			//sets the status and date of a previously submitted topic
			if (completedAttempts.length > 0) {
				let completedDate = moment(completedAttempts[0].es_timeend)
					.tz(this.timeZone)
					.format("MMMM Do YYYY, [at] h:mm a z")
				this.submitted = {
					status: true,
					date: completedDate
				}
			} else {
				this.submitted = {
					status: false,
					date: null
				}
			}
		},
		printPdf() {
			EventBus.$emit("print-pdf")
			this.updatePdfDownloading(true)
		},
		//updates the correctQuestions array with incoming data
		//replaces previously submitted questions with incoming question if theyre the same (e.g. when a student changes their answer choice)
		//gets called by eventbus 'answered' whenever a question is answered
		updateCorrectQuestions(data) {
			//see if correctQuestions array contains the recently answered question
			let matchingQuestionId = this.correctQuestions.find(element => element.questionid == data.questionid)

			//make new quesiton object
			var answeredQuestion = {
				questionid: data.questionid,
				choiceid: data.choiceid,
				topicid: this.currentTopic.es_topicid,
				text: data.text,
				type: data.type,
				questionText: data.questionText,
				status: data.status || false,
				file: data.file || null
			}

			if (data.correct && !matchingQuestionId) {
				//correct and not in correctQuestions = add it
				this.correctQuestions.push(answeredQuestion)
			} else if (data.correct && matchingQuestionId) {
				//correct and in correctQuestions = replace it
				var indexOfQuestionId = this.correctQuestions.indexOf(matchingQuestionId)
				this.correctQuestions.splice(indexOfQuestionId, 1, answeredQuestion)
			} else if (!data.correct && matchingQuestionId) {
				//incorrect and in correctQuestions = remove it
				var indexOfQuestionId = this.correctQuestions.indexOf(matchingQuestionId)
				this.correctQuestions.splice(indexOfQuestionId, 1)
			}
			this.checkCanSubmit()
		},
		checkCanSubmit() {
			if (this.correctQuestions.length == this.totalQuestions) {
				this.canSubmit = true
			} else {
				this.canSubmit = false
			}
		},
		//submits topics when any and all questions have been answered correctly
		attemptToSubmitAttempt(canSubmit) {
			if (this.canSubmit || canSubmit) {
				//set attempt saving to true
				this.setAttemptSaving()

				//calls up feedback modal
				EventBus.$emit("topic-submitted")

				//make data object
				let data = {
					attempt: this.currentAttempt,
					choices: this.correctQuestions
				}

				//check for pending
				if (
					this.correctQuestions.some(question => {
						return question.status == "pending"
					})
				) {
					data.pending = true
				} else {
					data.pending = false
				}

				//passes the attempt and the correctQuestions object to the server
				this.submitAttempt(data)

				//clears correctQuestions
				this.correctQuestions = []
			}
		},
		handleFeedbackClick(e) {
			e.preventDefault()
			EventBus.$emit("feedback-button-clicked")
		}
	}
}
</script>

<style lang="scss">
#app .submit-button-component {
	margin: 2rem 2rem;

	.end-white-space {
		height: 5rem;
	}

	.flex {
		display: flex;
	}

	.align-center {
		align-items: center;
	}

	.small {
		font-size: 0.8rem;
	}

	a.small {
		color: #0000ee;
		text-decoration: underline;
	}

	.flex-col-center {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.button {
		margin-right: 1rem;
	}

	.max-width-920 {
		max-width: 920px;
	}

	.max-width-1080 {
		max-width: 1080px;
	}

	.min-width-110 {
		min-width: 110px;
	}

	.text-center {
		text-align: center;
	}

	.pr-md {
		padding-right: 1rem;
	}

	@media only screen and (max-width: 600px) {
		.resp-flex-col {
			flex-direction: column;
		}
	}
}
</style>
