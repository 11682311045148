import moment from "moment";
import router from '../../router/router.js';
import { checkMultipleSelectQuestion } from '../../api/question.js';

const state = {
    // currentAttempt: null,
    checkingQuestion: false
};

const getters = {
    // currentAttempt: (state) => state.currentAttempt,
    checkingQuestion: (state) => state.checkingQuestion
};

const actions = {
    checkQuestion({ commit, dispatch }, data) {
        commit('updateCheckingQuestion', true)

        //axios req
        checkMultipleSelectQuestion(data).then(response => {
            commit('updateCheckingQuestion', false)
            return response;
        }).catch(err => {
            console.log("api/checkquestion error:", err)
            commit('updateCheckingQuestion', false)
            commit('setErrorMessage', "Error checking question.")
            router.push({ path: '/error' })
        })
    },
    updateCheckingQuestion({ commit }, data) {
        commit('updateCheckingQuestion', data)
    }
};

const mutations = {
    updateCheckingQuestion: (state, status) => (state.checkingQuestion = status)
};

export default {
    state,
    getters,
    actions,
    mutations
}
