<template>
	<div class="quiz-button-component">
		<span class="flex align-center justify-end margin-1">
			<b-button v-if="takingQuiz && !quizFinished" type="is-info" @click="handleContinueClick()">Continue</b-button>
			<b-button v-else type="is-info" :loading="attemptSaving" :disabled="attemptSaving" @click="handleSubmitQuiz()">Submit</b-button>
		</span>
		<!-- a popup modal unique to quizzes -->
		<b-modal :active.sync="skipGuard" has-modal-card trap-focus aria-role="dialog" aria-modal>
			<div class="card">
				<div class="card-content">
					<p>Are you sure you want to skip this question? You will not be able to return to this question later.</p>
				</div>
				<div class="card-buttons">
					<b-button type="is-danger" @click="skipQuestion()">Yes</b-button>
					<b-button type="is-info" @click="stayOnQuestion()">No</b-button>
				</div>
			</div>
		</b-modal>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import EventBus from "../event-bus"
import moment from "moment"
import timezone from "moment-timezone"

export default {
	name: "QuizButton",
	components: {},
	computed: {
		...mapGetters([
			"currentTopic",
			"attempts",
			"currentAttempt",
			"attemptSaving",
			"rootTimer",
			"timeZone",
			"currentTopic",
			"currentEnrollment",
			"currentCohort",
			"takingQuiz",
			"currentQuestionIndex",
			"quizQuestionsArr",
			"quizFinished",
			"currentQuestion",
			"previousSelections",
			"continuingQuiz",
			"studentQuizSelections",
			"isAnswered",
			"currentQuestionSelection"
		])
	},
	data() {
		return {
			studentSelections: [],
			submitted: { status: false },
			currentSelection: null,
			skipGuard: false
		}
	},
	mounted() {
		this.checkSubmittedStatus()

		//fired from course when component is destroyed before finish
		EventBus.$on("submit-quiz", data => {
			this.handleSubmitQuiz()
		})

		this.checkForContinue()

		this.checkForFinished()
	},
	watch: {
		attempts: function() {
			this.checkSubmittedStatus()
		},
		rootTimer: function() {
			//submit attempt if timer runs out
			if (this.rootTimer <= 0) {
				this.handleSubmitQuiz()
			}
		}
	},
	methods: {
		...mapActions([
			"submitQuiz",
			"setAttemptSaving",
			"updateCurrentQuestion",
			"updateCurrentQuestionIndex",
			"setQuizQuestionsArr",
			"updateQuizFinished",
			"updateTakingQuiz",
			"updateAttemptSelection",
			"skipSelection",
			"setStudentSelectionsArr",
			"updateStudentSelections",
			"clearCurrentSelection",
			"skipLocalSelection",
			"clearStudentQuizSelections",
			"stopTimer"
		]),
		checkSubmittedStatus() {
			//checks if this topic was submitted before
			let contactAttempts = this.attempts
			let completedAttempts = contactAttempts.filter(attempt => {
				return attempt._es_topic_value === this.currentTopic.es_topicid && attempt.es_issubmitted
			})
			this.setSubmitted(completedAttempts)
		},
		setSubmitted(completedAttempts) {
			//sets the status and date of a previously submitted topic
			if (completedAttempts.length > 0) {
				let completedDate = moment(completedAttempts[0].es_timeend)
					.tz(this.timeZone)
					.format("MMMM Do YYYY, [at] h:mm a z")
				this.submitted = {
					status: true,
					date: completedDate
				}
			}
		},
		checkForFinished() {
			if (this.studentSelections.length == this.currentTopic.es_totalquizquestions - 1) {
				this.updateQuizFinished(true)
			}
		},
		//submits quizes when called
		handleSubmitQuiz() {
			if (!this.isAnswered && !this.skipGuard) {
				this.skipGuard = true
				return
			} else if (!this.isAnswered) {
				this.skipGuard = false
				this.skipSelection({
					es_attemptid: this.currentAttempt,
					es_topicquestionid: this.currentQuestionSelection.es_topicquestionid,
					es_question_text: this.currentQuestionSelection.es_text
				})
				this.skipLocalSelection(this.currentQuestionSelection)
				// clearCurrent
				this.clearCurrentSelection()
			}

			this.updateTakingQuiz(false)
			this.updateCurrentQuestionIndex(0)
			this.updateCurrentQuestion(null)
			this.updateQuizFinished(false)

			//set attempt saving to true
			this.setAttemptSaving()

			//calls up feedback modal
			EventBus.$emit("topic-submitted")

			//clear and reset timer
			this.stopTimer()

			this.submitQuiz({
				es_attemptid: this.currentAttempt,
				es_enrollmentid: this.currentEnrollment.es_enrollmentid,
				es_cohortid: this.currentCohort.es_cohortid,
				es_topicid: this.currentTopic.es_topicid,
				es_issubmitted: true,
				questions: this.studentQuizSelections,
				es_timeend: moment()
			})

			//clears studentSelections
			// this.studentSelections = [];
			this.clearStudentQuizSelections()
		},
		handleContinueClick() {
			//save selection as skipped if no answer selected
			if (!this.isAnswered && !this.skipGuard) {
				this.skipGuard = true
				return
			} else if (!this.isAnswered) {
				this.skipGuard = false
				this.skipSelection({
					es_attemptid: this.currentAttempt,
					es_topicquestionid: this.currentQuestionSelection.es_topicquestionid,
					es_question_text: this.currentQuestionSelection.es_text
				})
				this.skipLocalSelection(this.currentQuestionSelection)
				// clearCurrent
				this.clearCurrentSelection()
			}

			//update attempt status to pending
			this.resubmitAsPending()

			//increase currentindex
			let prev = this.currentQuestionIndex + 1

			//check for end of quiz, or just next question
			if (prev < this.currentTopic.es_totalquizquestions) {
				this.updateCurrentQuestionIndex(prev)
				this.updateCurrentQuestion(this.quizQuestionsArr[prev])
				if (prev == this.currentTopic.es_totalquizquestions - 1) {
					this.updateQuizFinished(true)
				}
			} else {
				this.updateCurrentQuestion(null) //leads to null question
				this.updateQuizFinished(true)
				EventBus.$emit("fill-progress-bar")
			}
		},
		resubmitAsPending() {
			if (this.isAnswered) {
				//resubmit
				let localSelection = { ...this.currentQuestionSelection }
				localSelection.es_selectionstatus = 717580000
				this.updateStudentSelections(localSelection)
			}
		},
		postSelection(data, attemptId) {
			this.updateAttemptSelection({
				data: data,
				es_attemptid: attemptId
			})
		},
		checkForContinue() {
			if (this.continuingQuiz && this.previousSelections) {
				//filter previous
				let prev = this.previousSelections.filter(selection => {
					return selection.es_sort < this.currentQuestionIndex
				})

				//add each previous to local studentselections
				prev.forEach(selection => {
					let answeredQuestion = {
						es_topicquestionid: selection.es_question_id,
						choices: [
							{
								es_topicquestionchoiceid: null,
								es_topicquestionid: selection.es_question_id,
								es_text: selection.es_choice_text,
								file: null
							}
						],
						es_text: selection.es_question_text,
						es_type: this.convertType(selection.es_type),
						es_sort: selection.es_sort,
						es_selectionstatus: selection.es_selectionstatus
					}

					this.studentSelections.push(answeredQuestion)
				})
			}
		},
		convertType(text) {
			switch (text) {
				case "Multiple Choice":
					return 717580003
					break
				case "Fill in the blank":
					return 717580000
					break
				case "Multiple Select":
					return 717580004
					break
				case "Open Response":
					return 717580006
					break
				case "Upload":
					return 717580007
					break
				default:
					return 717580003
			}
		},
		skipQuestion() {
			if (!this.quizFinished) {
				this.handleContinueClick()
			} else {
				this.handleSubmitQuiz()
			}
		},
		stayOnQuestion() {
			this.skipGuard = false
		}
	}
}
</script>

<style lang="scss">
#app .quiz-button-component {
	// margin: 2rem 4rem;
	position: absolute;
	bottom: 0rem;
	right: 0;
	/* width: calc(100% - 64px); */
	width: 100%;
	background-color: #eaf5ff;
	height: 4.5rem;

	.end-white-space {
		height: 5rem;
	}

	.flex {
		display: flex;
	}

	.align-center {
		align-items: center;
	}

	.small {
		font-size: 0.8rem;
	}

	.flex-col-center {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.button {
		margin-right: 1rem;
	}

	.justify-end {
		justify-content: flex-end;
	}

	.margin-1 {
		margin: 1rem;
	}

	.card {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		height: 15rem;
		width: 25rem;
		border-radius: 10px;
	}

	.card-content {
		margin-bottom: 2rem;
	}

	.card-buttons {
		width: 15rem;
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		align-items: center;

		.button {
			width: 4rem;
		}
	}
}
</style>
