<template>
	<div class="reset-quiz-modal">
		<h2>Student: {{ gradebookCurrentAttempts.fullname }}</h2>
		<h2>Module: {{ gradebookCurrentAttempts.topic.module }}</h2>
		<h2>Topic: {{ gradebookCurrentAttempts.topic.name }}</h2>
		<h2 class="mb-md">
			Highest Score:
			<span v-if="!quizModalLoading">
				{{
					gradebookCurrentAttempts.attempt && gradebookCurrentAttempts.attempt.length > 0
						? Math.floor(gradebookCurrentAttempts.attempt[0].score * 100) + "%"
						: "None"
				}}
			</span>
			<InlineLoader v-else />
		</h2>

		Please click the reset button for the corresponding grade:

		<span v-if="gradebookCurrentAttempts.attempt.length > 0">
			<span v-if="!quizModalLoading">
				<div v-for="attempt in gradebookCurrentAttempts.attempt" :key="attempt.id" class="flex row">
					<div class="col">
						{{ `${Math.round(attempt.score * 100)}%` }}
					</div>

					<div class="col">
						<b-button type="is-danger" @click="handleReset(attempt)" :loading="quizModalLoading">Reset</b-button>
					</div>
				</div>
			</span>
			<Loader v-else />
		</span>

		<div v-else class="flex-center mt-lg">
			<p>No attempts for this topic</p>
		</div>

		<div class="close">
			<b-button @click="$emit('closeModal')" :loading="quizModalLoading">Close Modal</b-button>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import InlineLoader from "./InlineLoader"
import Loader from "./Loader"

export default {
	props: [],
	components: { InlineLoader, Loader },
	data() {
		return {}
	},
	computed: {
		...mapGetters(["currentCohort", "gradebookCurrentAttempts", "quizModalLoading"])
	},
	created() {},
	watch: {},
	methods: {
		...mapActions(["resetQuiz", "setGradebookModalActive", "setQuizModalLoading"]),
		handleReset(attempt) {
			this.setQuizModalLoading(true)
			this.resetQuiz({
				es_attemptid: attempt.id,
				es_cohortid: this.currentCohort.es_cohortid
			})
		}
	}
}
</script>

<style lang="scss">
#app .reset-quiz-modal {
	background-color: white !important;
	z-index: 5;
	display: block;
	padding: 2rem;
	border-radius: 5px;
	overflow-x: hidden;
	min-height: 15rem !important;
	height: fit-content;
	width: fit-content;
	margin-left: auto;
	margin-right: auto;

	.flex {
		width: 15rem;
		margin-left: auto;
		margin-right: auto;
		align-items: center;
		display: flex;
		padding-top: 2rem;
		justify-content: space-around;
	}

	.col {
		width: 5rem;
	}

	.close {
		display: flex;
		justify-content: center;
		padding-top: 3rem;
	}

	.flex-center {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.mt-lg {
		margin-top: 2rem;
	}

	.mb-md {
		margin-bottom: 1rem;
	}
}
</style>
