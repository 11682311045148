import router from "../../router/router.js"
import { getSessions, getSessionAttendance, patchAttendance } from "../../api/session.js"

const state = {
	currentSessions: null,
	sessions: null,
	sessionAttendance: null,
	sessionAttendanceLoading: false
}

const getters = {
	currentSessions: state => state.currentSessions,
	allSessions: state => state.sessions,
	sessionAttendance: state => state.sessionAttendance,
	sessionAttendanceLoading: state => state.sessionAttendanceLoading
}

const actions = {
	getSessions({ commit, rootState, dispatch }) {
		getSessions(rootState.cohorts.cohortsIdsQuery)
			.then(response => {
				commit("setSessions", response.data.value)
				//filters sessions by current cohort
				dispatch("makeCurrentSessions")
			})
			.catch(err => {
				console.log("api/sessions error", err)
				commit("setAuthentication", false)
				commit("setContact", null)
				commit("setErrorMessage", "Failed to get sessions.")
				router.push({ path: "/error" })
			})
	},
	makeCurrentSessions({ commit, rootState }) {
		let allSessions = rootState.sessions.sessions
		let currentCohortId = rootState.cohorts.currentCohort.es_cohortid
		let currentSessions = allSessions.filter(session => session._es_cohort_value === currentCohortId)
		commit("setCurrentSessions", currentSessions)
	},
	getSessionAttendance({ commit, rootState }, data) {
		commit("setSessionAttendanceLoading", true)
		getSessionAttendance(data)
			.then(response => {
				commit("setSessionAttendance", response.data)
				commit("setSessionAttendanceLoading", false)
			})
			.catch(err => {
				console.log("api/sessionattendance error", err)
				commit("setSessionAttendanceLoading", false)
				commit("setErrorMessage", "Not allowed.")
				router.push({ path: "/error" })
			})
	},
	patchAttendance({ commit }, data) {
		commit("setSessionAttendanceLoading", true)

		let patchedAttendance = data.attendance
		patchedAttendance.es_status = data.es_status
		patchAttendance(patchedAttendance)
			.then(response => {
				commit("updatePatchedAttendance", {
					es_attendanceid: data.attendance.es_attendanceid,
					es_status: data.es_status
				})
				commit("setSessionAttendanceLoading", false)
			})
			.catch(err => {
				console.log("patch attendance err", err)
				commit("setSessionAttendanceLoading", false)
				commit("setErrorMessage", "Not allowed.")
				router.push({ path: "/error" })
			})
	}
}

const mutations = {
	setCurrentSessions: (state, currentSessions) => (state.currentSessions = currentSessions),
	setSessions: (state, sessions) => (state.sessions = sessions),
	setSessionAttendance: (state, arr) => (state.sessionAttendance = arr),
	setSessionAttendanceLoading: (state, val) => (state.sessionAttendanceLoading = val),
	updatePatchedAttendance: (state, data) => {
		let currentAttendance = state.sessionAttendance

		let newAttendance = currentAttendance.map(val => {
			if (val.es_attendanceid === data.es_attendanceid) {
				val.es_status = data.es_status
			}
			return val
		})

		state.sessionAttendance = newAttendance
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}
