<template>
	<div class="attendance-page">
		<div class="attendance-container">
			<!-- attendance  -->
			<div v-if="currentSessions">
				<!-- heading  -->
				<h1>Attendance</h1>

				<!-- day nav  -->
				<b-field label="Date">
					<b-select placeholder="Select a date" @input="handleSelect" v-model="selectedSession">
						<option v-for="option in attendanceDates" :value="option" :key="option.es_sessionid">
							{{ formatDate(option.es_startdatetime) }}
						</option>
					</b-select>
				</b-field>

				<!-- session attendance -->
				<router-view />
			</div>

			<!-- loader  -->
			<Loader v-else />
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import Loader from "../components/Loader.vue"
import router from "../router/router.js"
import moment from "moment"

export default {
	name: "Attendance",
	components: { Loader },
	computed: mapGetters([
		"enrollmentRoles",
		"currentCohort",
		"loadingCohort",
		"enrollmentLoading",
		"allSessions",
		"currentSessions",
		"sessionAttendanceLoading",
		"sessionAttendance"
	]),
	data() {
		return {
			attendanceDates: null,
			selectedSession: null
		}
	},
	watch: {
		allSessions: function() {
			this.makeCurrentSessions()
		},
		currentSessions: function() {
			this.makeAttendanceDates()
		},
		currentCohort: function() {
			if (!this.allSessions) {
				this.getSessions()
			} else {
				this.makeCurrentSessions()
			}
		},
		attendanceDates: function() {
			if (this.$route.params.sessionid && !this.selectedSession) {
				this.selectedSession = this.attendanceDates.find(date => {
					return date.es_sessionid === this.$route.params.sessionid
				})
			}
		}
	},
	mounted() {
		this.changeCurrentPage("Attendance")

		//load cohort if missing
		if (this.$route.params.cohortid && (!this.currentCohort || this.$route.params.cohortid != this.currentCohort.es_cohortid)) {
			this.fetchCohortData({
				es_cohortid: this.$route.params.cohortid,
				preventNav: true
			})
		}

		// set selected session from route if exists
		if (!this.selectedSession && this.attendanceDates && this.$route.params.sessionid) {
			this.selectedSession = this.attendanceDates.find(date => {
				date.es_sessionid === this.$route.params.sessionid
			})
		}

		// sessions
		if (!this.allSessions && this.currentCohort) {
			this.getSessions()
		} else if (this.allSessions && this.allSessions[0] && this.allSessions[0]._es_cohort_value != this.currentCohort.es_cohortid) {
			this.getSessions()
		}

		//check current sessions are for current cohort
		if (this.currentSessions && this.currentSessions[0]) {
			if (this.currentSessions[0]._es_cohort_value != this.currentCohort.es_cohortid) {
				this.makeCurrentSessions()
			}
		}

		if (this.currentSessions && !this.attendanceDates) {
			this.makeAttendanceDates()
		}
	},
	methods: {
		...mapActions(["changeCurrentPage", "fetchCohortData", "makeCurrentSessions", "getSessions", "getSessionAttendance", "patchAttendance"]),
		handleSelect(selection) {
			this.getSessionAttendance({
				sessionId: selection.es_sessionid,
				cohortId: this.currentCohort.es_cohortid
			})

			router.push({
				path: `/admin/${this.currentCohort.es_cohortid}/attendance/${selection.es_sessionid}`
			})
		},
		handleClick(attendance, status) {
			if (attendance.es_status === status) {
				return
			}
			this.patchAttendance({
				attendance: attendance,
				es_status: status
			})
		},
		formatDate(date) {
			if (!date) {
				return null
			}
			let formattedDate = moment(date)
				.startOf("day")
				.format("ddd MMM D, YYYY")
			if (formattedDate) {
				return formattedDate
			}
			return null
		},
		makeAttendanceDates() {
			let sessionDates = []

			this.currentSessions.forEach(session => {
				if (session._es_cohort_value != this.currentCohort.es_cohortid) {
					//wrong cohort
				} else {
					//add sessions that are from this cohort
					if (session.es_startdatetime && session.es_enddatetime) {
						sessionDates.push(session)
					}
				}
			})

			this.attendanceDates = sessionDates
		}
	}
}
</script>

<style lang="scss">
#app .attendance-page {
	// background-color: #26a0da;
	// background-image: linear-gradient(to bottom right, #2aa8df, #1479bf);
	background-color: #0f172a;
	display: flex;
	// align-items: center;
	justify-content: center;
	overflow-y: auto;
	padding-top: 1rem;

	.flex-row {
		align-items: center;
	}

	.attendance-container {
		background-color: white;
		width: 800px;
		border-radius: 5px;
		height: fit-content;
		padding: 2rem;
		margin-bottom: 1rem;

		.es-my-lg {
			margin-top: 2rem;
			margin-bottom: 2rem;
		}

		.columns > div {
			margin-left: 0.5rem;
			margin-right: 0.5rem;
		}

		.columns > button {
			margin-left: 0.5rem;
			margin-right: 0.5rem;
		}

		button {
			padding-bottom: calc(0.5em - 1px);
			padding-left: 1em;
			padding-right: 1em;
			padding-top: calc(0.5em - 1px);
		}
	}
}
</style>
