<template>
    <div id="quiz-header" class="quiz-header-component">
        <div class="max-1080">
            <!-- topic name -->
            <div
                class="padding-1 padding-left-2"
            >{{currentTopic.es_displayname ? currentTopic.es_displayname : currentTopic.es_name}}</div>

            <!-- progress -->
            <progress-bar :key="currentAttempt+'progressbar'" />
            <!-- timer -->
            <timer />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Timer from "./Timer";
import ProgressBar from "./ProgressBar";

export default {
    name: "QuizHeader",
    computed: mapGetters(["currentTopic", "currentAttempt"]),
    components: {
        Timer,
        ProgressBar
    },
    methods: {
        ...mapActions(["updateCurrentTopic"])
    }
};
</script>

<style lang="scss">
#app .quiz-header-component {
    height: 4rem;
    background-color: #eaf5ff;
    display: flex;
    justify-content: center;

    .padding-1 {
        padding: 1rem;
    }

    .padding-left-2 {
        padding-left: 2rem;
    }

    .max-1080 {
        max-width: 1080px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
</style>