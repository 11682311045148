<template>
	<div class="grades-percentages-component">
		<grade-percent class="margin-2 padding-1 border-blue shadow width-8 height-8-5" />
		<attendance-percent class="margin-2 padding-1 border-blue shadow width-8 height-8-5" />
		<on-time-percent class="margin-2 padding-1 border-blue shadow width-8 height-8-5" />
	</div>
</template>

<script>
import GradePercent from "./GradePercent.vue"
import AttendancePercent from "./AttendancePercent.vue"
import OnTimePercent from "./OnTimePercent.vue"
import Loader from "./Loader.vue"

export default {
	name: "GradesPercentages",
	components: { Loader, GradePercent, AttendancePercent, OnTimePercent }
}
</script>

<style lang="scss">
#app .grades-percentages-component {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;

	.margin-2 {
		margin: 1rem 1rem 1rem 1rem;
	}

	.padding-1 {
		padding: 1rem;
	}

	.border-blue {
		border: 0.15rem solid #2da8df;
	}

	.shadow {
		box-shadow: 0 0.125em 0.25em rgba(10, 10, 10, 0.65);
	}

	.width-8 {
		width: 11rem;
	}

	.height-8-5 {
		height: 11rem;
	}
}
</style>
