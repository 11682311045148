<template>
  <div
    v-if="loadedQuestion"
    class="half-padding gray-background content-question-component"
  >
    <!-- multiple choice -->
    <span v-if="loadedQuestion.questionType == 717580003">
      <p class="question-margin-bottom">
        {{ this.loadedQuestion.questionText }}
      </p>
      <div class="choices-block">
        <b-radio
          v-for="choice in mixedChoices"
          :key="choice.es_topicquestionchoice_id"
          v-model="radio"
          :native-value="choice.es_text"
          :name="choice.es_topicquestionchoiceid"
          @input="handleChange(choice)"
        >
          <span class="choice-container">
            <div class="choice-text">
              <span>{{ choice.es_text }}</span>
            </div>
            <div
              v-if="checked && !loading && radio == choice.es_text"
              class="validity-icon"
            >
              <vue-fontawesome
                v-if="correctAnswer"
                icon="check"
                size="lg"
                color="#50e2c1"
              />

              <vue-fontawesome v-else icon="times" size="lg" color="#e94542" />
            </div>
            <div v-else class="validity-icon empty" />
          </span>
        </b-radio>
      </div>

      <span class="flex align-center">
        <b-button v-if="loading" loading type="is-info">Check answer</b-button>
        <b-button
          v-else-if="checked"
          disabled
          :type="correctAnswer ? 'is-info' : 'is-warning'"
          :outlined="correctAnswer"
          >Check answer</b-button
        >

        <b-button
          v-else
          @click="handleClick"
          :type="correctAnswer || !checked ? 'is-info' : 'is-warning'"
          :outlined="correctAnswer"
          >Check answer</b-button
        >
      </span>

      <div class="message-box">{{ message }}</div>
    </span>

    <!-- multiple select -->
    <span v-else-if="loadedQuestion.questionType == 717580004">
      <multiple-select-content-question
        class="quiz-question"
        :questionId="loadedQuestion.es_topicquestionid"
        :choices="choices"
        :key="loadedQuestion.es_topicquestionid"
      />
    </span>

    <!-- open response -->
    <span v-else-if="loadedQuestion.questionType == 717580006">
      <open-response-content-question
        class="quiz-question"
        :questionId="loadedQuestion.es_topicquestionid"
        :choices="choices"
        :key="loadedQuestion.es_topicquestionid"
      />
    </span>

    <!-- upload -->
    <span v-else-if="loadedQuestion.questionType == 717580007">
      <upload-content-question
        class="quiz-question"
        :question="choices[0].es_topicquestion"
      />
    </span>

    <!-- fill in the blank -->
    <span v-else>
      <p class="question-margin-bottom">
        {{ this.loadedQuestion.questionText }}
      </p>
      <div class="choices-block">
        <b-field>
          <template slot="label">
            <div class="flex">
              Answer
              <div v-if="checked && !loading" class="validity-icon">
                <vue-fontawesome
                  v-if="correctAnswer"
                  icon="check"
                  size="lg"
                  color="#50e2c1"
                />

                <vue-fontawesome
                  v-else
                  icon="times"
                  size="lg"
                  color="#e94542"
                />
              </div>
            </div>
          </template>
          <b-input
            v-model="answer"
            placeholder="Type your answer here"
            @input="handleChange"
          ></b-input>
        </b-field>
      </div>
      <span class="flex align-center">
        <b-button v-if="loading" loading type="is-info">Check answer</b-button>
        <b-button
          v-else-if="checked"
          disabled
          :type="correctAnswer ? 'is-info' : 'is-warning'"
          >Check answer</b-button
        >
        <b-button
          v-else
          @click="handleFitbClick"
          :type="correctAnswer || !checked ? 'is-info' : 'is-warning'"
          :outlined="correctAnswer"
          >Check answer</b-button
        >
      </span>

      <div class="message-box">{{ message }}</div>
    </span>
  </div>
  <div v-else>Question loading..</div>
</template>

<script>
import { mapGetters } from "vuex"
import axios from "axios"
import EventBus from "../event-bus"
import MultipleSelectContentQuestion from "./MultipleSelectContentQuestion"
import OpenResponseContentQuestion from "./OpenResponseContentQuestion"
import UploadContentQuestion from "./UploadContentQuestion"

export default {
  name: "ContentQuestion",
  props: ["choices"],
  components: {
    MultipleSelectContentQuestion,
    OpenResponseContentQuestion,
    UploadContentQuestion
  },
  computed: {
    ...mapGetters(["questionChoices", "baseUrl"]),
    mixedChoices: function() {
      let arr = this.choices
      if (arr.length == 2) {
        //probably true/false, so don't mix answers
        return arr
      }
      var currentIndex = arr.length,
        temporaryValue,
        randomIndex
      // While there remain elements to shuffle...
      while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex)
        currentIndex -= 1
        // And swap it with the current element.
        temporaryValue = arr[currentIndex]
        arr[currentIndex] = arr[randomIndex]
        arr[randomIndex] = temporaryValue
      }
      return arr
    }
  },
  data() {
    return {
      loadedQuestion: null,
      radio: null,
      checked: null,
      correctAnswer: false,
      answer: null,
      message: null,
      active: false,
      loading: false,
      checkboxChoices: []
    }
  },
  mounted() {
    this.getQuestion()
  },
  methods: {
    handleChange(choice) {
      this.checked = null
      this.correctAnswer = false
      this.message = ""
      EventBus.$emit("answered", {
        questionid: this.choices[0].es_topicquestion.es_topicquestionid,
        correct: false,
        choiceid: choice.es_topicquestionchoiceid,
        text: choice.es_text,
        type: this.choices[0].es_topicquestion.es_type
      })
    },
    handleClick() {
      //find choice that matches radio if radio has been selected
      if (this.radio) {
        this.loading = true
        let choice = this.choices.find(answer => answer.es_text == this.radio)

        let choiceIndex = choice

        this.checked = choiceIndex

        let isCorrect = this.getTopicQuestionChoice(
          this.checked.es_topicquestionchoiceid,
          this.checked.es_topicquestion._es_topic_value
        )
      } else {
        this.message = "Please select an answer choice."
        this.correctAnswer = false
        this.checked = true
      }
    },
    handleResponseChecking(isCorrect, es_message) {
      if (isCorrect) {
        this.correctAnswer = true
        EventBus.$emit("answered", {
          questionid: this.choices[0].es_topicquestion.es_topicquestionid,
          correct: true,
          choiceid: this.checked.es_topicquestionchoiceid || null,
          text: this.radio || this.answer,
          type: this.choices[0].es_topicquestion.es_type,
          questionText: this.choices[0].es_topicquestion.es_text
        })
      } else {
        EventBus.$emit("answered", {
          questionid: this.choices[0].es_topicquestion.es_topicquestionid,
          correct: false,
          choiceid: this.checked.es_topicquestionchoiceid || null,
          text: this.radio || this.answer,
          type: this.choices[0].es_topicquestion.es_type,
          questionText: this.choices[0].es_topicquestion.es_text
        })
      }
      //display message
      this.message = es_message
      this.loading = false
      this.active = true
    },
    getTopicQuestionChoice(topicquestionchoiceid, topicid) {
      axios
        .get(
          this.baseUrl +
            `api/questionchoice?topicquestionchoiceid=${topicquestionchoiceid}&topicid=${topicid}`
        )
        .then(response => {
          if (response.data.value[0].es_iscorrect) {
            this.handleResponseChecking(true, response.data.value[0].es_message)
          } else {
            this.handleResponseChecking(
              false,
              response.data.value[0].es_message
            )
          }
        })
        .catch(err => {
          console.log("api/questionchoice error", err)
          this.handleResponseChecking(false, err)
        })
    },
    handleFitbClick() {
      this.loading = true
      this.checked = true

      let isCorrect = this.getFitbTopicQuestionChoice(
        this.choices[0].es_topicquestionchoiceid,
        this.choices[0].es_topicquestion._es_topic_value,
        this.choices[0].es_topicquestion.es_topicquestionid
      )
    },
    getFitbTopicQuestionChoice(
      topicquestionchoiceid,
      topicid,
      topicquestionid
    ) {
      axios
        .get(
          this.baseUrl +
            `api/fitbquestionchoice?topicquestionchoiceid=${topicquestionchoiceid}&topicid=${topicid}&text=${this.answer}&topicquestionid=${topicquestionid}`
        )
        .then(response => {
          if (response.data.es_iscorrect) {
            this.handleResponseChecking(true, response.data.es_message)
          } else {
            this.handleResponseChecking(false, "That's incorrect")
          }
        })
        .catch(err => {
          console.log("api/questionchoice error", err)
          this.handleResponseChecking(false, err)
        })
    },
    getQuestion() {
      //get question info out of one of the choices (they're nested objects are the same)
      let filteredObject = {}
      filteredObject.questionText = this.choices[0]["es_topicquestion"][
        "es_text"
      ]
      filteredObject.questionType = this.choices[0]["es_topicquestion"][
        "es_type"
      ]
      this.loadedQuestion = filteredObject
    }
  }
}
</script>

<style lang="scss">
#app .content-question-component {
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  padding: 0.5rem;
  margin-left: 1rem;

  .message-box {
    margin: 1rem;
    border: 1px solid #2ca8df;
    height: 6rem;
    overflow-y: auto;
    padding: 0.5rem 1rem;
    background-color: #ced9fd;
  }

  .gray-background {
    background-color: #dde4fb;
  }

  .flex {
    display: flex;
  }

  .align-center {
    align-items: center;
  }

  .choice-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .validity-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 24px;
    margin-top: 0px;
    padding-left: 12px;
  }

  .empty {
    padding: 12px;
  }

  .b-radio.radio {
    margin-bottom: 0.5rem;
  }

  .button {
    margin-left: 1rem;
  }

  .choices-block .b-tooltip {
    line-height: 1.5rem;
  }

  .checkbox {
    margin-bottom: 0.5rem;
    line-height: 1.5rem;
  }

  .b-checkbox.checkbox + .checkbox {
    margin-left: 0rem;
  }
  label.b-radio.radio {
    width: 100%;
  }

  .control-label {
    width: 100%;
  }

  .choice-text {
    width: calc(100% - 24px);
    height: auto;
  }
}
</style>
