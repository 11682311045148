import axios from 'axios';
import request from './request';

export const checkMultipleSelectQuestion = (data) =>
    request({
        url: `/checkmsquestion`,
        method: 'post',
        data: data,
        headers: { 'Content-Type': 'application/json' },
    })


export const checkOpenResponseQuestion = (data) =>
    request({
        url: `/checkorquestion`,
        method: 'post',
        data: data,
        headers: { 'Content-Type': 'application/json' },
    })

