<template>
    <div
        v-if="loadedQuestion && shuffledAnswerChoices"
        class="quiz-question-component"
        :key="questionId"
    >
        <!-- multiple choice -->
        <span v-if="loadedQuestion.es_type==717580003">
            <p
                class="question-margin-bottom"
            >{{this.shuffledAnswerChoices[0].es_topicquestion.es_text}}</p>
            <div class="choices-block">
                <b-radio
                    v-for="(choice) in shuffledAnswerChoices"
                    :key="choice.es_topicquestionchoice_id"
                    v-model="radio"
                    :native-value="choice.es_text"
                    :name="choice.es_topicquestionchoiceid"
                    @input="handleChange(choice)"
                >
                    <span class="choice-container">
                        <div class="choice-text">{{choice.es_text}}</div>
                        <div class="validity-icon empty" />
                    </span>
                </b-radio>
            </div>
        </span>

        <!-- fill in the blank -->
        <span v-else>
            <p class="question-margin-bottom">{{this.loadedQuestion.es_text}}</p>
            <div class="choices-block">
                <b-field>
                    <template slot="label">
                        <div class="flex">Answer</div>
                    </template>
                    <b-input
                        v-model="answer"
                        placeholder="Type your answer here"
                        @input="handleFitbChange(answer)"
                    ></b-input>
                </b-field>
            </div>
        </span>
    </div>
    <Loader v-else />
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import EventBus from "../event-bus";
import Loader from "./Loader";

export default {
    name: "QuizQuestion",
    props: ["questionId"],
    components: { Loader },
    computed: {
        ...mapGetters([
            "questionChoices",
            "currentQuestion",
            "currentQuestionIndex",
            "previousSelections",
            "continuingQuiz"
        ])
    },
    data() {
        return {
            loadedQuestion: null,
            radio: null,
            correctAnswer: false,
            answer: null,
            filteredChoices: null,
            shuffledAnswerChoices: null
        };
    },
    watch: {
        currentQuestion: function() {
            this.radio = null;
            this.filterQuestion();
        }
    },
    mounted() {
        this.filterQuestion();
        this.checkPrevious();
    },
    methods: {
        ...mapActions(["answerQuizQuestion"]),
        checkPrevious() {
            if (this.previousSelections && this.continuingQuiz) {
                //find selection for this question

                let selection = this.findSelection();

                //save choice
                let choice = selection.es_choice_text;

                //set answer to choice
                if (choice && choice != "No answer selected") {
                    if (this.loadedQuestion.es_type == 717580003) {
                        this.radio = choice;
                    } else {
                        this.answer = choice;
                    }
                    this.emitAnswered();
                }
            }
        },
        emitAnswered() {
            if (this.loadedQuestion.es_type == 717580003) {
                this.handleChange(this.findChoice());
            } else {
                this.handleFitbChange(this.answer);
            }
        },
        findChoice() {
            return this.shuffledAnswerChoices.find(choice => {
                return choice.es_text == this.radio;
            });
        },
        findSelection() {
            if (this.currentQuestion) {
                return this.previousSelections.find(selection => {
                    return (
                        this.currentQuestion.es_question_id ==
                        selection.es_question_id
                    );
                });
            } else if (this.questionId) {
                return this.previousSelections.find(selection => {
                    return this.questionId == selection.es_question_id;
                });
            } else {
                return null;
            }
        },
        filterQuestion() {
            //find an answer choice from this topic with the same questionid
            let questionChoice = this.questionChoices.find(choice => {
                return (
                    choice.es_topicquestion.es_topicquestionid ==
                    this.questionId
                );
            });

            //set question in data equal to that choices question (it is expanded)
            this.loadedQuestion = questionChoice.es_topicquestion;

            //filter choices by questionid
            this.filteredChoices = this.filterChoices();

            //shuffle the filtered answer choices
            this.shuffledAnswerChoices = this.shuffleChoices(
                this.filteredChoices
            );

            //submit an unanswered question (placeholder selection in case student chooses no answer)
            this.submitUnansweredQuestion();
        },
        submitUnansweredQuestion() {
            this.answerQuizQuestion({
                es_topicquestionid: this.shuffledAnswerChoices[0]
                    .es_topicquestion.es_topicquestionid,
                es_text: this.loadedQuestion.es_text,
                es_type: this.shuffledAnswerChoices[0].es_topicquestion.es_type,
                es_sort: this.currentQuestionIndex,
                choices: null
            });
        },
        filterChoices() {
            //filters answer choices by current questions
            let filteredChoices = this.questionChoices.filter(
                choice =>
                    this.questionId ==
                    choice.es_topicquestion.es_topicquestionid
            );
            return filteredChoices;
        },
        shuffleChoices(filteredChoices) {
            //shuffles the filtered choices
            var currentIndex = filteredChoices.length,
                temporaryValue,
                randomIndex;

            // While there remain elements to shuffle...
            while (0 !== currentIndex) {
                // Pick a remaining element...
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex -= 1;

                // And swap it with the current element.
                temporaryValue = filteredChoices[currentIndex];
                filteredChoices[currentIndex] = filteredChoices[randomIndex];
                filteredChoices[randomIndex] = temporaryValue;
            }

            return filteredChoices;
        },
        handleChange(choice) {
            //emits to quizbutton and adds object to array this.correctQuestions which is passed to the server
            //when the quiz is submitted
            let choices = [
                {
                    es_topicquestionchoiceid: choice.es_topicquestionchoiceid,
                    es_topicquestionid: this.shuffledAnswerChoices[0]
                        .es_topicquestion.es_topicquestionid,
                    es_text: choice.es_text,
                    file: null
                }
            ];
            this.answerQuizQuestion({
                es_topicquestionid: this.shuffledAnswerChoices[0]
                    .es_topicquestion.es_topicquestionid,
                choices: choices,
                es_type: this.shuffledAnswerChoices[0].es_topicquestion.es_type,
                es_text: this.loadedQuestion.es_text,
                es_sort: this.currentQuestionIndex
            });
        },
        handleFitbChange(choice) {
            //emits to quizbutton and adds object to array this.correctQuestions which is passed to the server
            //when the quiz is submitted
            let choices = [
                {
                    es_topicquestionchoiceid: null,
                    es_topicquestionid: this.shuffledAnswerChoices[0]
                        .es_topicquestion.es_topicquestionid,
                    es_text: choice,
                    file: null
                }
            ];

            this.answerQuizQuestion({
                es_topicquestionid: this.shuffledAnswerChoices[0]
                    .es_topicquestion.es_topicquestionid,
                choices: choices,
                es_type: this.shuffledAnswerChoices[0].es_topicquestion.es_type,
                es_text: this.loadedQuestion.es_text,
                es_sort: this.currentQuestionIndex
            });
        }
    }
};
</script>

<style lang='scss'>
#app .quiz-question-component {
    -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    padding: 0.5rem;
    margin-left: 1rem;
    background-color: #dde4fb;
    margin-right: 5rem;
    margin-top: 2rem;
    margin-bottom: 1rem;

    .message-box {
        margin: 1rem;
        border: 1px solid #2ca8df;
        height: 6rem;
        overflow-y: auto;
        padding: 0.5rem 1rem;
        background-color: #ced9fd;
    }

    .flex {
        display: flex;
    }

    .align-center {
        align-items: center;
    }

    .choice-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .validity-icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 24px;
        margin-top: 0px;
        padding-left: 12px;
    }

    .empty {
        padding: 12px;
    }

    .b-radio.radio {
        margin-bottom: 0.5rem;
    }

    .button {
        margin-left: 1rem;
    }

    .choices-block .b-tooltip {
        line-height: 1.5rem;
    }
}
</style>