<template>
	<div>
		<div class="main-content" v-if="cohortsArray">
			<div class="columns is-mobile is-gapless course-content">
				<div class="column is-one-third content-index" :class="isIndexCollapsed ? 'collapsed' : null">
					<course-menu />
					<course-content />
				</div>
				<div
					id="course-content-container"
					class="column course-content-content content-window overflow-x-hidden"
					:class="currentPage === 'Course Content' || currentPage === 'Schedule' ? null : 'y-scroll'"
				>
					<router-view />
				</div>
			</div>
		</div>
		<Loader v-else />
	</div>
</template>

<script>
import CurrentLesson from "../components/CurrentLesson.vue"
import ContentNav from "../components/ContentNav.vue"
import Loader from "../components/Loader.vue"
import { mapGetters, mapActions } from "vuex"
import EventBus from "../event-bus"
import router from "../router/router.js"
import CourseMenu from "../components/CourseMenu"
import CourseContent from "../components/CourseContent"

export default {
	name: "LMS",
	components: {
		ContentNav,
		CurrentLesson,
		Loader,
		CourseContent,
		CourseMenu
	},
	data() {
		return {
			contentClass: "column is-one-third"
		}
	},
	created() {
		// this.changeCurrentPage(this.$router.history.current.name);
	},
	computed: mapGetters([
		"cohortsArray",
		"authentication",
		"incompleteCohort",
		"incompleteEnrollment",
		"isIndexCollapsed",
		"currentCohort",
		"currentModule",
		"currentPage",
		"cohortCards",
		"archivedCards"
	]),
	mounted() {
		//load and nav to cohort
		if (
			this.$route.params.topicid &&
			this.$route.params.moduleid &&
			this.$route.params.cohortid &&
			(!this.currentCohort || this.$route.params.cohortid != this.currentCohort.es_cohortid)
		) {
			this.fetchCohortData({ es_cohortid: this.$route.params.cohortid })
		}

		//load and no nav to cohort
		if (
			!this.$route.params.topicid &&
			!this.$route.params.moduleid &&
			this.$route.params.cohortid &&
			(!this.currentCohort || this.$route.params.cohortid != this.currentCohort.es_cohortid)
		) {
			this.fetchCohortData({
				es_cohortid: this.$route.params.cohortid,
				preventNav: true
			})
		}
	},
	watch: {
		isIndexCollapsed: function() {
			if (this.isIndexCollapsed) {
				this.contentClass = "column is-one-third collapsed"
			} else {
				this.contentClass = "column is-one-third"
			}
		},
		$route(to, from) {
			if (!to.name) {
				// let topic = this.currentModule.topics.find(
				//     topic => topic.es_topicid == this.$route.params.topicid
				// );
				// // this.updateCurrentTopic(topic);
			} else {
				this.changeCurrentPage(to.name)
			}
		}
	},
	methods: {
		...mapActions(["changeCurrentPage", "updateCurrentModule", "updateCurrentTopic", "fetchCohortData"])
	}
}
</script>

<style lang="scss">
#course-content-container {
	position: relative;
}

.collapsed {
	width: 0 !important;
	transition: width 0.5s;
}

.is-one-third {
	transition: width 0.5s;
}

.main-content {
	border: 1px black solid;
	height: 100% !important;
	overflow-y: hidden;
}

.course-content {
	height: 100%;
	/* background-color: #26a0da; */
	position: relative;
}

.content-index {
	background-color: #26a0da;
	// background-image: linear-gradient(to bottom right, #2aa8df, #1479bf);
	background-color: #0f172a;
	margin-bottom: 2rem;
	position: relative;
}

.course-content-menu {
	height: 100% !important;
	/* overflow-y: hidden; */
}
.course-content-content {
	height: 100% !important;
	overflow-y: hidden;
}

.y-scroll {
	overflow-y: scroll;
}

.overflow-x-hidden {
	overflow-x: hidden;
}
</style>
