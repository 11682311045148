<template>
    <!-- <img :src="thumbnail" alt="Placeholder image" /> -->
    <div class="add-cohort-image-component">
        <figure v-if="image && image != 'none'" class="image is-4by3">
            <img
                class="cohort-image"
                :src="`data:${cohort.image.mimetype};base64,${cohort.image.documentbody}`"
            />
        </figure>

        <figure v-else-if="image && image == 'none'" class="image is-4by3">
            <img class="cohort-image default" :src="thumbnail" />
        </figure>
        <figure v-else class="image margin-92-5">
            <Loader />
        </figure>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Loader from "./Loader";
import thumbnail from "../assets/academy-image-min.jpg";

export default {
    name: "AddCohortCardImage",
    components: { Loader },
    props: ["cohort"],
    computed: {
        ...mapGetters([
            "allCohorts",
            "currentCohort",
            "cohortCards",
            "freeCohorts"
        ])
    },
    data() {
        return {
            thumbnail: thumbnail,
            image: null
        };
    },
    watch: {
        //if cohorts changed, it is because the backend loaded a cohort
        allCohorts: function() {
            this.checkCohortImage();
        },
        //if cohorts changed, it is because the backend loaded a cohort
        freeCohorts: function() {
            this.checkCohortImage();
        }
    },
    mounted() {
        //get cohorts if don't have
        if (this.cohort && !this.cohort.image) {
            let data = {
                cohortid: this.cohort.es_cohortid,
                cardType: "freeCohorts"
            };
            this.getCohortImage(data);
        } else {
            this.image = this.cohort.image;
        }
    },
    methods: {
        ...mapActions(["getCohortImage"]),
        checkCohortImage() {
            //Check if the localCohort's image is the same as VUEX's state. Change it if needed.
            let localCohort = this.freeCohorts.find(cohort => {
                return cohort.es_cohortid == this.cohort.es_cohortid;
            });
            if (this.image != localCohort.image) {
                this.image = localCohort.image;
            }
        }
    }
};
</script>

<style lang="scss">
#app .add-cohort-image-component {
    .margin-92-5 {
        margin: 92.5px 0;
    }
}
</style>