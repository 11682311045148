import request from "./request"

export const getClassmates = data =>
	request({
		url: `classmates?cohortid=${data}`,
		method: "get",
		headers: { "X-Requested-With": "XMLHttpRequest" }
	})

export const getRoster = data =>
	request({
		url: `roster?cohortid=${data}&page=1`,
		method: "get",
		headers: { "X-Requested-With": "XMLHttpRequest" }
	})

export const getRosterPage = data =>
	request({
		url: `/rosterpage`,
		method: "post",
		data: { url: data },
		headers: { "Content-Type": "application/json" }
	})

export const getClassmateImage = data =>
	request({
		url: `classmateimage?cohortid=${data.cohortid}&contactid=${data.contactid}`,
		method: "get",
		headers: { "X-Requested-With": "XMLHttpRequest" }
	})
