<template>
    <div v-if="loadedQuestion" class="half-padding gray-background ms-content-question-component">
        <!-- multiple select -->
        <!-- question text -->
        <p class="question-margin-bottom">{{this.loadedQuestion.questionText}}</p>

        <!-- choices -->
        <div class="choices-block">
            <b-checkbox
                v-for="(choice) in mixedChoices"
                :key="choice.es_topicquestionchoice_id"
                v-model="checkboxChoices"
                :native-value="choice.es_text"
                :name="choice.es_topicquestionchoiceid"
                @input="handleChange(choice)"
            >
                <div class="field">
                    <div class="choice-container">
                        <div class="choice-text">{{choice.es_text}}</div>
                    </div>
                </div>
            </b-checkbox>

            <!-- selections -->
            <div class="student-choices">
                <p class="content">
                    <b>Selection:</b>
                    {{ checkboxChoices }}
                </p>

                <!-- icon -->
                <div v-if="checked && !loading" class="validity-icon">
                    <vue-fontawesome v-if="correctAnswer" icon="check" size="lg" color="#50e2c1" />

                    <vue-fontawesome v-else icon="times" size="lg" color="#e94542" />
                </div>
                <div v-else class="validity-icon empty" />
            </div>
        </div>

        <!-- check answer button -->
        <span class="flex align-center">
            <b-button v-if="loading" loading type="is-info">Check answer</b-button>
            <b-button
                v-else-if="checked"
                disabled
                :type="correctAnswer ?'is-info':'is-warning'"
                :outlined="correctAnswer"
            >Check answer</b-button>

            <b-button
                v-else
                @click="handleClick"
                :type="correctAnswer ||!checked ?'is-info':'is-warning'"
                :outlined="correctAnswer"
            >Check answer</b-button>
        </span>

        <!-- message box -->
        <div class="message-box">
            <span v-if="checked && !loading">{{correctAnswer? "Correct" : "Incorrect"}}</span>
        </div>
    </div>
    <div v-else>Question loading..</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import EventBus from "../event-bus";
import { checkMultipleSelectQuestion } from "../api/question.js";
import router from "../router/router.js";

export default {
    name: "MultipleSelectContentQuestion",
    props: ["choices"],
    computed: {
        ...mapGetters([
            "questionChoices",
            "baseUrl",
            "currentCohort",
            "checkingQuestion"
        ]),
        mixedChoices: function() {
            let arr = this.choices;
            if (arr.length == 2) {
                //probably true/false, so don't mix answers
                return arr;
            }
            var currentIndex = arr.length,
                temporaryValue,
                randomIndex;
            // While there remain elements to shuffle...
            while (0 !== currentIndex) {
                // Pick a remaining element...
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex -= 1;
                // And swap it with the current element.
                temporaryValue = arr[currentIndex];
                arr[currentIndex] = arr[randomIndex];
                arr[randomIndex] = temporaryValue;
            }
            return arr;
        }
    },
    data() {
        return {
            loadedQuestion: null,
            radio: null,
            checked: null,
            correctAnswer: false,
            answer: null,
            message: null,
            active: false,
            loading: false,
            checkboxChoices: []
        };
    },
    mounted() {
        this.getQuestion();
    },
    methods: {
        ...mapActions(["updateCheckingQuestion"]),
        handleChange(choice) {
            this.checked = null;
            this.correctAnswer = false;
            this.message = "";
            EventBus.$emit("answered", {
                questionid: this.choices[0].es_topicquestion.es_topicquestionid,
                correct: false,
                choiceid: choice.es_topicquestionchoiceid,
                text: choice.es_text,
                type: this.choices[0].es_topicquestion.es_type
            });
        },
        handleClick() {
            //find student's choices
            if (this.checkboxChoices) {
                this.loading = true;
                this.checked = true;

                //filter for choice objects
                let studentChoices = this.choices.filter(answer =>
                    this.checkboxChoices.includes(answer.es_text)
                );

                //make data object
                let data = {
                    choices: studentChoices,
                    es_topicquestionid:
                        studentChoices[0].es_topicquestion.es_topicquestionid,
                    es_topicid:
                        studentChoices[0].es_topicquestion._es_topic_value,
                    es_cohortid: this.currentCohort.es_cohortid
                };

                this.checkQuestion(data);
            } else {
                this.message = "Please select an answer choice.";
                this.correctAnswer = false;
                this.checked = true;
            }
        },
        checkQuestion(data) {
            this.updateCheckingQuestion(true);

            checkMultipleSelectQuestion(data)
                .then(response => {
                    if (response.data === true) {
                        this.correctAnswer = true;

                        EventBus.$emit("answered", {
                            questionid: this.choices[0].es_topicquestion
                                .es_topicquestionid,
                            correct: true,
                            choiceid: null,
                            text: null,
                            type: this.choices[0].es_topicquestion.es_type,
                            questionText: this.choices[0].es_topicquestion
                                .es_text
                        });
                    } else {
                        this.correctAnswer = false;

                        EventBus.$emit("answered", {
                            questionid: this.choices[0].es_topicquestion
                                .es_topicquestionid,
                            correct: false,
                            choiceid: null,
                            text: null,
                            type: this.choices[0].es_topicquestion.es_type,
                            questionText: this.choices[0].es_topicquestion
                                .es_text
                        });
                    }

                    this.loading = false;
                })
                .catch(err => {
                    this.loading = false;
                    console.log("api/checkquestion error:", err);
                    this.updateCheckingQuestion(false);
                    this.setErrorMessage("Error checking question.");
                    router.push({ path: "/error" });
                });
        },
        getQuestion() {
            //get question info out of one of the choices (they're nested objects are the same)
            let filteredObject = {};
            filteredObject.questionText = this.choices[0]["es_topicquestion"][
                "es_text"
            ];
            filteredObject.questionType = this.choices[0]["es_topicquestion"][
                "es_type"
            ];
            this.loadedQuestion = filteredObject;
        }
    }
};
</script>

<style lang='scss'>
#app .ms-content-question-component {
    .student-choices {
        display: flex;
    }
}
</style>