<template>
    <div class="progress-bar-component" v-if="quizQuestionsArr">
        <!-- non lab-quiz -->
        <div
            class="progress-fraction"
            v-if="currentTopic.es_type!=717580004"
        >{{currentQuestionIndex+adjuster}}/{{currentTopic.es_totalquizquestions || quizQuestionsArr.length}}</div>

        <!-- lab quiz only -->
        <div
            class="progress-fraction"
            v-else
        >{{answered}}/{{currentTopic.es_totalquizquestions || quizQuestionsArr.length}}</div>

        <div
            id="shadow-progress"
            class="progress-background"
            :style="{'box-shadow': returnShadow()}"
        >
            <!-- <div id="progress" class="progress" :style="{width: returnWidth()}"></div> -->
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import EventBus from "../event-bus";
import moment from "moment";

export default {
    name: "ProgressBar",
    computed: mapGetters([
        "currentTopic",
        "currentEnrollment",
        "currentAttempt",
        "currentCohort",
        "currentQuestionIndex",
        "quizQuestionsArr",
        "questionChoices",
        "studentSelectionsArr",
        "takingQuiz",
        "continuingQuiz",
        "studentQuizSelections"
    ]),
    data() {
        return {
            adjuster: 1,
            answered: 0,
            pixels: null
        };
    },
    components: {},
    watch: {
        studentQuizSelections: function() {
            this.countAnswered();
        },
        currentAttempt: function() {
            if (this.currentAttempt && !this.continuingQuiz) {
                this.postEmptyLabQuiz();
            }
        },
        quizQuestionsArr: function() {
            this.setAdjuster();
        }
    },
    mounted() {
        EventBus.$on("fill-progress-bar", () => {
            this.fillBar();
        });

        //make question array for labquizzes
        if (this.currentTopic.es_type == 717580004) {
            this.makeLabQuizArr();
        }

        //set adjuster
        this.setAdjuster();
    },
    methods: {
        ...mapActions([
            "updateCurrentTopic",
            "setQuizQuestionsArr",
            "submitSelections",
            "updateQuizFinished"
        ]),
        setAdjuster() {
            if (this.currentTopic.es_type == 717580004) {
                this.adjuster = 0;
            } else if (
                this.quizQuestionsArr &&
                this.currentQuestionIndex == this.quizQuestionsArr.length
            ) {
                //finished quiz
                this.adjuster = 0;
            } else {
                this.adjuster = 1;
            }
        },
        makeLabQuizArr() {
            if (
                this.currentTopic.es_type == 717580004 &&
                this.questionChoices &&
                this.questionChoices.length > 0 &&
                !this.quizQuestionsArr
            ) {
                if (this.currentAttempt && !this.continuingQuiz) {
                    this.postEmptyLabQuiz();
                }
                this.setQuizQuestionsArr(this.buildQuestionsArr());
            }
        },
        returnShadow() {
            //just started quiz-labs

            if (
                this.adjuster == 0 &&
                this.currentTopic.es_type == 717580004 &&
                this.answered == 0
            ) {
                this.pixels = 15;
                return `inset ${-305}px 0 0px 0px rgba(44, 168, 223, 1)`; //almost empty
            }

            let pixels;
            if (this.currentTopic.es_type == 717580004) {
                //quiz labs
                pixels =
                    (this.answered /
                        (this.currentTopic.es_totalquizquestions ||
                            this.quizQuestionsArr.length)) *
                    320;
            } else {
                //other quizzes
                pixels =
                    ((this.currentQuestionIndex + this.adjuster) /
                        this.quizQuestionsArr.length) *
                    320;
            }

            this.pixels = pixels;

            return `inset ${pixels - 320}px 0 0px 0px rgba(44, 168, 223, 1)`;
        },
        fillBar() {
            document.getElementById(
                "shadow-progress"
            ).style.boxShadow = `inset 0px 0 0px 0px rgba(44, 168, 223, 1)`;
        },
        buildQuestionsArr() {
            let arr = [];
            this.questionChoices.map(choice => {
                let id = choice.es_topicquestion.es_topicquestionid;

                if (!arr.includes(id)) {
                    arr.push(id);
                }
            });
            return arr;
        },
        countAnswered() {
            //if no selections, 0 progress
            if (!this.studentQuizSelections) {
                return 0;
            }

            let answeredQuestions = this.studentQuizSelections.filter(item => {
                if (
                    item.choices &&
                    item.choices[0] &&
                    item.choices[0].es_text &&
                    item.choices[0].es_text != "Invalid file type"
                ) {
                    return true;
                }
            });

            this.answered = answeredQuestions.length;
        },
        postEmptyLabQuiz() {
            let choices = this.questionChoices;
            let arr = [];

            if (!choices) {
                return;
            }

            //make array of all questions for the topic
            choices.forEach(choice => {
                let question = choice.es_topicquestion;

                //conditional to avid duplicates
                if (
                    !arr.some(
                        e =>
                            e.es_topicquestionid === question.es_topicquestionid
                    )
                ) {
                    arr.push(question);
                }
            });

            //return array if not truncated
            this.postEmptyQuiz(arr);
        },
        postEmptyQuiz(arr) {
            let sort = 0;
            let newArr = arr.map(item => {
                sort++;
                return {
                    es_topicquestionid: item.es_topicquestionid,
                    es_text: item.es_text,
                    es_type: item.es_type,
                    es_selectionstatus: 717580002, //unanswered
                    choices: null,
                    es_sort: sort - 1
                };
            });

            this.submitSelections({
                es_attemptid: this.currentAttempt,
                es_enrollmentid: this.currentEnrollment.es_enrollmentid,
                es_cohortid: this.currentCohort.es_cohortid,
                es_topicid: this.currentTopic.es_topicid,
                es_timestart: moment(),
                es_issubmitted: false,
                questions: newArr
            });
        }
    }
};
</script>

<style lang="scss">
#app .progress-bar-component {
    display: flex;
    align-items: center;

    .progress-fraction {
        padding-right: 1rem;
    }

    .progress-background {
        height: 1rem;
        width: 20rem;
        border-radius: 0.5rem;
        // background-color: #2ca8df;
        background-color: #1273b9;
    }

    .progress {
        height: 1rem;
        width: 0%;
        max-width: 100%;
        background-color: #1273b9;
        transition: width 150ms ease-out;
    }
}
</style>