import axios from 'axios';
import router from '../../router/router.js';
import { getSessionFeedback, getCohortFeedback, getTopicFeedback } from '../../api/feedback.js';

const state = {
    currentSessionFeedbacks: null,
    currentCohortFeedbacks: null,
    currentTopicFeedbacks: null,
    feedbackLoaded: false,
};

const getters = {
    currentSessionFeedbacks: (state) => state.currentSessionFeedbacks,
    currentCohortFeedbacks: (state) => state.currentCohortFeedbacks,
    currentTopicFeedbacks: (state) => state.currentTopicFeedbacks,
    feedbackLoaded: (state) => state.feedbackLoaded,
};

const actions = {
    getFeedbacksForSession({ commit, rootState, dispatch }, data) {

        getSessionFeedback(data).then(response => {
            commit('setCurrentSessionFeedbacks', response.data.value)
        }).catch(err => {
            console.log("api/sessionfeedbacks error", err)
            commit('setAuthentication', false)
            commit('setContact', null)
            commit('setErrorMessage', "Session expired. Please login again.")
            router.push({ path: '/error' })
        })
    },
    getFeedbacksForTopic({ commit, rootState, dispatch }, data) {
        commit('setFeedbackLoaded', false);

        getTopicFeedback(data).then(response => {
            commit('setCurrentTopicFeedbacks', response.data.value)
            commit('setFeedbackLoaded', true);
        }).catch(err => {
            console.log("api/topicfeedbacks error", err)
            commit('setAuthentication', false)
            commit('setContact', null)
            commit('setErrorMessage', "Session expired. Please login again.")
            router.push({ path: '/error' })
        })
    },
    getFeedbacksForCohort({ commit, rootState, dispatch }, data) {
        commit('setFeedbackLoaded', false);

        getCohortFeedback(data).then(response => {
            commit('setCurrentCohortFeedbacks', response.data.value)
            commit('setFeedbackLoaded', true);
        }).catch(err => {
            console.log("api/cohortfeedbacks error", err)
            commit('setAuthentication', false)
            commit('setContact', null)
            commit('setErrorMessage', "Session expired. Please login again.")
            router.push({ path: '/error' })
        })
    },
    clearCurrentFeedbacks({ commit }) {
        commit('clearCurrentTopicFeedbacks', null)
        commit('clearCurrentCohortFeedbacks', null)
        commit('clearCurrentSessionFeedbacks', null)
    },
};

const mutations = {
    setCurrentSessionFeedbacks: (state, currentSessionFeedbacks) => (state.currentSessionFeedbacks = currentSessionFeedbacks),
    setCurrentCohortFeedbacks: (state, currentCohortFeedbacks) => (state.currentCohortFeedbacks = currentCohortFeedbacks),
    setCurrentTopicFeedbacks: (state, currentTopicFeedbacks) => (state.currentTopicFeedbacks = currentTopicFeedbacks),
    setFeedbackLoaded: (state, feedbackLoaded) => (state.feedbackLoaded = feedbackLoaded),
    clearCurrentTopicFeedbacks: (state, val) => (state.currentSessionFeedbacks = val),
    clearCurrentCohortFeedbacks: (state, val) => (state.currentSessionFeedbacks = val),
    clearCurrentSessionFeedbacks: (state, val) => (state.currentSessionFeedbacks = val),
};

export default {
    state,
    getters,
    actions,
    mutations
}

