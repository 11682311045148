<template>
	<span>
		<b-navbar class="lms-nav" type="is-black" :mobile-burger="false">
			<template slot="brand">
				<b-navbar-item class="left-padding no-vertical-padding">
					<img :src="logo" alt="Evolve Academy logo" class="academy-logo" @click="redirectLms" />
				</b-navbar-item>
				<!-- cohort name -->
				<b-navbar-item v-if="currentCohort" class="left-margin no-vertical-padding no-left-padding cursor-default">
					<h3>|</h3>
					<h3 class="left-margin">{{ currentCohort.es_name }}</h3>
					<!-- cohort progress -->
					<inline-loader style="margin-left:2rem;" v-if="loadingCohorts" />
					<vue-circle
						v-else-if="!loadingCohort"
						:progress="cohortProgress()"
						empty-fill="rgba(100, 100, 100, 1)"
						:size="45"
						:fill="{ color: '#2da8df' }"
						:start-angle="Math.PI * 1.5"
						:thickness="4"
						:show-percent="true"
					></vue-circle>
				</b-navbar-item>
			</template>

			<template slot="end">
				<b-navbar-item tag="div" class="end-container">
					<div class="buttons">
						<div
							class="zoom-and-slack-wrapper"
							v-if="currentCohort && ![717580007, 717580005].includes(currentCohort.es_type)"
						>
							<zoom-dropdown />

							<slack-dropdown />
						</div>

						<letter-dropdown />
					</div>
				</b-navbar-item>
			</template>
		</b-navbar>
		<!--feedback modal-->
		<span class="cohort-feedback-modal-component">
			<b-modal
				:active.sync="feedbackModal"
				has-modal-card
				trap-focus
				aria-role="dialog"
				aria-modal
				id="feedback-modal"
				class="feedback-modal"
			>
				<cohort-feedback-modal />
			</b-modal>
		</span>
	</span>
</template>

<script>
import logo from "../assets/color-white.svg"
import calendar from "../assets/calendar.png"
import education from "../assets/education.png"
import folderOpen from "../assets/folder-open.png"
import list from "../assets/list.png"
import power from "../assets/power.png"
import presentation from "../assets/presentation.png"
import thumbnails from "../assets/thumbnails.png"
import userParents from "../assets/user-parents.png"
import { mapGetters, mapActions } from "vuex"
import router from "../router/router.js"
import CohortFeedbackModal from "./CohortFeedbackModal"
import EventBus from "../event-bus"
import ZoomDropdown from "../components/ZoomDropdown"
import SlackDropdown from "../components/SlackDropdown"
import LetterDropdown from "../components/LetterDropdown"
import VueCircle from "vue2-circle-progress/src/index.vue"
import InlineLoader from "./InlineLoader.vue"
export default {
	name: "Nav",
	components: {
		ZoomDropdown,
		CohortFeedbackModal,
		SlackDropdown,
		LetterDropdown,
		VueCircle,
		InlineLoader
	},
	computed: {
		...mapGetters([
			"contact",
			"currentPage",
			"baseUrl",
			"currentModule",
			"currentTopic",
			"currentCohort",
			"allMyCohortGroups",
			"slackUrls",
			"allCohortGroups",
			"cohortCards",
			"archivedCards",
			"loadingCohort",
			"loadingCohorts"
		])
	},
	data() {
		return {
			logo: logo,
			calendar: calendar,
			education: education,
			folderOpen: folderOpen,
			list: list,
			power: power,
			presentation: presentation,
			thumbnails: thumbnails,
			userParents: userParents,
			isActive: false,
			currentCohortGroup: null,
			feedbackModal: false
		}
	},
	mounted() {
		EventBus.$on("cohort-feedback-open", () => {
			this.openFeedbackModal()
		})
	},
	watch: {},
	methods: {
		openFeedbackModal() {
			this.feedbackModal = true
		},
		...mapActions(["changeCurrentPage"]),
		handleClick(item) {
			this.changeCurrentPage(item)
		},
		nav(location) {
			router.push({ path: location })
			this.changeCurrentPage(location)
		},
		filterMyCohortGroups() {
			this.currentCohortGroup = this.allMyCohortGroups.find(group => {
				return group._es_cohort_value == this.currentCohort.es_cohortid
			})
		},
		redirectLms() {
			if (this.currentModule && this.currentTopic && this.$router.history.current.name === "landingPage") {
				//logged in, on welcome page, refresh page
				location.reload()
			} else if (this.currentModule && this.currentTopic) {
				//logged in, not on welcome page, go to welcome page
				router.push({
					path: "/welcome"
				})
			} else {
				//not logged in, auth failed, etc.
				window.location.href = `${this.baseUrl}`
			}
		},
		cohortProgress() {
			let currentCard = this.cohortCards.find(card => card.es_cohortid === this.currentCohort.es_cohortid)

			if (!currentCard) {
				currentCard = this.archivedCards.find(card => card.es_cohortid === this.currentCohort.es_cohortid)
			}
			return currentCard && currentCard.progress ? currentCard.progress * 100 : 0
		}
	}
}
</script>

<style lang="scss">
#app .lms-nav {
	@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");
	height: 3.5rem;
	min-height: unset;

	h3 {
		padding: unset;
	}
	.academy-logo {
		max-height: 2.6rem;
	}

	.cursor-default {
		cursor: default;
	}

	.left-margin {
		margin-left: 20px;
	}

	.navbar {
		min-height: 5rem;
		user-select: none;
	}

	.navbar-brand {
		height: 3.5rem;
		min-height: 3.5rem;
	}

	.no-vertical-padding {
		padding-top: 0;
		padding-bottom: 0;
	}

	.no-left-padding {
		padding-left: 0px;
	}

	.navbar-item.left-padding {
		padding-left: 1.25rem;
	}

	.end-container {
		line-height: 2rem;
	}

	div.buttons {
		height: 2.5rem;
		margin: 0;
		padding: 0;
	}

	.navbar-dropdown {
		border-top: 0px;
		margin-top: 1rem;
		background-color: black;
		width: 15rem;
		border-radius: 0;
		font-weight: 400;
	}

	a.navbar-item {
		padding-right: 0px;
	}

	.inline-block {
		display: inline-block;
	}

	.inherit-width {
		width: inherit;
	}

	.end-container {
		padding-right: 2rem;
	}

	.zoom-and-slack-wrapper {
		display: flex;
		margin-right: 1rem;
	}

	.cursor-default {
		cursor: default;
	}

	.cognito {
		background-color: white;
	}

	.circle {
		display: flex;
		margin-left: 1.5rem;
	}

	.percent-text {
		font-size: 0.85rem !important;
	}
}

#app .navbar.is-black {
	background-color: #0f172a !important;
}
</style>
