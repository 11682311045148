<template>
  <div class="print-button-component">
    <!-- print version -->
    <div>
      <pdf-renderer />
      <b-dropdown :close-on-click="true">
        <div slot="trigger" tag="div">
          <b-button
            type="is-info"
            :loading="pdfDownloading"
            class="margin-half print-button"
          >
            <div>
              PDF
              <vue-fontawesome
                v-show="!pdfDownloading"
                :icon="['fas', 'file-pdf']"
                size="lg"
                color="#fff"
              />

              <vue-fontawesome icon="chevron-up" />
            </div>
          </b-button>
        </div>

        <b-dropdown-item @click="printPdf()">Download</b-dropdown-item>
        <b-dropdown-item @click="showPassword()">View password</b-dropdown-item>
      </b-dropdown>
    </div>

    <div class="end-white-space"></div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import EventBus from "../event-bus"
import PdfRenderer from "./PdfRenderer"
import { openSnackBar } from "../utils/openSnackBar"
import { closeableSnackBar } from "../utils/openSnackBar"

export default {
  name: "PrintButton",
  components: {
    PdfRenderer
  },
  computed: {
    ...mapGetters([
      "currentTopic",
      "pdfDownloading",
      "currentEnrollment",
      "pdfPassword",
      "currentCohort"
    ])
  },
  data() {
    return {}
  },
  updated() {},
  mounted() {},
  watch: {},
  beforeDestroy() {},
  methods: {
    ...mapActions(["generatePdf", "updatePdfDownloading"]),
    printPdf() {
      openSnackBar(this, `Your PDF is downloading.`)

      EventBus.$emit("print-pdf")
      this.updatePdfDownloading(true)
    },
    showPassword() {
      closeableSnackBar(
        this,
        `<p style="text-align:center;font-weight:bold">Your PDF password:</p><p style="text-align:center">${this.pdfPassword}</p>`
      )
    }
  }
}
</script>

<style lang="scss">
#app .print-button-component {
  display: flex;
  align-items: center;
  /* justify-self: flex-end; */
  margin-left: auto;

  .margin-half {
    margin: 0.5rem;
  }

  .print-button {
    width: 135px;
    span {
      display: flex;
      align-items: center;
    }

    svg {
      height: 1.25rem;
      margin-left: 0.5rem;
    }
  }

  .dropdown-menu {
    min-width: 135px;
  }

  .button {
    margin: 0px;
  }

  .dropdown-content {
    margin-top: -126px;
    width: 135px;

    border-top: none;
    border-radius: 6px 6px 0px 0px;
    box-shadow: 0 -8px 8px rgba(10, 10, 10, 0.1);
    left: unset;
    background-color: #3298dc;
  }

  .dropdown-item {
    color: white;
  }

  .dropdown-item:hover {
    background-color: #008eea;
    color: white;
  }

  .dropwdown-item:focus {
    background-color: #008eea;
    color: white;
  }

  .dropdown.is-active .button {
    border-radius: 0px 0px 6px 6px;
  }
}

.snackbar .text {
  word-break: break-all;
}
</style>
