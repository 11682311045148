import axios from 'axios';
import router from '../../router/router.js';
import { getAttendance, getCohortAttendance } from '../../api/attendance.js';

const state = {
    currentAttendance: null,
    attendanceReturned: false,
    cohortAttendance: null
};

const getters = {
    currentAttendance: (state) => state.currentAttendance,
    attendanceReturned: (state) => state.attendanceReturned,
    cohortAttendance: (state) => state.cohortAttendance
};

const actions = {
    getAttendanceForSession({ commit }, data) {
        commit('setAttendanceReturned', false);

        getAttendance(data).then(response => {
            commit('setCurrentAttendance', response.data.value[0])
            commit('setAttendanceReturned', true);
        }).catch(err => {
            console.log("api/attendance error", err)
            commit('setAuthentication', false)
            commit('setContact', null)
            commit('setErrorMessage', "Error loading attendance for session.")
            router.push({ path: '/error' })
        })
    },
    getAttendanceForCohort({ commit }, data) {

        getCohortAttendance(data).then(response => {
            commit('setCohortAttendance', response.data.value)
        }).catch(err => {
            console.log("api/attendance error", err)
            commit('setAuthentication', false)
            commit('setContact', null)
            commit('setErrorMessage', "Error loading attendance for cohort.")
            router.push({ path: '/error' })
        })
    },
    clearCurrentAttendance({ commit }) {
        commit('setCurrentAttendance', null)
    },
};

const mutations = {
    setCurrentAttendance: (state, currentAttendance) => (state.currentAttendance = currentAttendance),
    setAttendanceReturned: (state, attendanceReturned) => (state.attendanceReturned = attendanceReturned),
    setCohortAttendance: (state, cohortAttendance) => (state.cohortAttendance = cohortAttendance)
};

export default {
    state,
    getters,
    actions,
    mutations
}

