<template>
	<div class="grades-print-out">
		<div class="flex es-container ">
			<div class="back-div" @click="handleGoBack()">
				<p class="link is-info is-light ">
					Go back
				</p>
				<h4 class="back-page-title">
					Grades Page
				</h4>
			</div>

			<h2 class="text-center">Grades Page</h2>

			<b-button @click="handlePrintClick" :disabled="downloadingGradesPdf" :loading="downloadingGradesPdf" icon-left="print" icon-pack="fa">
				Print
			</b-button>
		</div>
		<div id="gradesTable" v-if="data">
			<b-table v-if="data" :data="data" :columns="columns" />
		</div>
		<Loader v-else />
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import Loader from "./Loader"
import moment from "moment"
import router from "../router/router.js"
import { format } from "path"

export default {
	name: "GradesPrintOut",
	components: { Loader },
	computed: {
		...mapGetters(["contact", "currentCohort", "attempts", "currentEnrollment", "downloadingGradesPdf"]),
		reportGenerated() {
			return `${moment(new Date())
				.startOf("day")
				.format("MMM D, YYYY")}`
		}
	},
	data() {
		return {
			data: null,
			attendanceDates: null,
			chartGenerated: false,
			columns: [
				{
					field: "topicName",
					label: "Topic"
				},
				{
					field: "moduleName",
					label: "Module"
				},
				{
					field: "topicType",
					label: "Topic Type"
				},
				{
					field: "dueDate",
					label: "Date"
				},
				{
					field: "grade",
					label: "Grade"
				}
			],
			statusDict: {
				717580000: "Attended",
				717580001: "Absent",
				717580002: "Excused",
				717580003: "Late"
			}
		}
	},
	mounted() {
		this.makeChartData()
	},
	watch: {},
	methods: {
		...mapActions(["getAttendanceForCohort", "getSessions", "printGradesPdf"]),
		makeChartData() {
			//filter topics from currentCohort
			let arrayOfTopics = this.buildArrayOfCurrentTopics()

			//filter attempts for submitted attempts
			let submittedAttempts = this.filterSubmittedAttempts()

			//append attempts to each element
			arrayOfTopics = this.appendAttempts(arrayOfTopics, submittedAttempts)

			this.data = arrayOfTopics
		},
		buildArrayOfCurrentTopics() {
			let arr = []
			let today = moment(new Date())

			this.currentCohort.modules.forEach(mod => {
				mod.topics.forEach(topic => {
					if (moment(topic.es_duedate || topic.es_enddate) <= today) {
						arr.push({
							topicid: topic.es_topicid,
							moduleName: this.truncateString(mod.es_name, 15),
							topicName: this.truncateString(topic.es_name || topic.es_displayname, 20),
							grade: null,
							dueDate: this.checkTopicDate(topic),
							topicType: this.makeTypeReadable(topic.es_type)
						})
					}
				})
			})
			return arr
		},
		filterSubmittedAttempts() {
			return this.attempts.filter(attempt => {
				return attempt.es_issubmitted && attempt.es_attemptstatus == 717580001
			})
		},
		appendAttempts(arrayOfTopics, submittedAttempts) {
			arrayOfTopics = arrayOfTopics.map(topic => {
				let topicAttempts = submittedAttempts.filter(attempt => topic.topicid === attempt._es_topic_value)

				//append attempts to the topic if there are any for the topic
				//return the new topic
				if (topicAttempts.length > 0) {
					//filter for the attempt with the highest score
					let highAttempt = this.returnHighestScore(topicAttempts)
					topic.grade = `${highAttempt.es_score * 100}%`
				} else {
					//set the topic to null since there are no attempts i.e. it hasn't been submitted
					topic.grade = "No record"
				}

				return topic
			})
			return arrayOfTopics
		},
		returnHighestScore(topicAttempts) {
			let reducedAttempt = topicAttempts.reduce((prev, current) => {
				return prev.es_score > current.es_score ? prev : current
			})

			return reducedAttempt
		},
		handlePrintClick() {
			this.printGradesPdf({
				es_cohortid: this.currentCohort.es_cohortid,
				es_enrollmentid: this.currentEnrollment.es_enrollmentid,
				email: this.contact.emailaddress1,
				cohortName: this.currentCohort.es_name,
				htmlString: this.makeHtmlString(),
				contactFullName: this.contact.fullname,
				date: `Report generated ${moment(new Date())
					.startOf("day")
					.format("MMM D, YYYY")}`,
				heading: `<div>Student Name: ${this.contact.fullname} <br />Report Date: ${this.reportGenerated}</div>`
			})
		},
		makeHtmlString() {
			return document.getElementById("gradesTable").outerHTML
		},
		handleGoBack() {
			router.push({
				path: `/grades/${this.currentCohort.es_cohortid}`
			})
		},
		makeTypeReadable(type) {
			switch (type) {
				case 717580000:
					return "Content"
					break
				case 717580001:
					return "Lab"
					break
				case 717580003:
					return "Assessment"
					break
				case 717580002:
				case 717580004:
				case 717580005:
					return "Quiz"
					break
				default:
					return null //no type
			}
		},
		formatDate(date) {
			let formattedDate = moment(new Date(date)).format("M/D/YYYY")
			if (formattedDate != "Invalid date") {
				return formattedDate
			}

			return null
		},
		checkTopicDate(topic) {
			if (topic.es_duedate) {
				return this.formatDate(topic.es_duedate)
			} else if (topic.es_enddate) {
				return this.formatDate(topic.es_enddate)
			} else {
				return null
			}
		},
		truncateString(str, val) {
			return str.length > val ? `${str.substring(0, val)}...` : str
		}
	}
}
</script>

<style scoped lang="scss">
#app .grades-print-out {
	#gradesTable {
		padding: 1rem;
	}

	.text-center {
		text-align: center;
		font-size: 2rem;
		align-self: center;
	}

	.mb-md {
		margin-bottom: 1rem;
	}

	.flex {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	.es-container {
		background-color: #eaf5ff;
		padding: 1rem;
	}

	.link {
		display: block !important;
		font-size: 0.75rem;
		font-weight: 800;
		color: #1262d8 !important;
	}

	.back-page-title {
		font-size: 0.7rem !important;
		color: gray !important;
		font-weight: bold !important;
		margin-top: 4px !important;
		font-family: "Roboto Condensed", sans-serif;
	}

	.back-div {
		cursor: pointer;
		padding: 0.5rem;
	}
}
</style>
