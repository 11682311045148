<template>
	<span class="letter-dropdown-component">
		<b-navbar-dropdown
			v-if="contact"
			id="letter-dropdown-menu"
			class="letter-dropdown"
			:arrowless="true"
			:label="contact.firstname ? contact.firstname.charAt(0) : ' '"
			:right="true"
			close-on-click
		>
			<b-navbar-item class="has-bottom-border black-hover extra-padding cursor-default padding-right-0">
				<div class="extra-bottom-padding lightblue-font letter-dropdown small-letter cursor-default height-2 width-2 flex-shrink-0">
					{{ contact.firstname ? contact.firstname.charAt(0) : " " }}
				</div>
				<div class="user-email truncate">{{ contact.emailaddress1 }}</div>
			</b-navbar-item>

			<!-- admin -->
			<b-navbar-item v-if="currentEnrollmentRole" class="dropdown-item" @click="openAdmin">
				<span class="custom-icon"> <vue-fontawesome :icon="['fas', 'users-cog']" size="lg" color="white" /> </span>Admin
			</b-navbar-item>

			<!-- feedback  -->
			<b-navbar-item class="dropdown-item" @click="openFeedbackModal">
				<span class="custom-icon"> <vue-fontawesome :icon="['far', 'comments']" size="lg" color="white" /> </span>Feedback
			</b-navbar-item>

			<!-- logout  -->
			<b-navbar-item tag="a" :href="baseUrl + 'api/signout'" class="dropdown-item">
				<span class="custom-icon"> <vue-fontawesome :icon="['fas', 'power-off']" size="lg" color="white" /> </span>Logout
			</b-navbar-item>
		</b-navbar-dropdown>
		<!-- <b-navbar-item > -->
		<b-button v-else-if="authentication === true" type="is-black">
			<a class="public-logout" :href="baseUrl + 'api/signout'">Logout</a>
		</b-button>
		<!-- </b-navbar-item> -->
	</span>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import router from "../router/router.js"
import EventBus from "../event-bus"

export default {
	name: "LetterDropdown",
	components: {},
	computed: {
		...mapGetters(["contact", "baseUrl", "authentication", "enrollmentRoles", "currentCohort"]),
		currentEnrollmentRole: function() {
			if (!this.enrollmentRoles) {
				return false
			}
			return this.enrollmentRoles.some(role => {
				return role["es_enrollment2.es_cohort"] === this.currentCohort.es_cohortid
			})
		}
	},
	data() {
		return {}
	},
	mounted() {},
	watch: {},
	methods: {
		openFeedbackModal() {
			EventBus.$emit("cohort-feedback-open")
		},
		openAdmin() {
			router.push({
				path: `/admin/${this.currentCohort.es_cohortid}`
			})
		}
	}
}
</script>

<style lang="scss">
#app .letter-dropdown-component {
	display: flex;

	.letter-dropdown {
		// background-color: #eff3f9;
		background-color: #4fe3c2;
		border-radius: 50%;
		cursor: pointer;
		text-align: center;
	}

	.letter-dropdown {
		vertical-align: middle;
		margin: auto;
		padding: 0px;
		font-family: "Roboto", sans-serif;
		font-size: 24px;
		color: #0c74bb;
		height: 2rem;
		width: 2rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.letter-dropdown .navbar-dropdown {
		margin-top: 0;
	}

	#letter-dropdown-menu a.navbar-link {
		height: unset;
		line-height: 2.25rem;
		padding-top: 0;
		padding-bottom: 0;
	}

	#letter-dropdown-menu a.navbar-link.is-arrowless {
		color: #0c74bb;
	}

	.lightblue-font {
		color: #0c74bb;
	}

	.user-email {
		padding-left: 0.5rem;
		color: #289aca;
	}

	.black-hover:hover {
		background-color: #000000 !important;
	}

	.cursor-default {
		cursor: default;
	}

	.small-letter {
		height: 1.25rem;
		width: 1.25rem;
		font-size: unset;
		margin: 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.dropdown-item a,
	a.dropdown-item {
		color: white !important;
		padding-right: 1rem;
	}

	a.dropdown-item:hover {
		background-color: #0f0f0f !important;
	}

	a.dropdown-item {
		padding-right: auto;
	}

	.custom-icon {
		display: inline-flex;
		align-items: center;
		height: 1.5rem;
		width: 1.5rem;
		justify-content: center;
		margin-right: 0.5rem;
	}

	.is-arrowless {
		background-color: unset !important;
	}

	.extra-padding {
		padding: 0.5rem 0rem 0.5rem 1rem !important;
	}

	.height-2 {
		height: 2rem;
	}

	.width-2 {
		width: 2rem;
	}

	.has-bottom-border {
		border-bottom: 2px solid #505050;
	}

	.truncate {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		padding-right: 1rem;
	}

	.flex-shrink-0 {
		flex-shrink: 0;
	}

	.public-logout {
		text-decoration: none;
		color: white;
	}
}
</style>
