<template>
	<div class="final-lab-container-component overflow-scroll hundo-height overflow-x-hidden">
		<!--pre-lab page-->
		<div v-if="!agreedToAttempt" class="padding-left-2 padding-top-2">
			<div v-if="topicAttempts">
				<div v-if="currentTopic.es_type != '717580004'">
					<p>
						Timer will start when you click start. If you leave the exam, you can continue if still within the allowed time.
						Once the topic is submitted, or time expires, your choices are final and the attempt will be graded.
					</p>
				</div>

				<div v-else>
					<p>
						The final lab is to be completed in one sitting, you will not be allowed to continue if you leave. You will not
						be able to go back to any questions.
					</p>

					<br />

					<p>
						Timer will start when you click start. Once the topic is submitted, or time expires, your choices are final and
						the attempt will be graded.
					</p>
				</div>

				<quiz-history :topicAttempts="topicAttempts" />
				<span class="flex align-center padding-top-1" v-if="topicAttempts">
					<b-button v-if="currentTopic.es_allowedattempts - topicAttempts.length <= 0" disabled>Start</b-button>
					<b-button v-else @click="postAttempt()">Start</b-button>
					<div v-if="submitted.status" class="flex-col-center padding-left-1">
						<span class="small">{{ `Last attempted on ${submitted.date}` }}</span>

						<a @click="handleFeedbackClick" class="small link-blue underline">Leave feedback</a>
					</div>
				</span>
				<div class="end-white-space"></div>
			</div>
			<Loader v-else />
		</div>

		<!--once start button pressed, renders lab-->
		<span v-else>
			<lab />
		</span>
	</div>
</template>

<script>
import EventBus from "../event-bus"
import { mapGetters, mapActions } from "vuex"
import MarkdownRenderer from "./MarkdownRenderer"
import QuizHistory from "./QuizHistory"
import moment from "moment"
import timezone from "moment-timezone"
import Timer from "./Timer"
import FinalLabButton from "./FinalLabButton"
import Loader from "./Loader"
import Lab from "./Lab"

export default {
	name: "FinalLabContainer",
	components: {
		MarkdownRenderer,
		QuizHistory,
		Timer,
		FinalLabButton,
		Loader,
		Lab
	},
	data() {
		return {
			agreedToAttempt: false,
			submitted: { status: false },
			intervalVar: null,
			topicAttempts: null
		}
	},
	mounted() {
		this.setCurrentAttempt(null)
		this.checkAttempts()

		// EventBus.$on("clear-interval-var", data => {
		//     clearInterval(this.intervalVar);
		// });

		EventBus.$on("agree-to-attempt", data => {
			this.agreedToAttempt = data
		})
	},
	updated() {},
	beforeDestroy() {},
	computed: {
		...mapGetters(["currentTopic", "questionChoices", "attempts", "timeZone", "currentAttempt"])
	},
	watch: {
		currentAttempt: function() {
			if (!this.currentAttempt) {
				this.agreedToAttempt = false
			}
		},
		attempts: function() {
			this.checkAttempts()
		},
		currentTopic: function() {
			this.checkAttempts()
		}
	},
	beforeDestroy() {
		//stops the timer
		// clearInterval(this.intervalVar);
		this.stopTimer()
	},
	methods: {
		...mapActions([
			"setRootTimer",
			"getCurrentQuestionChoices",
			"createAttempt",
			"decrementRootTimer",
			"setCurrentAttempt",
			"updateTakingQuiz",
			"stopTimer",
			"startTimer"
		]),
		handleFeedbackClick(e) {
			e.preventDefault()
			EventBus.$emit("feedback-button-clicked")
		},
		checkAttempts() {
			//filters through attempts on the topic
			//sometimes quizzes aren't submitted (e.g. start and exit browser)
			let contactAttempts = this.attempts
			let topicAttempts = contactAttempts.filter(attempt => {
				return attempt._es_topic_value === this.currentTopic.es_topicid
			})

			this.topicAttempts = topicAttempts

			this.setAttempts(topicAttempts)
		},
		setAttempts(topicAttempts) {
			if (topicAttempts.length > 0) {
				this.submitted = {
					status: true,
					date: moment(topicAttempts[0].es_timeend || topicAttempts[0].es_timestart)
						.tz(this.timeZone)
						.format("MMMM Do YYYY, [at] h:mm a z")
				}
			}
		},
		postAttempt() {
			if (!this.currentAttempt) {
				//if its a quiz, make an attempt when opened
				this.updateTakingQuiz(true)

				this.createAttempt()
				//start the interval for the timer
				this.startTimer()
				this.agreedToAttempt = true
			}
		}
	}
}
</script>

<style lang="scss">
#app .final-lab-container-component {
	position: relative;
	overflow-y: scroll;

	.absolute {
		position: fixed;
	}

	.right-1 {
		right: 1rem;
	}

	.background-white {
		background-color: white;
	}

	.rounded {
		border-radius: 5px;
	}

	.z-index-2 {
		z-index: 2;
	}
	.overflow-scroll {
		overflow-y: scroll;
	}

	.overflow-x-hidden {
		overflow-x: hidden;
	}

	.hundo-height {
		height: 100%;
	}

	.small {
		font-size: 0.8rem;
	}

	.flex-col-center {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.padding-left-1 {
		padding-left: 1rem;
	}

	.padding-top-1 {
		padding-top: 1rem;
	}

	.padding-left-2 {
		padding-left: 2rem;
	}

	.padding-top-2 {
		padding-top: 2rem;
	}

	.flex {
		display: flex;
	}

	.align-center {
		align-items: center;
	}

	.end-white-space {
		height: 5rem;
	}

	.link-blue {
		color: #0000ee;
	}

	.underline {
		text-decoration: underline;
	}
}
</style>
