<template>
    <div class="column instructors-component">
        <div
            v-if="currentCohort"
            class="font-size-1-5 font-bold"
            :class="isIndexCollapsed?'padding-left-1':null"
        >Instructors: {{currentCohort.es_name}}</div>
        <span v-if="currentInstructors">
            <div v-for="instructor in currentInstructors" :key="instructor.es_instructorid">
                <div class="flex padding-1 hundo-width">
                    <instructor-image :instructor="instructor" />
                    <div class="width-80 padding-1">
                        <div class="instructor-name font-bold font-size-1-5 flex">
                            {{instructor.es_contact.fullname}}
                            <div class="instructor-linkedin">
                                <a
                                    class="flex"
                                    :href="instructor.es_contact.es_linkedin"
                                    target="_blank"
                                >
                                    <vue-fontawesome
                                        :icon="['fab', 'linkedin']"
                                        size="xs"
                                        color="#0077b5"
                                    />
                                </a>
                            </div>
                        </div>
                        <div
                            class="instructor-name font-italic font-nine-tenths"
                        >{{convertOptionToTitle(instructor.es_role)}}</div>
                        <div class="instructor-office-hours">
                            <p>
                                <span style="font-weight:bold;">Office hours:</span>
                                <span
                                    v-if="instructor.es_officehours"
                                >{{" "+instructor.es_officehours}}</span>
                            </p>
                        </div>
                        <div class="instructor-bio">
                            <p v-if="instructor.es_contact.es_bio">{{instructor.es_contact.es_bio}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </span>
        <Loader v-else />
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import Loader from "./Loader";
import InstructorImage from "./InstructorImage";

export default {
    name: "Instructors",
    components: { Loader, InstructorImage },
    data() {
        return {
            currentInstructors: null,
            gregImage: null
        };
    },
    computed: mapGetters([
        "currentPage",
        "currentCohort",
        "allInstructors",
        "isIndexCollapsed",
        "baseUrl"
    ]),
    mounted() {
        if (this.allInstructors && this.currentCohort) {
            this.filterInstructors();
        } else {
            this.getInstructors();
        }
    },
    watch: {
        allInstructors: "filterInstructors",
        currentCohort: "filterInstructors"
    },
    methods: {
        ...mapActions(["getInstructors"]),
        filterInstructors() {
            if (this.currentCohort && this.allInstructors) {
                this.currentInstructors = this.allInstructors.filter(
                    instructor => {
                        return (
                            instructor._es_cohort_value ==
                            this.currentCohort.es_cohortid
                        );
                    }
                );
            }
        },
        convertOptionToTitle(option) {
            switch (option) {
                case 717580000:
                    return "Lead Instructor";
                    break;
                case 717580001:
                    return "Instructor";
                    break;
                case 717580002:
                    return "Adjunct Instructor";
                    break;
                case 717580003:
                    return "Assistant Instructor";
                    break;
                case 717580004:
                    return "Video Instructor";
                    break;
                default:
                    return "Staff";
            }
        }
    }
};
</script>

<style lang="scss">
#app .instructors-component {
    .menu {
        border: 1px black solid;
    }

    .font-size-1-5 {
        font-size: 1.5rem;
    }

    .font-bold {
        font-weight: bold;
    }

    .padding-1 {
        padding: 1rem;
    }

    .width-75 {
        width: 75%;
    }

    .width-80 {
        width: 80%;
    }

    .width-20 {
        width: 20%;
    }

    .flex {
        display: flex;
    }

    .double-padding {
        padding: 2rem;
    }

    .single-bottom-margin {
        margin-bottom: 1rem;
    }

    .padding-right-8 {
        padding-right: 8rem;
    }

    .center {
        align-items: center;
        justify-content: center;
    }

    .align-center {
        align-items: center;
    }

    .font-italic {
        font-style: italic;
    }

    .font-nine-tenths {
        font-size: 0.9rem;
    }

    .instructor-linkedin {
        display: flex;
        padding-left: 0.5rem;
        cursor: pointer;
    }

    .fa-linkedin:hover {
        color: #028bd2;
    }

    .hundo-width {
        width: 100%;
    }

    .padding-left-1 {
        padding-left: 1rem;
    }

    .instructor-office-hours {
        padding: 0.5rem 0;
    }
}
</style>