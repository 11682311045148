<template>
	<div v-if="classmatesGroupsArray" class="classmates-component column">
		<span v-for="group in classmatesGroupsArray" :key="group.es_cohortgroupid">
			<div class="font-size-1-2 font-bold padding-top-1-2">
				{{ group.es_name }}
			</div>

			<div class="flex row wrap">
				<div v-for="classmate in group.classmates" :key="classmate.es_contactid">
					<classmate-image :classmate="classmate" />
				</div>
			</div>
		</span>
	</div>
	<Loader v-else />
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import Loader from "./Loader"
import hackerHeadshot from "../assets/hacker-headshot.jpg"
import ClassmateImage from "./ClassmateImage.vue"

export default {
	name: "Classmates",
	components: { Loader, ClassmateImage },
	computed: mapGetters(["currentPage", "contact", "allClassmates", "currentCohort", "allMyCohortGroups", "allCohortGroups"]),
	data() {
		return {
			currentCohortClassmates: null,
			classmatesGroupsArray: null,
			currentCohortGroup: null,
			groupClassmates: null,
			nonUserGroups: null,
			nonGroupClassmates: null,
			columns: [
				{
					field: "fullname",
					label: ""
				}
			],
			hackerHeadshot: hackerHeadshot
		}
	},
	watch: {
		allClassmates: function() {
			this.filterClassmatesByCurrentCohort()
		},
		currentCohort: function() {
			this.filterClassmatesByCurrentCohort()
		},
		currentCohortClassmates: function() {
			this.filterClassmatesIntoGroups()
		}
	},
	mounted() {
		//get classmates if don't have
		if (this.allClassmates && this.currentCohort && this.isCurrentCohortClassmates()) {
			this.filterClassmatesByCurrentCohort()
		} else {
			this.getClassmates(this.$route.params.cohortid)
		}
	},
	methods: {
		...mapActions(["getClassmates"]),
		isCurrentCohortClassmates() {
			//     check if first classmate is current cohort
			if (this.allClassmates[0].cohortid === this.currentCohort.es_cohortid) {
				return true
			}
			return false
		},
		filterClassmatesByCurrentCohort() {
			if (this.allClassmates && this.currentCohort) {
				this.currentCohortClassmates = this.allClassmates.filter(classmate => {
					return classmate.cohortid == this.currentCohort.es_cohortid
				})
			}
		},
		filterClassmatesIntoGroups() {
			let classmatesGroupsArray = []

			this.currentCohortClassmates.forEach(classmate => {
				//check linkedin
				if (classmate.es_linkedin) {
					classmate.es_linkedin = this.isValidUrl(classmate.es_linkedin)
				}

				if (classmate.cohortgroup) {
					//see if group is already in array
					let indexOfGroup = this.isGroupInArray(classmatesGroupsArray, classmate.cohortgroup)

					if (indexOfGroup >= 0) {
						//group already in array
						classmatesGroupsArray[indexOfGroup]["classmates"].push(classmate)
					} else {
						//group not in array
						classmate.cohortgroup["classmates"] = [classmate]
						classmatesGroupsArray.push(classmate.cohortgroup)
					}
				} else {
					// classmate doesn't have a group
					// will not appear on classmates page
				}
			})
			this.classmatesGroupsArray = this.sortClassmatesGroupsArray(classmatesGroupsArray)
		},
		isGroupInArray(arr, group) {
			return arr.findIndex(el => {
				return el.es_cohortgroupid == group.es_cohortgroupid
			})
		},
		sortClassmatesGroupsArray(arr) {
			return arr.sort((a, b) => {
				return a.es_name < b.es_name ? -1 : 1
			})
		},
		isValidUrl(url) {
			//fix some common issues that lead to relative linking
			if (url.slice(0, 8) == "linkedin") {
				url = "https://www." + url
			} else if (url.slice(0, 3) == "www") {
				url = "https://" + url
			}

			var regex = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/
			if (regex.test(url)) {
				return url
			} else {
				return null
			}
		}
	}
}
</script>

<style lang="scss">
#app .classmates-component {
	.flex {
		display: flex;
	}

	.row {
		flex-direction: row;
	}

	.font-size-1-2 {
		font-size: 1.2rem;
	}

	.font-bold {
		font-weight: bold;
	}

	.classmate-container {
		width: 125px;
		margin-right: 1rem;
	}

	.classmate-image-container {
		height: 125px;
		overflow: hidden;
	}

	.classmate-image {
		object-fit: cover;
		object-position: 50% 50%;
	}

	.justify-center {
		justify-content: center;
	}

	.classmate-name-container {
		padding-top: 0.25rem;
	}

	.classmate-name-container p {
		font-size: 0.6rem;
	}

	.classmate-linkedin {
		display: flex;
		padding-left: 0.25rem;
		cursor: pointer;
	}

	.fa-linkedin:hover {
		color: #028bd2;
	}

	.padding-top-2 {
		padding-top: 2rem;
	}

	.center {
		justify-content: center;
		align-items: center;
	}

	.wrap {
		flex-wrap: wrap;
	}

	.padding-top-1 {
		margin-top: 1rem;
	}

	.padding-top-1-2 {
		padding-top: 0.5rem;
	}
}
</style>
