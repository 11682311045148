<template>
    <div
        v-if="loadedQuestion"
        class="half-padding gray-background open-response-content-question-component"
    >
        <p class="question-margin-bottom">{{this.loadedQuestion.questionText}}</p>
        <div class="choices-block">
            <b-field>
                <template slot="label">
                    <div class="flex">
                        Answer
                        <div v-if="checked && !loading" class="validity-icon">
                            <vue-fontawesome
                                v-if="correctAnswer"
                                icon="check"
                                size="lg"
                                color="#50e2c1"
                            />

                            <vue-fontawesome v-else icon="times" size="lg" color="#e94542" />
                        </div>
                    </div>
                </template>
                <b-input v-model="answer" placeholder="Type your answer here" @input="handleChange"></b-input>
            </b-field>
        </div>
        <span class="flex align-center">
            <b-button v-if="loading" loading type="is-info">Check answer</b-button>
            <b-button
                v-else-if="checked"
                disabled
                :type="correctAnswer ? 'is-info':'is-warning'"
                :outlined="correctAnswer"
            >Check answer</b-button>
            <b-button
                v-else
                @click="handleClick"
                :type="correctAnswer || !checked ? 'is-info':'is-warning'"
                :outlined="correctAnswer"
            >Check answer</b-button>
        </span>

        <div class="message-box">{{message}}</div>
    </div>
    <div v-else>Question loading..</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import EventBus from "../event-bus";
import { checkOpenResponseQuestion } from "../api/question.js";
import router from "../router/router.js";

export default {
    name: "OpenResponseContentQuestion",
    props: ["choices"],
    computed: {
        ...mapGetters([
            "questionChoices",
            "baseUrl",
            "currentCohort",
            "checkingQuestion",
            "currentTopic"
        ])
    },
    data() {
        return {
            loadedQuestion: null,
            radio: null,
            checked: null,
            answer: null,
            message: null,
            active: false,
            loading: false,
            correctAnswer: false,
            count: 0
        };
    },
    mounted() {
        this.getQuestion();
    },
    methods: {
        ...mapActions(["updateCheckingQuestion", "setErrorMessage"]),
        handleChange() {
            this.checked = null;
            this.message = "";
            this.correctAnswer = false;
            EventBus.$emit("answered", {
                questionid: this.choices[0].es_topicquestion.es_topicquestionid,
                correct: false,
                choiceid: null,
                text: this.answer,
                type: this.loadedQuestion.es_type
            });
        },
        handleClick() {
            //find student's choices
            if (this.answer) {
                this.loading = true;
                this.checked = true;

                //make choices object
                let data = {
                    es_topicquestionid: this.choices[0].es_topicquestion
                        .es_topicquestionid,
                    es_text: this.answer,
                    es_cohortid: this.currentCohort.es_cohortid,
                    es_topicid: this.currentTopic.es_topicid
                };
                this.checkQuestion(data);
            } else {
                this.message = "Please enter a response.";
                this.correctAnswer = false;
                this.checked = true;
            }
        },
        checkQuestion(data) {
            this.updateCheckingQuestion(true);

            checkOpenResponseQuestion(data)
                .then(response => {
                    if (response.data === true) {
                        this.correctAnswer = true;
                        this.count++;
                        if (this.count <= 1) {
                            this.message = "Response recorded!";
                        } else {
                            this.message = "Your response has been updated!";
                        }

                        EventBus.$emit("answered", {
                            questionid: this.choices[0].es_topicquestion
                                .es_topicquestionid,
                            correct: true,
                            choiceid: null,
                            text: this.answer,
                            type: this.choices[0].es_topicquestion.es_type,
                            questionText: this.choices[0].es_topicquestion
                                .es_text,
                            status: "pending"
                        });
                    } else {
                        this.correctAnswer = false;

                        EventBus.$emit("answered", {
                            questionid: this.choices[0].es_topicquestion
                                .es_topicquestionid,
                            correct: false,
                            choiceid: null,
                            text: this.answer,
                            type: this.choices[0].es_topicquestion.es_type,
                            questionText: this.choices[0].es_topicquestion
                                .es_text
                        });
                    }

                    this.loading = false;
                })
                .catch(err => {
                    this.loading = false;
                    console.log("api/checkorquestion error:", err);
                    this.updateCheckingQuestion(false);
                    this.setErrorMessage("Error checking question.");
                    router.push({ path: "/error" });
                });
        },
        getQuestion() {
            //get question info out of one of the choices (they're nested objects are the same)
            let filteredObject = {};
            filteredObject.questionText = this.choices[0]["es_topicquestion"][
                "es_text"
            ];
            filteredObject.questionType = this.choices[0]["es_topicquestion"][
                "es_type"
            ];
            this.loadedQuestion = filteredObject;
        }
    }
};
</script>

<style lang='scss'>
#app .open-response-content-question-component {
    .student-choices {
        display: flex;
    }
}
</style>