<template>
  <div class="selection-question-component">
    <div class="margin-bottom-1">{{ choice.es_question_text }}</div>

    <div class="choices-block">
      <b-radio v-model="radio" :native-value="choice.es_choice_text">
        <span class="choice-container">
          <div class="choice-text">{{ choice.es_choice_text }}</div>
          <div class="validity-icon">
            <!-- pending -->

            <div
              v-if="choice.es_selectionstatus == 717580000"
              class="pending-grade"
            >
              (Pending)
            </div>

            <!-- check -->
            <vue-fontawesome
              v-else-if="choice.es_choice_iscorrect"
              icon="check"
              size="lg"
              color="#50e2c1"
            />
            <!-- x -->
            <vue-fontawesome v-else icon="times" size="lg" color="#e94542" />
          </div>
        </span>
      </b-radio>
    </div>

    <!-- correct block -->
    <p style="margin-top:1rem;font-weight:600;font-size:.9rem;">
      Correct answer:
    </p>
    <div class="correct-block">
      <div v-if="!choice.es_choice_iscorrect">{{ returnCorrectChoice() }}</div>
      <div v-else>Correct!</div>
    </div>

    <!-- message block -->
    <p style="margin-top:1rem;font-weight:600;font-size:.9rem;">Message:</p>
    <div class="correct-block">
      <div v-if="!choice.es_choice_iscorrect && choice.es_choice_message">
        {{ choice.es_choice_message }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuestionSelection",
  props: ["choice", "correctChoices"],
  computed: {
    radio: function() {
      return this.choice.es_choice_text
    }
  },
  methods: {
    returnCorrectChoice() {
      if (!this.correctChoices) {
        return "See instructor."
      } else {
        let arr = this.correctChoices.map(choice => {
          return choice.es_text
        })

        return arr.join(", ")
      }
    }
  }
}
</script>

<style lang="scss">
#app .selection-question-component {
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  padding: 1.5rem;
  margin: 2rem;
  background-color: #dde4fb;

  .margin-bottom-1 {
    margin-bottom: 1rem;
  }

  .padding-left-1 {
    padding-left: 1rem;
  }

  .choice-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .validity-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // width: 24px;
    margin-top: 0px;
    padding-left: 12px;
  }

  .choice-text {
    padding-top: 0.125rem;
  }

  .pending-grade {
    color: #363636;
    // background-color: #f5f5f5;
    padding: default 1rem;
    // border-radius: 5px;
  }

  .correct-block {
    padding: 0.5rem 1rem;
    height: 5rem;
    border: 1px solid lightblue;
    overflow-y: auto;
  }
}
</style>
