<template>
	<span class="single-col-video-component">
		<div id="content-video" class="content-video hundo-width hundo-height">
			<div class="single-col-video is-gapless current-lesson-columns hundo-height overflow-y-scroll">
				<div id="video-lesson-container" class="flex black-background calc-height">
					<div id="video-frame-frame" class="frame-frame" :style="{ width: calcWidth }">
						<div id="video-frame-div" class="frame-div hundo-height">
							<iframe
								:src="currentTopic.es_video"
								frameborder="0"
								allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
								allowfullscreen
							></iframe>
						</div>
					</div>
				</div>
				<div class="submit-topic-container padding-top-half padding-bottom-half">
					<div class="flex-center row" :class="submitted.status ? 'space-buttons' : null">
						<b-button
							v-if="!submitted.status"
							type="is-info"
							:loading="attemptSaving"
							:disabled="attemptSaving"
							@click="attemptToSubmitAttempt()"
							>Mark Done</b-button
						>

						<b-tooltip v-else :label="`Last submitted on ${submitted.date}`" position="is-left" type="is-info" active>
							<b-button type="is-success">Done!</b-button>
						</b-tooltip>

						<!--feedback to the side if its submitted-->
						<a @click="handleFeedbackClick" class="small left-margin-1 pr-md">Leave feedback</a>
					</div>
				</div>
			</div>
		</div>
	</span>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
// import TopicFeedbackPage from "./TopicFeedbackPage";
import moment from "moment"
import EventBus from "../event-bus"
import timezone from "moment-timezone"

export default {
	name: "SingleColVideo",
	// components: { TopicFeedbackPage },
	computed: {
		...mapGetters(["currentTopic", "isIndexCollapsed", "attempts", "attemptSaving", "timeZone"]),
		filteredAttempts: function() {
			return this.attempts.filter(attempt => {
				return attempt._es_topic_value == this.currentTopic.es_topicid
			})
		}
	},
	data() {
		return {
			calcWidth: 0,
			submitted: { status: false }
		}
	},
	mounted() {
		this.checkSubmittedStatus()

		this.calculateWidth()

		window.addEventListener("resize", this.onResize)
	},
	beforeDestroy() {
		// Unregister the event listener before destroying this Vue instance
		window.removeEventListener("resize", this.onResize)
	},
	watch: {
		currentTopic: function() {
			this.calcVideoDimensions()
		},
		isIndexCollapsed: function() {
			var self = this
			//resizes video after the animation to resize window ends (300ms)
			setTimeout(function() {
				self.calculateWidth()
				self.calcVideoDimensions()
			}, 300)
		},
		attempts: function() {
			this.checkSubmittedStatus()
		}
	},
	methods: {
		...mapActions(["submitAttempt", "setAttemptSaving"]),
		calculateWidth() {
			let currentHeight = document.getElementById("video-lesson-container").offsetHeight
			let currentWidth = document.getElementById("video-lesson-container").offsetWidth

			//neededHeight based off 9:16 video ratio
			let neededHeight = (currentWidth * 9) / 16

			//if not enough height, use the ratio and width to calculate height
			if (neededHeight > currentHeight) {
				this.calcWidth = (currentHeight * 16) / 9 + "px"
			} else {
				this.calcWidth = "100%"
			}
		},
		handleFeedbackClick(e) {
			e.preventDefault()
			EventBus.$emit("feedback-button-clicked")
		},
		calcVideoDimensions() {
			//check current height width
			// _____16______
			// |            |
			// 9            9
			// |            |
			// _____16______

			//default is responsive height
			//but if there's not enough height
			//then the height should be 100%
			//and the width should be set dynamically
			let viewportHeight = document.getElementById("video-lesson-container").offsetHeight
			let currentWidth = document.getElementById("video-lesson-container").offsetWidth

			//neededHeight based off 9:16 video ratio
			let neededHeight = (currentWidth * 9) / 16

			let frameDim = {
				height: viewportHeight,
				width: Math.round((viewportHeight * 16) / 9, 0)
			}
			let videoDim = {
				height: (viewportHeight * 9) / 16,
				width: Math.round((viewportHeight * 16) / 9, 0)
			}
			if (neededHeight > viewportHeight) {
				let frame = document.getElementById("video-frame-frame")
				frame.style.width = videoDim.width + "px"
			}
		},
		onResize(event) {
			this.calcVideoDimensions()
		},
		checkSubmittedStatus() {
			let contactAttempts = this.attempts
			//filters for completed attempts for this topic
			let completedAttempts = contactAttempts.filter(attempt => {
				return attempt._es_topic_value === this.currentTopic.es_topicid && attempt.es_issubmitted
			})

			this.setSubmitted(completedAttempts)
		},
		setSubmitted(completedAttempts) {
			//if the filtered out completed attempts array has an elements
			//then the topic has been completed before
			//filtered attempts works the same way but is computed property
			if (completedAttempts.length > 0) {
				let completedDate = moment(completedAttempts[0].es_timeend)
					.tz(this.timeZone)
					.format("MMMM Do YYYY, [at] h:mm a z")

				//set submitted object for control flow in the template
				this.submitted = {
					status: true,
					date: completedDate
				}
			} else {
				this.submitted = {
					status: false,
					date: null
				}
			}
		},
		attemptToSubmitAttempt(canSubmit) {
			//set attempt saving to true
			this.setAttemptSaving()
			this.submitAttempt()

			//popup the feedback form only the first time
			//feedback component is in CurrntLesson.vue
			if (!this.submitted.status) {
				EventBus.$emit("topic-submitted")
			}
		}
	}
}
</script>

<style lang="scss">
#app .single-col-video-component {
	.button {
		width: 110px;
	}

	.space-buttons {
		flex-direction: row;
		/* padding: 0px 10px; */
		width: 218px;
		margin: auto;
		justify-content: space-around;
	}

	.small {
		font-size: 0.8rem;
		color: #2ca8df;
		text-decoration: underline;
	}

	.left-margin-1 {
		margin-left: 1rem;
	}

	.calc-height {
		height: calc(100% - 56px) !important;
	}

	.padding-top-half {
		padding-top: 0.5rem;
	}

	.padding-bottom-half {
		padding-bottom: 0.5rem;
	}

	.flex-center {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}

	.overflow-y-scroll {
		overflow-y: auto;
	}

	.frame-frame {
		width: 100%;
		// max-width: 700px;
		margin: auto auto !important;
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}
	.frame-div {
		position: relative;
		overflow: hidden;
		padding-top: 56.25%;
		// padding-top: 100%;
	}
	.frame-div iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 0;
	}
	.black-background {
		background-color: black;
	}

	.row {
		flex-direction: row;
	}
	.hundo-height {
		height: 100%;
	}

	.button.is-loading::after {
		height: 1rem;
		width: 1rem;
	}

	.pr-md {
		padding-right: 1rem;
	}
}
</style>
