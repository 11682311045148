import request from "./request"

export const getCohorts = () =>
  request({
    url: "/cohorts",
    method: "get"
  })

export const getCohort = data =>
  request({
    url: `/cohort?cohortid=${data.es_cohortid}`,
    method: "get"
  })

export const getCohortImage = data =>
  request({
    url: `/cohortimage?cohortid=${data.cohortid}`,
    method: "get",
    headers: { "Cache-Control": "public" }
  })

export const freeCohorts = () =>
  request({
    url: `/freecohorts`,
    method: "get"
  })
