<template>
	<div v-if="loaded" class="inline-handler">
		<span v-for="(item, i) in contentItemList" :key="'item' + i">
			<span v-if="item.type === 'text'" v-html="item.value"> </span>
			<span v-else>
				<b-tooltip
					append-to-body
					type="is-primary"
					position="is-bottom"
					multilined
					:triggers="['click']"
					:auto-close="['outside', 'escape']"
				>
					<span>{{ item.value }}</span>
					<template v-slot:content>
						<span v-html="item.label"></span>
					</template>
				</b-tooltip>
			</span>
		</span>
	</div>
</template>

<script>
import marked from "marked"
import { sanitize } from "dompurify"
import { mapGetters, mapActions } from "vuex"
import Loader from "./Loader"

export default {
	name: "InlineHandler",
	components: {},
	props: ["inlineText"],
	computed: {
		...mapGetters([])
	},
	data() {
		return {
			parsedHtml: null,
			loaded: false,
			contentItemList: []
		}
	},
	updated() {},
	mounted() {
		this.contentItemList = []
		this.parseInlineText(this.inlineText)
		let contentColumn = document.getElementById("content-column-left") || document.getElementById("single-col-topic")
		if (contentColumn) {
			contentColumn.addEventListener("scroll", this.hideTooltipOnScroll)
		}
	},
	watch: {},
	beforeDestroy() {},
	methods: {
		...mapActions([]),
		parseInlineText(text) {
			// look for callouts
			let startIdx = text.indexOf("[callout:")
			let endIdx = text.indexOf("[/callout]") + 10
			if (startIdx >= 0 && endIdx >= 0) {
				let prev = text.substring(0, startIdx)
				let callout = text.substring(startIdx, endIdx)
				let after = text.substring(endIdx)
				this.contentItemList.push({
					type: "text",
					value: prev
				})
				this.contentItemList.push({
					type: "callout",
					value: this.returnCalloutValue(callout),
					label: this.returnCalloutLabel(callout)
				})
				this.parseInlineText(after)
			} else {
				this.contentItemList.push({
					type: "text",
					value: text
				})
				this.loaded = true
			}
		},
		returnCalloutValue(callout) {
			//remove "[/callout] from end
			let indexOfEnd = callout.indexOf("[/")
			let value = callout.substring(0, indexOfEnd)
			// remove callout from the beginning
			value = value.substring(callout.indexOf("]") + 1)
			return value
		},
		returnCalloutLabel(callout) {
			// cut out the callout part
			let label = callout.substring(callout.indexOf(":") + 1, callout.indexOf("]"))
			return label
		},
		hideTooltipOnScroll() {
			let tooltips = document.getElementsByClassName("tooltip-content")
			let tooltipsLength = tooltips.length
			for (let i = 0; i < tooltipsLength; i++) {
				tooltips[i].style.display = "none"
			}
		}
	}
}
</script>

<style lang="scss">
#app .inline-handler {
	.tooltip-trigger {
		text-decoration: underline;
		text-decoration-style: dotted;
		text-decoration-color: #2ca8df;
		text-underline-offset: 2px;
		cursor: pointer;
		// font-weight: bold;
	}

	.b-tooltip.is-primary div.tooltip-content {
		background: #3273dc;
		color: #fff;
	}

	.b-tooltip.is-bottom.is-primary div.tooltip-content::before {
		border-bottom-color: #3273dc;
	}

	.b-tooltip.is-bottom div.tooltip-content::before {
		border-bottom: 5px solid #3273dc;
	}

	div.tooltip-content a {
		color: white;
		font-weight: normal;
		text-decoration: underline;
	}

	.b-tooltip.is-multiline.is-medium div.tooltip-content {
		min-width: 240px;
		width: unset;
	}
}

.b-tooltip.is-primary div.tooltip-content {
	background: #3273dc;
	color: #fff;
}

.b-tooltip.is-bottom.is-primary div.tooltip-content::before {
	border-bottom-color: #3273dc;
}

.b-tooltip.is-bottom div.tooltip-content::before {
	border-bottom: 5px solid #3273dc;
}

div.tooltip-content a {
	color: white;
	font-weight: normal;
	text-decoration: underline;
}

.b-tooltip.is-multiline.is-medium div.tooltip-content {
	min-width: 240px;
	width: unset;
}
</style>
