<template>
    <span class="instructor-image-component">
        <div class="width-300 flex justify-center align-start">
            <img
                v-if="image && image != 'none'"
                class="instructor-image"
                :src="`data:${instructor.image.mimetype};base64,${instructor.image.documentbody}`"
            />
            <img
                v-else-if="image && image == 'none'"
                class="instructor-image"
                :src="hackerHeadshot"
            />
            <Loader v-else />
        </div>
    </span>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import hackerHeadshot from "../assets/hacker-headshot.jpg";
import Loader from "./Loader";

export default {
    name: "InstructorImage",
    components: { Loader },
    props: ["instructor"],
    computed: mapGetters([
        "currentPage",
        "currentCohort",
        "allInstructors",
        "isIndexCollapsed",
        "baseUrl"
    ]),
    data() {
        return {
            hackerHeadshot: hackerHeadshot,
            image: null
        };
    },
    mounted() {
        //get instrucotr if don't have
        if (this.instructor && !this.instructor.image) {
            let data = {
                contactid: this.instructor.es_contact.contactid,
                cohortid: this.instructor._es_cohort_value
            };
            this.getInstructorImage(data);
        } else {
            this.image = this.instructor.image;
        }
    },
    watch: {
        allInstructors: function() {
            this.checkInstructorImage();
        }
    },
    methods: {
        ...mapActions(["getInstructors", "getInstructorImage"]),
        checkInstructorImage() {
            let localInstructor = this.allInstructors.find(instructor => {
                return (
                    instructor.es_contact.contactid ==
                    this.instructor.es_contact.contactid
                );
            });
            if (this.image != localInstructor.image) {
                this.image = localInstructor.image;
            }
        }
    }
};
</script>

<style lang="scss">
#app .instructor-image-component {
    .width-300 {
        width: 300px;
        padding: 1rem;
        max-height: 300px;
        overflow: hidden;
    }

    .flex {
        display: flex;
    }

    .justify-center {
        justify-content: center;
    }

    .align-start {
        align-items: flex-start;
    }

    .instructor-image {
        object-fit: contain;
    }
}
</style>
