import request from "./request"

export const autoEnrollCohort = data =>
  request({
    url: "/autoenroll",
    method: "post",
    data: data,
    headers: { "Content-Type": "application/json" }
  })

export const readCohortCards = () =>
  request({
    url: "/cohortcards",
    method: "get",
    headers: { "Content-Type": "application/json" }
  })

export const getEnrollmentRoles = cohortId =>
  request({
    url: `/enrollmentroles?cohortid=${cohortId}`,
    method: "get",
    headers: { "Content-Type": "application/json" }
  })
