<template>
    <section v-if="attendanceReturned" class="attendance-radio">
        <b-field>
            <b-radio-button
                v-model="radioButton"
                native-value="Attended"
                :disabled="radioButton=='Attended'?false:true"
                type="is-success"
            >
                <vue-fontawesome
                    :icon="['fas', 'check']"
                    :color="radioButton=='Attended'?'white':'black'"
                />
                <span>Attended</span>
            </b-radio-button>

            <b-radio-button
                v-model="radioButton"
                native-value="Excused Absence"
                type="is-info"
                :disabled="radioButton=='Excused Absence'?false:true"
            >
                <vue-fontawesome
                    :icon="['far', 'calendar-check']"
                    :color="radioButton=='Excused Absence'?'white':'black'"
                />
                <span>Excused Absence</span>
            </b-radio-button>

            <b-radio-button
                v-model="radioButton"
                native-value="Late"
                type="is-warning"
                :disabled="radioButton=='Late'?false:true"
            >
                <vue-fontawesome :icon="['far', 'clock']" color="black" />
                <span>Late</span>
            </b-radio-button>

            <b-radio-button
                v-model="radioButton"
                native-value="Absent"
                type="is-danger"
                :disabled="radioButton=='Absent'?false:true"
            >
                <vue-fontawesome
                    :icon="['fas', 'times']"
                    :color="radioButton=='Absent'?'white':'black'"
                />
                <span>Absent</span>
            </b-radio-button>
        </b-field>
    </section>
    <Loader v-else />
</template>

<script>
import { mapGetters } from "vuex";
import Loader from "./Loader";

export default {
    props: ["status"],
    components: { Loader },
    data() {
        return {
            radioButton: null
        };
    },
    computed: {
        ...mapGetters(["currentAttendance", "attendanceReturned"])
    },
    created() {
        this.radioButton = this.returnStatusKey();
    },
    watch: {
        currentAttendance: function() {
            this.radioButton = this.returnStatusKey();
        }
    },
    methods: {
        returnStatusKey() {
            let statusCode = null;
            if (this.currentAttendance && this.currentAttendance.es_status) {
                statusCode = this.currentAttendance.es_status;
            }
            switch (statusCode) {
                case 717580000:
                    return "Attended";
                    break;
                case 717580002:
                    return "Excused Absence";
                    break;
                case 717580003:
                    return "Late";
                    break;
                default:
                    return "Absent"; //717580001 or other
            }
        }
    }
};
</script>

<style lang="scss">
#app .attendance-radio {
    .b-radio.radio.button span {
        margin-left: 0.5rem;
    }
}
</style>