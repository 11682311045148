<template>
	<span class="content-nav-component">
		<div
			v-if="cohortsArray && currentModule && currentTopic"
			class="columns is-mobile no-margins is-gapless content-nav-container ice-background space-between flex"
			id="content-nav"
			:class="isIndexCollapsed ? 'full' : null"
		>
			<div class="column flex-grow-1 flex-shrink-none" :class="{ 'is-disabled': !previousTopic }">
				<a
					class="link is-info change-topic"
					@click="handleClick('prev', previousTopic)"
					:class="!previousTopic ? null : previousTopic.isLocked ? 'is-light not-allowed' : null"
					>Previous</a
				>
				<div>
					<h4 class="change-module-title">
						{{ prevModule ? prevModule.es_name.toUpperCase() : currentModule.es_name.toUpperCase() }}
					</h4>
					<h2 v-if="previousTopic" class="change-topic-title">{{ previousTopic.es_displayname }}</h2>
				</div>
			</div>
			<div class="column flex-grow-2 flex-shrink-none has-text-centered section-heading">
				<h4 class="module-title">{{ currentModule.es_name.toUpperCase() }}</h4>
				<h2 class="topic-title">
					{{ currentTopic.es_displayname ? currentTopic.es_displayname.toUpperCase() : currentTopic.es_name.toUpperCase() }}
				</h2>
			</div>
			<div class="column flex-grow-1 flex-shrink-none right-align-text" :class="{ 'is-disabled': !nextTopic }">
				<a
					class="link is-info change-topic"
					@click="handleClick('next', nextTopic)"
					:class="!nextTopic ? null : nextTopic.isLocked ? 'is-light not-allowed' : null"
					>Next</a
				>
				<div>
					<h4 class="change-module-title">
						{{ nextModule ? nextModule.es_name.toUpperCase() : currentModule.es_name.toUpperCase() }}
					</h4>
					<h2 v-if="nextTopic" class="change-topic-title">
						{{ nextTopic.es_displayname ? nextTopic.es_displayname : nextTopic.es_name }}
					</h2>
				</div>
			</div>
		</div>
	</span>
</template>

<script>
import { mapGetters, mapActions } from "vuex"

export default {
	name: "ContentNav",
	computed: mapGetters(["currentModule", "currentTopic", "previousTopic", "nextTopic", "nextModule", "prevModule", "cohortsArray", "isIndexCollapsed"]),
	methods: {
		handleClick(direction, newTopic) {
			if (!newTopic.isLocked) {
				if (this.nextModule && direction == "next") {
					this.updateCurrentModule(direction)
				} else if (this.prevModule && direction == "prev") {
					this.updateCurrentModule(direction)
				}

				if (!this.previousTopic && direction == "prev") {
					return
				} else if (!this.nextTopic && direction == "next") {
					return
				} else {
					this.navigateToTopic(direction)
				}
			}
		},
		...mapActions(["updateCurrentTopic", "updateCurrentModule", "navigateToTopic"])
	}
}
</script>

<style lang="scss">
#app .content-nav-component {
	.no-margins {
		margin: 0 !important;
	}
	.section-heading {
		display: flex;
		justify-content: center;
		align-items: middle;
		flex-direction: column;
	}

	.module-title {
		color: #9b9b9b !important;
		font-size: 0.85rem !important;
		font-weight: bold !important;
		margin-top: 4px !important;
	}

	.topic-title {
		font-size: 1rem !important;
		font-weight: bold !important;
	}

	.change-module-title {
		font-size: 0.7rem !important;
		color: gray !important;
		font-weight: bold !important;
		margin-top: 4px !important;
		font-family: "Roboto Condensed", sans-serif;
	}

	.change-topic-title {
		font-size: 0.75rem !important;
		font-weight: bold !important;
	}

	.link.is-info,
	.link.is-light {
		display: block !important;
		font-size: 0.75rem;
		font-weight: 800;
		color: #1262d8 !important;
	}

	.link.is-light {
		display: block !important;
		font-size: 0.75rem;
		font-weight: 800;
		color: gray !important;
	}

	.change-topic {
		padding: 0;
	}

	.content-nav {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	.content-nav-container {
		padding: 0.5rem 1rem;
	}

	.content-nav-container.full {
		padding: 0.5rem 1.5rem;
	}

	.right-align-text {
		align-items: flex-end !important;
		text-align: right !important;
	}

	.is-disabled {
		color: #9b9b9b;
		opacity: 0%;
		text-decoration: none;
		cursor: default !important;
	}

	.is-disabled a {
		cursor: default !important;
	}

	.not-allowed {
		cursor: not-allowed !important;
		color: gray !important;
	}

	.ice-background {
		background-color: #eaf5ff;
	}

	.space-between {
		justify-content: space-between;
	}

	.flex {
		display: flex;
	}

	.flex-grow-1 {
		flex-grow: 1;
	}

	.flex-grow-2 {
		flex-grow: 2;
	}

	.flex-shrink-none {
		flex-shrink: unset;
	}
}
</style>
