export const baseUrlApi =
    window.location.hostname !== "localhost" ?
        "api/" :
        "api/";

export const baseCyberLabUrlApi =
    window.location.hostname === "localhost" ? "https://localhost:5001/" : (window.location.hostname === "lmscore-dev.azurewebsites.net" ? "https://cyberlab-api-staging.azurewebsites.net/" : "https://api.cyberlab.evolvesecurity.com/");

export const devbox_machines = ["devbox"];
export const terminaldesktop_machines = ["esa_linux", "esa_linux_nta"];
export const onlydesktop_machines = ["windows_server"];
export const onlyterminal_machines = ["ubuntu", "ubuntu_elk"];
