<template>
	<div v-if="data" :class="'row ' + topic.es_topicid + ' topic-row'">
		<!-- row header  -->
		<div
			class="row-heading columns is-mobile border-right border-bottom border-left sticky-left white"
			:class="topic.isLocked ? 'locked' : 'hover-ice cursor-pointer'"
			@click="navToTopic"
		>
			<div class="expand-icon column is-2 flex-center justify-center icon-container">
				<vue-fontawesome v-if="topic.es_type === 717580001" icon="flask" :color="topic.isLocked ? 'gray' : 'black'" />

				<vue-fontawesome
					v-else-if="topic.es_type === 717580000"
					:icon="['fas', 'file-video']"
					:color="topic.isLocked ? 'gray' : 'black'"
				/>

				<vue-fontawesome
					v-else-if="topic.es_type === 717580003"
					:icon="['fas', 'clipboard-check']"
					size="lg"
					:color="topic.isLocked ? 'gray' : 'black'"
				/>
				<vue-fontawesome v-else :icon="['fas', 'question-circle']" size="lg" :color="topic.isLocked ? 'gray' : 'black'" />
			</div>
			<div class="column is-6">
				<p class="semi-bold font-size-nine-tenths overflow-ellipsis">{{ topic.es_displayname }}</p>
			</div>
			<div class="column is-2 flex-center justify-center">
				<p class="font-size-four-fifths condensed">{{ topic.es_estimatedtime }} min</p>
			</div>
			<div class="column is-2 flex-center justify-center">
				<div v-if="typeof topicDueStatus == 'object'" class="due grade" :class="topicDueStatus.isPassing ? null : 'red-font'">
					{{ (topicDueStatus.score * 100).toFixed(1) + "%" }}
				</div>
				<div v-if="topicDueStatus == 'done'" class="due flex">
					<img :src="check" class="topic-icon" />
				</div>
				<div v-else-if="topicDueStatus >= 0" class="due condensed">{{ topicDueStatus }} days</div>
				<div v-else-if="topicDueStatus < 0" class="due overdue">{{ topicDueStatus }} days</div>
			</div>
		</div>

		<!-- bar row  -->
		<div v-for="(monday, index) in mondays" :key="topic.es_topicid + 'topicrow' + index" class="row-column row border-right border-bottom">
			<div
				v-for="i in 7"
				:key="topic['es_topicid'] + index + i"
				class="day-column relative"
				:class="due === index * 7 + i - 1 ? 'has-due' : null"
			>
				<b-tooltip position="is-top" v-if="data[index * 7 + i - 1]" :label="formatDate(dates[index * 7 + i - 1])" type="is-info">
					<div class="full absolute" :class="determineClass(index * 7 + i - 1) + determineLocked()" @click="navToTopic">
						<div v-if="due === index * 7 + i - 1" class="due-dot" />
					</div>
				</b-tooltip>

				<b-tooltip v-else-if="due === index * 7 + i - 1" :label="formatDate(dates[index * 7 + i - 1])" class="just-due" type="is-info">
					<div class="due-dot just-due" />
				</b-tooltip>
			</div>
		</div>
	</div>
</template>

<script>
import contentActive from "../assets/content-icon.png"
import quizActive from "../assets/quiz-icon.png"
import labActive from "../assets/terminal-empty.png"
import check from "../assets/check.png"
import moment from "moment"
import { mapGetters, mapActions } from "vuex"
import EventBus from "../event-bus"
import router from "../router/router.js"

export default {
	name: "TopicRow",
	props: ["topic", "mondays", "topicDueStatus", "module", "dates"],
	data() {
		return {
			contentActive: contentActive,
			quizActive: quizActive,
			labActive: labActive,
			check: check,
			data: null,
			due: null
		}
	},
	computed: {
		...mapGetters(["attempts"])
	},
	created() {
		this.makeDataArray()
	},
	methods: {
		...mapActions(["updateCurrentTopic", "updateCurrentModule", "navigateToTopic"]),
		determineClass(index) {
			if (this.data[index] === "start and end") {
				return "round"
			} else if (this.data[index] === "start") {
				return "round-start"
			} else if (this.data[index] === "end") {
				return "round-end"
			} else if (typeof this.data[index] === "object") {
				return "has-due"
			}
		},
		determineLocked() {
			if (!this.topic.isLocked) {
				return " cursor-pointer"
			} else {
				return ""
			}
		},
		navToTopic() {
			if (!this.topic.isLocked) {
				this.updateCurrentModule(this.module)
				this.updateCurrentTopic(this.topic)
				this.navigateToTopic(this.topic)
			}
		},
		formatDate(date) {
			if (!date) {
				return null
			}
			let formattedDate = moment(date)
				.startOf("day")
				.format("MMM D, YYYY")
			if (formattedDate) {
				return formattedDate
			}
			return null
		},
		makeDataArray() {
			let dates = this.dates
			let dataArray = []
			let started = false
			let ended = false

			let dueDate = moment(this.topic.es_duedate)
				.startOf("day")
				.format("MMM D, YYYY")

			let formattedDate = moment(this.topic.es_startdate)
				.startOf("day")
				.format("MMM D, YYYY")

			dates.forEach((date, index) => {
				if (!ended) {
					if (
						!started &&
						moment.tz(this.topic.es_startdate, "UTC").isSame(moment.tz(date, "America/Chicago"), "day") &&
						moment.tz(this.topic.es_startdate, "UTC").isSame(moment(new Date(this.topic.es_enddate)), "day")
					) {
						//start and end date are the same day
						dataArray.push("start and end")
					} else if (!started && moment.tz(this.topic.es_startdate, "UTC").isSame(moment.tz(date, "America/Chicago"), "day")) {
						//if start date then push start
						dataArray.push("start")
						started = true
					} else if (started && moment.tz(this.topic.es_enddate, "UTC").isSame(date, "day")) {
						//if already started and is end date push end
						dataArray.push("end")
						started = false
						ended = true
					} else if (index == dates.length - 1 && started) {
						//if started, not the end, but last index (e.g. dates reversed, no enddate, etc)
						dataArray.push("end")
						started = false
						ended = true
					} else if (started) {
						//if started but not end date, push true
						dataArray.push(true)
					} else {
						dataArray.push(false)
					}
				} else {
					dataArray.push(false)
				}

				if (moment.tz(this.topic.es_duedate, "UTC").isSame(moment(date), "day")) {
					this.due = index
				}
			})
			this.data = dataArray
			EventBus.$emit("rows-built")
		}
	}
}
</script>

<style lang="scss">
#app .topic-row {
	.b-tooltip {
		display: inline;
		position: absolute;
		left: 0;
	}

	.b-tooltip.is-top .tooltip-content {
		transform: translateX(-41%);
	}

	.b-tooltip.is-top.just-due .tooltip-content {
		transform: translateX(-50%);
	}

	.b-tooltip:after {
		line-height: 0.85rem;
	}

	.b-tooltip:not([data-label=""]):hover:before,
	.b-tooltip:not([data-label=""]):hover:after {
		margin-left: 9px;
	}

	.just-due.b-tooltip:not([data-label=""]):hover:before,
	.just-due.b-tooltip:not([data-label=""]):hover:after {
		margin-left: -1px;
	}

	.due {
		font-size: 0.65rem;
	}

	.flex {
		display: flex;
	}

	.grade {
		color: #29a329;
		font-size: 0.75rem !important;
	}
}
.cursor-pointer {
	cursor: pointer;
}

.hover-ice:hover {
	background-color: #eef7fb;
}

.locked {
	// opacity: 0.5;
	// border-color: rgba(91.5, 91.5, 91.5, 1) !important;
	color: gray;
}
.icon-container {
	svg {
		font-size: 20px;
	}
}

.red-font {
	color: red !important;
}
</style>
