<template>
	<div class="quiz-renderer-component" v-if="currentQuestion && data && questionChoices">
		<div class="question-container">
			<div class="markdown">
				<markdown-renderer
					v-if="data[currentQuestionIndex].markdown.join('\n')"
					:markdownProp="data[currentQuestionIndex].markdown.join('\n')"
					:key="currentQuestion.es_topicquestionid + 'markdown'"
				/>
			</div>

			<multiple-select-quiz-question
				v-if="currentQuestion.es_type == '717580004' || currentQuestion.es_type == 'Multiple Select'"
				class="quiz-question"
				:questionId="currentQuestion.es_topicquestionid"
				:key="currentQuestion.es_topicquestionid + 'msqq'"
			/>
			<open-response-quiz-question
				v-else-if="currentQuestion.es_type == '717580006' || currentQuestion.es_type == 'Open Response'"
				class="quiz-question"
				:questionId="currentQuestion.es_topicquestionid"
				:key="currentQuestion.es_topicquestionid + 'orqq'"
			/>
			<upload-quiz-question
				v-else-if="currentQuestion.es_type == '717580007' || currentQuestion.es_type == 'Upload'"
				class="quiz-question"
				:questionId="currentQuestion.es_topicquestionid"
				:key="currentQuestion.es_topicquestionid + 'uqq'"
			/>

			<quiz-question
				v-else
				class="quiz-question"
				:key="currentQuestion.es_topicquestionid + 'qq'"
				:questionId="currentQuestion.es_topicquestionid"
			/>
		</div>
	</div>
</template>

<script>
import EventBus from "../event-bus"
import { mapGetters, mapActions } from "vuex"
import QuizQuestion from "./QuizQuestion"
import MultipleSelectQuizQuestion from "./MultipleSelectQuizQuestion"
import OpenResponseQuizQuestion from "./OpenResponseQuizQuestion"
import UploadQuizQuestion from "./UploadQuizQuestion"
import MarkdownRenderer from "./MarkdownRenderer"
import moment from "moment"

export default {
	name: "QuizRenderer",
	props: ["number"],
	components: {
		QuizQuestion,
		MultipleSelectQuizQuestion,
		OpenResponseQuizQuestion,
		UploadQuizQuestion,
		MarkdownRenderer
	},
	data() {
		return {
			parts: null,
			contentItemList: null,
			precedingContent: null,
			data: null,
			emptyPosted: false,
			quizGenerated: false
		}
	},
	computed: {
		...mapGetters([
			"currentTopic",
			"questionChoices",
			"currentTopic",
			"currentQuestion",
			"currentQuestionIndex",
			"quizQuestionsArr",
			"currentAttempt",
			"currentCohort",
			"currentEnrollment",
			"continuingQuiz"
		])
	},
	mounted() {
		this.getQuestions()
		if (!this.continuingQuiz && this.currentAttempt && this.questionChoices) {
			this.setQuizQuestionsArr(this.makeQuestionArray())
		} else {
			if (this.quizQuestionsArr && this.quizQuestionsArr.length > 0) {
				this.updateCurrentQuestion(this.quizQuestionsArr[this.currentQuestionIndex])
				this.makePartsArray()
			}
		}
	},
	watch: {
		quizQuestionsArr: function() {
			if (this.quizQuestionsArr && this.quizQuestionsArr.length > 0) {
				this.makePartsArray()
				this.updateCurrentQuestion(this.quizQuestionsArr[this.currentQuestionIndex])
			}
		},
		currentAttempt: function() {
			if (!this.continuingQuiz && this.currentAttempt && this.questionChoices) {
				this.setQuizQuestionsArr(this.makeQuestionArray())
			}
		},
		questionChoices: function() {
			if (!this.continuingQuiz && this.currentAttempt && this.questionChoices) {
				if (!this.quizGenerated) {
					this.setQuizQuestionsArr(this.makeQuestionArray())
				}
			}
		}
	},
	methods: {
		...mapActions([
			"setRootTimer",
			"getCurrentQuestionChoices",
			"updateCurrentQuestion",
			"updateCurrentQuestionIndex",
			"setQuizQuestionsArr",
			"submitSelections"
		]),
		getQuestions() {
			this.getCurrentQuestionChoices()
		},
		makePartsArray() {
			if (this.quizGenerated) {
				return
			} else {
				this.quizGenerated = true
			}
			//reset contentItemList
			this.contentItemList = []
			//reset loaded
			this.loaded = false
			if (this.currentTopic.es_content) {
				//split into parts by every new line character
				this.parts = this.currentTopic.es_content.split("\n")
				let prevIndex = 0
				let questionIndex = 0
				this.data = []
				this.parts.forEach((part, index) => {
					let type = part.substring(1, part.indexOf(":"))
					if (type === "question") {
						let question = {
							questionIndex: questionIndex,
							markdown: this.parts.slice(prevIndex, index)
						}
						this.data.push(question)

						questionIndex++
						prevIndex = index + 1
					}
				})
			}
		},
		makeQuestionArray() {
			//questionChoices are answer choices for the current topic's questions
			//the questions are expanded

			//prevent executing for wrong topic id
			if (!this.questionsForCurrentTopic(this.questionChoices)) {
				return []
			}

			let choices = this.questionChoices
			let arr = []

			//make array of all questions for the topic
			choices.forEach(choice => {
				let question = choice.es_topicquestion

				//questions are children of questionchoice objects, so questions have duplicates
				//make sure this question isn't already added
				if (
					!arr.some(e => {
						return e.es_topicquestionid === question.es_topicquestionid
					})
				) {
					arr.push(question)
				}
			})

			//filter for questions that are in the markdown
			arr = this.filterForMarkdownQuestions(arr)

			// sort in order appear in markdown
			arr = this.sortQuestionsByMarkdownOrder(arr)

			//return array if not truncated
			if (!this.emptyPosted) {
				this.emptyPosted = true
				this.postEmptyQuiz(arr)
			}
			return arr
		},
		filterForMarkdownQuestions(arr) {
			//check if topic's markdown includes the [question:id-##] string
			return arr.filter(question => {
				return this.currentTopic.es_content.includes(`[question:id-${question.es_question_id}]`)
			})
		},
		sortQuestionsByMarkdownOrder(arr) {
			let parts = this.currentTopic.es_content.split("\n")
			let questionParts = parts.filter(part => {
				return part.indexOf("question:id") > 0
			})
			let orderedQuestionIds = questionParts.map(part => {
				let questionId = part.substring(part.indexOf("question:id-") + 12, part.indexOf("]"))
				return questionId
			})

			let sortedQuestions = orderedQuestionIds.map(id => {
				return arr.find(question => {
					return question.es_question_id === id
				})
			})

			return sortedQuestions
		},
		questionsForCurrentTopic(choices) {
			return choices && choices[0].es_topicquestion && choices[0].es_topicquestion._es_topic_value == this.currentTopic.es_topicid
		},
		postEmptyQuiz(arr) {
			let sort = 0
			let newArr = arr.map(item => {
				sort++
				return {
					es_topicquestionid: item.es_topicquestionid,
					es_text: item.es_text,
					es_type: item.es_type,
					es_selectionstatus: 717580002, //unanswered
					choices: null,
					es_sort: sort - 1
				}
			})

			this.submitSelections({
				es_attemptid: this.currentAttempt,
				es_enrollmentid: this.currentEnrollment.es_enrollmentid,
				es_cohortid: this.currentCohort.es_cohortid,
				es_topicid: this.currentTopic.es_topicid,
				es_timestart: moment(),
				es_issubmitted: false,
				questions: newArr
			})
		}
	}
}
</script>

<style lang="scss">
#app .quiz-renderer-component {
	margin-top: 3rem;
	padding-bottom: 0.5rem;
	margin: 0;
	overflow-y: scroll;
	overflow-x: hidden;
	position: relative;
	height: calc(100% - 4.5rem);

	.question-container {
		max-width: 1080px;
		margin-left: auto;
		margin-right: auto;
		padding-left: 2rem;
		padding-right: 2rem;
		margin-top: 3rem;
	}

	.question-margin-bottom {
		margin-bottom: 10px;
		// padding-left:1rem;
	}

	p {
		// padding-left: 1rem;
		margin: 1rem 0rem;
		line-height: 1.5rem;
	}

	p.question-margin-bottom {
		padding-left: 1rem;
	}

	.choices-block {
		display: flex;
		flex-direction: column;
		padding: 1rem;
		justify-content: space-between;
	}

	.b-radio.radio + .radio {
		margin-left: 0rem;
	}
}
</style>
