<template>
    <div class="schedule" v-if="cohortsArray && currentSessions">
        <gantt-chart />
        <!--feedback modal-->

        <b-modal
            :active.sync="showSession"
            has-modal-card
            trap-focus
            aria-role="dialog"
            aria-modal
            id="feedback-modal"
            class="feedback-modal"
        >
            <session-page :sessionData="sessionData" />
        </b-modal>
    </div>
    <Loader v-else />
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import GanttChart from "../components/GanttChart.vue";
import EventBus from "../event-bus";
import SessionPage from "../components/SessionPage.vue";
import Loader from "./Loader.vue";
import router from "../router/router.js";

export default {
    name: "Schedule",
    components: { GanttChart, SessionPage, Loader },
    computed: mapGetters([
        "currentPage",
        "cohortsArray",
        "currentSessions",
        "allSessions",
        "currentCohort"
    ]),
    data() {
        return {
            showSession: false,
            sessionData: null
        };
    },
    watch: {
        allSessions: function() {
            this.makeCurrentSessions();
        },
        currentCohort: function() {
            if (!this.allSessions) {
                this.getSessions();
            } else {
                this.makeCurrentSessions();
            }
        }
    },
    created() {
        EventBus.$on("session-clicked", data => {
            this.showSession = !this.showSession;
            this.sessionData = data;
        });
    },
    mounted() {
        if (!this.allSessions && this.currentCohort) {
            this.getSessions();
        } else if (
            this.allSessions &&
            this.allSessions[0] &&
            this.allSessions[0]._es_cohort_value !=
                this.currentCohort.es_cohortid
        ) {
            this.getSessions();
        }

        //check current sessions are for current cohort
        if (this.currentSessions && this.currentSessions[0]) {
            if (
                this.currentSessions[0]._es_cohort_value !=
                this.currentCohort.es_cohortid
            ) {
                this.makeCurrentSessions();
            }
        }
    },
    methods: {
        ...mapActions(["makeCurrentSessions", "getSessions"])
    }
};
</script>

<style lang="scss">
#app .schedule {
    .menu {
        border: 1px black solid;
    }

    /*styles the modal*/
    .animation-content {
        background-color: white;
        z-index: 5;
        display: block;
        padding: 1rem;
        min-width: 526px;
    }

    /*styles the modal*/
    .animation-content {
        overflow-y: scroll;
        max-height: 90%;
        border-radius: 10px;
    }
}
</style>