import Vue from "vue"
import App from "./App.vue"
import axios from "axios"
import Buefy from "buefy"
import "buefy/dist/buefy.css"
import store from "./store"
import router from "./router/router"
import browserDetect from "vue-browser-detect-plugin"

import CyberLabHub from "./hubs/cyberLabHub"

import { library } from "@fortawesome/fontawesome-svg-core"
// internal icons
import {
	faChevronUp,
	faChevronDown,
	faChevronRight,
	faChevronLeft,
	faAngleRight,
	faArrowDown,
	faArrowUp,
	faArrowLeft,
	faArrowRight,
	faAngleDoubleLeft,
	faAngleDoubleRight,
	faArrowsAltV,
	faTimes,
	faCheck,
	faPlay,
	faStop,
	faRedo,
	faUndo,
	faAngleDown,
	faAngleUp,
	faCaretUp,
	faCaretDown,
	faHourglass,
	faFlask,
	faCog,
	faCogs,
	faFileVideo,
	faQuestionCircle,
	faExternalLinkAlt,
	faClipboardCheck,
	faPowerOff,
	faInfoCircle,
	faFilePdf,
	faPlus,
	faUpload,
	faCheckCircle,
	faTimesCircle,
	faUsersCog,
	faEdit,
	faPrint,
	faMinus,
	faLock
} from "@fortawesome/free-solid-svg-icons"

import { faPlayCircle, faComments, faClock, faCalendarCheck, faFrown, faFile, faCircle } from "@fortawesome/free-regular-svg-icons"

import { faLinkedin } from "@fortawesome/free-brands-svg-icons"

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import HomeIcon from "vue-material-design-icons/AccountGroup.vue"
import MeetingRoomIcon from "vue-material-design-icons/AccountMultiple.vue"
import PhoneCancelIcon from "vue-material-design-icons/PhoneCancel.vue"

Vue.component("home-icon", HomeIcon)
Vue.component("meeting-room-icon", MeetingRoomIcon)
Vue.component("phone-cancel-icon", PhoneCancelIcon)

library.add(
	faChevronUp,
	faChevronDown,
	faChevronRight,
	faChevronLeft,
	faAngleRight,
	faArrowDown,
	faArrowUp,
	faAngleDoubleLeft,
	faAngleDoubleRight,
	faArrowsAltV,
	faTimes,
	faCheck,
	faPlay,
	faStop,
	faRedo,
	faUndo,
	faAngleDown,
	faAngleUp,
	faCaretUp,
	faCaretDown,
	faHourglass,
	faFlask,
	faCog,
	faCogs,
	faPlayCircle,
	faQuestionCircle,
	faFileVideo,
	faExternalLinkAlt,
	faLinkedin,
	faPowerOff,
	faComments,
	faClipboardCheck,
	faClock,
	faTimes,
	faCalendarCheck,
	faInfoCircle,
	faFrown,
	faCircle,
	faFilePdf,
	faPlus,
	faUpload,
	faFile,
	faCheckCircle,
	faTimesCircle,
	faUsersCog,
	faEdit,
	faPrint,
	faArrowLeft,
	faArrowRight,
	faMinus,
	faLock
)

Vue.component("vue-fontawesome", FontAwesomeIcon)

Vue.use(Buefy, {
	defaultIconComponent: "vue-fontawesome",
	defaultIconPack: "fas"
})
Vue.use(CyberLabHub)
Vue.use(browserDetect)

Vue.prototype.$axios = axios
Vue.config.productionTip = false

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount("#app")
