<template>
    <div class="quiz-component" v-if="currentQuestion">
        <multiple-select-quiz-question
            v-if="currentQuestion.es_type=='717580004' || currentQuestion.es_type=='Multiple Select'"
            class="quiz-question"
            :questionId="currentQuestion.es_topicquestionid"
            :key="currentQuestion.es_topicquestionid+'msqq'"
        />
        <open-response-quiz-question
            v-else-if="currentQuestion.es_type=='717580006' || currentQuestion.es_type=='Open Response'"
            class="quiz-question"
            :questionId="currentQuestion.es_topicquestionid"
            :key="currentQuestion.es_topicquestionid+'orqq'"
        />
        <upload-quiz-question
            v-else-if="currentQuestion.es_type=='717580007' || currentQuestion.es_type=='Upload'"
            class="quiz-question"
            :questionId="currentQuestion.es_topicquestionid"
            :key="currentQuestion.es_topicquestionid+'uqq'"
        />

        <quiz-question
            v-else
            class="quiz-question"
            :key="currentQuestion.es_topicquestionid+'qq'"
            :questionId="currentQuestion.es_topicquestionid"
        />
    </div>
</template>

<script>
import EventBus from "../event-bus";
import { mapGetters, mapActions } from "vuex";
import QuizQuestion from "./QuizQuestion";
import MultipleSelectQuizQuestion from "./MultipleSelectQuizQuestion";
import OpenResponseQuizQuestion from "./OpenResponseQuizQuestion";
import UploadQuizQuestion from "./UploadQuizQuestion";
import moment from "moment";

export default {
    name: "Quiz",
    props: ["number"],
    components: {
        QuizQuestion,
        MultipleSelectQuizQuestion,
        OpenResponseQuizQuestion,
        UploadQuizQuestion
    },
    mounted() {
        if (!this.continuingQuiz && this.currentAttempt) {
            this.setQuizQuestionsArr(this.makeQuestionArray());
        } else {
            if (this.quizQuestionsArr && this.quizQuestionsArr.length > 0) {
                this.updateCurrentQuestion(
                    this.quizQuestionsArr[this.currentQuestionIndex]
                );
            }
        }
    },

    computed: {
        ...mapGetters([
            "currentTopic",
            "questionChoices",
            "currentTopic",
            "currentQuestion",
            "currentQuestionIndex",
            "quizQuestionsArr",
            "currentAttempt",
            "currentCohort",
            "currentEnrollment",
            "continuingQuiz"
        ])
    },
    watch: {
        quizQuestionsArr: function() {
            if (this.quizQuestionsArr.length > 0) {
                this.updateCurrentQuestion(
                    this.quizQuestionsArr[this.currentQuestionIndex]
                );
            }
        },
        currentAttempt: function() {
            if (!this.continuingQuiz && this.currentAttempt) {
                this.setQuizQuestionsArr(this.makeQuestionArray());
            }
        }
    },
    methods: {
        ...mapActions([
            "setRootTimer",
            "getCurrentQuestionChoices",
            "updateCurrentQuestion",
            "updateCurrentQuestionIndex",
            "setQuizQuestionsArr",
            "submitSelections"
        ]),
        makeQuestionArray() {
            //questionChoices are answer choices for the current topic's questions
            //the questions are expanded
            let choices = this.questionChoices;
            let arr = [];

            //make array of all questions for the topic
            choices.forEach(choice => {
                let question = choice.es_topicquestion;

                //questions are children of questionchoice objects, so questions have duplicates
                //make sure this question isn't already added
                if (
                    !arr.some(
                        e =>
                            e.es_topicquestionid === question.es_topicquestionid
                    )
                ) {
                    arr.push(question);
                }
            });

            //randomize array
            arr = this.shuffle(arr);

            //truncate the array
            if (this.number) {
                let val = arr.slice(0, this.number);
                this.postEmptyQuiz(val);

                return val;
            }

            //return array if not truncated
            this.postEmptyQuiz(arr);
            return arr;
        },
        shuffle(arr) {
            //randomly shuffles an array
            var currentIndex = arr.length,
                temporaryValue,
                randomIndex;

            // While there remain elements to shuffle...
            while (0 !== currentIndex) {
                // Pick a remaining element...
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex -= 1;

                // And swap it with the current element.
                temporaryValue = arr[currentIndex];
                arr[currentIndex] = arr[randomIndex];
                arr[randomIndex] = temporaryValue;
            }

            return arr;
        },
        postEmptyQuiz(arr) {
            let sort = 0;
            let newArr = arr.map(item => {
                sort++;
                return {
                    es_topicquestionid: item.es_topicquestionid,
                    es_text: item.es_text,
                    es_type: item.es_type,
                    es_selectionstatus: 717580002, //unanswered
                    choices: null,
                    es_sort: sort - 1
                };
            });

            this.submitSelections({
                es_attemptid: this.currentAttempt,
                es_enrollmentid: this.currentEnrollment.es_enrollmentid,
                es_cohortid: this.currentCohort.es_cohortid,
                es_topicid: this.currentTopic.es_topicid,
                es_timestart: moment(),
                es_issubmitted: false,
                questions: newArr
            });
        }
    }
};
</script>

<style lang="scss">
#app .quiz-component {
    margin-top: 3rem;
    padding-bottom: 0.5rem;
}
</style>