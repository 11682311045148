<template>
	<div class="reset-quiz-modal">
		<h2>Student: {{ gradebookCurrentAttempts.fullname }}</h2>
		<h2>Module: {{ gradebookCurrentAttempts.topic.module }}</h2>
		<h2>Topic: {{ gradebookCurrentAttempts.topic.name }}</h2>
		<h2 class="mb-md">
			Highest Score:
			<span>
				{{
					gradebookCurrentAttempts.attempt && gradebookCurrentAttempts.attempt.length > 0
						? Math.floor(gradebookCurrentAttempts.attempt[0].score * 100) + "%"
						: "None"
				}}
			</span>
		</h2>

		<div v-if="!gradebookCurrentAttempts.attempt || gradebookCurrentAttempts.attempt.length === 0" class="flex-center mt-lg">
			<p>No attempts for this topic</p>
		</div>

		<div class="close">
			<b-button @click="$emit('closeModal')">Close Modal</b-button>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"

export default {
	props: [],
	components: {},
	data() {
		return {}
	},
	computed: {
		...mapGetters(["currentCohort", "gradebookCurrentAttempts"])
	},
	created() {},
	watch: {},
	methods: {}
}
</script>

<style lang="scss">
#app .reset-quiz-modal {
	background-color: white !important;
	z-index: 5;
	display: block;
	padding: 2rem;
	border-radius: 5px;
	overflow-x: hidden;
	min-height: 15rem !important;
	height: fit-content;
	width: unset;

	.flex {
		width: 15rem;
		margin-left: auto;
		margin-right: auto;
		align-items: center;
		display: flex;
		padding-top: 2rem;
		justify-content: space-around;
	}

	.col {
		width: 5rem;
	}

	.close {
		display: flex;
		justify-content: center;
		padding-top: 3rem;
	}

	.flex-center {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.mt-lg {
		margin-top: 2rem;
	}

	.mb-md {
		margin-bottom: 1rem;
	}
}
</style>
