var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"markdown-renderer-component",class:_vm.takingQuiz && this.currentTopic.es_type != 717580004 && !this.markdownProp
			? 'quiz-height'
			: this.currentTopic.es_type == 717580004 && !this.markdownProp
			? 'quiz-lab'
			: null},[(_vm.loaded && _vm.contentItemList)?_c('div',{staticClass:"markdown has-padding max-width hundo-width",class:_vm.additionalClasses(),staticStyle:{"padding-top":"1rem"},attrs:{"id":"markdown"}},_vm._l((_vm.contentItemList),function(item,index){return _c('div',{key:index},[(item.type === 'image')?_c('div',{staticClass:"has-center-text-align image"},[_c('content-image',{attrs:{"image":item}})],1):(item.type === 'question')?_c('div',{staticClass:"content-question-container"},[(
						[717580002, 717580004, 717580005].includes(_vm.currentTopic.es_type) &&
							item.questionChoices[0].es_topicquestion.es_type == 717580004
					)?_c('multiple-select-quiz-question',{key:item.questionId,staticClass:"quiz-question",attrs:{"questionId":item.questionId}}):(
						[717580002, 717580004, 717580005].includes(_vm.currentTopic.es_type) &&
							item.questionChoices[0].es_topicquestion.es_type == 717580006
					)?_c('open-response-quiz-question',{key:item.questionId,staticClass:"quiz-question",attrs:{"questionId":item.questionId}}):(
						[717580002, 717580004, 717580005].includes(_vm.currentTopic.es_type) &&
							item.questionChoices[0].es_topicquestion.es_type == 717580007
					)?_c('upload-quiz-question',{key:item.questionId,staticClass:"quiz-question",attrs:{"questionId":item.questionId}}):([717580002, 717580004, 717580005].includes(_vm.currentTopic.es_type))?_c('quiz-question',{key:item.questionId,staticClass:"quiz-question",attrs:{"questionId":item.questionId}}):_c('content-question',{staticClass:"content-question",attrs:{"choices":item.questionChoices}})],1):(item.type === 'snippet')?_c('div',[_c('div',{staticClass:"markdown snippet",domProps:{"innerHTML":_vm._s(_vm.compiledSnippet(item.value))}})]):(item.type === 'quiz')?_c('div',[_c('quiz',{key:_vm.currentAttempt + 'quiz',attrs:{"number":item.value}})],1):(item.type === 'iframe')?_c('div',[_c('markdown-iframe',{attrs:{"value":item.value,"number":index}})],1):(item.type === 'callout')?_c('span',[_c('p',{staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(item.value)+" ")])]):_c('div',{staticClass:"test",staticStyle:{"margin-left":"1rem"}},[_c('inline-handler',{attrs:{"inlineText":_vm.compiledMarkdown(item.value)}})],1)])}),0):_c('Loader')],1)}
var staticRenderFns = []

export { render, staticRenderFns }