<template>
    <div
        v-if="loadedQuestion && shuffledAnswerChoices"
        class="ms-quiz-question-component"
        :key="questionId"
    >
        <!-- multiple select -->
        <!-- question text -->
        <p class="question-margin-bottom">{{this.shuffledAnswerChoices[0].es_topicquestion.es_text}}</p>

        <!-- choices -->
        <div class="choices-block">
            <b-checkbox
                v-for="(choice) in shuffledAnswerChoices"
                :key="choice.es_topicquestionchoice_id"
                v-model="checkboxChoices"
                :native-value="choice.es_text"
                :name="choice.es_topicquestionchoiceid"
                @input="handleChange()"
            >
                <div class="field">
                    <div class="choice-container">
                        <div class="choice-text">{{choice.es_text}}</div>
                    </div>
                </div>
            </b-checkbox>

            <!-- selections -->
            <p class="content">
                <b>Selection:</b>
                {{ checkboxChoices }}
            </p>
        </div>
    </div>
    <Loader v-else />
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import EventBus from "../event-bus";
import Loader from "./Loader";

export default {
    name: "MultipleSelectQuizQuestion",
    props: ["questionId"],
    components: { Loader },
    computed: {
        ...mapGetters([
            "questionChoices",
            "currentCohort",
            "currentQuestion",
            "currentQuestionIndex",
            "previousSelections",
            "continuingQuiz"
        ])
    },
    data() {
        return {
            loadedQuestion: null,
            radio: null,
            correctAnswer: false,
            answer: null,
            filteredChoices: null,
            shuffledAnswerChoices: null,
            checkboxChoices: []
        };
    },
    watch: {
        currentQuestion: function() {
            this.getQuestion();
        }
    },
    mounted() {
        this.getQuestion();
        this.checkPrevious();
    },
    methods: {
        ...mapActions(["answerQuizQuestion"]),
        checkPrevious() {
            if (this.previousSelections && this.continuingQuiz) {
                //find selection for this question

                let selection = this.findSelection();

                //save choice
                let choice = selection.es_choice_text;

                //split choices
                if (choice && choice != "No answer selected") {
                    var choiceArr = choice.split(", ");
                    choiceArr.forEach(choice => {
                        this.checkboxChoices.push(choice);
                    });
                    this.handleChange();
                }
            }
        },
        findSelection() {
            if (this.currentQuestion) {
                return this.previousSelections.find(selection => {
                    return (
                        this.currentQuestion.es_question_id ==
                        selection.es_question_id
                    );
                });
            } else if (this.questionId) {
                return this.previousSelections.find(selection => {
                    return this.questionId == selection.es_question_id;
                });
            } else {
                return null;
            }
        },
        getQuestion() {
            //find an answer choice from this topic with the same questionid
            let questionChoice = this.questionChoices.find(choice => {
                return (
                    choice.es_topicquestion.es_topicquestionid ==
                    this.questionId
                );
            });

            //set question in data equal to that choices question (it is expanded)
            this.loadedQuestion = questionChoice.es_topicquestion;

            //filter choices by questionid
            this.filteredChoices = this.filterChoices();

            //shuffle the filtered answer choices
            this.shuffledAnswerChoices = this.shuffleChoices(
                this.filteredChoices
            );

            //submit an unanswered question (placeholder selection in case student chooses no answer)
            // if (!this.continuingQuiz) {
            this.submitUnansweredQuestion();

            // }
        },
        submitUnansweredQuestion() {
            //sends a "no choice selected/answered" default selection to quizbutton component
            // EventBus.$emit("answered-quiz-question", {
            //     es_topicquestionid: this.shuffledAnswerChoices[0]
            //         .es_topicquestion.es_topicquestionid,
            //     choices: null,
            //     es_text: this.loadedQuestion.es_text,
            //     es_type: this.shuffledAnswerChoices[0].es_topicquestion.es_type,
            //     es_sort: this.currentQuestionIndex
            // });
            this.answerQuizQuestion({
                es_topicquestionid: this.shuffledAnswerChoices[0]
                    .es_topicquestion.es_topicquestionid,
                choices: null,
                es_text: this.loadedQuestion.es_text,
                es_type: this.shuffledAnswerChoices[0].es_topicquestion.es_type,
                es_sort: this.currentQuestionIndex
            });
        },
        filterChoices() {
            //filters answer choices by current questions
            let filteredChoices = this.questionChoices.filter(
                choice =>
                    this.questionId ==
                    choice.es_topicquestion.es_topicquestionid
            );
            return filteredChoices;
        },
        shuffleChoices(filteredChoices) {
            //shuffles the filtered choices
            var currentIndex = filteredChoices.length,
                temporaryValue,
                randomIndex;

            // While there remain elements to shuffle...
            while (0 !== currentIndex) {
                // Pick a remaining element...
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex -= 1;

                // And swap it with the current element.
                temporaryValue = filteredChoices[currentIndex];
                filteredChoices[currentIndex] = filteredChoices[randomIndex];
                filteredChoices[randomIndex] = temporaryValue;
            }

            return filteredChoices;
        },
        handleChange() {
            //emits to quizbutton and adds object to array this.correctQuestions which is passed to the server
            //when the quiz is submitted

            //filter for choice objects
            let studentChoices = this.shuffledAnswerChoices.filter(answer =>
                this.checkboxChoices.includes(answer.es_text)
            );

            studentChoices = studentChoices.map(choice => {
                choice.es_topicquestionid =
                    choice.es_topicquestion.es_topicquestionid;
                return choice;
            });

            // EventBus.$emit("answered-quiz-question", {
            //     es_topicquestionid: this.shuffledAnswerChoices[0]
            //         .es_topicquestion.es_topicquestionid,
            //     es_type: this.shuffledAnswerChoices[0].es_topicquestion.es_type,
            //     es_text: this.loadedQuestion.es_text,
            //     choices: studentChoices,
            //     es_sort: this.currentQuestionIndex
            // });
            this.answerQuizQuestion({
                es_topicquestionid: this.shuffledAnswerChoices[0]
                    .es_topicquestion.es_topicquestionid,
                es_type: this.shuffledAnswerChoices[0].es_topicquestion.es_type,
                es_text: this.loadedQuestion.es_text,
                choices: studentChoices,
                es_sort: this.currentQuestionIndex
            });
        }
    }
};
</script>

<style lang='scss'>
#app .ms-quiz-question-component {
    -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    padding: 0.5rem;
    margin-left: 1rem;
    background-color: #dde4fb;
    margin-right: 5rem;
    margin-top: 2rem;
    margin-bottom: 1rem;

    .message-box {
        margin: 1rem;
        border: 1px solid #2ca8df;
        height: 6rem;
        overflow-y: auto;
        padding: 0.5rem 1rem;
        background-color: #ced9fd;
    }

    .flex {
        display: flex;
    }

    .align-center {
        align-items: center;
    }

    .choice-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .validity-icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 24px;
        margin-top: 0px;
        padding-left: 12px;
    }

    .empty {
        padding: 12px;
    }

    .b-radio.radio {
        margin-bottom: 0.5rem;
    }

    .button {
        margin-left: 1rem;
    }

    .choices-block .b-tooltip {
        line-height: 1.5rem;
    }

    .checkbox {
        margin-bottom: 0.5rem;
        line-height: 1.5rem;
    }

    .b-checkbox.checkbox + .checkbox {
        margin-left: 0rem;
    }
}
</style>