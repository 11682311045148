<template>
    <!-- lab -->
    <span class="lab-component flex hundo-height hundo-width">
        <div id="lab-columns" class="is-mobile is-gapless lab-columns overflow-x-hidden">
            <div
                id="content-column-left"
                class="no-bottom-padding overflow-scroll overflow-x-hidden"
            >
                <markdown-renderer />
                <!-- normal lab submit button -->
                <submit-button v-if="currentTopic.es_type != 717580004" />
                <!-- final lab submit button -->
                <final-lab-button v-else :key="currentTopic.es_topicid+currentTopic.es_type" />
            </div>
            <resizer-column />
            <div id="content-column-right" class="relative flex-grow-3 terminal-section">
                <div class="overlay-waiting-launch" v-if="showStartLaunch">
                    <div
                        class="start-launch-btn guac-auth-btn--animated"
                        @click="removeStartLaunchOverlay"
                    >
                        <vue-fontawesome icon="play" color="#ffffff" />
                        <span class="start-launch-btn--text">Start Lab</span>
                    </div>
                </div>
                <p id="focus-iframe-coverer" class="full-height transparent" @click="focusGuac()"></p>
                <p id="iframe-coverer" class="hidden"></p>
                <iframe
                    title="guacamole virtual machines"
                    sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                    class="flex hundo-width hundo-minus-40-height top-margin-2-5"
                    id="guac"
                    ref="guac"
                    :src="frameUrl"
                />
                <control-panel />

                <div class="overlay-for-loader" v-if="!isMachineReady">
                    <div class="evolve-esa-linux-loader"></div>
                </div>
                <div
                    class="guac-auth-reminder guac-auth-btn--animated"
                    v-if="showGuacAuthReminder"
                    @click="showGuacAuthModal = true"
                >
                    <span>Click here if you are not connecting to the virtual machine</span>
                </div>
            </div>
        </div>

        <b-modal :active.sync="showGuacAuthModal" has-modal-card class="guac-modal">
            <header class="modal-card-head guac-modal-header">
                <p class="modal-card-title guac-modal-header-title">Connect to Virtual Machines</p>
            </header>
            <section class="modal-card-body guac-modal-body">
                <div class="guac-modal-body-text">
                    <span class="modal-body-title">Follow these steps:</span>
                    <ol class="modal-body-list">
                        <li>
                            <span class="bold-text">Click Connect</span> button below.
                        </li>
                        <li>
                            <span class="bold-text">Sign-in</span> using same log-in details used for CyberLAB™.
                        </li>
                        <li>
                            <span class="bold-text">Close popup window</span> when you are redirected to home page.
                        </li>
                    </ol>
                    <br />
                    <div class="modal-body-explain">
                        <span class="bold-text italic-text">About these steps:</span> Hands-on lab exercises are performed through virtual machines that are created for each student. Through a remote desktop gateway, you are able to access these virtual machines directly through your browser. By following the steps above, you are remotely connecting to your virtual machine.
                    </div>
                </div>
            </section>
            <footer class="modal-card-foot guac-modal-footer">
                <button
                    class="button is-primary save-button is-rounded"
                    @click="authenticateGuac"
                    :class="{'is-loading': isLoading}"
                    :disabled="disabled"
                >Connect Virtual Machines</button>
            </footer>
        </b-modal>
    </span>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Loader from "./Loader.vue";
import axios from "axios";
import moment from "moment";
import MarkdownRenderer from "./MarkdownRenderer.vue";
import SubmitButton from "./SubmitButton.vue";
import QuizButton from "./QuizButton.vue";
import ControlPanel from "./ControlPanel.vue";
import EventBus from "../event-bus";
import GuacAuthControls from "./GuacAuthControls.vue";
import ResizerColumn from "./ResizerColumn.vue";
import { openSnackBar } from "../utils/openSnackBar";
import FinalLabButton from "./FinalLabButton";
import Timer from "./Timer";

export default {
    name: "Lab",
    computed: {
        ...mapGetters([
            "currentContent",
            "currentTopic",
            "frameUrl",
            "allMachines",
            "topicContentPiecesLoaded",
            "currentMachineTypes",
            "currentShutdownTime",
            "currentCohort",
            "displayStartLaunch"
        ])
    },
    data() {
        return {
            disabled: false,
            isLoading: false,
            iframe: null,
            feedbackModal: false,
            count: 0,
            isMachineReady: false,
            timeIntervalForRefresh: null,
            timeIntervalForIframeUrl: null,
            showGuacAuthModal: false,
            showGuacAuthReminder: true,
            showStartLaunch: true,
            guacamoleAuthenticated: false,
            timeWaitingForMachineReady: 5000
        };
    },
    components: {
        Loader,
        MarkdownRenderer,
        ControlPanel,
        GuacAuthControls,
        ResizerColumn,
        SubmitButton,
        FinalLabButton,
        Timer,
        QuizButton
    },
    mounted() {
        if (window.location.hostname == "localhost") {
            return null;
        }
        this.timeIntervalForRefresh = setInterval(this.sendRefreshEvent, 10000);
        this.timeIntervalForIframeUrl = setInterval(this.checkIframeUrl, 5000);
        if (this.allMachines) this.setNewMachineTypesForTopic();
        this.startMachinesForTopic();
        EventBus.$on("open-guac-auth-modal", this.authenticateGuac);
        EventBus.$on("creating-new-machine", this.onCreateNewMachine);
        EventBus.$on(
            "load-connections-complete",
            this.reloadConnectionsForTopic
        ); // Handle the scenario when entire page is reloaded
        EventBus.$on("focus-guac", () => {
            this.focusGuac();
            if (!this.isMachineReady) {
                this.startMachinesForTopic();
            }
        });

        this.$cyberlabHub.$on(
            "student-instance-status-updated",
            this.onStudentInstanceStatusUpdated
        );

        if (this.currentTopic) {
            if (this.currentTopic.es_type === 717580001) {
                document.addEventListener("click", this.showGuacCover);
                document.addEventListener("keydown", this.showGuacCover);
            }
        }
        this.compareCookie();
        this.checkGuacamoleAuth();
        this.setWaitingTime();
        // this.showStartLaunch = this.displayStartLaunch;
    },
    beforeDestroy() {
        //remove lab event listeners
        if (this.currentTopic) {
            if (this.currentTopic.es_type === 717580001) {
                document.removeEventListener("click", this.showGuacCover);
                document.removeEventListener("keydown", this.showGuacCover);
            }
        }
        this.$cyberlabHub.$off(
            "student-instance-status-updated",
            this.onStudentInstanceStatusUpdated
        );
        EventBus.$off("creating-new-machine", this.onCreateNewMachine);
        EventBus.$off("focus-guac");
        EventBus.$off("load-connections-complete");
        EventBus.$off("open-guac-auth-modal", this.authenticateGuac);
        if (this.timeIntervalForRefresh)
            clearInterval(this.timeIntervalForRefresh);
        if (this.timeIntervalForIframeUrl)
            clearInterval(this.timeIntervalForIframeUrl);
    },
    watch: {
        currentTopic: function() {
            this.adjustPadding();

            if (this.currentTopic) {
                //turn on refocus guac method
                if (this.currentTopic.es_type === 717580001) {
                    document.addEventListener("click", this.showGuacCover);
                    document.addEventListener("keydown", this.showGuacCover);
                } else {
                    //remove them when topic changes and component gets reused
                    document.removeEventListener("click", this.showGuacCover);
                    document.removeEventListener("keydown", this.showGuacCover);
                }
            }
        }
    },
    methods: {
        ...mapActions([
            "changeCurrentContent",
            "setNewMachineTypesForTopic",
            "setCurrentShutdownTime",
            "startMachinesForTopic",
            "checkMachinesForTopic",
            "refreshGuacLink",
            "setCurrentFrameUrl",
            "setMachine",
            "setStartLaunchDisplay"
        ]),
        reloadConnectionsForTopic() {
            this.setNewMachineTypesForTopic();
            EventBus.$emit("machine-ready");
        },
        showGuacCover(e) {
            //show guac cover if it wasn't the click event's target
            if (e.target.id != "focus-iframe-coverer") {
                let cover = document.getElementById("focus-iframe-coverer");

                //show iframe cover via classes
                if (cover) {
                    cover.classList.add("full-height", "transparent");
                    cover.classList.remove("hidden");
                }
            }
        },
        hideGuacCover() {
            let cover = document.getElementById("focus-iframe-coverer");

            //hide iframe cover via classes
            if (cover) {
                cover.classList.add("hidden");
                cover.classList.remove("full-height", "transparent");
            }
        },
        focusGuac() {
            //hide the cover
            this.hideGuacCover();
            // Focuses iframe if it exists
            let iframe = document.getElementById("guac");
            if (iframe) {
                iframe.focus();
            }
        },
        onCreateNewMachine(data) {
            if (data.length === 0) {
                this.setMachineReady();
            } else if (data.length === 1) {
                let requiredBoxes = this.currentMachineTypes;
                if (requiredBoxes) {
                    let isRequiredESALinux = requiredBoxes.includes(
                        "esa_linux"
                    );
                    let allBoxes = data.map(si => si.box.boxName);
                    // If it requires ESA linux and it is already active, remove the overlay
                    if (isRequiredESALinux && !allBoxes.includes("esa_linux")) {
                        this.setMachineReady();
                    }
                }
            }

            if (data.length > 0) {
                data.forEach(m => {
                    this.setMachine(this.transformStudentInstance(m));
                });
            }
        },
        onStudentInstanceStatusUpdated(data) {
            let requiredBoxes = this.currentMachineTypes;
            let isRequiredESALinux = requiredBoxes.includes("esa_linux");
            if (requiredBoxes.length === 1) {
                this.setMachineReady();
            } else if (requiredBoxes.length > 1) {
                if (isRequiredESALinux) {
                    let boxName = data.studentInstance.box.boxName;
                    let status = data.status;
                    if (boxName === "esa_linux" && status === "Active") {
                        this.setMachineReady();
                    }
                }
            }
            this.setMachine(
                this.transformStudentInstance(data.studentInstance)
            );
        },
        transformStudentInstance(data) {
            let studentInstance = {};
            studentInstance.es_name = data.name;
            studentInstance.es_studentinstanceid = data.instanceId;
            studentInstance.es_privateipaddress = data.privateIpAddress;
            studentInstance.es_ami = data.ami;
            studentInstance.es_guacterminalid = data.guacTerminalId;
            studentInstance.es_guacdesktopid = data.guacDesktopId;
            studentInstance.es_box = data.box;
            studentInstance.es_box.es_boxname = data.box.boxName;
            studentInstance.es_box.es_displayname = data.box.displayName;
            studentInstance.es_enrollment = data.enrollment;
            studentInstance.es_status = data.instanceStatus;
            studentInstance.es_timetoshutdown = data.timeToShutDown;

            return studentInstance;
        },
        setMachineReady() {
            setTimeout(
                this.updateMachineReadyStatus,
                this.timeWaitingForMachineReady
            );
            if (this.timeIntervalForRefresh) {
                clearInterval(this.timeIntervalForRefresh);
            }
            this.checkIframeUrl();
            if (this.timeIntervalForIframeUrl)
                clearInterval(this.timeIntervalForIframeUrl);
        },
        updateMachineReadyStatus() {
            this.isMachineReady = true;
            this.setCurrentShutdownTime();
            this.setCurrentFrameUrl();
            this.refreshGuacLink();
            EventBus.$emit("machine-ready");

            this.showGuacAuthReminder = true;
            let self = this;
            setTimeout(() => {
                self.showGuacAuthReminder = false;
            }, 10000);
        },
        sendRefreshEvent() {
            if (!this.isMachineReady) {
                EventBus.$emit("resizer-used");
            }
        },
        checkIframeUrl() {
            let src = document.getElementById("guac").src;
            if (src === undefined || src === `${window.location.origin}/`) {
                document.getElementById("guac").src = this.frameUrl;
                if (this.timeIntervalForIframeUrl)
                    clearInterval(this.timeIntervalForIframeUrl);
            }
        },
        checkGuacamoleAuth() {
            if (!this.guacamoleAuthenticated) {
                this.showGuacAuthModal = true;
            }
        },
        authenticateGuac() {
            let width = 600;
            let height = 800;
            let left = screen.width / 2 - width / 2;
            let top = screen.height / 2 - height / 2;
            let guacAuthenticationWindow = window.open(
                "https://guacamole.cyberlab.evolvesecurity.com/",
                "Guacamole Authentication",
                "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
                    width +
                    ", height=" +
                    height +
                    ", top=" +
                    top +
                    ", left=" +
                    left
            );

            let self = this;
            let timer = setInterval(function() {
                if (guacAuthenticationWindow.closed) {
                    clearInterval(timer);
                    self.showGuacAuthModal = false;
                    self.setCurrentFrameUrl();
                    self.refreshGuacLink();
                    self.saveCookie();
                }
            }, 1000);
        },
        saveCookie() {
            var expireTime = new Date(
                moment()
                    .add(5, "hours")
                    .toDate()
            );
            document.cookie = `guacamoleSession=${expireTime}`;
            this.compareCookie();
        },
        compareCookie() {
            var guacamoleSession = this.getCookie("guacamoleSession");

            if (guacamoleSession) {
                var timeDiff = moment().diff(
                    moment(guacamoleSession),
                    "minutes"
                );
                if (timeDiff >= -5) {
                    this.guacamoleAuthenticated = false;
                } else {
                    this.guacamoleAuthenticated = true;
                }
            } else {
                this.guacamoleAuthenticated = false;
            }
        },
        getCookie(cname) {
            var name = cname + "=";
            var decodedCookie = decodeURIComponent(document.cookie);
            var ca = decodedCookie.split(";");
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == " ") {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        },
        setWaitingTime() {
            if (this.allMachines && this.currentMachineTypes) {
                let targetMachines = this.allMachines.filter(
                    m => this.currentMachineTypes.indexOf(m.es_box.es_name) > -1
                );
                let machineStatuses = targetMachines.map(m => m.status);
                let linux = targetMachines.filter(
                    m =>
                        m.es_box.es_name === "esa_linux" &&
                        m.status === "Active"
                );

                // If there are 2 machines required for the lab but Linux one is ready, don't need to wait
                if (targetMachines.length > 1 && linux.length > 0) {
                    this.timeWaitingForMachineReady = 10;
                } else if (
                    this.hasIntersection(machineStatuses, ["Terminating"])
                ) {
                    this.timeWaitingForMachineReady = 60000;
                    openSnackBar(
                        this,
                        `One of the machines is being terminated.`
                    );
                } else if (
                    this.hasIntersection(machineStatuses, ["Stopping"])
                ) {
                    this.timeWaitingForMachineReady = 60000;
                    openSnackBar(this, `One of the machines is being stopped.`);
                } else if (
                    this.hasIntersection(machineStatuses, [
                        "None",
                        "Terminated",
                        "Creating",
                        "Resetting"
                    ])
                ) {
                    this.timeWaitingForMachineReady = 60000;
                } else if (
                    this.hasIntersection(machineStatuses, [
                        "Inactive",
                        "Starting"
                    ])
                ) {
                    this.timeWaitingForMachineReady = 15000;
                } else if (this.hasIntersection(machineStatuses, ["Active"])) {
                    this.timeWaitingForMachineReady = 10;
                }
            }
        },
        hasIntersection(groupOne, groupTwo) {
            for (let item of groupOne) {
                if (groupTwo.indexOf(item) > -1) {
                    return true;
                }
            }
            return false;
        },
        removeStartLaunchOverlay() {
            this.showStartLaunch = false;
            this.showGuacAuthReminder = true;
            this.refreshGuacLink();

            let self = this;
            setTimeout(() => {
                self.showGuacAuthReminder = false;
            }, 10000);
            // this.setStartLaunchDisplay(false);
        }
    }
};
</script>

<style lang="scss">
#app .lab-component {
    .lab-columns {
        margin: 0;
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: row;
    }

    .overflow-x-hidden {
        overflow-x: hidden;
    }

    .flex {
        display: flex;
    }

    .hundo-width {
        width: 100%;
    }

    .sticky {
        position: sticky;
    }

    .left-100-percent {
        left: 100%;
    }

    .top-0 {
        top: 0;
    }

    .hundo-height {
        height: 100%;
    }

    .no-bottom-padding {
        padding-bottom: 0px;
    }

    .relative {
        position: relative;
    }

    .bottom-margin-2-5 {
        margin-bottom: 2.5rem;
    }

    .top-margin-2-5 {
        margin-top: 2.5rem;
    }

    .hundo-minus-80-height {
        height: calc(100% - 5rem);
        // height: 100%;
    }

    .hundo-minus-40-height {
        height: calc(100% - 2.5rem);
        // height: 100%;
    }

    #content-column-left {
        flex: 0 1 auto;
    }

    #content-column-right {
        flex: 1 1 auto;
    }

    .hidden {
        display: none;
    }

    .bold-text {
        font-weight: bold;
    }

    .italic-text {
        font-style: italic;
    }

    #evolve-loader {
        background: url("../assets/evolve-logo-black-spinner.gif") no-repeat
            center center;
        height: 3rem;
        width: 3rem;
        position: relative;
        left: 50%;
        transform: translate(-50%, -50%);
        display: block;
        top: 2rem;
    }

    .message-area {
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 1.5rem 2rem;
        background-color: white;
        box-shadow: 0 1.125em 1.25em rgba(10, 10, 10, 0.8);
    }

    .overlay-waiting-launch {
        position: absolute;
        background-color: black;
        width: 100%;
        height: 100%;
        z-index: 2;
    }

    .start-launch-btn {
        color: #ffffff;
        border: 1px solid #ffffff;
        position: absolute;
        top: 33%;
        padding: 0.7rem;
        width: 20rem;
        font-size: 1.1rem;
        border-radius: 2rem;
        text-align: center;
        left: 50%;
        transform: translate(-50%, 0);
        cursor: pointer;

        &--text {
            margin-left: 1rem;
        }

        &:hover {
            background-color: white;
            color: black !important;
            svg {
                color: black !important;
            }
        }
    }

    .overlay-for-loader {
        position: absolute;
        background-color: black;
        width: 100%;
        top: 2.5rem;
        left: 0;
        height: calc(100% - 2.5rem);
        overflow-y: hidden;
        overflow-x: hidden;
    }

    .guac-auth-reminder {
        cursor: pointer;
        position: absolute;
        bottom: 2rem;
        font-size: 0.9rem;
        border: 1px solid black;
        border-radius: 1rem;
        padding: 0.25rem 0.5rem;
        left: 50%;
        transform: translate(-50%, 0);
        width: 25rem;
        text-align: center;
        transition: all 0.2s ease-out;
    }

    .guac-auth-btn--animated {
        animation: moveInBottom 0.5s ease-out 0.75s;
        animation-fill-mode: backwards;
    }

    .modal-body-title {
        font-weight: bold;
        text-decoration: underline;
    }

    .modal-body-list {
        margin-left: 2rem;
    }

    .evolve-esa-linux-loader {
        background: url("../assets/cyberlab_esa_linux_loader.gif") no-repeat
            center center;
        background-size: 35rem 100%;
        height: 100%;
        position: absolute;
        width: 105%;
        min-height: 600px;
    }

    .guac-modal {
        z-index: 2;

        .animation-content {
            max-width: 35rem !important;
            z-index: 2;
        }

        &-header {
            background-color: #1479bf;

            &-title {
                color: #fff;
                font-size: 1.2rem;
            }
        }

        &-footer {
            display: inline-block;
            width: 100%;
            text-align: center;
            padding: 0.8rem;

            button {
                text-align: center;
                background-color: #1479bf;
            }
        }
    }

    .overflow-hidden {
        overflow-y: hidden !important;
    }

    @media (min-width: 1700px) {
        .evolve-esa-linux-loader {
            background-size: 42rem 100%;
        }
    }

    #iframe-coverer.full-height.transparent,
    #focus-iframe-coverer.full-height.transparent {
        background-color: red;
        opacity: 0;
        height: 100%;
        position: absolute;
        top: 0;
        width: 100%;
    }

    #iframe-coverer.full-height.transparent {
        cursor: ew-resize;
    }

    #focus-iframe-coverer.full-height.transparent {
        cursor: text;
    }
}
</style>
