import Vue from "vue"
import Router from "vue-router"
import LMS from "../views/LMS.vue"
import Enroll from "../views/Enroll.vue"
import Schedule from "../components/Schedule.vue"
import Instructors from "../components/Instructors.vue"
// import Objectives from '../components/Objectives.vue'
import Grades from "../components/Grades.vue"
import Classmates from "../components/Classmates.vue"
import Course from "../components/Course.vue"
import ErrorPage from "../views/ErrorPage.vue"
import LandingPage from "../views/LandingPage.vue"
import EnterpriseLandingPage from "../views/EnterpriseLandingPage.vue"
import Admin from "../views/Admin.vue"
import Attendance from "../components/Attendance.vue"
import AdminAttendance from "../components/AdminAttendance.vue"
import Gradebook from "../components/Gradebook.vue"
import StudentAttendance from "../components/StudentAttendance.vue"
import GradesPrintOut from "../components/GradesPrintOut.vue"

Vue.use(Router)

export default new Router({
	routes: [
		// {
		//     path: '/module/:moduleid/topic/:topicid',
		//     component: LMS
		// },
		{
			path: "/",
			name: "home",
			component: LandingPage
		},
		{
			path: "/enterprise",
			name: "enterpriseLandingPage",
			component: EnterpriseLandingPage
		},
		{
			path: "*",
			name: "token",
			component: LMS
		},
		{
			path: "/error",
			name: "errorPage",
			component: ErrorPage
		},
		{
			path: "/welcome",
			name: "landingPage",
			component: LandingPage
		},
		{
			path: "/enterprise-welcome",
			name: "enterpriseLandingPage",
			component: EnterpriseLandingPage
		},
		{
			path: "/welcome/:message/:cohortid",
			component: LandingPage
		},
		{
			path: "/enroll/:cohortid/:email",
			name: "enroll",
			component: Enroll
		},
		{
			path: "/enroll/:cohortid",
			component: Enroll
		},
		{
			path: "/admin/:cohortid",
			component: Admin
		},
		{
			path: "/admin/:cohortid/gradebook",
			component: Gradebook
		},
		{
			path: "/admin/:cohortid/attendance",
			component: Attendance,
			children: [
				{
					path: ":sessionid",
					component: AdminAttendance
				}
			]
		},
		{
			path: "/",
			component: LMS,
			children: [
				{
					path: "cohort/:cohortid/module/:moduleid/topic/:topicid",
					component: Course
				},
				{
					path: "/schedule/:cohortid",
					name: "Schedule",
					component: Schedule
				},
				{
					path: "/instructors/:cohortid",
					name: "Instructors",
					component: Instructors
				},
				// {
				//     path: '/objectives',
				//     name: 'Objectives',
				//     component: Objectives
				// },
				{
					path: "/classmates/:cohortid",
					name: "Classmates",
					component: Classmates
				},
				{
					path: "/grades/:cohortid",
					name: "Grades",
					component: Grades
				},
				{
					path: "/grades/:cohortid/attendance",
					name: "StudentAttendance",
					component: StudentAttendance
				},
				{
					path: "/grades/:cohortid/print",
					name: "GradesPrintOut",
					component: GradesPrintOut
				}
			]
		}
	]
})
