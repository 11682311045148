<template>
	<div class="grade-percent-component">
		<b-tooltip label="Current grade is based on submitted topics" type="is-info">
			<vue-fontawesome :icon="['fas', 'info-circle']" size="sm" color="#0077b5" />
		</b-tooltip>
		<p v-if="this.currentFinalGrade && !this.finalGradeLoading" class="font-size-2 flex-center">{{ Math.round(currentFinalGrade * 100) + "%" }}</p>
		<Loader v-else />

		<div class="height-3 flex flex-center">
			<p class="text-center">Current Grade</p>
		</div>

		<div class="flex-center align-center pt-sm">
			<b-button size="is-small" @click="goToGradesPrintout">View and Print</b-button>
		</div>
	</div>
</template>

<script>
import EventBus from "../event-bus"
import { mapGetters, mapActions } from "vuex"
import Loader from "./Loader"
import router from "../router/router.js"

export default {
	name: "GradePercent",
	components: { Loader },
	computed: {
		...mapGetters(["attempts", "currentCohort", "currentEnrollment", "currentFinalGrade", "finalGradeLoading"])
	},
	mounted() {
		this.setFinalGradeLoading(true)
		this.returnFinalGrade(this.currentEnrollment.es_enrollmentid)
	},
	methods: {
		...mapActions(["getAttendanceForCohort", "returnFinalGrade", "setFinalGradeLoading"]),
		goToGradesPrintout() {
			router.push({
				path: `/grades/${this.currentCohort.es_cohortid}/print`
			})
		}
	}
}
</script>

<style lang="scss">
#app .grade-percent-component {
	display: flex;
	flex-direction: column;
	position: relative;

	.flex-center {
		display: flex;
		flex-direction: row;
		justify-content: center;
	}
	.font-size-2 {
		font-size: 2rem;
	}

	.text-center {
		text-align: center;
	}

	svg {
		cursor: help;
	}

	.height-3 {
		height: 3rem;
	}

	.flex {
		display: flex;
	}

	.align-center {
		align-items: center;
	}

	.b-tooltip {
		position: absolute;
		top: 4px;
		right: 4px;
	}

	.pt-sm {
		padding-top: 0.5rem;
	}
}
</style>
