import Vuex from 'vuex';
import Vue from 'vue';
import modules from './modules/modules';
import auth from './modules/auth';
import nav from './modules/nav';
import cohorts from './modules/cohorts'
import attempts from './modules/attempts'
import sessions from './modules/sessions'
import classmates from './modules/classmates'
import objectives from './modules/objectives'
import instructors from './modules/instructors'
import topics from './modules/topics'
import enrollments from './modules/enrollments'
import timer from './modules/timer'
import frame from './modules/frame'
import attendance from './modules/attendance'
import feedbacks from './modules/feedbacks'
import selections from './modules/selections'
import waitlists from './modules/waitlists'
import pdfs from './modules/pdfs'
import questions from './modules/questions'
import grades from './modules/grades'
import quizzes from './modules/quizzes'

//load vuex
Vue.use(Vuex);

//create store
export default new Vuex.Store({
    modules: {
        modules,
        auth,
        nav,
        cohorts,
        attempts,
        sessions,
        classmates,
        objectives,
        instructors,
        topics,
        enrollments,
        timer,
        frame,
        attendance,
        feedbacks,
        selections,
        waitlists,
        pdfs,
        questions,
        grades,
        quizzes
    },
});
