<template>
	<span class="course-content-component">
		<b-tabs :class="isIndexCollapsed ? 'hidden' : 'visible'" v-model="activeTab" expanded id="course-content-tabs">
			<b-tab-item v-if="![717580003, 717580005, 717580006, 717580007].includes(currentCohort.es_type)">
				<menu-tab />
			</b-tab-item>

			<b-tab-item>
				<content-index />
			</b-tab-item>
		</b-tabs>
	</span>
</template>

<script>
import ContentIndex from "./ContentIndex"
import CurrentLesson from "./CurrentLesson"
import MenuTab from "./MenuTab"
import EventBus from "../event-bus"
import { mapGetters, mapActions } from "vuex"

export default {
	name: "CourseContent",
	components: { ContentIndex, CurrentLesson, MenuTab },
	computed: mapGetters(["isIndexCollapsed", "currentCohort"]),
	data() {
		return {
			activeTab: 0,
			showBooks: false,
			bTabsClass: "visible"
		}
	},
	mounted() {
		EventBus.$on("change-tab", () => {
			let tabs = document.querySelectorAll("#course-content-tabs nav.tabs ul li")

			for (var i = 0; i < tabs.length; i++) {
				if (!tabs[i].classList.contains("is-active")) {
					tabs[i].firstElementChild.click()
				}
			}
		})
		this.loadConnectionIds()
	},
	methods: {
		...mapActions(["loadConnectionIds"])
	}
}
</script>
<style lang="scss">
#app .course-content-component {
	nav.tabs.is-fullwidth {
		display: none;
	}

	.hidden {
		visibility: hidden;
		opacity: 0;
		transition: visibility 0.5s, opacity 0.2s;
	}

	.visible {
		visibility: visible;
		opacity: 100;
		transition: visibility 0.5s, opacity 0.2s;
		height: calc(100% - 40.75px);
	}

	nav.tabs.is-fullwidth {
		position: sticky;
		top: 0;
		background-color: white;
		z-index: 1;
	}

	.b-tabs.is-fullwidth.visible {
		overflow-y: scroll;
	}

	@media only screen and (max-width: 600px) {
		.hidden {
			display: none;
		}
	}
}
</style>
