<template>
    <div id="resizer-column" class="resizer-column"></div>
</template>

<script>
import EventBus from "../event-bus";
import { mapGetters, mapActions } from "vuex";

export default {
    name: "ResizerColumn",
    computed: {
        ...mapGetters(["currentContent"])
    },
    data() {
        return {
            leftColumnElement: null,
            rightColumnElement: null,
            resizer: null,
            canResize: false
        };
    },
    components: {},
    mounted() {
        this.leftColumnElement = document.getElementById("content-column-left");
        this.rightColumnElement = document.getElementById(
            "content-column-right"
        );

        this.leftColumnElement.style.width = "50%";

        this.resizer = document.getElementById("resizer-column");

        this.resizer.addEventListener("mousedown", this.addListeners);
    },
    beforeDestroy() {
        // Unregister the event listener before destroying this Vue instance
        this.resizer.removeEventListener("mousedown", this.addListeners);
        document.removeEventListener("mouseup", this.stopResize);
        document.removeEventListener("mousemove", this.resizeColumn);
    },
    methods: {
        ...mapActions(["changeCurrentContent"]),
        addListeners() {
            this.canResize = true;

            //ignore events over iframe
            this.makeTransparentDiv();

            document.addEventListener("mousemove", this.resizeColumn);
            document.addEventListener("mouseup", this.stopResize);
        },
        resizeColumn(e) {
            if (this.canResize) {
                let cursorPositionX;
                let currentLessonWindow = document.getElementById(
                    "lab-columns"
                );
                let newWidth;

                //use min of cursor position or screen width
                if (e.pageX > screen.width - 1) {
                    cursorPositionX = screen.width;
                } else {
                    cursorPositionX = e.pageX;
                }

                newWidth =
                    cursorPositionX -
                    this.leftColumnElement.getBoundingClientRect().left;

                //new width is maximum of #current-lesson-columns width - 450px (resizer + iframe)
                if (currentLessonWindow.offsetWidth - 470 < newWidth) {
                    newWidth = currentLessonWindow.offsetWidth - 470 + "px";
                }

                //sets width of column
                this.leftColumnElement.style.width = newWidth + "px";
            }
        },
        stopResize() {
            this.canResize = false;

            let cover = document.getElementById("iframe-coverer");

            document.removeEventListener("mousemove", this.resizeColumn);
            document.removeEventListener("mouseup", this.stopResize);

            //remove iframe cover
            cover.classList.add("hidden");
            cover.classList.remove("full-height", "transparent");

            //refresh box (for size)

            EventBus.$emit("resizer-used");
        },
        makeTransparentDiv() {
            let cover = document.getElementById("iframe-coverer");

            cover.classList.add("full-height", "transparent");
            cover.classList.remove("hidden");
        }
    }
};
</script>

<style lang="scss">
#app .resizer-column {
    padding: 2px;
    background-color: rgb(82, 82, 82);
    cursor: ew-resize;
}
</style>
