<template>
  <span class="row session-rows" v-if="data">
    <div
      class="row-heading columns is-mobile border-right border-bottom light-gray border-left sticky-left white"
    >
      <div class="column is-2"></div>
      <div class="column is-8 semi-bold">
        <p>Live Session</p>
      </div>
      <div
        class="column is-2 semi-bold font-size-nine-tenths justify-center flex-center"
      >
        <p>{{ pastSessions }}/{{ currentSessions.length }}</p>
      </div>
    </div>
    <div
      v-for="(monday, index) in mondays"
      :key="'sessions' + index"
      class="row-column row border-right border-bottom"
    >
      <div
        v-for="i in 7"
        :key="'sessionday' + index + i"
        class="day-column light-gray relative white"
      >
        <!-- <b-tooltip
          v-if="data[index * 7 + i - 1]"
          position="is-left"
          :label="formatDate(dates[index * 7 + i - 1])"
          type="is-success"
        > -->
        <div
          v-if="data[index * 7 + i - 1]"
          @click="handleSessionClick(data[index * 7 + i - 1])"
          class="full absolute session-green cursor-pointer"
          :class="determineClass(index * 7 + i - 1)"
        />
        <!-- </b-tooltip> -->
      </div>
    </div>
  </span>
</template>

<script>
import { mapGetters } from "vuex"
import EventBus from "../event-bus"
import moment from "moment"

export default {
  name: "SessionsRow",
  props: ["mondays", "dates"],
  computed: {
    ...mapGetters(["currentSessions", "timeZone"]),
    pastSessions: function() {
      //check

      let today = new Date()
      let pastSessions = 0
      var a = moment()

      this.currentSessions.forEach(session => {
        let b = moment(session.es_enddatetime)
        let diff = b.diff(a, "days")

        if (diff < 0) {
          pastSessions++
        }
      })

      return pastSessions
    }
  },
  data() {
    return {
      isVisible: false,
      data: null
    }
  },
  watch: {
    currentSessions: function() {
      this.makeDataArray(this.dates)
    }
  },
  mounted() {
    this.makeDataArray(this.dates)
  },
  methods: {
    determineClass(index) {
      //data[n] only has value if session exists
      if (this.data[index]) {
        return "round width-18 horizontal-margin-2"
      }
    },
    formatDate(date) {
      if (!date) {
        return null
      }
      let formattedDate = moment(date)
        .startOf("day")
        .format("MMM D, YYYY")
      if (formattedDate) {
        return formattedDate
      }
      return null
    },
    makeDataArray(dates) {
      let currentSession = 0
      let dataArray = []
      let started

      // prettier-ignore
      dates.forEach(date => {
                if (currentSession < this.currentSessions.length) {
                    let formattedDate = moment(this.currentSessions[currentSession].es_startdatetime).startOf("day").format("MMM D, YYYY");
                    if (moment(formattedDate).isSame(moment(date, "MMM D, YYYY"), "day")) {
                        dataArray.push(this.currentSessions[currentSession]);
                        currentSession++;
                    } else {
                        dataArray.push(false)
                    }
                } else {
                    dataArray.push(false);
                }
            });

      this.data = dataArray
    },
    handleSessionClick(data) {
      EventBus.$emit("session-clicked", data)
    }
  }
}
</script>

<style lang="scss">
#app .session-rows {
  z-index: 1;

  .b-tooltip {
    display: inline;
    position: absolute;
    left: 0;
  }

  .b-tooltip:after {
    line-height: 0.85rem;
  }

  .b-tooltip:not([data-label=""]):hover:before,
  .b-tooltip:not([data-label=""]):hover:after {
    margin-left: 9px;
    margin-top: 34px;
  }

  .round {
    border-radius: 5px;
  }

  .full.absolute.round.horizontal-margin-2 {
    margin-left: 2px;
    margin-right: 2px;
  }

  .full.absolute.round.width-18 {
    width: 18px;
  }

  .full.absolute.round.session-green {
    background-color: #42b427;
  }

  .day-column {
    z-index: 1;
  }

  .row-column {
    z-index: 5;
  }
}
</style>
