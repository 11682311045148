import moment from "moment"
const FileDownload = require("js-file-download")
import router from "../../router/router.js"
import {
	postAttempt,
	patchAttempt,
	patchQuizAttempt,
	getAttempts,
	postAttemptSelection,
	resetQuiz,
	postAssessment,
	patchAssessment,
	getAttemptFiles,
	checkForAttemptFiles
} from "../../api/attempt.js"

const state = {
	currentAttempt: null,
	attemptSubmitted: false,
	attemptSaving: false,
	attemptSaved: false,
	attempts: null,
	gradebookModalActive: false,
	assessmentModalActive: false,
	gradebookCurrentAttempts: null,
	gradebookModalMode: null,
	currentAttemptsAttachmentsData: null
}

const getters = {
	currentAttempt: state => state.currentAttempt,
	attemptSubmitted: state => state.attemptSubmitted,
	attemptSaving: state => state.attemptSaving,
	attemptSaved: state => state.attemptSaved,
	attempts: state => state.attempts,
	gradebookModalActive: state => state.gradebookModalActive,
	assessmentModalActive: state => state.assessmentModalActive,
	gradebookCurrentAttempts: state => state.gradebookCurrentAttempts,
	gradebookModalMode: state => state.gradebookModalMode,
	currentAttemptsAttachmentsData: state => state.currentAttemptsAttachmentsData
}

const actions = {
	checkForAttemptOnTopic({ commit, dispatch, rootState }, data) {
		let filtered = rootState.attempts.attempts.filter(attempt => attempt._es_topic_value === data.es_topicid && !attempt.es_issubmitted)
		let finished = rootState.attempts.attempts.some(attempt => attempt._es_topic_value === data.es_topicid && attempt.es_issubmitted)
		if ([717580000, 717580001].includes(data.es_type) && !finished) {
			if (filtered.length === 0) {
				dispatch("createAttempt")
			} else {
				commit("currentAttempt", filtered[0].es_attemptid)
			}
		} else {
			commit("currentAttempt", null)
		}
	},
	createAttempt({ commit, rootState, dispatch }, data) {
		let currentTime = moment()
		let attempt = {
			es_enrollment: `/es_enrollments(${rootState.enrollments.currentEnrollment.es_enrollmentid})`,
			es_topic: `/es_topics(${rootState.topics.currentTopic.es_topicid})`,
			es_timestart: currentTime,
			es_timeend: null,
			es_issubmitted: false,
			es_score: 0,
			enrollmentid: rootState.enrollments.currentEnrollment.es_enrollmentid
		}

		postAttempt(attempt)
			.then(response => {
				let startIndex = response.data.indexOf("es_attempts(") + 12
				let createdAttemptId = response.data.slice(startIndex, response.data.length - 1)
				commit("currentAttempt", createdAttemptId)
				dispatch("getAttempts")

				//check if fired from submitAttempt, which happens for content topics
				if (data && data.submitAttempt == true) {
					dispatch("submitAttempt", data)
				} else {
					commit("attemptSaved", true)
					// commit('attemptSaving', false)
				}
			})
			.catch(err => {
				console.log("api/attempts post error", err)
				commit("attemptSaving", false)
				commit("setAuthentication", false)
				commit("setContact", null)
				commit("setErrorMessage", "Error posting attempt.")
				router.push({ path: "/error" })
			})
	},
	submitAttempt({ commit, dispatch, rootState }, data) {
		let currentTime = moment()

		//see if there's a current attempt
		if (!rootState.attempts.currentAttempt) {
			//modifies data object so createAttempt action knows to call submit attempt again

			if (data) {
				data.submitAttempt = true
			} else {
				data = { submitAttempt: true }
			}
			dispatch("createAttempt", data)
		} else {
			commit("attemptSaving", true)

			let attempt = {
				attemptid: rootState.attempts.currentAttempt,
				es_issubmitted: true,
				es_timeend: currentTime,
				enrollmentid: rootState.enrollments.currentEnrollment.es_enrollmentid,
				es_score: 1,
				topicid: rootState.topics.currentTopic.es_topicid
			}

			if (data && data.choices) {
				attempt.choices = JSON.stringify(data.choices)
			}

			if (data && data.pending) {
				attempt.pending = data.pending
			}

			patchAttempt(attempt)
				.then(response => {
					let isSequential = rootState.cohorts.currentCohort.es_issequential
					let unlockedTopics = rootState.topics.unlockedTopics
					if (
						isSequential &&
						response.data === unlockedTopics[unlockedTopics.length - 1] &&
						rootState.topics.nextTopic.es_topicid &&
						rootState.cohorts.currentCohort.es_issequential
					) {
						unlockedTopics.push(rootState.topics.nextTopic.es_topicid)
					}
					commit("setUnlockedTopics", unlockedTopics)
					commit("attemptSaved", true)
					commit("currentAttempt", null)
					dispatch("getAttempts")
					commit("attemptSaving", false)
				})
				.catch(err => {
					console.log("api/attempts patch error", err)
					commit("attemptSaving", false)
					commit("setAuthentication", false)
					commit("setContact", null)
					commit("setErrorMessage", "Error patching attempt.")
					router.push({ path: "/error" })
				})
		}
	},
	submitQuiz({ commit, dispatch, rootState }, data) {
		commit("attemptSaving", true)

		patchQuizAttempt(data)
			.then(response => {
				commit("attemptSaved", true)
				commit("currentAttempt", null)
				dispatch("getAttempts")
				commit("attemptSaving", false)
				// commit('setQuestionChoices', null)
				commit("updateContinuingQuiz", false)

				let unlockedTopics = rootState.topics.unlockedTopics
				let isSequential = rootState.cohorts.currentCohort.es_issequential

				if (
					isSequential &&
					response.data === unlockedTopics[unlockedTopics.length - 1] &&
					rootState.topics.nextTopic.es_topicid &&
					rootState.cohorts.currentCohort.es_issequential
				) {
					unlockedTopics.push(rootState.topics.nextTopic.es_topicid)
				}
			})
			.catch(err => {
				console.log("api/attempts patch error", err)
				commit("attemptSaving", false)
				commit("setAuthentication", false)
				commit("setContact", null)
				commit("setErrorMessage", "Error patching quiz.")
				router.push({ path: "/error" })
			})
	},
	getAttempts({ commit, rootState }) {
		let contactid = rootState.auth.contact.contactid

		getAttempts(contactid)
			.then(response => {
				let myAttempts = response.data.value
				commit("setAttempts", myAttempts)
			})
			.catch(err => {
				console.log("api/attempts get error", err)
				commit("setAuthentication", false)
				commit("setContact", null)
				commit("setErrorMessage", "Error getting attempts.")
				router.push({ path: "/error" })
			})
	},
	setAttemptSaving({ commit }) {
		commit("attemptSaving", true)
	},
	setCurrentAttempt({ commit }, data) {
		commit("currentAttempt", data)
	},
	setGradebookCurrentAttempts({ commit }, data) {
		commit("setGradebookCurrentAttempts", data)
	},
	updateAttemptSelection({ commit }, data) {
		postAttemptSelection(data)
			.then(response => {
				// console.log("response: ", response)
			})
			.catch(err => {
				console.log("api/selections post error", err)
				commit("setErrorMessage", "Error saving selection.")
				router.push({ path: "/error" })
			})
	},
	resetQuiz({ commit }, data) {
		resetQuiz(data)
			.then(response => {
				commit("removeQuizAttemptFromGrades", data)
				commit("removeQuizAttemptFromCurrentAttempts", data)
				commit("setQuizModalLoading", false)
			})
			.catch(err => {
				commit("setQuizModalLoading", false)
				commit("setErrorMessage", "Error resetting quiz.")
				router.push({ path: "/error" })
			})
	},
	setGradebookModalActive({ commit }, data) {
		commit("setGradebookModalActive", data)
	},
	setGradebookModalMode({ commit }, data) {
		commit("setGradebookModalMode", data)
	},
	setAssessmentModalActive({ commit }, data) {
		commit("setAssessmentModalActive", data)
	},
	postAssessment({ commit }, data) {
		postAssessment(data)
			.then(response => {
				commit("addAssessmentScoreToGrades", response.data)
				commit("setAssessmentModalLoading", false)
			})
			.catch(err => {
				console.log("api/assessment post error", err)
				commit("setErrorMessage", "Error saving assessment.")
				router.push({ path: "/error" })
			})
	},
	patchAssessment({ commit }, data) {
		patchAssessment(data)
			.then(response => {
				commit("patchCohortGrades", data)
				commit("setAssessmentModalLoading", false)
			})
			.catch(err => {
				console.log("api/assessment patch error", err)
				commit("setErrorMessage", "Error saving assessment.")
				router.push({ path: "/error" })
				commit("setAssessmentModalLoading", false)
			})
	},
	getAttemptFiles({ commit }, data) {
		getAttemptFiles(data.attemptid)
			.then(response => {
				let name = `${data.fullname}-${data.topicname}.pdf`
				FileDownload(response.data, name)
			})
			.catch(err => {
				console.log("getAttemptFiles err:", err)
				commit("setErrorMessage", "Error getting attachment data for attempt.")
				router.push({ path: "/error" })
			})
	},
	checkForAttemptFiles({ commit }, data) {
		checkForAttemptFiles(data)
			.then(response => {
				commit("setCurrentAttemptsAttachmentsData", response.data.value)
				commit("setFinalLabModalLoading", false)
			})
			.catch(err => {
				console.log("checkForAttemptFiles err:", err)
				commit("setFinalLabModalLoading", false)
				commit("setErrorMessage", "Error checking for attachments in attempt.")
				router.push({ path: "/error" })
			})
	}
}

const mutations = {
	setAttempts: (state, attempts) => (state.attempts = attempts),
	attemptSaving: (state, attemptSaving) => (state.attemptSaving = attemptSaving),
	attemptSubmitted: (state, attemptSubmitted) => (state.attemptSubmitted = attemptSubmitted),
	attemptSaved: (state, attemptSaved) => (state.attemptSaved = attemptSaved),
	currentAttempt: (state, id) => (state.currentAttempt = id),
	setGradebookModalActive: (state, bool) => (state.gradebookModalActive = bool),
	setAssessmentModalActive: (state, bool) => (state.assessmentModalActive = bool),
	setGradebookCurrentAttempts: (state, data) => (state.gradebookCurrentAttempts = data),
	setGradebookModalMode: (state, data) => (state.gradebookModalMode = data),
	setCurrentAttemptsAttachmentsData: (state, data) => (state.currentAttemptsAttachmentsData = data),
	removeQuizAttemptFromCurrentAttempts: (state, data) => {
		let index = state.gradebookCurrentAttempts.attempt.findIndex(attempt => {
			return attempt.id === data.es_attemptid
		})
		if (index > -1) {
			state.gradebookCurrentAttempts.attempt.splice(index, 1)
		}
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}
