<template>
    <div v-if="currentCorrectChoices" class="grades-quiz-selections-component">
        <selection-question
            v-for="selection in selections"
            :key="selection.es_selectionid"
            :choice="selection"
            :correctChoices="filterChoices(selection)"
        />
    </div>
    <Loader v-else />
</template>

<script>
import SelectionQuestion from "./SelectionQuestion";
import Loader from "./Loader";
import { mapGetters, mapActions } from "vuex";

export default {
    name: "GradesQuizSelections",
    props: ["topic", "attempt"],
    components: { SelectionQuestion, Loader },
    computed: {
        ...mapGetters([
            "currentTopic",
            "attempts",
            "contact",
            "currentSelections",
            "currentEnrollment",
            "currentCorrectChoices"
        ])
    },
    data() {
        return {
            selections: null,
            wrongQuestionIds: null
        };
    },
    created() {
        if (
            !this.currentSelections ||
            this.currentSelections[0].es_attempt._es_topic_value !=
                this.topic.es_topicid
        ) {
            this.getSelections({
                topicid: this.topic.es_topicid,
                enrollmentid: this.currentEnrollment.es_enrollmentid
            });
        } else {
            this.buildSelections();
            this.getWrongQuestionIds();
        }
    },
    watch: {
        currentSelections: function() {
            this.buildSelections();
        },
        wrongQuestionIds: function() {
            this.getCorrectChoices({
                ids: this.wrongQuestionIds,
                topicid: this.topic.es_topicid
            });
        },
        selections: function() {
            this.getWrongQuestionIds();
        }
    },
    beforeDestroy() {
        this.clearCorrectChoices();
    },
    methods: {
        ...mapActions([
            "getSelections",
            "getCorrectChoices",
            "clearCorrectChoices"
        ]),
        buildSelections() {
            //filter topic selections by attempt
            let selections = null;
            if (this.currentSelections) {
                selections = this.currentSelections.filter(selection => {
                    return (
                        selection.es_attempt.es_attemptid ==
                        this.attempt.es_attemptid
                    );
                });
            }
            this.selections = selections;
        },
        getWrongQuestionIds() {
            let wrongQuestions = this.selections.filter(selection => {
                return (
                    !selection.es_choice_iscorrect &&
                    [
                        "Multiple Choice",
                        "Fill in the blank",
                        "Multiple Select"
                    ].includes(selection.es_question_type)
                );
            });

            this.wrongQuestionIds = wrongQuestions.map(selection => {
                return selection.es_question_id;
            });
        },
        filterChoices(selection) {
            // if in the correctchoices array
            let answersForQuestion = this.currentCorrectChoices.filter(item => {
                return item.es_topicquestionid == selection.es_question_id;
            });
            if (answersForQuestion.length > 0) {
                // return all for this questionid
                return answersForQuestion;
            }
            return;
        }
    }
};
</script>

<style lang="scss">
#app .grades-quiz-selections-component {
}
</style>
