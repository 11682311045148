import request from "./request"

export const getSessions = data =>
  request({
    url: `sessions?cohortid=${data}`,
    method: "get"
  })

export const getSessionAttendance = data =>
  request({
    url: `session-attendance?cohortid=${data.cohortId}&sessionid=${data.sessionId}`,
    method: "get"
  })

export const patchAttendance = data =>
  request({
    url: "/patch-attendance",
    method: "patch",
    data: data,
    headers: { "Content-Type": "application/json" }
  })
