<template>
	<span class="inline-loader-component">
		<div class="spinner-container">
			<div class="button is-loading"></div>
		</div>
	</span>
</template>

<script>
export default {
	name: "InlineLoader"
}
</script>

<style lang="scss">
#app .inline-loader-component {
	.is-loading {
		border: none;
	}
	.button.is-loading::after {
		height: 1rem;
		width: 1rem;
	}

	.spinner-container {
		width: 1rem;
		display: inline-block;
	}

	.button {
		background-color: transparent;
		width: 1rem;
		height: 1rem;
		padding: 0;
	}

	.button.is-loading::after {
		left: calc(50% - (1rem * 0.25));
		top: calc(50% - (1rem * 0.25));
	}
}
</style>
