<template>
	<div class="grades-name-component">
		<h1>Student Name: {{ contact.fullname }}</h1>
		<div v-if="currentEnrollment && currentEnrollment.es_grademessage" class="pl-lg">
			<span class="text-red">*</span><span>Note from Instructor:</span>
			{{ " " + currentEnrollment.es_grademessage }}
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"

export default {
	name: "GradesName",
	computed: {
		...mapGetters(["contact", "currentEnrollment"])
	},
	methods: {
		...mapActions(["getAttendanceForCohort", "updateCurrentModule", "navigateToTopic", "updateCurrentTopic", "changeCurrentPage", "getSelections"])
	}
}
</script>

<style scoped lang="scss">
#app .grades-name-component {
	h1 {
		font-size: 2rem;
		padding: 0.5rem 2rem 0rem;
	}

	.pl-lg {
		padding-left: 2rem;
	}

	.text-red {
		color: red;
		padding-right: 0.25rem;
	}
}
</style>
