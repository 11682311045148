<template>
    <span
        v-if="currentCohort && currentCohort.es_type!=717580005 && currentCohort.es_type!=717580006"
        class="slack-dropdown-component"
    >
        <div class="social-dropdown slack-dropdown">
            <b-dropdown aria-role="list" position="is-bottom-left">
                <div class="slack-button" slot="trigger" role="button">
                    <img :src="slackLogo" alt="slack logo" class="slack-logo" />
                    Slack
                    <vue-fontawesome icon="chevron-down" />
                </div>

                <span v-if="currentCohort" class="slack-dropdown">
                    <b-dropdown-item v-if="currentCohort.es_mainslackurl" aria-role="listitem">
                        <a
                            :href="currentCohort.es_mainslackurl"
                            target="_blank"
                            class="flex align-center"
                        >
                            <meeting-room-icon :size="16" class="margin-right-half icon-1x" />

                            <p
                                class="truncate"
                            >{{currentCohort.es_slackchannelname || currentCohort.es_name}}</p>
                        </a>
                    </b-dropdown-item>

                    <b-dropdown-item aria-role="listitem" class="disable-link" v-else>
                        <a class="flex align-center disable-link">
                            <phone-cancel-icon :size="16" class="margin-right-half icon-1x" />
                            <p>No Main Channel</p>
                        </a>
                    </b-dropdown-item>
                </span>

                <span v-if="slackUrls">
                    <b-dropdown-item
                        v-for="item in slackUrls"
                        aria-role="listitem"
                        :key="item.es_environmentvariableid"
                    >
                        <a :href="item.es_value" class="flex align-center" target="_blank">
                            <meeting-room-icon :size="16" class="margin-right-half icon-1x" />
                            <p>{{item.es_description}}</p>
                        </a>
                    </b-dropdown-item>
                </span>
            </b-dropdown>
        </div>
    </span>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import slackLogo from "../assets/slack-logo2.png";
import presentation from "../assets/presentation.png";
import thumbnails from "../assets/thumbnails.png";
import userParents from "../assets/user-parents.png";

export default {
    name: "SlackDropdown",
    computed: mapGetters(["currentCohort", "slackUrls"]),
    data() {
        return {
            slackLogo: slackLogo,
            presentation: presentation,
            thumbnails: thumbnails,
            userParents: userParents
        };
    },
    mounted() {},
    watch: {},
    methods: {}
};
</script>

<style lang="scss">
#app .slack-dropdown-component {
    .dropdown-item a {
        color: white;
    }

    .my-group {
        font-weight: bold;
    }

    .assigned-legend {
        text-align: center;
        font-size: 0.75rem;
        font-style: italic;
        line-height: 0.75rem;
        font-weight: 400;
    }

    .flex {
        display: flex;
    }

    .align-center {
        align-items: center;
    }

    .slack-button {
        background-color: #521752;
        width: 10rem;
        border-radius: 0.5rem;
        padding: 0rem 0.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 2rem;
        cursor: pointer;
    }

    .slack-logo {
        display: inline-block;
        max-width: unset;
        max-height: unset;
        height: 1.4rem;
        margin-left: 4px;
    }

    .is-active > div > .slack-button {
        border-radius: 0.5rem 0.5rem 0 0;
    }

    .slack-dropdown .dropdown-content {
        background-color: #521752;
    }

    .slack-dropdown a:hover {
        background-color: #753175 !important;
    }

    .slack-dropdown .dropdown-menu {
        margin-top: 0px;
        padding-top: 0px;
    }

    .slack-dropdown .dropdown-content {
        border-radius: 0 0 0.5rem 0.5rem;
        box-shadow: none;
        width: 215px;
    }

    .slack-button {
        width: 215px;
    }

    .social-dropdown {
        margin-right: 1rem;
    }

    .align-center {
        align-items: center;
    }
    .margin-right-half {
        margin-right: 0.5rem;
    }
    .disable-link {
        cursor: default;
    }
    .material-design-icon.icon-1x {
        height: 1em;
        width: 1em;
        margin-bottom: 0.2rem;
    }

    .truncate {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    a.dropdown-item {
        padding-right: 1rem;
    }
}
</style>