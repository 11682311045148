<template>
	<div v-if="authentication === 1 || loadingCohorts">
		<Loader />
	</div>
	<div v-else class="landing-page">
		<cohort-tabs v-if="authentication == true" />

		<div v-else class="login-page flex row">
			<div class="image"><img src="../assets/LandingPage-Collection.png" /></div>
			<div class="login-container flex align vertical">
				<div class="welcome">
					<h1 class="oswald">WELCOME TO CYBERLAB&trade;</h1>
				</div>
				<div class="cta">
					<h2 style="font-family:roboto;">Please log in to continue.</h2>
				</div>
				<div class="login-button">
					<a :href="baseUrl + 'api/signin'">
						<b-button size="is-large" type="is-primary">Log in</b-button>
					</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import router from "../router/router.js"
import Loader from "../components/Loader.vue"
import CohortTabs from "../components/CohortTabs.vue"

export default {
	name: "EnterpriseLandingPage",
	components: { Loader, CohortTabs },
	computed: {
		...mapGetters([
			"baseUrl",
			"currentTopic",
			"currentModule",
			"contact",
			"authentication",
			"cohortsArray",
			"currentCohort",
			"freeCohorts",
			"loadingCohorts"
		]),
		cta: function() {
			if (this.currentTopic && this.currentModule) {
				return null
			}
			return "Please sign in to continue."
		},
		heading: function() {
			if (this.currentTopic && this.currentModule) {
				return `Welcome back to Evolve Academy, ${this.contact.firstname}.`
			}
			return "Welcome to Evolve Academy Enterprise Training"
		}
	},
	mounted() {
		if (this.$route.params.message == "waitlist" && this.$route.params.cohortid) {
			this.waitlist({
				contact: this.contact,
				cohortid: this.$route.params.cohortid
				// es_name: cohort.es_name
			})
		}
	},
	methods: {
		goToCourse() {
			router.push({
				path: `/cohort/${this.currentCohort.es_cohortid}/module/${this.currentModule.es_moduleid}/topic/${this.currentTopic.es_topicid}`
			})
		},
		...mapActions(["changeCurrentPage", "changeCurrentCohort", "waitlist"]),
		changeCohort(cohort) {
			this.changeCurrentCohort(cohort)
			this.goToCourse()
		}
	}
}
</script>

<style lang="scss">
#app .landing-page {
	height: 100%;
	background-color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow-y: hidden;
	position: relative;

	.dark-bg {
		background-color: black;
	}

	.login-page {
		max-height: 100%;
		display: flex;
	}

	.oswald {
		font-family: Oswald;
	}

	.image {
		height: auto;
	}

	.image img {
		margin-left: auto;
		margin-right: auto;
		padding: 2rem;
		max-height: 100%;
		width: auto;
	}

	.login-container {
		min-width: 40%;
		margin: 2rem;
		display: flex;
		justify-content: center;
	}

	h1 {
		font-size: 3rem;
		font-weight: bold;
		line-height: 3rem;
		text-align: center;
	}

	h2 {
		line-height: 1.5rem !important;
		font-size: 1.5rem !important;
		margin-top: 1.5rem;
		margin-bottom: 2rem !important;
		text-align: center;
	}

	.flex {
		display: flex;
	}

	.justify {
		justify-content: flex-start;
	}

	.align {
		align-items: center;
	}

	.vertical {
		flex-direction: column;
	}

	.login-button {
		margin-bottom: 2rem;
		display: flex;
		justify-content: space-around;
		width: 100%;
		max-width: 410px;
	}

	.button.is-primary {
		background-color: #0184ff;
		width: 9rem;
	}

	.button span {
		font-size: 1.75rem;
		font-weight: bold;
		font-family: Roboto;
	}

	@media only screen and (min-height: 700px) {
		.six-margin-bottom {
			margin-bottom: 6rem;
		}
	}

	// hides the image on mobile to show login
	@media only screen and (max-width: 900px) {
		.image {
			display: none;
		}
	}
}

#app .dark-bg {
	background-color: #0f172a;
}
</style>
