<template>
	<div class="admin-page">
		<div
			v-if="enrollmentRoles && enrollmentRoles[0]"
			class="white-background rounded-border three-hundo-width flex justify align vertical six-margin-bottom es-pa-lg fit-content min-height"
		>
			<!-- admin -->
			<div>
				<!-- title  -->
				<h1>Admin page</h1>
				<!-- description -->
				<div style="display:flex;flex-direction:column;align-items:center;justify-content:center;">
					<b-button
						v-if="['Teaching Assistant', 'Remote Enterprise Manager'].includes(enrollmentRoles[0]['es_role1.es_name'])"
						style="width:80%;margin-bottom:1rem;"
						@click="goToAttendance"
						>Attendance</b-button
					>
					<b-button style="width:80%" @click="goToGradebook">Gradebook</b-button>
				</div>
			</div>
		</div>
		<!-- loader -->
		<Loader v-else-if="loadingCohort || enrollmentLoading" />
		<!-- N/A -->
		<div
			v-else-if="!enrollmentRoles"
			class="white-background rounded-border three-hundo-width flex justify align vertical six-margin-bottom es-pa-lg fit-content min-height"
		>
			Error: Not authorized
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import Loader from "../components/Loader.vue"
import router from "../router/router.js"

export default {
	name: "Admin",
	components: {
		Loader
	},
	computed: mapGetters(["enrollmentRoles", "currentCohort", "loadingCohort", "enrollmentLoading"]),
	mounted() {
		this.changeCurrentPage("Admin")

		//load cohort if missing
		if (this.$route.params.cohortid && (!this.currentCohort || this.$route.params.cohortid != this.currentCohort.es_cohortid)) {
			this.fetchCohortData({
				es_cohortid: this.$route.params.cohortid,
				preventNav: true
			})
		}
	},
	methods: {
		...mapActions(["changeCurrentPage", "fetchCohortData"]),
		goToAttendance() {
			router.push({
				path: `/admin/${this.currentCohort.es_cohortid}/attendance`
			})
		},
		goToGradebook() {
			router.push({
				path: `/admin/${this.currentCohort.es_cohortid}/gradebook`
			})
		}
	}
}
</script>

<style lang="scss">
#app .admin-page {
	height: 100%;
	min-height: 396px;
	// background-color: #26a0da;
	// background-image: linear-gradient(to bottom right, #2aa8df, #1479bf);
	background-color: #0f172a;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow-y: scroll;

	.fit-content {
		height: fit-content;
	}

	.white-background {
		background-color: white;
	}

	.es-pa-lg {
		padding: 2rem;
	}

	h1 {
		font-size: 2rem !important;
		margin: 2rem 0 !important;
		line-height: 3rem !important;
		text-align: center;
	}

	h2 {
		line-height: 1.5rem !important;
		font-size: 1rem !important;
		margin-bottom: 2rem !important;
		text-align: center;
	}

	.rounded-border {
		border-radius: 5px;
	}

	.three-hundo-width {
		min-width: 345px;
	}

	.flex {
		display: flex;
	}

	.justify {
		justify-content: flex-start;
	}

	.align {
		align-items: center;
	}

	.vertical {
		flex-direction: column;
	}

	@media only screen and (min-height: 700px) {
		.six-margin-bottom {
			margin-bottom: 10rem;
		}
	}
}
</style>
