<template>
	<!--content + video-->
	<span class="content-video-component">
		<div class="content-video-columns">
			<div id="content-column-left" class="column is-half no-bottom-padding overflow-scroll">
				<markdown-renderer />
				<submit-button />
			</div>
			<div class="column is-half flex black-background side-padding-0">
				<div class="frame-frame">
					<div class="frame-div">
						<iframe
							:src="currentTopic.es_video"
							frameborder="0"
							allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
							allowfullscreen
						></iframe>
					</div>
				</div>
			</div>
		</div>
	</span>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import SubmitButton from "./SubmitButton.vue"
import MarkdownRenderer from "./MarkdownRenderer.vue"

export default {
	name: "VideoWithContent",
	computed: {
		...mapGetters(["currentTopic", "currentCohort"])
	},
	data() {
		return {
			iframe: null,
			feedbackModal: false,
			calcWidth: 0
		}
	},
	components: {
		MarkdownRenderer,
		SubmitButton
	},
	mounted() {},
	methods: {}
}
</script>

<style lang="scss">
#app .content-video-component {
	.overflow-scroll {
		overflow-y: scroll;
	}

	.time {
		font-size: 10px;
		font-family: "Roboto Condensed", sans-serif;
	}

	.due {
		font-size: 10px;
		font-family: "Roboto Condensed", sans-serif;
	}
	.class-header-subtitle {
		display: block;
	}

	.card-header-title {
		display: -webkit-box;
	}

	.current-lesson-container {
		height: auto;
	}

	.content-video-columns {
		margin: 0;
		display: flex;
		width: 100%;
		height: 100%;
	}

	.flex {
		display: flex;
	}

	.hundo-width {
		width: 100%;
	}

	.hundo-height {
		height: 100%;
	}

	.no-bottom-padding {
		padding-bottom: 0px;
	}

	.relative {
		position: relative;
	}

	// #app iframe {
	//     padding: unset ;
	// }

	.top-padding-2-5 {
		padding-top: 2.5rem;
	}

	.bottom-padding-2-5 {
		padding-bottom: 2.5rem;
	}

	.bottom-margin-2-5 {
		margin-bottom: 2.5rem;
	}

	.top-margin-2-5 {
		margin-top: 2.5rem;
	}

	.hundo-minus-80-height {
		height: calc(100% - 5rem);
		// height: 100%;
	}

	.black-background {
		background-color: black;
	}

	.align-center {
		align-items: center;
	}

	.flex-column {
		flex-direction: column;
	}

	.justify-center {
		justify-content: center;
	}

	.white-background {
		background-color: white;
	}

	#current-lesson-view {
		height: 100%;
	}

	.frame-frame {
		width: 100%;
		// max-width: 700px;
		margin: auto auto;
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}

	.frame-div {
		position: relative;
		overflow: hidden;
		padding-top: 56.25%;
		// padding-top: 100%;
	}

	.frame-div iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 0;
	}

	.black-background {
		background-color: black;
	}
	#content-column-left {
		flex: 0 1 auto;
	}

	#content-column-right {
		flex: 1 1 auto;
	}

	.hidden {
		display: none;
	}

	#iframe-coverer.full-height.transparent,
	#focus-iframe-coverer.full-height.transparent {
		background-color: red;
		opacity: 0;
		height: 100%;
		position: absolute;
		top: 0;
		width: 100%;
	}

	#iframe-coverer.full-height.transparent {
		cursor: ew-resize;
	}

	#focus-iframe-coverer.full-height.transparent {
		cursor: text;
	}

	.resize-both {
		resize: both;
	}

	.side-padding-0 {
		padding-left: 0px;
		padding-right: 0px;
	}
	@media only screen and (max-width: 600px) {
		.is-half {
			min-width: 50%;
		}
	}
}
</style>
