<template>
	<div v-if="loadedQuestion" class="upload-content-question-component" :key="loadedQuestion.es_topicquestionid">
		<!-- Upload -->
		<!-- question text -->
		<p class="question-margin-bottom">{{ this.loadedQuestion.es_text }}</p>

		<div class="flex-center">
			<!-- file box -->
			<div class="margin-top-1 flex-center upload-light-border height-10 width-30 alice-blue-bg-color border-radius-2 box-shadow-4">
				<div class="test-div">
					<div v-if="file" class="flex-row center-self">
						<vue-fontawesome :icon="['far', 'file']" size="2x" color="black" />

						<!-- file name -->
						<span class="file-name">{{ file.name }}</span>

						<vue-fontawesome v-if="correctFileType" :icon="['fas', 'check-circle']" size="2x" color="#4be66d" />
						<vue-fontawesome v-else :icon="['fas', 'times-circle']" size="2x" color="#e94542" />
					</div>
					<div v-else class="flex-row">
						<vue-fontawesome :icon="['far', 'file']" size="2x" color="black" />
						<span class="file-name">No file chosen</span>
					</div>

					<p class="error-p" v-if="file && !correctFileType">
						Only files with following extensions are allowed:
						<em>pdf</em>.
					</p>
				</div>
			</div>
		</div>

		<!-- upload -->
		<b-field class="file flex-center vertical-margin-2">
			<b-upload v-model="file" @input="handleChange(file)">
				<a class="button is-info clear-markdown">
					<vue-fontawesome :icon="['fas', 'upload']" size="sm" color="white" />
					<span v-if="!checkingQuestion">Choose File</span>
					<Loader class="padding-right-2" v-else />
				</a>
			</b-upload>
		</b-field>
	</div>
	<Loader v-else />
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import axios from "axios"
import EventBus from "../event-bus"
import Loader from "./Loader"
import { openSnackBar } from "../utils/openSnackBar"
import { checkOpenResponseQuestion } from "../api/question.js"
import router from "../router/router.js"

export default {
	name: "UploadContentQuestion",
	props: ["question"],
	components: { Loader },
	computed: {
		...mapGetters(["questionChoices", "currentCohort", "baseUrl", "currentCohort", "checkingQuestion", "currentTopic"])
	},
	data() {
		return {
			loadedQuestion: null,
			correctAnswer: false,
			answer: null,
			file: null,
			loading: false,
			active: false,
			correctFileType: false
		}
	},
	mounted() {
		this.loadedQuestion = this.question
	},
	methods: {
		...mapActions(["updateCheckingQuestion", "setErrorMessage"]),
		handleChange(file) {
			this.updateCheckingQuestion(true)

			let reader = new FileReader()
			let correctFileType = this.checkFileType(file)

			if (!correctFileType) {
				this.alertWrongType(file)
				this.clearPreviousFile()
			} else {
				reader.readAsDataURL(file)

				let handler = this

				//for some reason, the result wasn't working like in UploadQuizQuestion, hence the arrow function
				reader.onload = res => {
					handler.handleFile(res.currentTarget.result)
				}
				// reader.onload = this.checkUploadQuestion(reader);
			}
		},
		handleFile(result) {
			//parse
			let idx = result.indexOf("base64,") + "base64,".length

			//make the data object
			let localData = {
				questionid: this.loadedQuestion.es_topicquestionid,
				correct: true,
				choiceid: null,
				text: "See attachment",
				type: this.loadedQuestion.es_type,
				status: "pending",
				questionText: this.loadedQuestion.es_text,
				file: result.slice(idx) //parsed
			}

			let serverData = {
				es_topicquestionid: this.loadedQuestion.es_topicquestionid,
				es_text: "See attachment",
				es_cohortid: this.currentCohort.es_cohortid,
				es_topicid: this.currentTopic.es_topicid
			}

			checkOpenResponseQuestion(serverData)
				.then(response => {
					if (response.data === true) {
						EventBus.$emit("answered", localData)
					} else {
						this.clearPreviousFile()
					}
					this.updateCheckingQuestion(false)
				})
				.catch(err => {
					console.log("api/checkuploadquestion error:", err)
					this.updateCheckingQuestion(false)
					this.setErrorMessage("Error checking question.")
					router.push({ path: "/error" })
				})
		},
		checkFileType(file) {
			let correctType = file.type == "application/pdf"
			this.correctFileType = correctType

			return correctType
		},
		alertWrongType(file) {
			openSnackBar(this, `Error: ${file.type} is not supported.`)
		},
		clearPreviousFile() {
			//update selection to null

			EventBus.$emit("answered", {
				questionid: this.loadedQuestion.es_topicquestionid,
				correct: false,
				choiceid: null,
				text: "Wrong file type and/or no file",
				type: this.loadedQuestion.es_type,
				questionText: this.loadedQuestion.es_text,
				file: null
			})
		}
	}
}
</script>

<style lang="scss">
#app .upload-content-question-component {
	// -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
	// box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
	// padding: 0.5rem;
	// margin-left: 1rem;
	// background-color: #dde4fb;
	// margin-right: 5rem;
	// margin-top: 2rem;
	// margin-bottom: 1rem;

	.message-box {
		margin: 1rem;
		border: 1px solid #2ca8df;
		height: 6rem;
		overflow-y: auto;
		padding: 0.5rem 1rem;
		background-color: #ced9fd;
	}

	.flex {
		display: flex;
	}

	.align-center {
		align-items: center;
	}

	.choice-container {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.validity-icon {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 24px;
		margin-top: 0px;
		padding-left: 12px;
	}

	.empty {
		padding: 12px;
	}

	.b-radio.radio {
		margin-bottom: 0.5rem;
	}

	.button {
		margin-left: 1rem;
	}

	.choices-block .b-tooltip {
		line-height: 1.5rem;
	}

	.checkbox {
		margin-bottom: 0.5rem;
		line-height: 1.5rem;
	}

	.b-checkbox.checkbox + .checkbox {
		margin-left: 0rem;
	}

	.upload-light-border {
		border: 1px solid lightblue;
	}

	.flex-center {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.file-name {
		border: none;
		max-width: 12rem;
	}

	a.clear-markdown {
		text-decoration: none;
		color: white;
	}

	.upload span {
		padding-left: 0.5rem;
	}

	.vertical-margin-2 {
		margin-bottom: 2rem;
		margin-top: 2rem;
	}

	.square {
		width: 50%;
		min-width: 20rem;
		max-width: 25rem;
		max-height: 8rem;
	}

	.square:after {
		content: "";
		display: block;
		padding-bottom: 100%;
	}

	.margin-top-1 {
		margin-top: 1rem;
	}

	.alice-blue-bg-color {
		background-color: aliceblue;
	}

	.flex-row {
		display: flex;
		flex-direction: row;
	}

	.box-shadow-4 {
		box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
	}

	.border-radius-2 {
		border-radius: 1rem;
	}

	.column {
		flex-direction: column;
	}

	.height-10 {
		height: 10rem;
	}

	.width-30 {
		width: 30rem;
	}

	.test-div {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: relative;
		height: inherit;
		width: inherit;
	}

	.center-self {
		justify-self: center;
		align-self: center;
	}

	.test-div .error-p {
		margin: 0;
		padding: 0;
		position: absolute;
		font-weight: bold;
		bottom: 1px;
		padding-bottom: 1rem;
		color: #ea4542;
	}

	.padding-right-2 {
		padding-right: 2rem;
	}
}

#app .markdown-renderer-component .markdown .upload-content-question-component {
	.vertical-margin-2 {
		margin-bottom: 2rem;
		margin-top: 2rem;
	}

	a {
		text-decoration: none;
		color: white;
	}
}
</style>
