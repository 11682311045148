<template>
    <span class="loader-component">
        <div class="spinner-container">
            <div class="button is-loading"></div>
        </div>
    </span>
</template>

<script>
export default {
    name: "Loader"
};
</script>

<style lang="scss">
#app .loader-component {
    // background-color: none;
    .is-loading {
        border: none;
    }
    .button.is-loading::after {
        height: 2rem;
        width: 2rem;
    }

    .spinner-container {
        width: 2rem;
        margin-left: auto;
        margin-right: auto;
    }

    .button {
        background-color: transparent;
    }
}
</style>