import { render, staticRenderFns } from "./ContentIndex.vue?vue&type=template&id=278f50ba&"
import script from "./ContentIndex.vue?vue&type=script&lang=js&"
export * from "./ContentIndex.vue?vue&type=script&lang=js&"
import style0 from "./ContentIndex.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports