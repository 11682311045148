<template>
	<div v-if="cohortsArray" class="course-component">
		<!-- contains the ice-blue header bar on each topic -->
		<quiz-header v-if="takingQuiz" />
		<content-nav v-else />
		<!-- container for topics -->
		<current-lesson :key="currentTopic.es_topicid + 'course'" />
		<!-- a popup modal unique to quizzes -->
		<b-modal
			v-if="[717580002, 717580004, 717580005].includes(this.currentTopic.es_type)"
			:active.sync="quizGuard"
			has-modal-card
			trap-focus
			aria-role="dialog"
			aria-modal
		>
			<div class="card">
				<div class="card-content">
					<p>If you leave the quiz now, the timer will keep running. Are you sure you want to leave?</p>
				</div>
				<div class="card-buttons">
					<b-button type="is-danger" @click="navAway()">Yes</b-button>
					<b-button type="is-info" @click="stayOnPage()">No</b-button>
				</div>
			</div>
		</b-modal>
	</div>
	<div v-else>
		<Loader />
	</div>
</template>

<script>
import CourseContent from "../components/CourseContent.vue"
import CurrentLesson from "../components/CurrentLesson.vue"
import ContentNav from "../components/ContentNav.vue"
import CourseMenu from "../components/CourseMenu.vue"
import Loader from "../components/Loader.vue"
import QuizHeader from "../components/QuizHeader.vue"
import { mapGetters, mapActions } from "vuex"
import EventBus from "../event-bus"
import router from "../router/router.js"

export default {
	name: "Course",
	components: {
		CourseContent,
		ContentNav,
		CurrentLesson,
		Loader,
		CourseMenu,
		QuizHeader
	},
	data() {
		return {
			contentClass: "column is-one-third",
			quizGuard: false,
			navPath: null
		}
	},
	computed: mapGetters([
		"cohortsArray",
		"authentication",
		"incompleteCohort",
		"incompleteEnrollment",
		"isIndexCollapsed",
		"currentCohort",
		"currentModule",
		"currentPage",
		"currentTopic",
		"currentAttempt",
		"takingQuiz"
	]),
	mounted() {
		this.changeCurrentPage("Course Content")
		this.changeCurrentContent() //calls api/topiccontent
	},
	watch: {
		isIndexCollapsed: function() {
			if (this.isIndexCollapsed) {
				this.contentClass = "column is-one-third collapsed"
			} else {
				this.contentClass = "column is-one-third"
			}
		},
		$route(to, from) {
			if (from.name != "course") {
				let topic = this.currentModule.topics.find(topic => topic.es_topicid == this.$route.params.topicid)
				this.updateCurrentTopic(topic)
				this.changeCurrentContent() //calls api/topiccontent
			}
		},
		currentAttempt: function() {
			if (!this.currentAttempt) {
				this.updateTakingQuiz(false)
			}
		}
	},
	beforeRouteLeave(to, from, next) {
		//check if topic is quiz and attempt started
		if ([717580002, 717580004, 717580005].includes(this.currentTopic.es_type) && this.currentAttempt && !this.navPath) {
			this.navPath = to.path
			this.quizGuard = true
		} else {
			next()
		}
	},
	beforeRouteUpdate(to, from, next) {
		//check if topic is quiz and attempt started
		if ([717580002, 717580004, 717580005].includes(this.currentTopic.es_type) && this.currentAttempt && !this.navPath) {
			this.navPath = to.path
			this.quizGuard = true
		} else {
			next()
		}
	},
	methods: {
		...mapActions([
			"changeCurrentPage",
			"updateCurrentModule",
			"updateCurrentTopic",
			"changeCurrentContent",
			"updateTakingQuiz",
			"setCurrentAttempt",
			"stopTimer",
			"clearStudentQuizSelections"
		]),
		navAway() {
			this.stopTimer()

			this.updateTakingQuiz(false)
			this.setCurrentAttempt(null)

			EventBus.$emit("agree-to-attempt", false)
			this.quizGuard = false

			//nav away
			router.push({ path: this.navPath })
			this.navPath = null

			this.clearStudentQuizSelections()
		},
		stayOnPage() {
			this.quizGuard = false
			this.navPath = null
		}
	}
}
</script>

<style lang="scss">
#app .course-component {
	height: 100%;
	// display: flex;
	// flex-direction: column;

	.collapsed {
		width: 0;
		transition: width 0.5s;
	}

	.is-one-third {
		transition: width 0.5s;
	}

	.card {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		height: 15rem;
		width: 25rem;
		border-radius: 10px;
	}

	.card-content {
		margin-bottom: 2rem;
	}

	.card-buttons {
		width: 15rem;
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		align-items: center;

		.button {
			width: 4rem;
		}
	}
}
</style>
