import router from '../../router/router.js';
import { getSelections, submitSelections, skipSelection, getCorrectChoices } from '../../api/selection.js';

const state = {
    currentSelections: null,
    previousSelections: null,
    currentCorrectChoices: null
};

const getters = {
    currentSelections: (state) => state.currentSelections,
    previousSelections: (state) => state.previousSelections,
    currentCorrectChoices: (state) => state.currentCorrectChoices,
};

const actions = {
    getSelections({ commit }, data) {
        getSelections(data).then(response => {
            commit('setCurrentSelections', response.data.value)
        }).catch(err => {
            console.log("api/selections error", err)
            commit('setAuthentication', false)
            commit('setContact', null)
            commit('setErrorMessage', "Error getting selections.")
            router.push({ path: '/error' })
        })
    },
    submitSelections({ commit }, data) {
        submitSelections(data).then(response => {
            // console.log(response)
        }).catch(err => {
            console.log("api/batchselections error", err)
            commit('setAuthentication', false)
            commit('setContact', null)
            commit('setErrorMessage', "Error patching selections.")
            router.push({ path: '/error' })

        })
    },
    skipSelection({ commit }, data) {
        skipSelection(data).then(response => {
            // console.log(response)
        }).catch(err => {
            console.log("api/skipselection error", err)
            commit('setAuthentication', false)
            commit('setContact', null)
            commit('setErrorMessage', "Error skipping selection.")
            router.push({ path: '/error' })

        })
    },
    setPreviousSelections({ commit }, data) {
        commit("setPreviousSelections", data)
    },
    getCorrectChoices({ commit, rootState }, data) {
        if (!data.ids.length > 0) {
            commit('setCurrentCorrectChoices', [])
            return
        }
        let enrollmentAndQuestions = {
            enrollmentid: rootState.enrollments.currentEnrollment.es_enrollmentid,
            questionids: data.ids,
            topicid: data.topicid || rootState.topics.currentTopic.es_topicid
        }
        getCorrectChoices(enrollmentAndQuestions).then(response => {
            // console.log(response.data.results)
            commit('setCurrentCorrectChoices', response.data.results)

        }).catch(err => {
            console.log("api/correctselections error", err)
            commit('setErrorMessage', "Error getting correct selections.")
            router.push({ path: '/error' })
        })
    },
    clearCorrectChoices({ commit }) {
        commit('clearCorrectChoices')

    }
};

const mutations = {
    setCurrentSelections: (state, currentSelections) => (state.currentSelections = currentSelections),
    setPreviousSelections: (state, arr) => (state.previousSelections = arr),
    setCurrentCorrectChoices: (state, arr) => (state.currentCorrectChoices = arr),
    clearCorrectChoices: (state) => (state.currentCorrectChoices = null)
};

export default {
    state,
    getters,
    actions,
    mutations
}

