import EventBus from "../../event-bus"

const state = {
	takingQuiz: false,
	quizFinished: false,
	currentQuestion: null,
	currentQuestionIndex: 0,
	quizQuestionsArr: null,
	continuingQuiz: false,
	studentSelectionsArr: [],
	studentQuizSelections: [],
	currentQuestionSelection: null,
	isAnswered: false
}

const getters = {
	takingQuiz: state => state.takingQuiz,
	continuingQuiz: state => state.continuingQuiz,
	quizFinished: state => state.quizFinished,
	currentQuestion: state => state.currentQuestion,
	currentQuestionIndex: state => state.currentQuestionIndex,
	quizQuestionsArr: state => state.quizQuestionsArr,
	studentSelectionsArr: state => state.studentSelectionsArr,
	studentQuizSelections: state => state.studentQuizSelections,
	currentQuestionSelection: state => state.currentQuestionSelection,
	isAnswered: state => state.isAnswered
}

const actions = {
	addSelection({ commit }, data) {
		commit("addSelection", data)
	},
	updateTakingQuiz({ commit }, data) {
		commit("updateTakingQuiz", data)
	},
	updateContinuingQuiz({ commit }, data) {
		commit("updateContinuingQuiz", data)
	},
	updateQuizFinished({ commit }, data) {
		commit("updateQuizFinished", data)
	},
	updateCurrentQuestion({ commit }, data) {
		commit("updateCurrentQuestion", data)
	},
	updateCurrentQuestionIndex({ commit }, data) {
		commit("updateCurrentQuestionIndex", data)
	},
	setQuizQuestionsArr({ commit, rootState, dispatch }, data) {
		commit("setQuizQuestionsArr", data)
	},
	setStudentSelectionsArr({ commit, rootState, dispatch }, data) {
		commit("setStudentSelectionsArr", data)
	},
	answerQuizQuestion({ commit, rootState, dispatch }, data) {
		// this.currentSelection = data;
		commit("setCurrentQuestionSelection", data)

		//check if answer was skipped
		if (data.choices && data.choices[0] && data.choices[0].es_text && data.choices[0].es_text != "Invalid file type") {
			commit("setIsAnswered", true)
		} else {
			commit("setIsAnswered", false)
		}

		// this.updateStudentSelections(data);
		dispatch("updateStudentSelections", data)
	},
	updateStudentSelections({ commit, rootState, dispatch }, data) {
		//posts selection if choices made
		if (data.choices) {
			if (!data.es_selectionstatus) {
				let dataCopy = { ...data }
				dataCopy.es_selectionstatus = 717580002
				dispatch("updateAttemptSelection", { data: dataCopy, es_attemptid: rootState.attempts.currentAttempt })
			} else {
				dispatch("updateAttemptSelection", { data: data, es_attemptid: rootState.attempts.currentAttempt })
			}
		}

		//see if studentQuizSelections array contains the recently answered question
		let matchingQuestionId = rootState.quizzes.studentQuizSelections.find(element => {
			return element.es_topicquestionid == data.es_topicquestionid
		})

		//make new studentQuestion object
		let answeredQuestion = {
			es_topicquestionid: data.es_topicquestionid,
			choices: data.choices,
			es_text: data.es_text,
			es_type: data.es_type,
			es_sort: data.es_sort,
			es_selectionstatus: data.es_selectionstatus ? data.es_selectionstatus : 717580002 //pending or unanswered
		}

		if (!matchingQuestionId) {
			commit("addSelection", answeredQuestion)
		} else if (matchingQuestionId) {
			let indexOfQuestionId = rootState.quizzes.studentQuizSelections.indexOf(matchingQuestionId)

			commit("spliceSelection", { indexOfQuestionId, answeredQuestion })
		}
		EventBus.$emit("adjust-padding") //keeps everything in view
	},
	clearCurrentSelection({ commit }) {
		commit("clearCurrentSelection")
	},
	clearStudentQuizSelections({ commit }) {
		commit("clearStudentQuizSelections")
	},
	skipLocalSelection({ commit, dispatch, rootState }, data) {
		//make object
		var skippedQuestion = {
			es_topicquestionid: data.es_topicquestionid,
			choices: data.choices,
			es_text: data.es_text,
			es_type: data.es_type,
			es_sort: data.es_sort,
			es_selectionstatus: 717580003
		}

		//find it's index
		let matchingQuestionId = rootState.quizzes.studentQuizSelections.find(element => {
			return element.es_topicquestionid == data.es_topicquestionid
		})

		//replace it
		var indexOfQuestionId = rootState.quizzes.studentQuizSelections.indexOf(matchingQuestionId)

		commit("spliceSelection", { indexOfQuestionId, answeredQuestion: skippedQuestion })
	}
}

const mutations = {
	updateTakingQuiz: (state, bool) => (state.takingQuiz = bool),
	updateContinuingQuiz: (state, bool) => (state.continuingQuiz = bool),
	updateCurrentQuestion: (state, question) => (state.currentQuestion = question),
	updateCurrentQuestionIndex: (state, int) => (state.currentQuestionIndex = int),
	setQuizQuestionsArr: (state, arr) => (state.quizQuestionsArr = arr),
	updateQuizFinished: (state, bool) => (state.quizFinished = bool),
	setStudentSelectionsArr: (state, arr) => (state.studentSelectionsArr = arr),
	setStudentQuizSelections: (state, arr) => (state.studentQuizSelections = arr),
	setCurrentQuestionSelection: (state, selection) => (state.currentQuestionSelection = selection),
	setIsAnswered: (state, bool) => (state.isAnswered = bool),
	addSelection(state, selection) {
		state.studentQuizSelections.push(selection)
	},
	spliceSelection(state, data) {
		state.studentQuizSelections.splice(data.indexOfQuestionId, 1, data.answeredQuestion)
	},
	clearCurrentSelection: state => (state.currentQuestionSelection = null),
	clearStudentQuizSelections: state => (state.studentQuizSelections = [])
}

export default {
	state,
	getters,
	actions,
	mutations
}
