<template>
	<div id="app">
		<Nav />
		<router-view id="body-wrapper" />
	</div>
</template>

<script>
import Nav from "./components/Nav.vue"
import { mapActions, mapGetters } from "vuex"
import router from "./router/router.js"

export default {
	name: "app",
	components: { Nav },
	mounted() {
		this.determineUrl()
		this.getCohortCards()
		this.setTimeZone()
		if (window.location.hostname != "localhost") {
			this.setCyberLabToken()
		}
	},
	computed: mapGetters(["attempts"]),
	watch: {
		attempts: function() {
			this.getCohortCards()
		}
	},
	methods: {
		...mapActions(["determineUrl", "fetchCohortData", "setTimeZone", "getCohortCards", "setCyberLabToken", "checkAuth"])
	}
}
</script>

<style lang="scss">
html,
body {
	height: 100% !important;
	min-width: 920px;
}

html {
	overflow-y: hidden;
}

#body-wrapper {
	height: calc(100% - 56px) !important;
}
#app {
	font-family: "Roboto", "Avenir", Helvetica, Arial, sans-serif;
	overflow-y: hidden;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	box-shadow: none !important;
	height: 100vh;
	// Import Bulma's core
	@import "~bulma/sass/utilities/_all";

	// Set your colors
	$primary: #3273dc;
	$primary-invert: findColorInvert($primary);
	$twitter: #4099ff;
	$twitter-invert: findColorInvert($twitter);
	$black: #000000;

	$evolve-blue: #1479bf;
	$evolve-lightblue: #2aa8df;

	$evolve-blue-invert: findColorInvert($evolve-blue);

	// Setup $colors to use as bulma classes (e.g. 'is-twitter')
	$colors: (
		"white": (
			$white,
			$black
		),
		"black": (
			$black,
			$white
		),
		"light": (
			$light,
			$light-invert
		),
		"dark": (
			$dark,
			$dark-invert
		),
		"primary": (
			$primary,
			$primary-invert
		),
		"info": (
			$info,
			$info-invert
		),
		"success": (
			$success,
			$success-invert
		),
		"warning": (
			$warning,
			$warning-invert
		),
		"danger": (
			$danger,
			$danger-invert
		),
		"twitter": (
			$twitter,
			$twitter-invert
		),
		"evolve-blue": (
			$evolve-blue,
			$evolve-blue-invert
		)
	);

	// Links
	$link: $primary;
	$link-invert: $primary-invert;
	$link-focus-border: $primary;

	$navbar-breakpoint: 10px;

	// Import Bulma and Buefy styles
	@import "~bulma";
	@import "~buefy/src/scss/buefy";

	color: black !important;
}

html {
	overflow-y: hidden !important;
}

@media screen and (max-width: 1023px) {
	.navbar-menu {
		background-color: black !important;
	}
}

.notices .snackbar {
	background-color: #ffdd56 !important;
	color: #363636 !important;
}

@keyframes moveInBottom {
	0% {
		opacity: 0;
		left: 50%;
		transform: translate(-50%, 2rem);
	}

	100% {
		opacity: 1;
		left: 50%;
		transform: translate(-50%, 0);
	}
}
</style>
