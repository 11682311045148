<template>
	<section v-if="cohortsArray && attempts" class="content-container content-index content-index-component">
		<!-- module -->
		<div v-for="(module, index) of currentCohort.modules" class="card" :key="index" :class="isOpen === index ? 'shadow' : 'header-shadow'">
			<div
				class="card-header"
				:style="{ cursor: 'pointer' }"
				@click="handleOpenClose(index)"
				:class="determineCardClass(isOpen, index, module.es_moduleid)"
			>
				<div class="columns is-mobile">
					<div class="column is-1">
						<p class="card-header-title">{{ index + 1 }}</p>
					</div>
					<div class="column">
						<p class="card-header-title">{{ module.es_name }}</p>
					</div>
					<div class="column is-2">
						<p class="card-header-title">
							{{ topicsCompleted(module.es_moduleid, module.topics) }}/{{ module.topics.length }}
						</p>
					</div>
					<div class="column is-1">
						<a class="card-header-icon">
							<b-icon :icon="isOpen == index ? 'chevron-up' : 'chevron-down'"></b-icon>
						</a>
					</div>
				</div>
			</div>
			<div class="card-content" :id="'card-' + index" :class="isOpen == index ? 'opened' : 'collapsed'">
				<!-- topics -->
				<content-index-topic-row
					v-for="topic in module.topics"
					:key="topic.es_topicid"
					:topic="topic"
					:module="module"
					:currentTopic="currentTopic"
					class="columns is-mobile topic-listing"
					:dueStatus="topicDueStatus(topic)"
					:isOpen="isOpen"
					:index="index"
				/>
			</div>
		</div>
	</section>
</template>
<script>
import { mapGetters, mapActions } from "vuex"
import contentActive from "../assets/content-icon.png"
import contentInactive from "../assets/content-icon.png"
import quizActive from "../assets/quiz-icon.png"
import quizInactive from "../assets/quiz-icon.png"
import moment from "moment"
import logo from "../assets/ea-logo.png"
import check from "../assets/check.png"
import overdueDot from "../assets/oval.png"
import ContentIndexTopicRow from "./ContentIndexTopicRow.vue"

export default {
	name: "ContentIndex",
	computed: mapGetters(["attempts", "currentTopic", "cohortsArray", "currentCohort", "currentModule"]),
	components: { ContentIndexTopicRow },
	data() {
		return {
			isOpen: 0,
			contentActive: contentActive,
			contentInactive: contentInactive,
			quizActive: quizActive,
			quizInactive: quizInactive,
			check: check,
			overdueDot: overdueDot
		}
	},
	mounted() {
		this.checkStartOpen()
	},
	watch: {
		isOpen: function() {
			this.calcHeights()
		},
		currentModule: function(newVal, oldVal) {
			//detemine index of new module
			let newIndex = this.currentCohort.modules.findIndex(module => module.es_moduleid == newVal.es_moduleid)
			if (this.isOpen != newIndex) {
				this.isOpen = newIndex
			}
		}
	},
	methods: {
		checkStartOpen() {
			//on direct navigation or remount of component, finds current module and opens that card
			let newIndex = this.currentCohort.modules.findIndex(module => module.es_moduleid == this.currentModule.es_moduleid)
			if (this.isOpen != newIndex) {
				this.isOpen = newIndex
			}
		},
		calcHeights() {
			//heights are calculated dynamically affected by opened/closed and number of topics
			//height must be set explicitly for animations
			if (this.isOpen != null) {
				for (let i = 0; i < this.currentCohort["modules"].length; i++) {
					if (this.isOpen == i) {
						document.getElementById("card-" + i).style.height = this.currentCohort.modules[i].topics.length * 48 + 24 + "px"
					} else {
						document.getElementById("card-" + i).style.height = "0px"
					}
				}
			} else {
				let height = 0
				for (let i = 0; i < this.currentCohort["modules"].length; i++) {
					document.getElementById("card-" + i).style.height = height + "px"
				}
			}
		},
		handleOpenClose(index) {
			//if already open, close
			if (this.isOpen === index) {
				this.isOpen = null
			} else {
				this.isOpen = index
			}
		},
		determineCardClass(isOpen, index, moduleid) {
			//cards have different class depending on if they're open, the first card, or after an opened card
			let cardClass
			let order = index
			let prevModule

			if (order > 0) {
				prevModule = this.currentCohort["modules"][order - 1]
			}

			if (isOpen == index) {
				cardClass = "open"
			} else {
				cardClass = "closed"
			}

			if (index == 0) {
				cardClass += " first-card"
			}

			if (index - 1 == isOpen) {
				cardClass += " after-opened-card"
			}

			return cardClass
		},
		topicDueStatus(topic) {
			//affects the styling of the topic row, presence of due dot, use of score vs checkmark/overdue/due in

			let contactAttempts = this.attempts
			let completedAttempts = contactAttempts.filter(attempt => {
				return attempt._es_topic_value === topic.es_topicid && attempt.es_issubmitted && attempt.es_attemptstatus != 717580000
			})

			//for quizes only
			if (completedAttempts.length > 0 && [717580002, 717580005, 717580004].includes(topic.es_type)) {
				return this.handleQuizTypes(completedAttempts, topic)
			}

			//everything else gets a checkmark if it has any completed attempts at all
			if (completedAttempts.length > 0) {
				if (
					completedAttempts.some(attempt => {
						return attempt.es_attemptstatus == 717580000
					})
				) {
					return "pending"
				} else {
					return "done"
				}
			} else if (!topic.es_duedate && topic.es_enddate) {
				//no due date, use end date as end date
				let today = new Date()
				let daysBeforeDue = null
				var a = moment().startOf("day")
				var b = moment(topic.es_enddate).endOf("day")
				var diff = b.diff(a, "hours") // =1
				return diff
			} else {
				let today = new Date()
				let daysBeforeDue = null
				var a = moment().startOf("day")
				var b = moment(topic.es_duedate).endOf("day")
				var diff = b.diff(a, "hours") // =1
				return diff
			}
		},
		handleQuizTypes(completedAttempts, topic) {
			let score = 0
			//return highest grade
			completedAttempts.forEach(attempt => {
				if (attempt.es_score > score && attempt.es_attemptstatus != 717580000) {
					score = attempt.es_score
				}
			})
			let isPassing

			//find out if fail
			let requiredScore = topic.es_requiredscore || 70
			if (score * 100 < requiredScore) {
				isPassing = false
			} else {
				isPassing = true
			}

			return {
				status: "quiz",
				score: score,
				isPassing: isPassing,
				isPending: false
			}
			// }
		},
		topicsCompleted(moduleid, topics) {
			//calculates the fraction at the top of each module indicating topics completed out of total topics in module
			let submitted = 0
			let contactAttempts = this.attempts

			let completedTopics = topics.filter(topic =>
				contactAttempts.some(
					attempt => topic.es_topicid === attempt._es_topic_value && attempt.es_issubmitted && attempt.es_score >= 0.7
				)
			)

			return completedTopics.length
		}
	}
}
</script>

<style lang="scss">
#app .content-index-component {
	.shadow {
		box-shadow: 0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.8);
		transition: box-shadow 0.6s ease-in;
	}

	.card-header-title {
		display: -webkit-box;
	}

	.content-container {
		height: 100%;
	}

	#course-content-tabs div.card.shadow {
		// height: auto;
	}

	.card-header.after-opened-card {
		margin-top: 1rem;
		transition: margin-top 0.3s ease-out;
	}

	.card-header {
		margin-top: 0px;
		transition: margin-top 0.3s ease-in;
	}

	.card-header.open {
		margin-top: 1rem;
		transition: margin-top 0.3s ease-out;
	}

	.card-header.first-card {
		margin-top: 1rem;
	}

	.card-header .columns {
		width: 100%;
	}

	.card-header-title {
		color: #9b9b9b;
	}

	.card-header.open .card-header-title {
		color: black;
	}

	.card-header.open {
		background-color: #eaf5ff;
	}

	.topic-listing {
		align-items: middle;
		justify-content: center;
		cursor: pointer;
		max-height: 3rem;
	}

	.topic-listing div:nth-child(3) {
		padding: 0;
	}

	.time {
		padding: 0;
	}

	.topic-listing:hover {
		background-color: #eef7fb;
	}

	.topic-listing.topic-row.locked-topic:hover {
		background-color: white;
	}

	.overdue {
		color: #e94542;
	}

	.topic-listing .topic-icon {
		max-width: unset;
		height: 1rem;
		width: auto;
	}

	.columns.topic-listing {
		align-items: center;
		position: relative;
	}

	.columns.topic-listing .content {
		flex: 1;

		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.card-header .columns {
		margin-top: 0;
		margin-bottom: 0;
		align-items: center;
		height: 4rem;
	}

	.card-header:hover {
		background-color: #eaf5ff;
	}

	.collapse.card:last-of-type {
		margin-bottom: 2rem;
	}

	.collapsed {
		height: 0px;
		width: auto;
		padding-top: 0px;
		padding-bottom: 0px;
		overflow-y: hidden;
		transition: padding-top 0.3s ease-in, padding-bottom 0.3s ease-in, height 0.3s ease-in;
	}
	.opened {
		overflow-y: hidden;
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
		transition: height 0.3s ease-out, padding-top 0.3s ease-out, padding-bottom 0.3s ease-out;
	}

	.shadow {
		box-shadow: 0rem 0.5rem 0.5rem rgba(0, 0, 0, 0.8);
		transition: box-shadow 0.3s ease-out;
	}

	.card.header-shadow {
		box-shadow: 0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.8);
	}

	.header-shadow,
	.card-header,
	.card {
		border-radius: 0rem;
	}
}
</style>
