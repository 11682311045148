<template>
    <span class="control-panel-controls-component">
        <div
            class="controls flex space-evenly full-width align-center double-height quarter-padding-top quarter-padding-bottom"
        >
            <span v-if="true">
                <div class="machine-btn hover-pointer inline-block-display" @click="handleClick('stop')">
                    <vue-fontawesome icon="stop" color="#f14768" />
                    <span class="red padding-left">Stop</span>
                </div>
                <div class="machine-btn hover-pointer inline-block-display margin-left" @click="handleClick('reset')">
                    <vue-fontawesome icon="redo" color="#af983a" />
                    <span class="yellow padding-left">Reset</span>
                </div>
                <div class="machine-btn hover-pointer inline-block-display margin-left" @click="handleClick('add-time')">
                    <vue-fontawesome icon="hourglass" color="#3298dc" />
                    <span class="blue padding-left">+30 mins</span>
                </div>
            </span>
        <!-- <div v-else>
            <b-button
                size="is-small"
                type="is-warning"
                href="https://control.cyberlab.evolvesecurity.com/#/login"
                target="_blank"
                tag="a"
            >Authenticate Controls</b-button>
            </div>-->
        </div>
    </span>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import EventBus from "../event-bus";
import { openSnackBar } from "../utils/openSnackBar";

export default {
    name: "ControlPanelControls",
    computed: mapGetters([
        "currentTopic",
        "frameUrl",
        "baseUrl",
        "controlAuthenticated",
        "currentMachine"
    ]),
    data() {
        return {
            active: true
        };
    },
    beforeDestroy() {
        // null
    },
    watch: {
        currentTopic: function() {
            //null
        }
    },
    methods: {
        ...mapActions([
            // "checkControlAuthenticated",
            "stopMachineForTopic",
            "resetMachineForTopic",
            "addTimeForMachineForTopic"
        ]),
        handleClick(command) {
            if (command === "stop") {
                openSnackBar(this, `Stopping ${this.currentMachine.es_box.es_name} machine`);
                this.stopMachineForTopic();
            }

            if (command === "reset") {
                openSnackBar(this, `Reseting ${this.currentMachine.es_box.es_name} machine`);
                this.resetMachineForTopic();
            }
            if (command === "add-time") {
                openSnackBar(this, `Adding 30 mins for ${this.currentMachine.es_box.es_name} machine`);
                this.addTimeForMachineForTopic();
            }
        }
    }
};
</script>

<style lang="scss">
#app .control-panel-controls-component {
    .half-width {
        width: 50%;
    }

    .full-width {
        width: 100%;
    }

    .flex {
        display: flex;
    }

    .inline-block-display {
        display: inline-block;
    }

    .machine-btn {
        font-size: 0.9rem;
        transition: all 0.2s;

        &:hover {
            transform: translateY(-3px);
            box-shadow: 0 1rem 2rem rgba(black, 0.2);

            &::after {
                transform: scaleX(1.4) scaleY(1.6);
                opacity: 0;
            }
        }
    }

    .space-evenly {
        justify-content: space-evenly;
    }

    .align-center {
        align-items: center;
    }

    .double-height {
        height: 2.5rem;
    }

    .green {
        color: #49c774;
    }

    .red {
        color: #f14768;
    }

    .yellow {
        color: #af983a;
    }

    .blue {
        color: #3298dc;
    }

    .padding-left {
        padding-left: .5rem;
    }

    .margin-left {
        margin-left: 1rem;
    }

    .hover-pointer {
        cursor: pointer;
    }
}
</style>
