<template>
    <div class="feedback-modal cognito">
        <h2 class="date-title">{{currentCohort.es_name}}</h2>

        <!--  show form if its a cohort or if its a topic with no feedback-->
        <div :style="feedbackLoaded && iframeLoaded ? null:'display:none;'">
            <iframe
                :src="`https://services.cognitoforms.com/f/cWWlgBg3P0iegMgpV3diNA?id=${formNumber}`"
                style="position:relative;border-radius:5px;height:286px"
                frameborder="0"
                scrolling="yes"
                seamless="seamless"
                @load="iframeLoaded = true"
            ></iframe>

            <script
                type="application/javascript"
                src="https://services.cognitoforms.com/scripts/embed.js"
            ></script>

            <!-- cohort prefill -->
            <script type="application/javascript">
  Cognito.prefill({
    CohortId: "{{this.currentCohort.es_cohortid}}",
    EnrollmentId: "{{this.currentEnrollment.es_enrollmentid}}"
  });
            </script>

            <!--cognito css-->
            <script type="application/javascript">
  Cognito.setCss(
    ".cognito:not(.c-forms-actionBar) {margin:0px} .cognito * {font-weight:400 !important;} .cognito .c-forms-form .c-forms-form-title h2 {font-size:1.5rem;font-weight:400;} .cognito .c-forms-heading .c-forms-form-title {padding:0px}"
  );
            </script>
            <div
                v-if="feedbackLoaded && currentCohortFeedbacks && currentCohortFeedbacks.length > 0"
                class="submitted-date"
            >
                <p>Feedback last submitted on {{submittedDate}}.</p>
            </div>
        </div>
        <Loader v-if="!iframeLoaded" />
    </div>
</template>

<script>
import EventBus from "../event-bus";
import Loader from "./Loader";
import moment from "moment";
import timezone from "moment-timezone";
import { mapGetters, mapActions } from "vuex";

export default {
    name: "CohortFeedbackModal",
    components: { Loader },
    computed: {
        ...mapGetters([
            "currentTopic",
            "currentEnrollment",
            "currentCohort",
            "CurrentSession",
            "feedbackLoaded",
            "currentSessionFeedbacks",
            "currentCohortFeedbacks",
            "currentTopicFeedbacks",
            "timeZone"
        ]),
        formNumber: function() {
            switch (this.formType) {
                case "topic":
                    return 2;
                    break;
                case "sessions":
                    return 3;
                    break;
                default:
                    //cohort and other
                    return 4;
            }
        },
        submittedDate: function() {
            return moment(this.currentCohortFeedbacks[0].createdon)
                .tz(this.timeZone)
                .format("MMMM Do YYYY, [at] h:mm a z");
        }
    },
    data() {
        return {
            iframeLoaded: false
        };
    },
    mounted() {
        this.getFeedbacksForCohort({
            cohortid: this.currentCohort.es_cohortid,
            enrollmentid: this.currentEnrollment.es_enrollmentid
        });
    },
    beforeDestroy() {
        this.clearCurrentFeedbacks();
    },
    methods: {
        ...mapActions([
            "getFeedbacksForTopic",
            "getFeedbacksForCohort",
            "clearCurrentFeedbacks"
        ])
    }
};
</script>


<style lang='scss'>
#app .cohort-feedback-modal-component {
    #feedback-modal .cognito {
        height: unset;
    }

    #feedback-modal .animation-content {
        background-color: white !important;
        z-index: 5;
        display: block;
        padding: 1rem;
        border-radius: 5px;
        max-width: 350px !important;
        max-height: 90%;
        overflow-y: auto;
        overflow-x: hidden;
        min-width: 344px !important;
        min-height: 200px !important;
    }

    .cognito {
        .margin-bottom-1 {
            margin-bottom: 1rem !important;
        }

        $radio-size: 1rem;

        h2 {
            text-transform: uppercase;
            padding: 1rem 0.5rem !important;
            font-weight: bold !important;
        }

        .align-items-center {
            align-items: center;
        }

        .padding-bottom-1 {
            padding-bottom: 1rem;
        }

        .padding-top-1 {
            padding-top: 1rem;
        }

        .margin-left-half {
            margin-left: 0.5rem;
        }

        .submitted-date {
            padding: 1rem 0rem;
            font-size: 0.8rem;
            font-style: italic;
        }
    }
}
</style>