<template>
    <div class="row empty-row">
        <div class="row-heading empty-row border-right border-bottom"></div>
        <div
            v-for="(monday,index) in mondays"
            :key="'emptyrow'+row+index"
            class="row-column row border-right border-bottom"
        >
            <div class="day-column"></div>
            <div class="day-column"></div>
            <div class="day-column"></div>
            <div class="day-column"></div>
            <div class="day-column"></div>
            <div class="day-column"></div>
            <div class="day-column"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "EmptyRow",
    props: ["mondays", "row"]
};
</script>

<style>
</style>
