<template>
	<div class="assessment-modal">
		<h2>Student: {{ gradebookCurrentAttempts.fullname }}</h2>
		<h2>Module: {{ gradebookCurrentAttempts.topic.module }}</h2>
		<h2>Topic: {{ gradebookCurrentAttempts.topic.name }}</h2>
		<h2>
			Existing Score:
			<span v-if="!assessmentModalLoading">
				{{ gradebookCurrentAttempts.attempt ? Math.floor(gradebookCurrentAttempts.attempt[0].score * 100) + "%" : "None" }}
			</span>
			<InlineLoader v-else />
		</h2>

		<!-- post new  -->
		<div>
			<p>Please choose pass/fail for the assessment:</p>

			<b-field label="Pass/Fail">
				<b-select v-model="score" placeholder="Select pass/fail">
					<option value="pass">Pass</option>
					<option value="fail">Fail</option>
				</b-select>
			</b-field>
			<div class="flex space-between action-buttons">
				<div class="col">
					<b-button @click="$emit('closeModal')" :loading="assessmentModalLoading">Close Modal</b-button>
				</div>

				<div class="col">
					<b-button type="is-info" @click="handleSave" :loading="assessmentModalLoading">
						Save Score
					</b-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import InlineLoader from "./InlineLoader"

export default {
	props: [],
	components: {
		InlineLoader
	},
	data() {
		return {
			data: [],
			score: null,
			loading: false
		}
	},
	computed: {
		...mapGetters(["currentCohort", "gradebookModalMode", "gradebookCurrentAttempts", "assessmentModalLoading"])
	},
	mounted() {
		if (this.gradebookCurrentAttempts.attempt) {
			let score = this.gradebookCurrentAttempts.attempt[0].score
			score === 1 ? (this.score = "pass") : (this.score = "fail")
		}
	},
	created() {},
	watch: {},
	methods: {
		...mapActions(["patchAssessment", "postAssessment", "setAssessmentModalActive", "setAssessmentModalLoading"]),
		handleSave() {
			this.setAssessmentModalLoading(true)

			if (this.gradebookCurrentAttempts.attempt) {
				this.patchAssessment({
					attemptid: this.gradebookCurrentAttempts.attempt[0].id,
					cohortid: this.currentCohort.es_cohortid,
					score: this.score
				})
			} else {
				this.postAssessment({
					cohortid: this.currentCohort.es_cohortid,
					score: this.score,
					enrollmentid: this.gradebookCurrentAttempts.enrollment,
					topicid: this.gradebookCurrentAttempts.topic.topicid
				})
			}
		}
	}
}
</script>

<style lang="scss">
#app .assessment-modal {
	background-color: white !important;
	z-index: 5;
	display: block;
	padding: 2rem;
	border-radius: 5px;
	overflow-x: hidden;
	min-height: 15rem !important;
	height: fit-content;
	width: fit-content;
	margin-left: auto;
	margin-right: auto;
	min-width: 20rem;

	.flex {
		width: 15rem;
		margin-left: auto;
		margin-right: auto;
		align-items: center;
		display: flex;
		padding-top: 2rem;
		justify-content: space-around;
	}

	.close {
		display: flex;
		justify-content: center;
		padding-top: 3rem;
	}

	.space-between {
		width: 100%;
		justify-content: space-between;
	}

	p {
		margin-bottom: 1.5rem;
	}

	.actions-buttons .button {
		width: 8rem;
	}
}
</style>
