<template>
	<div class="overdue-chart-component" v-if="data">
		<div class="sticky-header">
			<div class="block padding-right-1 padding-top-half">
				<b-checkbox v-model="checkboxGroup" type="is-info" native-value="Topic">Lectures</b-checkbox>
				<b-checkbox v-model="checkboxGroup" type="is-info" native-value="Lab">Labs</b-checkbox>
				<b-checkbox v-model="checkboxGroup" type="is-info" native-value="Quiz">Quizzes</b-checkbox>
				<b-checkbox v-model="checkboxGroup" type="is-info" native-value="Assessment">Assessments</b-checkbox>
				<!-- <b-checkbox v-model="checkboxGroup" type="is-info" native-value="Session">Sessions</b-checkbox> -->
			</div>
		</div>
		<b-table :data="isEmpty ? [] : data" sticky-header :row-class="applyClasses" :default-sort="['status', 'asc']" striped>
			<!-- Icon -->
			<b-table-column field="type" centered class="width-3" label="Type" :sortable="true" v-slot="props">
				<vue-fontawesome v-if="props.row.type === 'Lab'" icon="flask" color="black" size="lg" />

				<vue-fontawesome v-else-if="props.row.type === 'Topic'" :icon="['fas', 'file-video']" size="lg" color="black" />

				<vue-fontawesome v-else-if="props.row.type === 'Assessment'" :icon="['fas', 'clipboard-check']" size="lg" color="black" />

				<vue-fontawesome v-else-if="props.row.type === 'Quiz'" :icon="['fas', 'question-circle']" size="lg" color="black" />

				<home-icon v-else-if="props.row.type === 'Session'" :size="20" class="margin-right-half icon-1x" />
			</b-table-column>

			<!-- topic name -->
			<b-table-column field="name" class="width-28" label="Name" :sortable="true" v-slot="props">
				<div class="truncate pointer color-blue bold" @click="handleClick(props.row.topic, props.row.module)">
					{{ props.row.name }}
				</div>
			</b-table-column>

			<!-- status -->
			<b-table-column
				field="status"
				class="color-red padding-left-4 text-center"
				label="Days Overdue"
				:sortable="true"
				:custom-sort="sortStatus"
				v-slot="props"
				>{{ props.row.status + " days" }}
			</b-table-column>

			<!-- empty -->
			<template slot="empty">
				<section class="section">
					<div class="content has-text-grey has-text-centered">
						<p>
							<vue-fontawesome :icon="['far', 'frown']" />
						</p>
						<p>Nothing here.</p>
					</div>
				</section>
			</template>
		</b-table>
	</div>
	<loader v-else />
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import Loader from "./Loader"
import moment from "moment"

export default {
	name: "OverdueChart",
	components: { Loader },
	computed: {
		...mapGetters(["currentCohort", "attempts", "currentEnrollment", "cohortAttendance"])
	},
	data() {
		return {
			data: null,
			checkboxGroup: ["Topic", "Lab", "Quiz", "Assessment"],
			isEmpty: false
		}
	},
	created() {
		// this.getAttendanceForCohort({
		//     cohortid: this.currentCohort.es_cohortid,
		//     enrollmentid: this.currentEnrollment.es_enrollmentid
		// });
	},
	mounted() {
		if (this.attempts) {
			this.buildGradeData()
		}
	},
	watch: {
		attempts: function() {
			this.buildGradeData()
		},
		checkboxGroup: function() {
			if (this.checkboxGroup.length != 0) {
				this.isEmpty = false
			} else {
				this.isEmpty = true
			}
		}
	},
	methods: {
		...mapActions(["getAttendanceForCohort", "updateCurrentModule", "navigateToTopic", "updateCurrentTopic", "changeCurrentPage"]),
		applyClasses(row, index) {
			//check if the topic's type is in the checkbox e.g. Quiz
			let displayed = this.checkboxGroup.includes(row.type)
			if (!displayed) {
				//if it isn't, hide the row
				return "hidden-row"
			}
			return "visible-row"
		},
		handleClick(topic, module) {
			this.updateCurrentModule(module)
			this.updateCurrentTopic(topic)
			this.navigateToTopic(topic)
		},
		sortStatus(a, b, c) {
			//custom sort function for b-table
			if (!c) {
				return b.status - a.status
			} else {
				return a.status - b.status
			}
		},
		buildGradeData() {
			//filter topics from currentCohort
			let arrayOfTopics = this.buildArrayOfCurrentTopics()

			//adds number of days due (positive for overdue, negative for not due)
			arrayOfTopics = this.appendDueStatus(arrayOfTopics)

			//filter topics that aren't due
			arrayOfTopics = this.filterUndueTopics(arrayOfTopics)

			//filter attempts for submitted attempts
			let submittedAttempts = this.filterSubmittedAttempts()

			//append attempts to each matching element
			arrayOfTopics = this.appendAttempts(arrayOfTopics, submittedAttempts)

			//filter out topics with attempts
			arrayOfTopics = this.filterSubmittedTopics(arrayOfTopics)

			//left with topics due/end date before today with no submitted attempt

			this.data = arrayOfTopics
		},
		buildArrayOfCurrentTopics() {
			let arr = []
			this.currentCohort.modules.forEach(module => {
				module.topics.forEach(topic => {
					arr.push({
						topicid: topic.es_topicid,
						name: topic.es_name || topic.es_displayname,
						status: null,
						duedate: topic.es_duedate,
						enddate: topic.es_enddate,
						type: this.makeTypeReadable(topic.es_type),
						moduleid: topic._es_module_value,
						topic: topic,
						module: module
					})
				})
			})
			return arr
		},
		filterSubmittedAttempts() {
			return this.attempts.filter(attempt => {
				return attempt.es_issubmitted
			})
		},
		appendDueStatus(arrayOfTopics) {
			return arrayOfTopics.map(topic => {
				if (!topic.duedate && topic.enddate) {
					//no due date, use end date as end date
					let today = new Date()
					let daysBeforeDue = null
					var a = moment().startOf("day")
					var b = moment(topic.enddate).startOf("day")
					var diff = b.diff(a, "days") // =1
					// return diff;
				} else {
					let today = new Date()
					let daysBeforeDue = null
					var a = moment().startOf("day")
					var b = moment(topic.duedate).startOf("day")
					var diff = b.diff(a, "days") // =1
					// return diff;
				}

				topic.status = diff
				return topic
			})
		},
		filterUndueTopics(arrayOfTopics) {
			return arrayOfTopics.filter(topic => {
				return topic.status < 0
			})
		},
		appendAttempts(arrayOfTopics, submittedAttempts) {
			arrayOfTopics = arrayOfTopics.map(topic => {
				let topicAttempts = submittedAttempts.filter(attempt => topic.topicid === attempt._es_topic_value)

				//append attempts to the topic if there are any for the topic
				//return the new topic
				if (topicAttempts.length > 0) {
					//filter for the attempt with the highest score
					topicAttempts = this.returnHighestScore(topicAttempts)
					topic.attempts = topicAttempts
					topic.status = this.returnChartStatus(topicAttempts, topic.type)
				} else {
					//set the topic to null since there are no attempts i.e. it hasn't been submitted
					topic.attempts = null
				}

				return topic
			})
			return arrayOfTopics
		},
		filterSubmittedTopics(arrayOfTopics) {
			//return topics that have attempt data
			return arrayOfTopics.filter(topic => {
				return topic.attempts == null
			})
		},
		returnChartStatus(topicAttempts, type) {
			//sets the status for topics
			switch (type) {
				case "Topic":
				case "Lab":
					return "Submitted"
					break
				case "Quiz":
				case "Assessment":
					return topicAttempts.es_score >= 0.7 ? "On Track" : "Needs Improvement"
					break
				default:
					return null //no attendance
			}
		},
		appendAttendances(arrayOfTopics) {
			this.cohortAttendance.forEach(attendance => {
				//set attendance status to human readable e.g. 71580001 => "Excused Absence"
				let status = this.makeStatusReadable(attendance.es_status)

				//apend attendance to arrayOfTopics
				if (status) {
					arrayOfTopics.push({
						topicid: null,
						status: status,
						duedate: null,
						type: "Session",
						moduleid: null,
						session: attendance.es_session,
						name: attendance.es_session.es_name
					})
				}
			})

			return arrayOfTopics
		},
		returnHighestScore(topicAttempts) {
			return topicAttempts.reduce((prev, current) => {
				return prev.es_score > current.es_score ? prev : current
			})
		},
		makeStatusReadable(status) {
			switch (status) {
				case 717580000:
					return "Attended"
					break
				case 717580002:
					return "Excused Absence"
					break
				case 717580003:
					return "Late"
					break
				case 717580001:
					return "Absent"
					break
				default:
					return null //no attendance
			}
		},
		makeTypeReadable(type) {
			switch (type) {
				case 717580000:
					return "Topic"
					break
				case 717580001:
					return "Lab"
					break
				case 717580003:
					return "Assessment"
					break
				case 717580002:
					return "Quiz"
					break
				default:
					return null //no type
			}
		}
	}
}
</script>

<style lang="scss">
#app .overdue-chart-component {
	.sticky-header {
		position: sticky;
		top: 41px;
		background-color: white;
		z-index: 10;
		height: 3rem;
		display: flex;
		align-items: center;
		border-bottom: 2px solid #d4d2d2;
		justify-content: flex-end;
	}

	.sticky-header p {
		padding-left: 2rem;
		font-size: 1.1rem;
	}

	.b-table .table {
		border-top: 0px;
	}

	.hidden-row {
		display: none;
	}

	.truncate {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.padding-right-1 {
		padding-right: 2.5rem;
	}

	.b-table .table-wrapper.has-sticky-header {
		height: 23rem;
	}
	.padding-top-half {
		padding-top: 0.5rem;
	}

	label {
		padding-right: 0.5rem;
	}

	.width-28 {
		width: 28rem;
		max-width: 28rem;
		padding-right: 1rem;
	}

	.width-2 {
		width: 2.5rem;
	}

	.width-3 {
		width: 3rem;
		max-width: 3rem;
		min-width: 3rem;
	}

	.padding-right-1 {
		padding-right: 3rem;
	}

	.b-table .table {
		border-collapse: separate;
		border-bottom: solid 1px #dbdbdb;
	}

	.color-red {
		color: red;
	}

	.padding-left-4 {
		padding-left: 4rem;
	}

	.pointer {
		cursor: pointer;
	}

	.color-blue {
		color: #1472b8;
	}

	.color-blue:hover {
		color: #2494d1;
	}

	.bold {
		font-weight: bold;
	}

	.th-wrap .icon svg {
		width: 1rem;
		height: 1rem;
	}

	.text-center {
		text-align: center;
	}

	th:last-of-type .th-wrap {
		justify-content: center;
	}

	th {
		position: sticky;
		top: 89px;
		z-index: 5;
		background-color: white;
	}
}
</style>
