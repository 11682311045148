import request from './request';

export const getInstructors = (data) =>
    request({
        url: `instructors?cohortid=${data}`,
        method: 'get',
    })


export const getInstructorImage = (data) =>
    request({
        url: `instructorimage?cohortid=${data.cohortid}&contactid=${data.contactid}`,
        method: 'get',
        headers: { "X-Requested-With": "XMLHttpRequest" }
    })