import axios from 'axios';
import router from '../../router/router.js';

const state = {
    hackerObjectives: null,
    hackerObjectiveProgress: null
};

const getters = {
    hackerObjectives: (state) => state.hackerObjectives,
    hackerObjectiveProgress: (state) => state.hackerObjectiveProgress
};

const actions = {
    getObjectives({ commit, rootState }) {
        let url = rootState.auth.baseUrl

        //use contactid to get hackerobjectives, objectives
        axios.get(url + "api/hackerobjectives?contactid=" + rootState.auth.contact.contactid).then(
            response => {
                let myHackerObjectives = response.data.value
                commit('setHackerObjectives', myHackerObjectives)
                let myHackerObjectiveProgress = response.data.hackerobjectiveprogresses.value
                myHackerObjectiveProgress = myHackerObjectiveProgress.sort((a, b) => a.es_objectivetopic_sort - b.es_objectivetopic_sort);
                commit('setHackerObjectiveProgress', myHackerObjectiveProgress)
            }
        ).catch(err => {
            console.log("api/hackerobjectives error", err)
            commit('setAuthentication', false)
            commit('setContact', null)
            commit('setErrorMessage', "Session expired. Please login again.")
            router.push({ path: '/error' })
        })
    }
};

const mutations = {
    setHackerObjectives: (state, hackerObjectives) => (state.hackerObjectives = hackerObjectives),
    setHackerObjectiveProgress: (state, progress) => (state.hackerObjectiveProgress = progress)
};

export default {
    state,
    getters,
    actions,
    mutations
}

