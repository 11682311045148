<template>
	<div class="final-lab-modal">
		<h2>Student: {{ gradebookCurrentAttempts.fullname }}</h2>
		<h2>Module: {{ gradebookCurrentAttempts.topic.module }}</h2>
		<h2>Topic: {{ gradebookCurrentAttempts.topic.name }}</h2>
		<h2 class="mb-5">
			Highest Score:
			<span v-if="!finalLabModalLoading">
				{{
					gradebookCurrentAttempts.attempt && gradebookCurrentAttempts.attempt.length > 0
						? Math.floor(gradebookCurrentAttempts.attempt[0].score * 100) + "%"
						: "None"
				}}
			</span>
			<InlineLoader v-else />
		</h2>

		Here are the student's attempts:

		<span v-if="gradebookCurrentAttempts.attempt.length > 0">
			<span v-if="!finalLabModalLoading && localAttemptsData">
				<div
					v-for="attempt in localAttemptsData"
					:value="attempt.id"
					:key="attempt.id"
					style="display:flex;align-items:center;margin-left:1rem;margin-right:1rem;margin-top:1rem;justify-content:space-between;"
				>
					<div style="margin-right:1rem;">{{ relativeTime(attempt.timeend) }}</div>
					<b-button v-if="attempt.hasAttachments" type="is-primary" @click="downloadAttempt(attempt)">Download</b-button>
					<span v-else>No data</span>
				</div>

				<!-- <b-field label="Grade">
					<b-numberinput v-model="number"></b-numberinput>
				</b-field> -->

				<!-- <b-select placeholder="Select a status">
					<option v-for="option in statusOptions" :value="selectedStatus" :key="option">
						{{ option }}
					</option>
				</b-select> -->

				<!-- <b-button>Save</b-button> -->
			</span>

			<Loader v-else />
		</span>

		<div v-else class="flex-center mt-lg">
			<p>No attempts for this topic</p>
		</div>

		<div class="close">
			<b-button @click="$emit('closeModal')" :loading="finalLabModalLoading">Close Modal</b-button>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import InlineLoader from "./InlineLoader"
import Loader from "./Loader"
import moment from "moment"

export default {
	props: [],
	components: { InlineLoader, Loader },
	data() {
		return {
			localAttemptsData: null
			// statusOptions: ["Pending", "Graded"],
			// selectedStatus: "Pending"
		}
	},
	computed: {
		...mapGetters(["currentCohort", "gradebookCurrentAttempts", "finalLabModalLoading", "currentAttemptsAttachmentsData"])
	},
	created() {},
	watch: {
		finalLabModalLoading: {
			deep: true,
			handler() {
				this.makeTableData()
			}
		}
	},
	methods: {
		...mapActions(["resetQuiz", "setGradebookModalActive", "setfinalLabModalLoading", "getAttemptFiles"]),
		load(attempt) {
			this.setfinalLabModalLoading(true)
		},
		relativeTime(time) {
			return moment(time).fromNow()
		},
		downloadAttempt(attempt) {
			if (attempt) {
				this.getAttemptFiles({
					attemptid: attempt.id,
					topicname: this.gradebookCurrentAttempts.topic.name,
					fullname: this.gradebookCurrentAttempts.fullname
				})
			}
		},
		makeTableData() {
			let data = this.currentAttemptsAttachmentsData
			let attemptsData = []
			this.gradebookCurrentAttempts.attempt.forEach(attempt => {
				if (data.find(item => item["es_selection1.es_attempt"] == attempt.id)) {
					let copy = JSON.parse(JSON.stringify(attempt))
					copy.hasAttachments = true
					attemptsData.push(copy)
				} else {
					let copy = JSON.parse(JSON.stringify(attempt))
					copy.hasAttachments = false
					attemptsData.push(copy)
				}
			})

			this.localAttemptsData = attemptsData
		}
	}
}
</script>

<style lang="scss">
#app .final-lab-modal {
	background-color: white !important;
	z-index: 5;
	display: block;
	padding: 2rem;
	border-radius: 5px;
	overflow-x: hidden;
	min-height: 15rem !important;
	height: fit-content;
	width: fit-content;
	margin-left: auto;
	margin-right: auto;

	.flex {
		width: 15rem;
		margin-left: auto;
		margin-right: auto;
		align-items: center;
		display: flex;
		padding-top: 2rem;
		justify-content: space-around;
	}

	.col {
		width: 5rem;
	}

	.close {
		display: flex;
		justify-content: center;
		padding-top: 3rem;
	}

	.flex-center {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.mt-lg {
		margin-top: 2rem;
	}

	.mb-md {
		margin-bottom: 1rem;
	}
}
</style>
