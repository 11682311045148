<template>
    <div class="quiz-selections-component" v-if="currentCorrectChoices">
        <selection-question
            v-for="selection in selections"
            :key="selection.es_selectionid"
            :choice="selection"
            :correctChoices="filterChoices(selection)"
        />
    </div>
    <Loader v-else />
</template>

<script>
import SelectionQuestion from "./SelectionQuestion";
import Loader from "./Loader";
import { mapGetters, mapActions } from "vuex";

export default {
    name: "QuizSelections",
    props: ["selections"],
    components: { SelectionQuestion, Loader },
    data() {
        return {
            wrongQuestionIds: null
        };
    },
    computed: {
        ...mapGetters(["currentCorrectChoices"])
    },
    mounted() {
        this.getWrongQuestionIds();
    },
    beforeDestroy() {
        this.clearCorrectChoices();
    },
    watch: {
        wrongQuestionIds: function() {
            this.getCorrectChoices({ ids: this.wrongQuestionIds });
        }
    },
    methods: {
        ...mapActions(["getCorrectChoices", "clearCorrectChoices"]),
        getWrongQuestionIds() {
            let wrongQuestions = this.selections.filter(selection => {
                return (
                    !selection.es_choice_iscorrect &&
                    [
                        "Multiple Choice",
                        "Fill in the blank",
                        "Multiple Select"
                    ].includes(selection.es_question_type)
                );
            });

            this.wrongQuestionIds = wrongQuestions.map(selection => {
                return selection.es_question_id;
            });
        },
        filterChoices(selection) {
            // if in the correctchoices array
            let answersForQuestion = this.currentCorrectChoices.filter(item => {
                return item.es_topicquestionid == selection.es_question_id;
            });
            if (answersForQuestion.length > 0) {
                // return all for this questionid
                return answersForQuestion;
            }
            return;
        }
    }
};
</script>

<style lang="scss">
#app .quiz-selections-component {
}
</style>
