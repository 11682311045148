<template>
	<div class="student-attendance">
		<div class="flex es-container ">
			<div class="back-div" @click="handleGoBack()">
				<p class="link is-info is-light ">
					Go back
				</p>
				<h4 class="back-page-title">
					Grades Page
				</h4>
			</div>

			<h2 class="text-center">Attendance Page</h2>

			<b-button
				@click="handlePrintClick"
				:disabled="downloadingAttendancePdf"
				:loading="downloadingAttendancePdf"
				icon-left="print"
				icon-pack="fa"
			>
				Print
			</b-button>
		</div>
		<div id="attendanceTable" v-if="data">
			<b-table v-if="data" :data="data" :columns="columns" />
		</div>
		<Loader v-else />
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import Loader from "./Loader"
import moment from "moment"
import router from "../router/router.js"

export default {
	name: "StudentAttendance",
	components: { Loader },
	computed: {
		...mapGetters(["contact", "currentCohort", "currentSessions", "currentEnrollment", "cohortAttendance", "downloadingAttendancePdf"]),
		reportGenerated() {
			return `${moment(new Date())
				.startOf("day")
				.format("MMM D, YYYY")}`
		}
	},
	data() {
		return {
			data: null,
			attendanceDates: null,
			chartGenerated: false,
			columns: [
				{
					field: "sessionName",
					label: "Session Name"
				},
				{
					field: "sessionDate",
					label: "Session Date"
				},
				{
					field: "attendance",
					label: "Attendance"
				}
			],
			statusDict: {
				717580000: "Attended",
				717580001: "Absent",
				717580002: "Excused",
				717580003: "Late"
			}
		}
	},
	mounted() {
		this.getAttendanceForCohort({
			cohortid: this.currentCohort.es_cohortid,
			enrollmentid: this.currentEnrollment.es_enrollmentid
		})
		this.getSessions()
		if (this.currentSessions && this.cohortAttendance) {
			this.makeChartData()
		}
	},
	watch: {
		currentSessions: function() {
			if (!this.chartGenerated && this.cohortAttendance) {
				this.makeChartData()
			}
		},
		cohortAttendance: function() {
			if (!this.chartGenerated && this.currentSessions) {
				this.makeChartData()
			}
		}
	},
	methods: {
		...mapActions(["getAttendanceForCohort", "getSessions", "printAttendancePdf"]),
		formatDate(date) {
			if (!date) {
				return null
			}
			let formattedDate = moment(date)
				.startOf("day")
				.format("dddd, MMM D, YYYY")
			if (formattedDate) {
				return formattedDate
			}
			return null
		},
		makeChartData() {
			let items = []
			let today = new Date()

			for (let i = 0; i < this.currentSessions.length; i++) {
				let session = this.currentSessions[i]
				if (moment(session.es_enddatetime) >= moment(today)) {
					break
				} else {
					// add each session as row up to current date
					let currentItem = {
						sessionName: session.es_name,
						sessionDate: this.formatDate(session.es_startdatetime)
					}
					currentItem.attendance = this.findAttendance(session)
					items.push(currentItem)
				}
			}
			this.chartGenerated = true
			this.data = items
		},
		findAttendance(session) {
			let result = this.cohortAttendance.find(att => {
				return att.es_session.es_sessionid === session.es_sessionid
			})

			if (result && result.es_status) {
				return this.statusDict[result.es_status]
			} else {
				return "No Record"
			}
		},
		handlePrintClick() {
			this.printAttendancePdf({
				es_cohortid: this.currentCohort.es_cohortid,
				es_enrollmentid: this.currentEnrollment.es_enrollmentid,
				email: this.contact.emailaddress1,
				cohortName: this.currentCohort.es_name,
				htmlString: this.makeHtmlString(),
				contactFullName: this.contact.fullname,
				date: `Report generated ${moment(new Date())
					.startOf("day")
					.format("MMM D, YYYY")}`,
				heading: `<div>Student Name: ${this.contact.fullname} <br />Report Date: ${this.reportGenerated}</div>`
			})
		},
		makeHtmlString() {
			return document.getElementById("attendanceTable").outerHTML
		},
		handleGoBack() {
			router.push({
				path: `/grades/${this.currentCohort.es_cohortid}`
			})
		}
	}
}
</script>

<style scoped lang="scss">
#app .student-attendance {
	#attendanceTable {
		padding: 1rem;
	}

	.text-center {
		text-align: center;
		font-size: 2rem;
		align-self: center;
	}

	.mb-md {
		margin-bottom: 1rem;
	}

	.flex {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	.container {
		background-color: #eaf5ff;
		padding: 1rem;
	}

	.link {
		display: block !important;
		font-size: 0.75rem;
		font-weight: 800;
		color: #1262d8 !important;
	}

	.back-page-title {
		font-size: 0.7rem !important;
		color: gray !important;
		font-weight: bold !important;
		margin-top: 4px !important;
		font-family: "Roboto Condensed", sans-serif;
	}

	.back-div {
		cursor: pointer;
		padding: 0.5rem;
	}
}
</style>
