import request from "./request"

export const getAttendance = data =>
  request({
    url: `attendance?enrollmentid=${data.enrollmentid}&sessionid=${data.sessionid}`,
    method: "get"
  })

export const getCohortAttendance = data =>
  request({
    url: `cohortattendance?cohortid=${data.cohortid}&enrollmentid=${data.enrollmentid}`,
    method: "get"
  })
