<template>
	<div class="final-lab-button-component">
		<span class="flex align-center">
			<b-button type="is-info" :loading="attemptSaving" :disabled="attemptSaving" @click="handleSubmitQuiz()">Submit</b-button>
			<div class="flex-col-center">
				<span class="small" v-if="submitted.status">{{ `Last attempted on ${submitted.date}` }}</span>
			</div>
		</span>
		<div class="end-white-space"></div>

		<!-- a popup modal unique to quizzes -->
		<b-modal :active.sync="skipGuard" has-modal-card trap-focus aria-role="dialog" aria-modal>
			<div class="card">
				<div class="card-content">
					<p>
						You haven't answered all the questions, are you sure you want to submit? You will not be able to return to this
						attempt later.
					</p>
				</div>
				<div class="card-buttons">
					<b-button type="is-danger" @click="skipQuestions()">Yes</b-button>
					<b-button type="is-info" @click="stayOnQuiz()">No</b-button>
				</div>
			</div>
		</b-modal>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import EventBus from "../event-bus"
import moment from "moment"
import timezone from "moment-timezone"

export default {
	name: "FinalLabButton",
	components: {},
	computed: {
		...mapGetters([
			"currentTopic",
			"attempts",
			"currentAttempt",
			"attemptSaving",
			"rootTimer",
			"timeZone",
			"currentTopic",
			"currentEnrollment",
			"currentCohort",
			"studentSelectionsArr",
			"studentQuizSelections",
			"isAnswered"
		])
	},
	data() {
		return {
			studentSelections: [],
			currentSelection: null,
			submitted: { status: false },
			skipGuard: false,
			emptyPosted: false
		}
	},
	mounted() {
		this.checkSubmittedStatus()

		//fired from course when component is destryoed before finish
		EventBus.$on("submit-quiz", data => {
			this.handleSubmitQuiz(true)
		})
	},
	watch: {
		attempts: function() {
			this.checkSubmittedStatus()
		},
		rootTimer: function() {
			//submit attempt if timer runs out
			if (this.rootTimer <= 0) {
				this.handleSubmitQuiz(true)
			}
		},
		studentQuizSelections: function() {
			if (this.studentQuizSelections.length == this.currentTopic.es_totalquizquestions && this.currentAttempt) {
				this.postEmptyQuiz()
			}
		},
		currentAttempt: function() {
			if (this.studentQuizSelections.length == this.currentTopic.es_totalquizquestions && this.currentAttempt) {
				this.postEmptyQuiz()
			}
		}
	},
	beforeDestroy() {
		// EventBus.$off("answered-quiz-question");
	},
	methods: {
		...mapActions([
			"submitQuiz",
			"setAttemptSaving",
			"updateAttemptSelection",
			"setStudentSelectionsArr",
			"clearCurrentSelections",
			"clearStudentQuizSelections",
			"updateTakingQuiz",
			"updateCurrentQuestionIndex",
			"updateCurrentQuestion",
			"updateQuizFinished",
			"submitSelections",
			"stopTimer"
		]),
		checkSubmittedStatus() {
			//checks if this topic was submitted before
			let contactAttempts = this.attempts
			let completedAttempts = contactAttempts.filter(attempt => {
				return attempt._es_topic_value === this.currentTopic.es_topicid && attempt.es_issubmitted
			})
			this.setSubmitted(completedAttempts)
		},
		setSubmitted(completedAttempts) {
			//sets the status and date of a previously submitted topic
			if (completedAttempts.length > 0) {
				let completedDate = moment(completedAttempts[0].es_timeend)
					.tz(this.timeZone)
					.format("MMMM Do YYYY, [at] h:mm a z")
				this.submitted = {
					status: true,
					date: completedDate
				}
			}
		},
		postSelection(data, attemptId) {
			this.updateAttemptSelection({
				data: data,
				es_attemptid: attemptId
			})
		},
		//submits quizes when called
		handleSubmitQuiz() {
			if (this.hasEmptyQuestions() && !this.skipGuard) {
				this.skipGuard = true
				return
			} else {
				this.skipGuard = false
			}

			this.updateTakingQuiz(false)
			this.updateCurrentQuestionIndex(0)
			this.updateCurrentQuestion(null)
			this.updateQuizFinished(false)

			//set attempt saving to true
			this.setAttemptSaving()

			//calls up feedback modal
			EventBus.$emit("topic-submitted")

			//clear and reset timer
			this.stopTimer()

			//append the attempt
			this.submitQuiz({
				es_attemptid: this.currentAttempt,
				es_enrollmentid: this.currentEnrollment.es_enrollmentid,
				es_cohortid: this.currentCohort.es_cohortid,
				es_topicid: this.currentTopic.es_topicid,
				es_issubmitted: true,
				questions: this.studentQuizSelections,
				es_timeend: moment()
			})

			//clears studentSelections
			// this.studentSelections = [];
			this.clearStudentQuizSelections()
		},
		hasEmptyQuestions() {
			//check this.studentQuizSelections for empty choices

			let hasEmpty = false

			if (this.studentQuizSelections) {
				this.studentQuizSelections.forEach(selection => {
					if (
						selection.choices &&
						selection.choices[0] &&
						selection.choices[0].es_text &&
						selection.choices[0].es_text != "Invalid file type"
					) {
					} else {
						hasEmpty = true
					}
				})
			}
			return hasEmpty
		},
		skipQuestions() {
			this.handleSubmitQuiz()
		},
		stayOnQuiz() {
			this.skipGuard = false
		},
		postEmptyQuiz() {
			if (!this.emptyPosted) {
				this.emptyPosted = true

				this.submitSelections({
					es_attemptid: this.currentAttempt,
					es_enrollmentid: this.currentEnrollment.es_enrollmentid,
					es_cohortid: this.currentCohort.es_cohortid,
					es_topicid: this.currentTopic.es_topicid,
					es_issubmitted: false,
					questions: this.studentQuizSelections,
					es_timestart: moment()
				})
			}
		}
	}
}
</script>

<style lang="scss">
#app .final-lab-button-component {
	margin: 2rem 4rem;

	.end-white-space {
		height: 5rem;
	}

	.flex {
		display: flex;
	}

	.align-center {
		align-items: center;
	}

	.small {
		font-size: 0.8rem;
	}

	.flex-col-center {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.button {
		margin-right: 1rem;
	}

	.card {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		height: 15rem;
		width: 25rem;
		border-radius: 10px;
	}

	.card-content {
		margin-bottom: 2rem;
	}

	.card-buttons {
		width: 15rem;
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		align-items: center;

		.button {
			width: 4rem;
		}
	}
}
</style>
