import axios from 'axios';
import router from '../../router/router.js';
import { getInstructors, getInstructorImage } from '../../api/instructor.js';

const state = {
    instructors: null,
    instructorImages: null
};

const getters = {
    allInstructors: (state) => state.instructors,
    instructorImages: (state) => state.instructorImages
};

const actions = {
    getInstructors({ commit, rootState }) {
        let cohortsIdsQuery = rootState.cohorts.cohortsIdsQuery

        getInstructors(cohortsIdsQuery).then(response => {
            var instructors = response.data.value;
            commit('setInstructors', instructors)
        }).catch(err => {
            console.log("api/instructors error", err)
            commit('setAuthentication', false)
            commit('setContact', null)
            commit('setErrorMessage', "Session expired. Please login again.")
            router.push({ path: '/error' })
        })
    },
    getInstructorImage({ commit, rootState }, data) {

        getInstructorImage(data).then(response => {
            let image = response.data.value

            let localInstructors = rootState.instructors.instructors;

            if (image[0]) {
                localInstructors.forEach((instructor, index) => {
                    if (image[0].objectid_contact.contactid == instructor.es_contact.contactid) {
                        let newInstructor = instructor;
                        newInstructor["image"] = image[0]
                        localInstructors.splice(index, 1, newInstructor);
                    }
                })
                commit('setInstructors', localInstructors)
            } else {
                //set hackerimage so frontend knows it's loaded and there's no image
                localInstructors.forEach((instructor, index) => {
                    if (data.contactid == instructor.es_contact.contactid) {
                        let newInstructor = instructor;
                        newInstructor["image"] = "none"
                        localInstructors.splice(index, 1, newInstructor);
                    }
                })
                commit('setInstructors', localInstructors)
            }

        }).catch(err => {
            console.log("api/instructorimage error", err)
            commit('setAuthentication', false)
            commit('setContact', null)
            commit('setErrorMessage', "Session expired. Please login again.")
            router.push({ path: '/error' })
        })
    },
};

const mutations = {
    setInstructors: (state, instructors) => (state.instructors = instructors),
    setInstructorImages: (state, images) => (state.instructorImages = images),
};

export default {
    state,
    getters,
    actions,
    mutations
}

