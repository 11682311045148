
<template>
    <span class="zoom-dropdown-component">
        <div v-if="currentCohort" class="social-dropdown zoom-dropdown">
            <b-dropdown aria-role="list" position="is-bottom-left">
                <div class="zoom-button" slot="trigger" role="button">
                    <img :src="zoomLogo" alt="zoom logo" class="zoom-logo" />
                    Zoom
                    <vue-fontawesome icon="chevron-down" />
                </div>

                <span v-if="currentCohort" class="zoom-dropdown">
                    <b-dropdown-item
                        aria-role="listitem"
                        v-if="currentCohort && currentCohort.es_mainzoomurl"
                    >
                        <a
                            :href="currentCohort.es_mainzoomurl"
                            target="_blank"
                            class="flex align-center"
                        >
                            <home-icon :size="16" class="margin-right-half icon-1x" />
                            <p>Main Room</p>
                        </a>
                    </b-dropdown-item>
                    <b-dropdown-item aria-role="listitem" class="disable-link" v-else>
                        <a class="flex align-center disable-link">
                            <phone-cancel-icon :size="16" class="margin-right-half icon-1x" />
                            <p>No Main Room</p>
                        </a>
                    </b-dropdown-item>

                    <span v-if="allCohortGroups && currentCohortGroup">
                        <span v-for="group in allCohortGroups" :key="group.es_cohortgroupid">
                            <b-dropdown-item
                                v-if="currentCohortGroup.es_zoomurl == group.es_zoomurl"
                                aria-role="listitem"
                            >
                                <a
                                    v-if="currentCohortGroup.es_zoomurl == group.es_zoomurl"
                                    :href="group.es_zoomurl"
                                    target="_blank"
                                    class="flex align-center my-group"
                                >
                                    <meeting-room-icon
                                        :size="16"
                                        class="margin-right-half icon-1x"
                                    />
                                    <p>{{group.es_name}}*</p>
                                </a>
                            </b-dropdown-item>

                            <b-dropdown-item
                                v-else-if="group._es_cohort_value == currentCohort.es_cohortid"
                                aria-role="listitem"
                            >
                                <a
                                    :href="group.es_zoomurl"
                                    target="_blank"
                                    class="flex align-center"
                                >
                                    <meeting-room-icon
                                        :size="16"
                                        class="margin-right-half icon-1x"
                                    />
                                    <p>{{group.es_name}}</p>
                                </a>
                            </b-dropdown-item>
                        </span>
                    </span>
                    <!-- <b-dropdown-item aria-role="listitem"> -->
                    <div class="assigned-legend">* assigned</div>
                    <!-- </b-dropdown-item> -->
                </span>
            </b-dropdown>
        </div>
    </span>
</template>

<script>
import zoomLogo from "../assets/zoom-icon.png";
import presentation from "../assets/presentation.png";
import thumbnails from "../assets/thumbnails.png";
import userParents from "../assets/user-parents.png";
import { mapGetters, mapActions } from "vuex";

export default {
    name: "ZoomDropdown",
    computed: mapGetters([
        "currentCohort",
        "allMyCohortGroups",
        "allCohortGroups"
    ]),
    data() {
        return {
            zoomLogo: zoomLogo,
            presentation: presentation,
            thumbnails: thumbnails,
            userParents: userParents,
            isActive: false,
            currentCohortGroup: null
        };
    },
    mounted() {
        if (this.allMyCohortGroups) {
            this.filterMyCohortGroups();
        }
    },
    watch: {
        allMyCohortGroups: function() {
            this.filterMyCohortGroups();
        },
        currentCohort: function() {
            this.filterMyCohortGroups();
        }
    },
    methods: {
        filterMyCohortGroups() {
            this.currentCohortGroup = this.allMyCohortGroups.find(group => {
                return group._es_cohort_value == this.currentCohort.es_cohortid;
            });

            if (!this.currentCohortGroup) {
                //student has no group
                this.currentCohortGroup = { es_zoomurl: null };
            }
        }
    }
};
</script>

<style lang="scss">
#app .zoom-dropdown-component {
    a {
        color: white;
    }

    a:hover {
        color: white;
    }

    .my-group {
        font-weight: bold;
    }

    .assigned-legend {
        text-align: center;
        font-size: 0.75rem;
        font-style: italic;
        line-height: 0.75rem;
        font-weight: 400;
    }

    .flex {
        display: flex;
    }

    .align-center {
        align-items: center;
    }

    .zoom-button {
        background-color: #009bff;
        width: 10rem;
        border-radius: 0.5rem;
        padding: 0rem 0.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 2rem;
        cursor: pointer;
    }

    .zoom-logo {
        display: inline-block;
        height: 2rem;
        max-width: unset;
        max-height: unset;
        margin-left: -5px;
    }

    .is-active > div > .zoom-button {
        border-radius: 0.5rem 0.5rem 0 0;
    }

    .zoom-dropdown .dropdown-content {
        background-color: #009bff;
    }

    .zoom-dropdown a:hover {
        background-color: #008eea !important;
    }

    .zoom-dropdown .dropdown-menu {
        margin-top: 0px;
        padding-top: 0px;
    }

    .zoom-dropdown .dropdown-content {
        border-radius: 0 0 0.5rem 0.5rem;
        box-shadow: none;
    }

    .zoom-button {
        width: 192px;
    }

    .social-dropdown {
        margin-right: 1rem;
    }

    .align-center {
        align-items: center;
    }
    .margin-right-half {
        margin-right: 0.5rem;
    }
    .disable-link {
        cursor: default;
    }
    .material-design-icon.icon-1x {
        height: 1em;
        width: 1em;
        margin-bottom: 0.2rem;
    }
}
</style>