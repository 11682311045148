import _Vue from "vue";
import {
    HubConnectionBuilder,
    LogLevel
} from "@aspnet/signalr";
import {
    baseCyberLabUrlApi
} from "../config/index";

export default {
    install (Vue) {
        // use a new Vue instance as the interface for Vue components to receive/send SignalR events
        // this way every component can listen to events or send new events using this.$cyberlabHub
        const cyberlabHub = new Vue()
        Vue.prototype.$cyberlabHub = cyberlabHub

        // Provide methods to connect/disconnect from the SignalR hub
        let connection = null
        let startedPromise = null
        let manuallyClosed = false

        Vue.prototype.startSignalR = (jwtToken) => {
            connection = new HubConnectionBuilder()
                .withUrl(`${baseCyberLabUrlApi}cyberlab-hub`, {
                    accessTokenFactory: () => jwtToken
                })
                .configureLogging(LogLevel.Information)
                .build();

            // Forward hub events through the event, so we can listen for them in the Vue components
            connection.on(
                "LmsStudentInstanceStatusUpdated",
                (studentInstance, status) => {
                    cyberlabHub.$emit("student-instance-status-updated", {
                        studentInstance: studentInstance,
                        status: status
                    });
                }
            );

            // You need to call connection.start() to establish the connection but the client wont handle reconnecting for you!
            // Docs recommend listening onclose and handling it there.
            // This is the simplest of the strategies
            function start() {
                startedPromise = connection.start()
                    .catch(err => {
                        console.error('Failed to connect with hub', err)
                        return new Promise((resolve, reject) => setTimeout(() => start().then(resolve).catch(reject), 5000))
                    })
                return startedPromise
            }
            connection.onclose(() => {
                if (!manuallyClosed) start()
            })

            // Start everything
            manuallyClosed = false
            start()
        }

        Vue.prototype.stopSignalR = () => {
            if (!startedPromise) return;

            manuallyClosed = true;
            return startedPromise
                .then(() => connection.stop())
                .then(() => {
                    startedPromise = null;
                });
        };

        // Provide methods for components to send messages back to server
        // Make sure no invocation happens until the connection is established
        function studentInstancesOpened(email) {
            if (!startedPromise) return;

            return startedPromise
                .then(() => connection.invoke("JoinStudentInstanceGroup", email))
                .catch(console.error);
        }

        function studentInstancesClosed(email) {
            if (!startedPromise) return;

            return startedPromise
                .then(() => connection.invoke("LeaveStudentInstanceGroup", email))
                .catch(console.error);
        }
    }
}
