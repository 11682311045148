<template>
	<div v-show="false" style="background-color: white;overflow-x: hidden;">
		<div>
			<!-- logo watermark-->
			<img id="certificate-template" src="../assets/CyberLabCert.png" />
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import EventBus from "../event-bus"
import Loader from "./Loader"

export default {
	name: "PdfRenderer",
	components: {},
	computed: {
		...mapGetters(["currentEnrollment", "contact", "currentCohort"])
	},
	data() {
		return {}
	},
	updated() {},
	mounted() {
		EventBus.$on("print-certificate", data => {
			this.logHtml(data)
		})
	},
	watch: {},
	beforeDestroy() {
		EventBus.$off("print-certificate")
	},
	methods: {
		...mapActions(["getCohortCertificate"]),
		logHtml(cohort) {
			let base64Img = this.convertCertToBase64()

			this.getCohortCertificate({
				es_cohortid: cohort.es_cohortid,
				es_enrollmentid: cohort.es_enrollmentid,
				es_name: cohort.es_name,
				certificateTemplate: `<img src="${base64Img}"/>`,
				es_certificatetitle: cohort.es_certificatetitle,
				es_certificatedescription: cohort.es_certificatedescription
			})
		},
		convertCertToBase64() {
			var c = document.createElement("canvas")
			var img = document.getElementById("certificate-template")
			c.height = img.naturalHeight
			c.width = img.naturalWidth
			var ctx = c.getContext("2d")

			ctx.drawImage(img, 0, 0, c.width, c.height)
			return c.toDataURL()
		}
	}
}
</script>

<style lang="scss">
#app .pdf-renderer-component {
}
</style>
