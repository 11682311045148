import axios from 'axios';
import router from '../../router/router.js';
import moment from 'moment';
import { crateCookie, createCookie } from "../../utils/createCookie";
import { getCookie } from "../../utils/getCookie";
import {
    waitlistInCohort
} from "./../../api/waitlist";
import { SnackbarProgrammatic as Snackbar } from 'buefy'

const state = {
    waitlistingCohort: false,
    waitlistedCohorts: []
};

const getters = {
    waitlistingCohort: (state) => state.waitlistingCohort,
    waitlistedCohorts: (state) => state.waitlistedCohorts
};

const actions = {
    waitlist({ commit }, data) {
        commit('setWaitlistingCohort', data.cohortid)

        waitlistInCohort(data).then(response => {
            commit('setWaitlistingCohort', false)

            if (response.data.message != "cohort is not open enroll") {
                Snackbar.open({
                    duration: 3000,
                    message: `This cohort is full. You were added to the waitlist.`,
                    type: 'is-warning',
                    position: "is-top"
                })
            } else if (response.data.message == "cohort is not open enroll") {
                Snackbar.open({
                    duration: 3000,
                    message: `This cohort is not available for open enrollment.`,
                    type: 'is-warning',
                    position: "is-top"
                })
            }

            //update cookies and store
            var json_str = getCookie('waitlistedCohorts');
            if (json_str) {
                //update cookie
                var waitlistedCohorts = json_str.split(" ");
                if (!waitlistedCohorts.includes(data.cohortid)) {
                    waitlistedCohorts.push(data.cohortid);
                    waitlistedCohorts.join(" ");
                    createCookie('waitlistedCohorts', waitlistedCohorts);
                    commit('setWaitlistedCohorts', waitlistedCohorts);
                }
            } else {
                //make cookie
                var waitlistedCohorts = [data.cohortid];
                waitlistedCohorts.join(" ");
                createCookie('waitlistedCohorts', waitlistedCohorts);
                commit('setWaitlistedCohorts', waitlistedCohorts);
            }
        }).catch(error => {
            commit('setWaitlistingCohort', false)
            console.log("api/waitlist error", error)
            commit('setErrorMessage', "Error auto enrolling in cohort")
            router.push({ path: '/error' })
        })
    },
    getWaitlistedCohorts({ commit }) {
        //update cookies and store
        var json_str = getCookie('waitlistedCohorts');
        if (json_str) {
            var waitlistedCohorts = json_str.split(" ");
            commit('setWaitlistedCohorts', waitlistedCohorts);
        }
    }
}


const mutations = {
    setWaitlistingCohort: (state, status) => (state.waitlistingCohort = status),
    setWaitlistedCohorts: (state, arr) => (state.waitlistedCohorts = arr)

};

export default {
    state,
    getters,
    actions,
    mutations
}

