<template>
	<div class="error-page">
		<div class="white-background rounded-border three-hundo-width flex justify align vertical six-margin-bottom right-padding left-padding">
			<div class="welcome">
				<h1>Error</h1>
			</div>
			<div class="cta">
				<h2>{{ errorMessage ? errorMessage : "No error message." }}</h2>
			</div>
			<div v-if="!errorMessage">
				<div class="two-buttons">
					<a :href="baseUrl">
						<b-button type="is-info">Home</b-button>
					</a>
					<a :href="baseUrl + 'api/signout'">
						<b-button type="is-info">Logout</b-button>
					</a>
				</div>
			</div>
			<div v-else-if="contact || incompleteEnrollment" class="login-button">
				<div class="two-buttons">
					<a :href="baseUrl">
						<b-button type="is-info">Home</b-button>
					</a>
					<a :href="baseUrl + 'api/signout'">
						<b-button type="is-info">Logout</b-button>
					</a>
				</div>
			</div>
			<div v-else class="login-button">
				<div class="two-buttons">
					<a :href="baseUrl">
						<b-button type="is-info">Home</b-button>
					</a>
					<a :href="baseUrl + 'api/signin'">
						<b-button type="is-info">Login</b-button>
					</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"

export default {
	name: "ErrorPage",
	computed: mapGetters(["errorMessage", "baseUrl", "contact", "incompleteEnrollment"]),
	mounted() {
		this.changeCurrentPage("Error")
	},
	methods: {
		...mapActions(["changeCurrentPage"])
	}
}
</script>

<style lang="scss">
#app .error-page {
	height: 100%;
	min-height: 396px;
	// background-color: #26a0da;
	// background-image: linear-gradient(to bottom right, #2aa8df, #1479bf);
	background-color: #0f172a;
	display: flex;
	align-items: center;
	justify-content: center;

	.two-buttons {
		display: flex;
		flex-direction: row;
		margin: auto;
		margin-bottom: 2rem;
		width: 15rem;
		align-items: center;
		justify-content: center;
	}

	.two-buttons button {
		margin: 1rem;
	}
	.white-background {
		background-color: white;
	}

	.right-padding {
		padding-right: 2rem;
	}
	.left-padding {
		padding-left: 2rem;
	}

	h1 {
		font-size: 2rem !important;
		margin: 2rem 0 !important;
		line-height: 3rem !important;
		text-align: center;
	}

	h2 {
		line-height: 1.5rem !important;
		font-size: 1rem !important;
		margin-bottom: 2rem !important;
		text-align: center;
	}

	.rounded-border {
		border-radius: 5px;
	}

	.three-hundo-width {
		max-width: 345px;
	}

	.flex {
		display: flex;
	}

	.justify {
		justify-content: flex-start;
	}

	.align {
		align-items: center;
	}

	.vertical {
		flex-direction: column;
	}

	.login-button {
		margin-bottom: 2rem;
	}

	@media only screen and (min-height: 700px) {
		.six-margin-bottom {
			margin-bottom: 6rem;
		}
	}
}
</style>
