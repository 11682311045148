// import axios from 'axios';
// import router from '../../router/router.js';
import moment from "moment"

const state = {
	rootTimer: 0,
	intervalVar: null,
	attemptStartTime: null,
	attemptTimeEnd: null
}

const getters = {
	rootTimer: state => state.rootTimer
}

const actions = {
	incrementRootTimer({ commit }) {
		commit("incrementTimer", 1)
	},
	decrementRootTimer({ commit }) {
		commit("incrementTimer", -1)
	},
	resetRootTimer({ commit }) {
		commit("resetTimer")
	},
	setRootTimer({ commit }, data) {
		commit("setRootTimer", data)
	},
	startTimer({ commit, dispatch, rootState }) {
		dispatch("setTimerTimes")
		commit("startTimer")
	},
	setTimerTimes({ commit, dispatch, rootState }) {
		let start, end

		if (rootState.attempts.currentAttempt) {
			// set based on attempt start time, e.g. when continuing an attempt
			let topicId = rootState.topics.currentTopic.es_topicid
			let topicAttempts = rootState.attempts.attempts.filter(attempt => attempt._es_topic_value === topicId)
			let currentAttempt = topicAttempts.find(attempt => attempt.es_attemptid === rootState.attempts.currentAttempt)

			start = moment(currentAttempt.es_timestart)
			end = moment(start).add(rootState.topics.currentTopic.es_allowedtime, "minutes")
		} else {
			// set based on the topic allowed time and current time
			end = moment().add(rootState.topics.currentTopic.es_allowedtime, "minutes")
			start = moment()
		}

		dispatch("setAttemptStartTime", start.utc().format())
		dispatch("setAttemptTimeEnd", end.utc().format())
	},
	stopTimer({ commit }) {
		commit("stopTimer")
		commit("setAttemptStartTime", null)
	},
	setAttemptStartTime({ commit }, data) {
		commit("setAttemptStartTime", data)
	},
	setAttemptTimeEnd({ commit }, data) {
		commit("setAttemptTimeEnd", data)
	}
}

const mutations = {
	incrementTimer: (state, val) => (state.rootTimer += val),
	resetTimer: state => (state.rootTimer = 0),
	setRootTimer: (state, time) => (state.rootTimer = time),
	startTimer: state => {
		state.intervalVar = setInterval(() => {
			let now = moment()
			let end = moment(state.attemptTimeEnd)
			let timeRemaining = end.diff(now, "seconds")

			state.rootTimer = timeRemaining
		}, 1000)
	},
	stopTimer: state => clearInterval(state.intervalVar),
	setAttemptStartTime: (state, time) => (state.attemptStartTime = time),
	setAttemptTimeEnd: (state, time) => (state.attemptTimeEnd = time)
}

export default {
	state,
	getters,
	actions,
	mutations
}
