import moment from "moment"
import router from "../../router/router.js"
import EventBus from "../../event-bus"
import { getTopicsWithBoxesByCohort } from "./../../api/topic"

import {
	checkMachinesForTopic,
	startMachinesForTopic,
	stopStudentInstance,
	resetStudentInstance,
	addTimeForStudentInstance
} from "./../../api/cyberlab/studentMachine"
import { terminaldesktop_machines, onlydesktop_machines, onlyterminal_machines, devbox_machines } from "../../config/index"
import { createCookie } from "../../utils/createCookie"
import { getCookie } from "../../utils/getCookie"

const state = {
	topics: null,
	topicsWithMachineTypes: null,
	currentTopic: null,
	currentMachineTypes: null,
	previousTopic: null,
	nextTopic: null,
	topicContentPiecesLoaded: 3,
	currentVideo: null,
	totalQuestions: 0,
	unlockedTopics: []
}

const getters = {
	allTopics: state => state.topics,
	topicsWithMachineTypes: state => state.topicsWithMachineTypes,
	currentTopic: state => state.currentTopic,
	currentMachineTypes: state => state.currentMachineTypes,
	previousTopic: state => state.previousTopic,
	nextTopic: state => state.nextTopic,
	currentVideo: state => state.currentVideo,
	topicContentPiecesLoaded: state => state.topicContentPiecesLoaded,
	totalQuestions: state => state.totalQuestions,
	unlockedTopics: state => state.unlockedTopics
}

const actions = {
	updateCurrentTopic({ commit, dispatch, rootState }, data) {
		commit("changeCurrentContent", null)
		let newValue
		if (data === "next") {
			newValue = rootState.topics.nextTopic
		} else if (data === "prev") {
			newValue = rootState.topics.previousTopic
		} else {
			newValue = data
		}

		commit("changeCurrentTopic", newValue)
		dispatch("setNextTopic")
		dispatch("setPreviousTopic")
		dispatch("checkForAttemptOnTopic", data)
		// dispatch("changeCurrentContent")
	},
	// checkForAttemptOnTopic({commit, dispatch, rootState}, data) {
	//     console.log("checkForAttemptOnTopic")
	// },
	setStartTopic({ commit, rootState }) {
		if (router.currentRoute.params.topicid) {
			let startTopic = rootState.modules.currentModule.topics.find(topic => topic.es_topicid == router.currentRoute.params.topicid)
			commit("setStartTopic", startTopic)
		} else {
			let startTopic = rootState.modules.currentModule["topics"][0]
			commit("setStartTopic", startTopic)
		}
	},
	setPreviousTopic({ commit, dispatch, rootState }) {
		let previousTopic
		let currentModuleId = rootState.modules.currentModule.es_moduleid
		let currentTopicId = rootState.topics.currentTopic.es_topicid

		//get indexof current topic id
		let currentIndex = rootState.modules.currentModule["topics"].findIndex(topic => topic.es_topicid == currentTopicId)
		//if > 0, get index-1 topic id and set
		if (currentIndex > 0) {
			previousTopic = rootState.modules.currentModule["topics"][currentIndex - 1]
			dispatch("setPrevModule", null)
		} else {
			//if 0, check indexof prev module id(or sort)
			let currentModuleIndex = rootState.cohorts.currentCohort["modules"].findIndex(module => module.es_moduleid == currentModuleId)
			if (currentModuleIndex > 0) {
				//if > 0, get index-1 module id, last topic and set
				let previousModule = rootState.cohorts.currentCohort["modules"][currentModuleIndex - 1]
				previousTopic = previousModule["topics"][previousModule["topics"].length - 1]
				//also need to change previousModule
				dispatch("setPrevModule", previousModule)
			} else if (currentModuleIndex === 0) {
				//if 0, disabled
				previousTopic = null
				dispatch("setPrevModule", null)
			}
		}
		commit("setPreviousTopic", previousTopic)
	},
	changeCurrentTopicTopicContent({ commit, rootState }, content) {
		let currentTopic = rootState.topics.currentTopic

		currentTopic["es_content"] = content

		commit("updateCurrentTopicContent", currentTopic)
	},
	changeCurrentTopicTopicVideo({ commit, rootState }, video) {
		let currentTopic = rootState.topics.currentTopic

		currentTopic["es_video"] = video

		commit("updateCurrentTopicVideo", currentTopic)
	},
	setNextTopic({ commit, dispatch, rootState }) {
		let nextTopic
		let currentModuleId = rootState.modules.currentModule.es_moduleid
		let currentTopicId = rootState.topics.currentTopic.es_topicid
		//check indexof current topic id
		let currentIndex = rootState.modules.currentModule["topics"].findIndex(topic => topic.es_topicid == currentTopicId)
		//if not last topic, get index+1 topic id and set
		if (currentIndex < rootState.modules.currentModule["topics"].length - 1) {
			nextTopic = rootState.modules.currentModule["topics"][currentIndex + 1]
			dispatch("setNextModule", null)
		} else {
			//if last topic, check indexof next module id
			let currentModuleIndex = rootState.cohorts.currentCohort["modules"].findIndex(module => module.es_moduleid == currentModuleId)
			//get length of modules
			if (currentModuleIndex < rootState.cohorts.currentCohort["modules"].length - 1) {
				//if not last module, get next module id, first topic and set
				let nextModule = rootState.cohorts.currentCohort["modules"][currentModuleIndex + 1]
				nextTopic = nextModule["topics"][0]
				//also need to change nextModule
				dispatch("setNextModule", nextModule)
			} else if (currentModuleIndex === rootState.modules.currentModule["topics"].length - 1) {
				//if last topic, disabled
				nextTopic = null
				dispatch("setNextModule", null)
			}
		}
		commit("setNextTopic", nextTopic)
	},
	setTotalQuestions({ commit }, data) {
		//take an integer and sets totalquestions

		commit("updateTotalQuestions", data)
	},
	loadTopicsWithBoxes({ commit, getters }) {
		getTopicsWithBoxesByCohort(getters.currentCohort.es_cohortid).then(
			response => {
				commit("setTopicsWithMachineTypes", response.data)
			},
			error => {
				console.log("Fail to load machine types for topics")
			}
		)
	},
	setNewMachineTypesForTopic({ commit, dispatch, getters }) {
		if (getters.topicsWithMachineTypes) {
			let targetTopic = getters.topicsWithMachineTypes.find(t => t.es_topicid === getters.currentTopic.es_topicid)
			let requiredBoxes = targetTopic.boxes.map(b => b.es_name)
			commit("changeCurrentMachineTypes", requiredBoxes)
			let isRequiredESALinux = terminaldesktop_machines.some(m => requiredBoxes.includes(m))
			let isRequiredWindows = onlydesktop_machines.some(m => requiredBoxes.includes(m))
			let isRequiredUbuntu = onlyterminal_machines.some(m => requiredBoxes.includes(m))
			let isRequiredDevBox = devbox_machines.some(m => requiredBoxes.includes(m))
			if (requiredBoxes.length === 1) {
				if (isRequiredWindows) {
					dispatch("switchDisplay", "windows_server")
				} else if (isRequiredUbuntu) {
					dispatch("switchDisplay", "ubuntu")
				} else if (isRequiredESALinux) {
					dispatch("switchDisplay", "esa_linux")
				} else {
					dispatch("switchDisplay", "devbox")
				}
			} else if (requiredBoxes.length > 1) {
				if (isRequiredESALinux) dispatch("switchDisplay", "esa_linux")
				if (isRequiredDevBox) dispatch("switchDisplay", "devbox")
			}
		}
	},
	startMachinesForTopic({ commit, getters }) {
		let topicId = getters.currentTopic.es_topicid
		let enrollmentId = getters.currentEnrollment.es_enrollmentid
		let lastTimeStartMachines = getCookie("lastTimeStartMachines")
		if (lastTimeStartMachines) {
			let timeDiff = moment().diff(moment(lastTimeStartMachines), "seconds")
			if (timeDiff < 4) return
		}
		createCookie("lastTimeStartMachines", new Date(moment().toDate()))

		if (topicId !== null && enrollmentId !== null) {
			startMachinesForTopic({
				topicId: topicId,
				enrollmentId: enrollmentId
			}).then(
				response => {
					// Notify users if machines start
					EventBus.$emit("creating-new-machine", response.data)
					return response.data
				},
				error => {
					console.log("Fail to start machines.", error)
				}
			)
		}
	},
	checkMachinesForTopic({ commit, getters }) {
		let topicId = getters.currentTopic.es_topicid
		let enrollmentId = getters.currentEnrollment.es_enrollmentid
		let lastTimeStartMachines = getCookie("lastTimeStartMachines")
		if (lastTimeStartMachines) {
			let timeDiff = moment().diff(moment(lastTimeStartMachines), "seconds")
			if (timeDiff < 4) return
		}
		createCookie("lastTimeStartMachines", new Date(moment().toDate()))

		if (topicId !== null && enrollmentId !== null) {
			checkMachinesForTopic({
				topicId: topicId,
				enrollmentId: enrollmentId
			}).then(
				response => {
					// Notify users if machines start
					EventBus.$emit("creating-new-machine", response.data)
					return response.data
				},
				error => {
					console.log("Fail to start machines.", error)
				}
			)
		}
	},
	stopMachineForTopic({ getters }) {
		let studentInstanceId = getters.currentMachine.es_studentinstanceid
		stopStudentInstance({
			studentInstanceId: studentInstanceId
		}).then(
			response => {
				// Notify users if machine stops
				// EventBus.$emit("stop-machine", response.data);
				return response.data
			},
			error => {
				console.log("Fail to stop machines.", error)
			}
		)
	},
	resetMachineForTopic({ getters }) {
		let studentInstanceId = getters.currentMachine.es_studentinstanceid
		if (studentInstanceId !== null) {
			resetStudentInstance({
				studentInstanceId: studentInstanceId
			}).then(
				response => {
					// Notify users if machine stops
					// EventBus.$emit("reset-machine", response.data);
					return response.data
				},
				error => {
					console.log("Fail to reset machine.", error)
				}
			)
		}
	},
	addTimeForMachineForTopic({ commit, getters, dispatch }) {
		let studentInstanceId = getters.currentMachine.es_studentinstanceid
		if (studentInstanceId !== null) {
			addTimeForStudentInstance({
				studentInstanceId: studentInstanceId
			}).then(
				response => {
					let data = response.data
					switch (getters.currentMachineName) {
						case "Linux":
							commit("setEsaLinuxMachine", data)
							break
						case "DevBox":
							commit("setDevBoxMachine", data)
							break
						case "Window":
							commit("setWindowsMachine", data)
							break
						case "Ubuntu":
							commit("setUbuntuMachine", data)
							break
					}

					dispatch("setCurrentShutdownTime")
				},
				error => {
					console.log("Fail to add time for machine.", error)
				}
			)
		}
	}
	// unlockTopic({ commit, dispatch, rootState }, data) {
	// 	let modules = rootState.cohorts.currentCohort.modules

	// 	let mod = modules.map(module => {
	// 		module.topics.map(topic => {
	// 			if (topic.es_topicid === data.es_topicid) {
	// 				topic.isLocked = false
	// 			}
	// 			return topic
	// 		})
	// 		return module;
	// 	})

	// 	commit("setCurrentCohort", modules)

	// }
}

const mutations = {
	setTopics: (state, topics) => (state.topics = topics),
	setTopicsWithMachineTypes: (state, topics) => (state.topicsWithMachineTypes = topics),
	changeCurrentTopic: (state, newTopic) => (state.currentTopic = newTopic),
	changeCurrentMachineTypes: (state, newMachineTypes) => (state.currentMachineTypes = newMachineTypes),
	setStartTopic: (state, startTopic) => (state.currentTopic = startTopic),
	setPreviousTopic: (state, previousTopic) => (state.previousTopic = previousTopic),
	setNextTopic: (state, nextTopic) => (state.nextTopic = nextTopic),
	resetTopicContentPiecesLoaded: (state, value) => (state.topicContentPiecesLoaded = value),
	updateTopicContentPiecesLoaded: (state, value) => (state.topicContentPiecesLoaded += value),
	updateCurrentTopicContent: (state, currentTopic) => (state.currentTopic = currentTopic),
	updateCurrentTopicVideo: (state, currentTopic) => (state.currentTopic = currentTopic),
	updateTotalQuestions: (state, totalQuestions) => (state.totalQuestions = totalQuestions),
	setUnlockedTopics: (state, unlockedTopics) => (state.unlockedTopics = unlockedTopics)
}

export default {
	state,
	getters,
	actions,
	mutations
}
