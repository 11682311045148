import request from './request';

export const getMachineConnectionIds = () =>
    request({
        url: '/student-instances/machine-connection-ids',
        method: 'get'
    })

export const getInstanceConnections = (params) =>
    request({
        url: '/student-instances/connections',
        method: 'get',
        params
    })
