import axios from "axios"
import request from "./request"

export const postHtmlString = data =>
	request({
		url: `/contentpdf`,
		method: "post",
		data: data,
		headers: {
			"Content-Type": "application/json; charset=utf-8",
			Accept: "application/pdf"
		},
		responseType: "arraybuffer"
	})

export const downloadCertificate = data =>
	request({
		url: "/downloadcert",
		method: "post",
		data: data,
		headers: {
			"Content-Type": "application/json; charset=utf-8",
			Accept: "application/pdf"
		},
		responseType: "arraybuffer"
	})

export const downloadAttendancePdf = data =>
	request({
		url: "/attendancepdf",
		method: "post",
		data: data,
		headers: {
			"Content-Type": "application/json; charset=utf-8",
			Accept: "application/pdf"
		},
		responseType: "arraybuffer"
	})
