<template>
  <!-- Main container -->
  <nav
    class="course-menu-component"
    :class="isIndexCollapsed ? 'no-padding-left no-padding-right' : null"
  >
    <div
      v-if="
        ![717580003, 717580005, 717580006, 717580007].includes(
          currentCohort.es_type
        )
      "
      class="menu-item flex"
      :class="isIndexCollapsed ? 'hidden no-width' : null"
    >
      <!-- Left side -->
      <div
        class="item pointer-cursor"
        @click="handleClick"
        :class="isActive == 0 ? 'active-level' : null"
      >
        <p>Menu</p>
      </div>
      <!-- Right side -->
      <div
        class="item pointer-cursor"
        @click="handleClick"
        :class="isActive == 1 ? 'active-level' : null"
      >
        <p>Course Contents</p>
      </div>
    </div>

    <!--Single tab -->
    <div
      v-else
      class="margin-center cursor-default"
      :class="isIndexCollapsed ? 'hidden no-width' : null"
    >
      <div class="active-level">
        <p>Course Contents</p>
      </div>
    </div>

    <div
      class="item pointer-cursor flex justify-end index-10"
      @click="handleCollapse"
      :class="
        isIndexCollapsed ? null : 'fifty-width angle-hover margin-right-0'
      "
    >
      <vue-fontawesome
        size="lg"
        :icon="isIndexCollapsed ? 'angle-double-right' : 'angle-double-left'"
        :class="isIndexCollapsed ? 'blue-color' : null"
      />
    </div>
  </nav>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import EventBus from "../event-bus"

export default {
  name: "CourseMenu",
  computed: mapGetters(["isIndexCollapsed", "currentCohort"]),
  data() {
    return {
      isActive: 0
    }
  },
  mounted() {
    EventBus.$on("change-menu-tab", () => {
      this.handleClick()
    })

    if (
      [717580005, 717580006, 717580007].includes(this.currentCohort.es_type)
    ) {
      this.isActive = 1
    }
    this.loadTopicsWithBoxes()
    this.setStartLaunchDisplay(true)
  },
  methods: {
    ...mapActions([
      "toggleCollapse",
      "loadTopicsWithBoxes",
      "setStartLaunchDisplay"
    ]),
    handleClick() {
      EventBus.$emit("change-tab")
      this.$emit("change-tab")

      if (this.isActive == 1) {
        this.isActive = 0
      } else {
        this.isActive = 1
      }
    },
    handleCollapse() {
      this.toggleCollapse()
    }
  }
}
</script>

<style lang="scss">
/*conditionally applied to component when index is collapsed*/
.no-padding-left {
  padding-left: 0px !important;
}

.no-padding-right {
  padding-right: 0px;
}

/*normal css*/
#app .course-menu-component {
  display: flex;
  padding: 0.5rem;
  padding-left: 2rem;
  align-items: middle;
  margin-bottom: 0px;
  justify-content: space-between;
  color: white;

  .no-width {
    width: 0px;
  }

  .pointer-cursor {
    cursor: pointer;
  }

  .blue-color {
    color: #1262d8;
  }

  .angle-hover {
    padding-right: 0px;
    transition: padding-right 300ms;
  }

  .angle-hover:hover {
    padding-right: 5px;
    transition: padding-right 300ms;
  }

  .fifty-width {
    width: 50px;
  }

  .justify-end {
    justify-content: flex-end;
  }

  .hidden {
    display: none;
    width: 0;
  }
  .item {
    cursor: pointer;
    position: relative;
    margin-right: 2rem;
  }

  .margin-right-0 {
    margin-right: 0px;
  }

  .hidden {
    display: none !important;
  }

  .active-level {
    margin-right: 30.5px;
    font-weight: 500;
  }

  .active-level p {
    border-bottom: 0.75px solid white;
  }

  .flex {
    display: flex;
  }

  .margin-center {
    margin-left: auto;
    margin-right: auto;
  }

  .cursor-default {
    cursor: default;
  }

  .index-10 {
    z-index: 10;
  }
}
</style>
