import Vue from 'vue';
import {
    getClaims
} from "./../../api/user";

const state = {
    baseUrl: null,
    cyberLabAccessToken: null,
    authentication: 1,
    contact: null,
    errorMessage: null,
    timeZone: "America/Chicago"
};

const getters = {
    baseUrl: (state) => state.baseUrl,
    authentication: (state) => state.authentication,
    cyberLabAccessToken: (state) => state.cyberLabAccessToken,
    contact: (state) => state.contact,
    errorMessage: (state) => state.errorMessage,
    timeZone: (state) => state.timeZone
};

const actions = {
    determineUrl({ commit }) {
        let baseUrl = "/"
        commit('setBaseUrl', baseUrl)
    },
    setTimeZone({ commit }) {
        let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        if (timeZone) {
            commit('changeTimeZone', timeZone)
        }
    },
    setCyberLabToken({ commit }) {
        getClaims().then(response => {
            let accessToken = response.data.accessToken;
            document.cookie = `cyberlab=${accessToken}`;
            commit('setCyberLabToken', accessToken);
            Vue.prototype.startSignalR(accessToken);
        }, error => {
            console.log("Failed to load Access Token for CyberLAB Api")
        })
    },
    setErrorMessage({ commit }, data) {
        commit('setErrorMessage', data);
    }
};

const mutations = {
    setBaseUrl: (state, baseUrl) => (state.baseUrl = baseUrl),
    setAuthentication: (state, authentication) => (state.authentication = authentication),
    setCyberLabToken: (state, cyberLabAccessToken) => (state.cyberLabAccessToken = cyberLabAccessToken),
    setContact: (state, contact) => (state.contact = contact),
    setErrorMessage: (state, errorMessage) => (state.errorMessage = errorMessage),
    changeTimeZone: (state, zone) => (state.timeZone = zone)
};

export default {
    state,
    getters,
    actions,
    mutations
}
