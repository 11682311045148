<template>
	<div class="grades-chart-component">
		<b-tabs v-model="activeChart" :animated="false">
			<b-tab-item label="Submitted">
				<submitted-chart />
			</b-tab-item>

			<b-tab-item label="Overdue">
				<overdue-chart />
			</b-tab-item>
		</b-tabs>
	</div>
</template>

<script>
import OverdueChart from "./OverdueChart"
import SubmittedChart from "./SubmittedChart"

export default {
	name: "GradesChart",
	components: { SubmittedChart, OverdueChart },
	data() {
		return {
			activeChart: 0
		}
	}
}
</script>

<style lang="scss">
#app .grades-chart-component {
	margin: 0rem 2rem;
	max-height: calc(100vh - 27rem); /*above elements total 27rem*/

	.tabs li.is-active a,
	.tabs a:hover {
		border-bottom-color: #2da8df;
	}

	nav {
		position: sticky;
		top: 0;
		background-color: white;
		z-index: 10;
	}

	.b-tabs .tab-content {
		padding-top: 0px;
	}

	.b-tabs {
		border-bottom: 2px solid #d4d2d2;
	}
}
</style>
