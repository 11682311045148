<template>
  <div class="session-page" v-if="attendanceReturned">
    <h2 class="date-title">{{ startDate }}</h2>

    <!-- recordings  -->
    <div
      v-if="recordingsArray.length > 0"
      class="flex-column padding-bottom-1 padding-top-1"
    >
      <p
        v-for="(recording, index) in recordingsArray"
        :key="recording"
        class="flex align-items-center line-height-2"
      >
        Session recording {{ index + 1 }}:
        <a class="flex" :href="recording" target="_blank">
          <img :src="zoomLogo" alt="zoom logo" class="zoom-logo" />
        </a>
      </p>
    </div>

    <!-- pending recordings  -->
    <div v-else class="flex align-items-center padding-bottom-1 padding-top-1">
      <span class="line-height-2">
        Session recording:
      </span>
      <span class="flex es-ml-sm">
        <b-tag type="is-primary" Colored tag label>Coming soon</b-tag>
      </span>
    </div>

    <attendance-radio />

    <div v-if="isFeedbackFormReleased">
      <div
        v-if="currentSessionFeedbacks && currentSessionFeedbacks.length <= 0"
        class="cognito"
        :style="iframeLoaded ? null : 'display:none;'"
      >
        <iframe
          src="https://services.cognitoforms.com/f/cWWlgBg3P0iegMgpV3diNA?id=3"
          style="position:relative;height:559px;margin-left:-.5rem;margin-top:1rem;width:100%"
          @load="iframeLoaded = true"
          frameborder="0"
          scrolling="yes"
          seamless="seamless"
        ></iframe>

        <script
          type="application/javascript"
          src="https://services.cognitoforms.com/scripts/embed.js"
        ></script>

        <!-- session prefill -->
        <script type="application/javascript">
          Cognito.prefill({
            SessionId: "{{this.sessionData.es_sessionid}}",
            EnrollmentId: "{{this.currentEnrollment.es_enrollmentid}}"
          });
        </script>

        <!--cognito css-->
        <script type="application/javascript">
          Cognito.setCss(
            ".cognito:not(.c-forms-actionBar) {margin:0px} .cognito * {font-weight:400 !important;}.cognito .c-forms-form .c-forms-form-title h2 {font-size:1.5rem;font-weight:400;} .cognito .c-forms-heading .c-forms-form-title {padding:0px} #c-submit-button {width:150px;}"
          );
        </script>
      </div>

      <div
        class="feedback-date"
        v-else-if="
          currentSessionFeedbacks && currentSessionFeedbacks.length > 0
        "
      >
        <p>Feedback submitted on {{ submittedDate }}.</p>
      </div>
    </div>

    <div class="feedback-date" v-else>
      Feedback form is not available before the day of the session.
    </div>

    <Loader
      v-if="
        !iframeLoaded &&
          currentSessionFeedbacks &&
          currentSessionFeedbacks.length <= 0 &&
          isFeedbackFormReleased
      "
    />
  </div>
  <Loader v-else />
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import EventBus from "../event-bus";
import Loader from "./Loader";
import moment from "moment";
import timezone from "moment-timezone";

import zoomLogo from "../assets/zoom-icon.png";
import AttendanceRadio from "./AttendanceRadio";

export default {
  name: "SessionPage",
  props: ["sessionData"],
  components: { Loader, AttendanceRadio },
  computed: {
    ...mapGetters([
      "currentSessions",
      "currentEnrollment",
      "currentAttendance",
      "currentSessionFeedbacks",
      "attendanceReturned",
      "timeZone"
    ]),
    startDate: function() {
      return moment(this.sessionData.es_startdatetime)
        .tz(this.timeZone)
        .format("MMM D, YYYY h:mm a z");
    },
    submittedDate: function() {
      return moment(this.currentSessionFeedbacks[0].createdon)
        .tz(this.timeZone)
        .format("MMMM Do YYYY, [at] h:mm a z");
    },
    isFeedbackFormReleased: function() {
      //this is the date the feedback form can be rendered

      if (this.sessionData) {
        let today = new moment(new Date()).startOf("day");
        let sessionDate = moment(this.sessionData.es_startdatetime).startOf(
          "day"
        );

        if (today.isSameOrAfter(sessionDate)) {
          return true;
        } else {
          return false;
        }
      }
    }
  },
  data() {
    return {
      data: null,
      zoomLogo: zoomLogo,
      iframeLoaded: false,
      recordingsArray: []
    };
  },
  watch: {
    currentAttendance: function() {
      let example = null;
    },
    sessionData: function() {
      if (this.sessionData.es_zoomrecordings) {
        this.recordingsArray = this.parseRecordingUrls(
          this.sessionData.es_zoomrecordings
        );
      } else {
        this.recordingsArray = [];
      }
    }
  },
  mounted() {
    this.getAttendanceForSession({
      sessionid: this.sessionData.es_sessionid,
      enrollmentid: this.currentEnrollment.es_enrollmentid
    });

    this.getFeedbacksForSession({
      sessionid: this.sessionData.es_sessionid,
      enrollmentid: this.currentEnrollment.es_enrollmentid
    });

    if (this.sessionData.es_zoomrecordings) {
      this.recordingsArray = this.parseRecordingUrls(
        this.sessionData.es_zoomrecordings
      );
    }
  },
  beforeDestroy() {
    this.clearCurrentAttendance();
  },
  methods: {
    ...mapActions([
      "getAttendanceForSession",
      "getFeedbacksForSession",
      "clearCurrentAttendance"
    ]),
    handleBackClick() {
      EventBus.$emit("session-clicked", null);
    },
    parseRecordingUrls(urls) {
      return urls.split("\n");
    }
  }
};
</script>

<style lang="scss">
#app .session-page {
  .flex {
    display: flex;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
  }

  .margin-bottom-1 {
    margin-bottom: 1rem !important;
  }

  .line-height-2 {
    line-height: 2rem;
  }

  $radio-size: 1rem;

  h2 {
    font-size: 2rem !important;
    text-align: center;
  }

  #feedback-modal .cognito {
    height: unset;
  }

  #feedback-modal .animation-content {
    max-width: 960px !important;
  }

  .zoom-logo {
    display: inline-block;
    height: 1.5rem !important;
    max-width: unset !important;
    max-height: unset !important;
    background-color: #019bff;
    border-radius: 5px;
    margin-left: 0.5rem;
  }

  .align-items-center {
    align-items: center;
  }

  .padding-bottom-1 {
    padding-bottom: 1rem;
  }

  .padding-top-1 {
    padding-top: 1rem;
  }

  .margin-left-half {
    margin-left: 0.5rem;
  }

  .feedback-date {
    padding: 1rem 0rem;
    font-size: 0.8rem;
    font-style: italic;
  }

  .es-ml-sm {
    margin-left: 0.5rem;
  }
}
</style>
