import moment from "moment"
import router from "../../router/router.js"
// import example from "../../../../example.pdf"
import { postHtmlString, downloadCertificate, downloadAttendancePdf } from "../../api/pdf.js"
const FileDownload = require("js-file-download")

const state = {
	// currentAttempt: null,
	pdfDownloading: false,
	pdfPassword: null,
	downloadingCertificate: false,
	downloadingAttendancePdf: false,
	downloadingGradesPdf: false
}

const getters = {
	// currentAttempt: (state) => state.currentAttempt,
	pdfDownloading: state => state.pdfDownloading,
	pdfPassword: state => state.pdfPassword,
	downloadingCertificate: state => state.downloadingCertificate,
	downloadingAttendancePdf: state => state.downloadingAttendancePdf,
	downloadingGradesPdf: state => state.downloadingGradesPdf
}

const actions = {
	generatePdf({ commit, dispatch, rootState }, data) {
		postHtmlString(data)
			.then(response => {
				commit("pdfDownloading", false)

				let name = `${rootState.cohorts.currentCohort.es_name} - ${rootState.topics.currentTopic.es_name} - ${rootState.auth.contact.fullname}.pdf`

				FileDownload(response.data, name)
			})
			.catch(err => {
				console.log("api/pdfs post error", err)
				commit("pdfDownloading", false)
				commit("setErrorMessage", "Error generating PDF.")
				router.push({ path: "/error" })
			})
	},
	updatePdfDownloading({ commit }, data) {
		commit("pdfDownloading", data)
	},
	getCohortCertificate({ commit, dispatch, rootState }, cohortData) {
		commit("setDownloadingCertificate", true)

		downloadCertificate(cohortData)
			.then(response => {
				let name = `Completion Certificate.pdf`
				FileDownload(response.data, name)
				commit("setDownloadingCertificate", false)
			})
			.catch(error => {
				commit("setDownloadingCertificate", false)
				console.log("api/downloadcert error", error)
				commit("setErrorMessage", "Error getting cohort certificate")
				router.push({ path: "/error" })
			})
	},
	printAttendancePdf({ commit }, data) {
		commit("setDownloadingAttendancePdf", true)

		downloadAttendancePdf(data)
			.then(response => {
				let name = `${data.cohortName} Attendance.pdf`
				FileDownload(response.data, name)
				commit("setDownloadingAttendancePdf", false)
			})
			.catch(error => {
				commit("setDownloadingAttendancePdf", false)
				console.log("api/attendancepdf error", error)
				commit("setErrorMessage", "Error getting attendance PDF")
				router.push({ path: "/error" })
			})
	},
	printGradesPdf({ commit }, data) {
		commit("setDownloadingGradesPdf", true)

		downloadAttendancePdf(data)
			.then(response => {
				let name = `${data.cohortName} Grades.pdf`
				FileDownload(response.data, name)
				commit("setDownloadingGradesPdf", false)
			})
			.catch(error => {
				commit("setDownloadingGradesPdf", false)
				console.log("api/gradespdf error", error)
				commit("setErrorMessage", "Error getting grades PDF")
				router.push({ path: "/error" })
			})
	}
}

const mutations = {
	pdfDownloading: (state, status) => (state.pdfDownloading = status),
	setDownloadingCertificate: (state, bool) => (state.downloadingCertificate = bool),
	setDownloadingAttendancePdf: (state, bool) => (state.downloadingAttendancePdf = bool),
	setDownloadingGradesPdf: (state, bool) => (state.downloadingGradesPdf = bool),
	setPdfPassword: (state, password) => (state.pdfPassword = password)
}

export default {
	state,
	getters,
	actions,
	mutations
}
