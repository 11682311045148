import axios from "axios"
import router from "../../router/router.js"
import { getClassmates, getClassmateImage, getRoster, getRosterPage } from "../../api/classmate.js"

const state = {
	classmates: null,
	classmatesImages: null,
	nextRosterPage: null,
	rosterPages: [0],
	currentRosterPage: 0,
	rosterCount: 0
}

const getters = {
	allClassmates: state => state.classmates,
	classmatesImages: state => state.classmatesImages,
	nextRosterPage: state => state.nextRosterPage,
	currentRosterPage: state => state.currentRosterPage,
	rosterCount: state => state.rosterCount
}

const actions = {
	getClassmates({ commit, rootState }, cohortid) {
		axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest"

		getClassmates(cohortid)
			.then(response => {
				let myClassmatesEnrollments = response.data.value
				let myClassmates = []
				myClassmatesEnrollments.forEach(enrollment => {
					if (!enrollment.es_isstaff) {
						let newClassmate = enrollment.es_contact
						if (newClassmate) {
							newClassmate["cohortid"] = enrollment._es_cohort_value
							newClassmate["cohortgroup"] = enrollment.es_cohortgroup
							newClassmate["enrollmentid"] = enrollment.es_enrollmentid
							myClassmates.push(newClassmate)
						}
					}
				})

				commit("setClassmates", myClassmates)
			})
			.catch(err => {
				console.log("api/classmates error", err)
				commit("setAuthentication", false)
				commit("setContact", null)
				commit("setErrorMessage", "Session expired. Please login again.")
				router.push({ path: "/error" })
			})
	},
	getRoster({ commit, rootState }, cohortid) {
		getRoster(cohortid)
			.then(response => {
				let myClassmatesEnrollments = response.data.value
				let myClassmates = []
				commit("setNextRosterPage", response.data["@odata.nextLink"])
				if (rootState.classmates.currentRosterPage == rootState.classmates.rosterPages.length - 1) {
					commit("pushNextLink", response.data["@odata.nextLink"])
				}
				commit("setRosterCount", response.data["@odata.count"])
				myClassmatesEnrollments.forEach(enrollment => {
					if (!enrollment.es_isstaff) {
						let newClassmate = enrollment.es_contact
						if (newClassmate) {
							newClassmate["cohortid"] = enrollment._es_cohort_value
							newClassmate["cohortgroup"] = enrollment.es_cohortgroup
							newClassmate["enrollmentid"] = enrollment.es_enrollmentid
							myClassmates.push(newClassmate)
						}
					}
				})

				commit("setClassmates", myClassmates)
			})
			.catch(err => {
				console.log("api/roster error", err)
				commit("setAuthentication", false)
				commit("setContact", null)
				commit("setErrorMessage", "Error getting roster for cohort.")
				router.push({ path: "/error" })
			})
	},
	getNextRosterPage({ commit, rootState }, data) {
		getRosterPage(data)
			.then(response => {
				let myClassmatesEnrollments = response.data.value
				let myClassmates = []
				commit("setNextRosterPage", response.data["@odata.nextLink"])
				commit("setCurrentRosterPage", 1)
				// push link if array index isn't already populated
				if (rootState.classmates.currentRosterPage == rootState.classmates.rosterPages.length - 1) {
					commit("pushNextLink", response.data["@odata.nextLink"])
				}

				myClassmatesEnrollments.forEach(enrollment => {
					if (!enrollment.es_isstaff) {
						let newClassmate = enrollment.es_contact
						if (newClassmate) {
							newClassmate["cohortid"] = enrollment._es_cohort_value
							newClassmate["cohortgroup"] = enrollment.es_cohortgroup
							newClassmate["enrollmentid"] = enrollment.es_enrollmentid
							myClassmates.push(newClassmate)
						}
					}
				})

				commit("setClassmates", myClassmates)
			})
			.catch(err => {
				console.log("api/roster error", err)
				commit("setAuthentication", false)
				commit("setContact", null)
				commit("setErrorMessage", "Error getting roster for cohort.")
				router.push({ path: "/error" })
			})
	},
	getPreviousRosterPage({ commit, rootState, dispatch }) {
		let prevLink = rootState.classmates.rosterPages[rootState.classmates.currentRosterPage - 1]

		if (prevLink === 0) {
			// makes original request, as there's no odata link for first page of roster
			commit("setCurrentRosterPage", -1)
			dispatch("getRoster", rootState.cohorts.currentCohort.es_cohortid)
		} else {
			getRosterPage(prevLink)
				.then(response => {
					let myClassmatesEnrollments = response.data.value
					let myClassmates = []
					// update next using former current value
					commit("setNextRosterPage", rootState.classmates.rosterPages[rootState.classmates.currentRosterPage])
					// set current page back 1
					commit("setCurrentRosterPage", -1)

					myClassmatesEnrollments.forEach(enrollment => {
						if (!enrollment.es_isstaff) {
							let newClassmate = enrollment.es_contact
							if (newClassmate) {
								newClassmate["cohortid"] = enrollment._es_cohort_value
								newClassmate["cohortgroup"] = enrollment.es_cohortgroup
								newClassmate["enrollmentid"] = enrollment.es_enrollmentid
								myClassmates.push(newClassmate)
							}
						}
					})

					commit("setClassmates", myClassmates)
				})
				.catch(err => {
					console.log("api/roster error", err)
					commit("setAuthentication", false)
					commit("setContact", null)
					commit("setErrorMessage", "Error getting roster for cohort.")
					router.push({ path: "/error" })
				})
		}
	},
	getClassmateImage({ commit, rootState }, data) {
		getClassmateImage(data)
			.then(response => {
				let image = response.data.value

				let localClassmates = rootState.classmates.classmates

				if (image[0]) {
					localClassmates.forEach((classmate, index) => {
						if (image[0].objectid_contact.contactid == classmate.contactid) {
							let newClassmate = classmate
							newClassmate["image"] = image[0]
							localClassmates.splice(index, 1, newClassmate)
						}
					})
					commit("setClassmates", localClassmates)
				} else {
					//set hackerimage so frontend knows it's loaded and there's no image
					localClassmates.forEach((classmate, index) => {
						if (data.contactid == classmate.contactid) {
							let newClassmate = classmate
							newClassmate["image"] = "none"
							localClassmates.splice(index, 1, newClassmate)
						}
					})
					commit("setClassmates", localClassmates)
				}
			})
			.catch(err => {
				console.log("api/classmates error", err)
				commit("setAuthentication", false)
				commit("setContact", null)
				commit("setErrorMessage", "Session expired. Please login again.")
				router.push({ path: "/error" })
			})
	}
}

const mutations = {
	setClassmates: (state, classmates) => (state.classmates = classmates),
	setClassmatesImages: (state, images) => (state.classmatesImages = images),
	setNextRosterPage: (state, url) => (state.nextRosterPage = url),
	setCurrentRosterPage: (state, change) => (state.currentRosterPage += change),
	pushNextLink: (state, link) => {
		state.rosterPages.push(link)
	},
	setRosterCount: (state, count) => (state.rosterCount = count)
}

export default {
	state,
	getters,
	actions,
	mutations
}
