import axios from "axios"
import router from "../../router/router.js"
import moment from "moment"
import { autoEnrollCohort, readCohortCards, getEnrollmentRoles } from "./../../api/enrollment"

const state = {
	enrollments: null,
	enrollingCohort: false,
	currentEnrollment: null,
	incompleteEnrollment: false,
	cohortCards: false,
	archivedCards: null,
	enrollmentRoles: null,
	enrollmentLoading: false
}

const getters = {
	enrollments: state => state.enrollments,
	currentEnrollment: state => state.currentEnrollment,
	incompleteEnrollment: state => state.incompleteEnrollment,
	enrollingCohort: state => state.enrollingCohort,
	cohortCards: state => state.cohortCards,
	archivedCards: state => state.archivedCards,
	enrollmentRoles: state => state.enrollmentRoles,
	enrollmentLoading: state => state.enrollmentLoading
}

const actions = {
	getEnrollmentRoles({ commit, dispatch, rootState }) {
		commit("setEnrollmentLoading", true)

		getEnrollmentRoles(rootState.cohorts.currentCohort.es_cohortid)
			.then(response => {
				commit("setEnrollmentRoles", response.data.value)
				commit("setEnrollmentLoading", false)
			})
			.catch(error => {
				console.log("getEnrollmentRoles error", error)
				commit("setEnrollmentLoading", false)
			})
	},
	getCohortCards({ commit, dispatch, rootState }) {
		//gets enrollments, cohorts, attempts
		commit("setLoadingCohorts", true)

		readCohortCards()
			.then(response => {
				commit("setAuthentication", true)

				//check for cards
				if (response.data && response.data.myCards) {
					//assign cohortsCards
					commit("setCohortCards", response.data.myCards || "none")
					commit("setArchivedCards", response.data.archivedCards || "none")

					//assign contact if have one
					commit("setContact", response.data.contact || null)
				}

				dispatch("getFreeCohorts")
				// commit("setLoadingCohorts", false) //don't reset here, do after freecohorts
			})
			.catch(error => {
				if (error.message == "Network Error") {
					console.log("api/cohortcards error", error)
					// commit('setErrorMessage', "Network Error loading cohort cards")
					if (router.history.current.name !== "enterpriseLandingPage") {
						router.push({ path: "/welcome" })
					}
					commit("setAuthentication", false)
					commit("setLoadingCohorts", false)
				} else {
					router.push({ path: "/error" })
					commit("setErrorMessage", "Error loading cohort cards")
				}
			})
	},
	setEnrollmentCurrentCohort({ commit, dispatch, rootState }) {
		let enrollment = rootState.enrollments.enrollments.find(enrollment => {
			return enrollment.es_cohort.es_cohortid == rootState.cohorts.currentCohort.es_cohortid
		})
		commit("setCurrentEnrollment", enrollment)
	},
	autoEnroll({ commit, dispatch, rootState }, data) {
		commit("setEnrollingCohort", true)

		autoEnrollCohort({
			contact: data.contact,
			cohortid: data.es_cohortid
		})
			.then(response => {
				//check for message from guard
				let message = response.data.message || null

				if (message == "cohort enrollment is closed") {
					commit("setEnrollingCohort", false)
					console.log("api/autoenroll error", response)
					commit("setErrorMessage", "Enrollment is no longer available for this course.")
					router.push({ path: "/error" })
				} else {
					commit("setEnrollingCohort", false)
					dispatch("fetchCohortData", data)
					dispatch("getCohortCards", data)
					dispatch("getFreeCohorts", data)
				}
			})
			.catch(error => {
				commit("setEnrollingCohort", false)
				console.log("api/autoenroll error", error)
				commit("setErrorMessage", "Error auto enrolling in cohort")
				router.push({ path: "/error" })
			})
	}
}

const mutations = {
	setEnrollments: (state, enrollments) => (state.enrollments = enrollments),
	setCurrentEnrollment: (state, enrollment) => (state.currentEnrollment = enrollment),
	incompleteEnrollment: (state, status) => (state.incompleteEnrollment = status),
	setEnrollingCohort: (state, status) => (state.enrollingCohort = status),
	setCohortCards: (state, cards) => (state.cohortCards = cards),
	setArchivedCards: (state, cards) => (state.archivedCards = cards),
	setEnrollmentRoles: (state, roles) => (state.enrollmentRoles = roles),
	setEnrollmentLoading: (state, val) => (state.enrollmentLoading = val)
}

export default {
	state,
	getters,
	actions,
	mutations
}
