import router from '../../router/router.js';
import { getTopicContent, getTopicAnnotations, getTopicQuestionChoices } from '../../api/module.js';


const state = {
    modules: null,
    currentModule: null,
    currentContent: null,
    prevModule: null,
    nextModule: null,
    topicsByModuleId: null,
    images: null,
    loadedImages: [],
    questionChoices: null,
    incompleteCohort: false
};

const getters = {
    allModules: (state) => state.modules,
    currentModule: (state) => state.currentModule,
    currentContent: (state) => state.currentContent,
    prevModule: (state) => state.prevModule,
    nextModule: (state) => state.nextModule,
    topicsByModuleId: (state) => state.topicsByModuleId,
    images: (state) => state.images,
    questionChoices: (state) => state.questionChoices,
    loadedImages: (state) => state.loadedImages,
    incompleteCohort: (state) => state.incompleteCohort
};

const actions = {
    updateCurrentModule({ commit, dispatch, rootState }, data) {
        let newModule
        if (data === "next") {
            //set newmodule to nextmodule
            newModule = rootState.modules.nextModule
            dispatch("setNextModule", null)
            commit('changeCurrentModule', newModule)
        } else if (data === "prev") {
            newModule = rootState.modules.prevModule
            dispatch("setPrevModule", null)
            commit('changeCurrentModule', newModule)
        } else {
            commit('changeCurrentModule', data)
        }
    },
    setStartModule({ commit, rootState, dispatch }) {
        if (router.currentRoute.params.moduleid) {
            let startModule = rootState.cohorts.currentCohort.modules.find(
                module => module.es_moduleid == router.currentRoute.params.moduleid
            );
            if (!startModule) {
                //check other cohorts
                startModule = rootState.modules.modules.find(
                    module => module.es_moduleid == router.currentRoute.params.moduleid
                )
                if (!startModule) {
                    //bad topic/module
                    console.log('no access')
                    commit('setAuthentication', true)
                    commit("incompleteCohort", true)

                    let startModule = rootState.cohorts.currentCohort["modules"][0]
                    commit('setStartModule', startModule)
                    commit('setErrorMessage', "We could not find your enrollment data for this cohort.")
                    router.push({ path: '/error' })
                    return
                } else {
                    //update cookie
                    document.cookie = `currentCohort=${startModule._es_cohort_value}`;
                    //set cohort and enrollment when direct linked to topic
                    commit("setCurrentCohort", rootState.cohorts.cohortsArray.find(cohort => cohort.es_cohortid == startModule._es_cohort_value))
                    dispatch("setEnrollmentCurrentCohort")
                }
            }
            commit('setStartModule', startModule)
        } else {
            let startModule = rootState.cohorts.currentCohort["modules"][0]
            commit('setStartModule', startModule)
        }
    },
    changeCurrentContent({ commit, dispatch, rootState }) {
        dispatch("getCurrentContent")
        dispatch("getCurrentImages")
        if (rootState.topics.currentTopic.es_type != 717580002) {
            dispatch("getCurrentQuestionChoices")
        }
    },
    getCurrentContent({ commit, dispatch, rootState }) {
        commit('updateTopicContentPiecesLoaded', -1)
        // commit('changeCurrentContent', null)
        // commit('changeCurrentVideo', null)
        getTopicContent(rootState.topics.currentTopic.es_topicid).then(
            response => {
                let currentTopicContent = response.data.es_content
                let currentTopicVideo = response.data.es_video
                commit('changeCurrentContent', currentTopicContent)
                commit('changeCurrentVideo', currentTopicVideo)
                dispatch('changeCurrentTopicTopicContent', currentTopicContent)
                dispatch('changeCurrentTopicTopicVideo', currentTopicVideo)
                commit('updateTopicContentPiecesLoaded', 1)
            }).catch(err => {
                console.log("api/topiccontent error", err)
                commit('setAuthentication', false)
                commit('setContact', null)
                commit('setErrorMessage', "Session expired. Please login again.")
                router.push({ path: '/error' })
            })
    },
    getCurrentImages({ commit, rootState }) {
        commit('updateTopicContentPiecesLoaded', -1)
        getTopicAnnotations(rootState.topics.currentTopic.es_topicid).then(
            response => {
                commit('setImages', response.data.value)
                commit('updateTopicContentPiecesLoaded', 1)
            }).catch(err => {
                console.log("api/annotations error", err)
                commit('setAuthentication', false)
                commit('setContact', null)
                commit('setErrorMessage', "Session expired. Please login again.")
                router.push({ path: '/error' })
            })
    },
    getCurrentQuestionChoices({ commit, rootState }) {
        commit('updateTopicContentPiecesLoaded', -1)

        //gets topic question choices for the current topic
        //the topic questions are expanded
        getTopicQuestionChoices(rootState.topics.currentTopic.es_topicid).then(
            response => {
                commit('setQuestionChoices', response.data.value)
                commit('updateTopicContentPiecesLoaded', 1)
            }).catch(err => {
                console.log("api/questionchoices error", err)
                commit('setAuthentication', false)
                commit('setContact', null)
                commit('setErrorMessage', "Session expired. Please login again.")
                router.push({ path: '/error' })
            })
    },
    updateLoadedImages({ commit, rootState }, data) {
        //check if any images loaded
        if (rootState.modules.loadedImages.length > 0) {
            if (!rootState.modules.loadedImages.some(image => image.annotationid === data.annotationid)) {
                commit("appendLoadedImage", data)
            }
        } else {
            commit("appendLoadedImage", data)
        }
    },
    setNextModule({ commit }, nextModule) {
        commit('changeNextModule', nextModule)
    },
    setPrevModule({ commit }, prevModule) {
        commit('changePrevModule', prevModule)
    },
};

const mutations = {
    setModules: (state, modules) => (state.modules = modules),
    changeCurrentModule: (state, newModule) => (state.currentModule = newModule),
    changeCurrentContent: (state, content) => (state.currentContent = content),
    changeCurrentVideo: (state, video) => (state.currentVideo = video),
    setTopicsByModuleId: (state, topicsByModuleId) => (state.topicsByModuleId = topicsByModuleId),
    setStartModule: (state, startModule) => (state.currentModule = startModule),
    changeNextModule: (state, nextModule) => (state.nextModule = nextModule),
    changePrevModule: (state, prevModule) => (state.prevModule = prevModule),
    setImages: (state, images) => (state.images = images),
    setQuestionChoices: (state, questionChoices) => (state.questionChoices = questionChoices),
    appendLoadedImage: (state, arr) => (state.loadedImages.push(arr)),
    incompleteCohort: (state, status) => (state.incompleteCohort = status)
};

export default {
    state,
    getters,
    actions,
    mutations
}

