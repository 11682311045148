import request from '../cyberLabRequest';

export const startMachinesForTopic = (params) =>
    request({
        url: '/lms-student-machines/start-for-topic',
        method: 'get',
        params
    })

export const checkMachinesForTopic = (params) =>
    request({
        url: '/lms-student-machines/check-for-topic',
        method: 'get',
        params
    })

export const startStudentInstance = (params) =>
    request({
        url: "/lms-student-machines/start",
        method: "post",
        params
    });

export const stopStudentInstance = (params) =>
    request({
        url: "/lms-student-machines/stop",
        method: "post",
        params
    });

export const resetStudentInstance = (params) =>
    request({
        url: "/lms-student-machines/reset",
        method: "post",
        params
    });

export const addTimeForStudentInstance = (params) =>
    request({
        url: "/lms-student-machines/add-time",
        method: "post",
        params
    });
