<template>
	<span
		class="topic-row content-index-topic-row-component"
		:class="topic.isLocked || !isUnlocked ? 'locked-topic' : null"
		@click="handleClick(topic, module)"
	>
		<div class="column is-1 icon-container" :class="isOpen == index ? null : 'content-hidden'">
			<vue-fontawesome v-if="topic.es_type === 717580001" icon="flask" color="#168c16" />

			<vue-fontawesome v-else-if="topic.es_type === 717580000" :icon="['fas', 'file-video']" color="#1273b9" />

			<vue-fontawesome v-else-if="topic.es_type === 717580003" :icon="['fas', 'clipboard-check']" size="lg" color="darkgray" />

			<vue-fontawesome v-else :icon="['fas', 'question-circle']" size="lg" color="black" />
		</div>
		<div class="column is-8">
			<div class="content" :class="topic.es_topicid == currentTopic.es_topicid ? 'current-topic' : null">
				{{ topic.es_displayname || topic.es_name }}
			</div>
		</div>
		<div class="column is-1 no-padding-left no-padding-right text-center">
			<div class="time" :class="isOpen == index ? null : 'content-hidden'">{{ topic.es_estimatedtime }} min</div>
		</div>

		<!-- due status -->
		<div class="column is-2 flex align-center justify-center no-padding-left no-padding-right" :class="isOpen == index ? null : 'content-hidden'">
			<!-- pending -->
			<div v-if="(typeof dueStatus == 'object' && dueStatus.isPending) || dueStatus == 'pending'" class="due grade pending-grade">
				Pending
			</div>

			<!-- score -->
			<div v-else-if="typeof dueStatus == 'object'" class="due grade" :class="dueStatus.isPassing ? null : 'red-font'">
				{{ (dueStatus.score * 100).toFixed(1) + "%" }}
			</div>

			<!-- checkmark -->
			<div v-else-if="dueStatus == 'done'" class="due check">
				<img :src="check" />
			</div>

			<!-- due days -->
			<div v-else-if="dueStatus >= 24" class="due" :class="isOpen == index ? null : 'content-hidden'">
				{{ Math.floor(dueStatus / 24) }} day{{ Math.floor(dueStatus / 24) === 1 ? null : "s" }}
			</div>

			<!-- due today -->
			<div v-else-if="dueStatus > 0" class="due" :class="isOpen == index ? null : 'content-hidden'">due today</div>

			<!-- overdue days -->
			<div v-else-if="dueStatus <= 0" class="due overdue" :class="isOpen == index ? null : 'content-hidden'">
				<img :src="overdueDot" class="overdue-dot" />
				overdue
			</div>
		</div>
	</span>
</template>
<script>
import { mapGetters, mapActions } from "vuex"
import contentActive from "../assets/content-icon.png"
import quizActive from "../assets/quiz-icon.png"
import labActive from "../assets/terminal-empty.png"
import moment from "moment"
import check from "../assets/check.png"
import overdueDot from "../assets/oval.png"
import router from "../router/router.js"

export default {
	name: "ContentIndexTopicRow",
	props: ["dueStatus", "isOpen", "topic", "currentTopic", "index", "module"],
	data() {
		return {
			contentActive: contentActive,
			labActive: labActive,
			quizActive: quizActive,
			check: check,
			overdueDot: overdueDot
			// isUnlocked: true
		}
	},
	computed: {
		...mapGetters(["unlockedTopics", "currentCohort"]),
		isUnlocked: {
			get() {
				if (this.unlockedTopics && this.unlockedTopics.includes(this.topic.es_topicid)) {
					return true
				} else if (this.currentCohort && this.currentCohort.es_issequential) {
					return false
				}
				return true
			},
			set(value) {
				return value
			}
		}
	},
	watch: {
		unlockedTopics: function() {
			if (this.unlockedTopics.includes(this.topic.es_topicid)) {
				this.isUnlocked = true
			}
		}
	},
	methods: {
		...mapActions(["updateCurrentTopic", "updateCurrentModule", "navigateToTopic", "unlockTopic"]),
		handleClick(topic, module) {
			let isUnlocked = !topic.isLocked
			let locallyUnlocked = this.isUnlocked
			// topic isLocked == false, local unlocked == false
			if (isUnlocked && locallyUnlocked) {
				this.updateCurrentModule(module)
				this.navigateToTopic(topic)
			}
		}
	}
}
</script>

<style lang="scss">
#app .content-index-topic-row-component.locked-topic {
	cursor: not-allowed !important;
	opacity: 0.5;
}

#app .content-index-topic-row-component {
	.time {
		font-size: 10px;
		font-family: "Roboto Condensed", sans-serif;
	}

	.due {
		font-size: 10px;
		text-align: right;
		color: #9b9b9b;
		font-family: "Roboto Condensed", sans-serif;
	}

	.content {
		color: #4a4a4a;
	}

	.content.current-topic {
		font-weight: 600;
		color: #1154bf;
	}

	.overdue-dot {
		position: absolute;
		left: -3px;
		top: 9px;
	}

	.icon-container {
		justify-content: center;
		padding: 0;
		align-items: center;
		display: flex;
	}

	.no-padding-left {
		padding-left: 0;
	}

	.no-padding-right {
		padding-right: 0;
	}

	.content-hidden {
		display: none !important;
	}

	.text-center {
		text-align: center;
	}

	.flex {
		display: flex;
	}

	.justify-center {
		justify-content: center;
	}

	.grade {
		color: #29a329;
		font-size: 0.75rem;
	}

	.pending-grade {
		color: #4a4a4a;
		// background-color: #f5f5f5;
		padding: 0.15rem 0.25rem;
		border-radius: 5px;
	}

	.red-font {
		color: red;
	}

	.icon-container {
		svg {
			font-size: 20px;
		}
	}
}
</style>
