import router from "../../router/router.js"
import { getFinalGrade, getCohortGrades } from "../../api/finalgrade.js"

const state = {
	currentFinalGrade: null,
	finalGradeLoading: false,
	cohortGrades: null,
	cohortGradesLoading: false,
	assessmentModalLoading: false,
	quizModalLoading: false,
	finalLabModalLoading: false
}

const getters = {
	currentFinalGrade: state => state.currentFinalGrade,
	finalGradeLoading: state => state.finalGradeLoading,
	cohortGradesLoading: state => state.cohortGradesLoading,
	cohortGrades: state => state.cohortGrades,
	assessmentModalLoading: state => state.assessmentModalLoading,
	quizModalLoading: state => state.quizModalLoading,
	finalLabModalLoading: state => state.finalLabModalLoading
}

const actions = {
	returnFinalGrade({ dispatch, commit }, data) {
		//data is string enrollmentid
		dispatch("setFinalGradeLoading", true)

		//turn off loading status after
		getFinalGrade(data)
			.then(response => {
				commit("setCurrentFinalGrade", response.data.value[0].es_finalgrade)
				dispatch("setFinalGradeLoading", false)
			})
			.catch(err => {
				dispatch("setFinalGradeLoading", false)
				commit("setErrorMessage", "Error getting final grade.")
				router.push({ path: "/error" })
			})
	},
	setFinalGradeLoading({ commit }, data) {
		commit("setFinalGradeLoading", data)
	},
	getCohortGrades({ commit }, data) {
		commit("setCohortGradesLoading", true)

		getCohortGrades(data)
			.then(response => {
				commit("setCohortGrades", response.data)
				commit("setCohortGradesLoading", false)
			})
			.catch(err => {
				console.log("getcohortgrades err", err)
				commit("setCohortGradesLoading", false)
				commit("setErrorMessage", "Error getting gradebook data.")
				router.push({ path: "/error" })
			})
	},
	setAssessmentModalLoading({ commit }, data) {
		commit("setAssessmentModalLoading", data)
	},
	setQuizModalLoading({ commit }, data) {
		commit("setQuizModalLoading", data)
	},
	setFinalLabModalLoading({ commit }, data) {
		commit("setFinalLabModalLoading", data)
	}
}

const mutations = {
	setCurrentFinalGrade: (state, finalGrade) => (state.currentFinalGrade = finalGrade),
	setFinalGradeLoading: (state, bool) => (state.finalGradeLoading = bool),
	setCohortGradesLoading: (state, bool) => (state.cohortGradesLoading = bool),
	setCohortGrades: (state, arr) => (state.cohortGrades = arr),
	setAssessmentModalLoading: (state, bool) => (state.assessmentModalLoading = bool),
	setQuizModalLoading: (state, bool) => (state.quizModalLoading = bool),
	setFinalLabModalLoading: (state, bool) => (state.finalLabModalLoading = bool),
	patchCohortGrades: (state, data) => {
		let idx = -1
		let prevAttempt = state.cohortGrades.find((grade, index) => {
			if (grade.es_attemptid === data.attemptid) {
				idx = index
			}
			return grade.es_attemptid === data.attemptid
		})
		// udpate
		let score = 0.0
		if (data.score === "pass") {
			score = 1.0
		}

		prevAttempt.es_score = score

		state.cohortGrades[idx] = prevAttempt
	},
	addAssessmentScoreToGrades: (state, data) => {
		let newAttempt = {
			es_attemptid: data.es_attemptid,
			es_attemptstatus: data.es_attemptstatus,
			es_cohortid: null,
			es_enrollmentid: data._es_enrollment_value,
			es_score: data.es_score,
			es_topicid: data._es_topic_value,
			es_type: null,
			fullname: null
		}
		state.cohortGrades.push(newAttempt)
	},
	removeQuizAttemptFromGrades: (state, data) => {
		let index = state.cohortGrades.findIndex(attempt => {
			return attempt.es_attemptid === data.es_attemptid
		})
		if (index > -1) {
			state.cohortGrades.splice(index, 1)
		}
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}
