<template>
	<span class="control-panel-component">
		<div class="block-display main-content-block">
			<div class="remaining-time-area float-left" v-if="remainingTimeInSeconds > 0 && remainingTimeInSeconds < 36000">
				<div class="remaining-time">
					<span>{{ remainingTime }}</span>
				</div>
				<b-button class="add-time-btn" @click="addTimeForCurrentMachine">
					<vue-fontawesome class="margin-left-small" :icon="['fas', 'plus']" color="#3298dc" />
				</b-button>
			</div>
			<div class="help-btn" @click="openHelpModal">
				<vue-fontawesome :icon="['fas', 'question-circle']" size="lg" color="#736e6e" />
			</div>
			<div class="current-machine-name" v-if="remainingTimeInSeconds > 0">
				<span>{{ machineName }}</span>
			</div>
			<div class="expand-guac-terminal" @click="expandTerminal">
				<vue-fontawesome class="margin-left-small" :icon="['fas', 'external-link-alt']" color="#ffffff" />
			</div>
			<div id="control-panel-buttons" class="redo-button-container flex center refresh-button-padding float-right">
				<b-button
					size="is-small"
					type="is-info"
					class="side-margins-0-25"
					aria-controls="controlPanelContentId"
					rounded
					@click="controlPanelActive = !controlPanelActive"
				>
					<span>Settings</span>
					<vue-fontawesome class="margin-left-small control-dropdown-icon" :icon="['fas', 'cog']" color="white" />
				</b-button>
				<b-collapse ref="controlPanelCollapse" :open="controlPanelActive" class="control-panel-collapse">
					<!-- <b-collapse
          :open="false"
          ref="controlPanelCollapse"
          aria-id="controlPanelContentId"
          class="control-panel-collapse"
        > -->
					<!-- <template #trigger>

          <b-button
            size="is-small"
            type="is-info"
            class="side-margins-0-25"
            aria-controls="controlPanelContentId"
            rounded
          >
            <span>Settings</span>
            <vue-fontawesome
              class="margin-left-small control-dropdown-icon"
              :icon="['fas', 'cog']"
              color="white"
            />
          </b-button>
          </template> -->
					<div class="control-panel-table" v-bind:class="{ 'width-with-menu': !isIndexCollapsed }">
						<div class="content">
							<b-table class="dark-background" :data="machineData">
								<b-table-column label="Virtual Machine" v-slot="props">
									<div class="machine-name">
										{{ props.row.machineName }}
									</div>
									<div class="machine-ipaddress">({{ props.row.ipAddress }})</div>
								</b-table-column>
								<b-table-column label="Status" centered v-slot="props">
									<div>
										<span
											class="machine-status"
											v-bind:class="{
												'red-color': props.row.status === 'Inactive',
												'green-color': isMachineStatusActive(props.row.status)
											}"
											>{{ convertStatus(props.row.status) }}</span
										>
									</div>
								</b-table-column>
								<b-table-column label="View" centered v-slot="props">
									<div v-if="props.row.es_box.es_isstudentmachine">
										<b-button
											class="machine-btn bold-font-weight"
											v-bind:class="{
												'machine-active': props.row.terminalActive
											}"
											:disabled="props.row.disabledTerminal"
											@click="switchToTerminalMode(props.row)"
											>Terminal</b-button
										>
									</div>
									<div class="small-margin-top" v-if="props.row.es_box.es_isstudentmachine">
										<b-button
											class="machine-btn bold-font-weight"
											v-bind:class="{
												'machine-active': props.row.desktopActive
											}"
											:disabled="props.row.disabledDesktop"
											@click="switchToDesktopMode(props.row)"
											>Desktop</b-button
										>
									</div>
									<div class="machine-icon" v-if="!props.row.es_box.es_isstudentmachine">
										<img
											v-if="props.row.es_box.es_machinetype === 717580001"
											src="@/assets/icons/windows.png"
											class="machine-type-icon"
										/>
										<img v-else src="@/assets/icons/linux.png" class="machine-type-icon" />
									</div>
								</b-table-column>
								<b-table-column label="Time" centered v-slot="props">
									<div class="cp-remaining-time">
										{{ props.row.remainingTime }}
									</div>
									<div class="cp-add-time">
										<span class="small-padding-left"></span>
										<b-button
											class="machine-btn bold-font-weight blue-bg"
											@click="addTimeForMachine(props.row)"
										>
											<span class="white-color">+30 mins</span>
										</b-button>
									</div>
								</b-table-column>
								<b-table-column label="Reset" centered v-slot="props">
									<div class="reset-machine">
										<b-button
											type="is-danger"
											class="machine-btn bold-font-weight grey-bg"
											@click="openResetConfirmModal(props.row)"
										>
											<vue-fontawesome icon="undo" color="#ffffff" />
										</b-button>
									</div>
								</b-table-column>
								<!-- </template> -->
								<!-- </b-table-column>-->
								<template slot="footer">
									<div class="control-panel-footer">
										<b-button
											size="is-small"
											type="is-info"
											class="side-margins-0-25"
											slot="trigger"
											aria-controls="controlPanelContentId"
											@click="openGuacAuthModal"
											rounded
										>
											<img src="@/assets/icons/connect_white.png" class="connect-icon" />
											<span class="connect-guac-text">Connect Virtual Machines</span>
										</b-button>
									</div>
								</template>
							</b-table>
						</div>
					</div>
				</b-collapse>
			</div>
		</div>

		<b-modal :active.sync="showResetConfirmationModal" has-modal-card class="reset-modal">
			<header class="modal-card-head reset-modal-header">
				<p class="modal-card-title reset-modal-header-title">
					Machine Reset Confirmation
				</p>
			</header>
			<section class="modal-card-body reset-modal-body">
				<div>
					<span reset-modal-body-text>You are about to reset your machine. All of your files will be erased.</span>
				</div>
			</section>
			<footer class="modal-card-foot reset-modal-footer">
				<button class="button is-rounded" type="button" @click="showResetConfirmationModal = false">
					Close
				</button>
				<button class="button is-primary save-button is-rounded blue-bg" @click="resetMachine" :disabled="disabled">
					Reset
				</button>
			</footer>
		</b-modal>

		<b-modal :active.sync="showHelpModal" has-modal-card class="reset-modal">
			<header class="modal-card-head reset-modal-header">
				<p class="modal-card-title reset-modal-header-title">
					Having issues connecting to a lab?
				</p>
			</header>
			<section class="modal-card-body reset-modal-body">
				<div>
					<span class="modal-body-title">Try the following steps:</span>
					<ol class="modal-body-list">
						<li><span class="bold-text">Refresh</span> your browser.</li>
						<li><span class="bold-text">Logout</span> of account, close browser and log back in.</li>
						<li>
							<span class="bold-text">Email{{ " " }}</span>
							<a class="help-email" href="mailto:help@evolvesecurity.com">help@evolvesecurity.com</a>.
						</li>
					</ol>
				</div>
			</section>
			<footer class="modal-card-foot reset-modal-footer">
				<button class="button is-rounded" type="button" @click="showHelpModal = false">
					Close
				</button>
			</footer>
		</b-modal>
	</span>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import axios from "axios"
import moment from "moment"
import EventBus from "../event-bus"
import ControlPanelControls from "./ControlPanelControls"
import { openSnackBar } from "../utils/openSnackBar"
import { startStudentInstance, stopStudentInstance, resetStudentInstance, addTimeForStudentInstance } from "../api/cyberlab/studentMachine"
import { terminaldesktop_machines, onlydesktop_machines, onlyterminal_machines } from "../config/index"

export default {
	name: "ControlPanel",
	components: { ControlPanelControls },
	computed: {
		...mapGetters([
			"currentTopic",
			"currentEnrollment",
			"frameUrl",
			"isIndexCollapsed",
			"currentShutdownTime",
			"allMachines",
			"baseGuacUrl",
			"currentMachine",
			"currentMachineTypes"
		]),
		machineData() {
			let results = []
			if (this.allMachines) {
				this.allMachines.forEach(m => {
					if (m && this.currentMachineTypes && this.currentMachineTypes.indexOf(m.es_box.es_name) > -1) {
						m.machineName = m.es_box.es_displayname
						m.ipAddress = m.es_privateipaddress || "Unavailable"
						m.status = m.es_status
						m.terminalActive = this.getStatus(m.es_box.es_name, "terminal")
						m.desktopActive = this.getStatus(m.es_box.es_name, "desktop")
						m.disabledTerminal = m.es_guacterminalid === null || m.es_status !== "Active"
						m.disabledDesktop = m.es_guacdesktopid === null || m.es_status !== "Active"
						m.remainingTime = this.getRemainingTime(m.es_status, m.es_timetoshutdown)
						results.push(m)
					}
				})
				results.sort((a, b) => (a.es_box.es_name > b.es_box.es_name ? 1 : -1))
			}
			return results
		},
		remainingTime() {
			let duration = moment.duration(this.remainingTimeInSeconds, "seconds")
			let hours = `0${duration.hours()}`
			let minutes = `0${duration.minutes()}`
			let seconds = `0${duration.seconds()}`
			hours = hours.substr(hours.length - 2)
			minutes = minutes.substr(minutes.length - 2)
			seconds = seconds.substr(seconds.length - 2)
			return `${hours}:${minutes}:${seconds}`
		},
		machineName() {
			let boxName = this.currentMachine.es_box.es_displayname
			if (this.currentMode === "terminal") {
				return `${boxName} Terminal`
			} else {
				return `${boxName} Desktop`
			}
		}
	},
	data() {
		return {
			iframe: null,
			active: "terminal",
			controlPanelActive: false,
			currentBox: "esa_linux",
			currentMode: "terminal",
			showResetConfirmationModal: false,
			showHelpModal: false,
			targetMachineForReset: null,
			disabled: false,
			timeInterval: null,
			timeIntervalCheckShutdownTime: null,
			remainingTimeInSeconds: 0
		}
	},
	mounted() {
		this.refreshFrame()
		EventBus.$on("resizer-used", () => {
			this.refreshFrame()
		})

		if (this.allMachines && this.allMachines.length === 0) {
			this.loadConnectionIds()
		}

		EventBus.$on("machine-ready", this.initializeTasksWhenMachineReady)
		this.timeInterval = setInterval(this.decrementRemainingTime, 1000)
		document.addEventListener("click", this.handleClickOutsideSettings)
		document.addEventListener("keydown", this.handleClickOutsideSettings)
	},
	beforeDestroy() {
		if (this.timeInterval) clearInterval(this.timeInterval)
		EventBus.$off("load-connections-complete", this.activateCheckShutdownTime)
		EventBus.$off("resizer-used")
		document.removeEventListener("click", this.handleClickOutsideSettings)
		document.removeEventListener("keydown", this.handleClickOutsideSettings)
	},
	watch: {
		isIndexCollapsed: function() {
			//refreshes iframe when index collapsed
			this.refreshFrame()
		}
	},
	methods: {
		...mapActions([
			"loadConnectionIds",
			"addTimeForMachineForTopic",
			"startMachinesForTopic",
			"updateCurrentMachineAction",
			"setNewMachineTypesForTopic"
		]),
		initializeTasksWhenMachineReady() {
			this.setTimeRemaining()
			this.setInitialMachine()
		},
		isInactionState(status) {
			return ["Creating", "Starting", "Stopping", "Resetting", "Terminating"].indexOf(status) > -1
		},
		getRemainingTime(status, time) {
			if (status != "Active") {
				return "0 mins"
			} else {
				let timeDiffInSeconds = this.getTimeRemainingInSeconds(time)
				if (timeDiffInSeconds <= 0) {
					return "0 mins"
				} else {
					let duration = moment.duration(timeDiffInSeconds, "seconds")
					return `${duration.hours() * 60 + duration.minutes()} mins`
				}
			}
		},
		getTimeRemainingInSeconds(time) {
			let fromTime = moment.utc(new Date().toUTCString())
			let toTime = moment.utc(new Date(time).toUTCString())
			let timeDiffInSeconds = toTime.unix() - fromTime.unix()
			return timeDiffInSeconds
		},
		setGuacSrc(src) {
			document.getElementById("guac").src = `${this.baseGuacUrl}${src}`
		},
		refreshFrame() {
			//refresh the frame
			let guac = document.getElementById("guac")
			let iframeCoverer = document.getElementById("iframe-coverer")
			iframeCoverer.insertAdjacentHTML("afterend", guac.cloneNode(true).outerHTML) // Insert a duplicate to force iframe reload
			guac.remove() // Remove the original iframe

			// Ensure iframe is focused (listening in CurrentLesson.vue)
			this.emitFocusGuac()
		},
		emitFocusGuac() {
			EventBus.$emit("focus-guac")
		},
		startMachine(data) {
			startStudentInstance({
				studentInstanceId: this.currentMachine.es_studentinstanceid
			}).then(
				response => {
					// Notify users if machine stops
					// EventBus.$emit("stop-machine", response.data);
					return response.data
				},
				error => {
					console.log("Fail to start machine.", error)
				}
			)

			openSnackBar(this, `${data.status === "Inactive" ? "Starting" : "Creating"} ${machineType} machine`)
		},
		stopMachine(data) {
			stopStudentInstance({
				studentInstanceId: this.currentMachine.es_studentinstanceid
			}).then(
				response => {
					// Notify users if machine stops
					// EventBus.$emit("stop-machine", response.data);
					return response.data
				},
				error => {
					console.log("Fail to stop machine.", error)
				}
			)

			openSnackBar(this, `Stopping ${this.currentMachine.es_box.es_name} machine`)
		},
		resetMachine() {
			resetStudentInstance({
				studentInstanceId: this.targetMachineForReset.es_studentinstanceid
			}).then(
				response => {
					// Notify users if machine stops
					// EventBus.$emit("stop-machine", response.data);
					return response.data
				},
				error => {
					console.log("Fail to reset machine.", error)
				}
			)
			openSnackBar(this, `Reseting ${this.targetMachineForReset.es_box.es_name} machine`)
			this.showResetConfirmationModal = false
		},
		addTimeForMachine(data) {
			addTimeForStudentInstance({
				studentInstanceId: data.es_studentinstanceid
			}).then(
				response => {
					// Notify users if machine stops
					// EventBus.$emit("stop-machine", response.data);
					// return response.data;
					this.updateTimeInControlPanel()
				},
				error => {
					console.log("Fail to add time for machine.", error)
				}
			)

			openSnackBar(this, `Adding 30 mins for ${data.es_box.es_name} machine`)
		},
		openResetConfirmModal(data) {
			this.targetMachineForReset = data
			this.showResetConfirmationModal = true
		},
		switchToTerminalMode(data) {
			if (data) {
				let boxName = data.es_box.es_name
				this.setCurrentBox(boxName)
				this.setMode("terminal")
				this.setGuacSrc(data.es_guacterminalid)
				this.updateCurrentMachine(boxName)
				this.setTimeRemaining()
			}
		},
		switchToDesktopMode(data) {
			if (data) {
				let boxName = data.es_box.es_name
				this.setCurrentBox(boxName)
				this.setMode("desktop")
				this.setGuacSrc(data.es_guacdesktopid)
				this.updateCurrentMachine(boxName)
				this.setTimeRemaining()
			}
		},
		updateCurrentMachine(boxName) {
			let currentMachine = this.allMachines.find(m => m.es_box.es_name === boxName)
			this.updateCurrentMachineAction(currentMachine)
		},
		setCurrentBox(boxName) {
			this.currentBox = boxName
		},
		setMode(mode) {
			this.currentMode = mode
		},
		getStatus(boxName, mode) {
			if (this.currentBox === boxName) {
				return mode === this.currentMode
			} else {
				return false
			}
		},
		convertStatusToAction(status) {
			return status === "Inactive" ? "Start" : "Create"
		},
		addTimeForCurrentMachine() {
			openSnackBar(this, `Adding 30 mins for ${this.currentMachine.es_box.es_displayname} machine`)
			this.addTimeForMachineForTopic()

			if (this.remainingTimeInSeconds < 1800) {
				this.remainingTimeInSeconds = this.remainingTimeInSeconds + 1800
			} else {
				this.remainingTimeInSeconds = 3600
			}
			this.updateTimeInControlPanel()
		},
		updateTimeInControlPanel() {
			let targetMachine = this.allMachines.filter(m => m.es_box.es_name === this.currentBox)
			if (targetMachine.length > 0) {
				this.loadConnectionIds()
				let machineData = this.machineData // Trigger re-compute machineData
			}
		},
		setTimeRemaining() {
			this.remainingTimeInSeconds = this.getTimeRemainingInSeconds(this.currentShutdownTime)
		},
		setInitialMachine() {
			if (this.currentMachine) {
				this.currentBox = this.currentMachine.es_box.es_name
				this.currentMode = "terminal"
				if (this.currentBox === "windows_server") {
					this.currentMode = "desktop"
				}
			}
		},
		decrementRemainingTime() {
			this.remainingTimeInSeconds = this.remainingTimeInSeconds - 1
		},
		getTimeRemainingInSeconds(time) {
			let fromTime = moment.utc(new Date().toUTCString())
			let toTime = moment.utc(new Date(time).toUTCString())
			let timeDiffInSeconds = toTime.unix() - fromTime.unix()
			return timeDiffInSeconds
		},
		expandTerminal() {
			let link = document.getElementById("guac").src
			window.open(link, "_blank")
		},
		convertStatus(status) {
			if (status === "Active") {
				return "Running"
			} else if (status === "Inactive") {
				return "Stopped"
			} else {
				return status
			}
		},
		handleClickOutsideSettings(event) {
			if (this.$browserDetect.isFirefox || this.$browserDetect.isSafari) {
				// Handle starting machine when it stopped
				if (this.remainingTimeInSeconds < 0) {
					this.startMachinesForTopic()
				}
				return null
			}
			let focused = document.activeElement

			// Handle closing for Control Panel
			if (focused && focused.getAttribute("aria-controls") !== "controlPanelContentId") {
				if (this.$refs.controlPanelCollapse.isOpen) {
					this.controlPanelActive = false
				}
			}

			// Handle starting machine when it stopped
			if (this.remainingTimeInSeconds < 0) {
				this.startMachinesForTopic()
			}
		},
		isMachineStatusActive(status) {
			return ["Active", "Creating"].indexOf(status) > -1
		},
		openGuacAuthModal() {
			EventBus.$emit("open-guac-auth-modal")
		},
		openHelpModal() {
			this.showHelpModal = true
		}
	}
}
</script>

<style lang="scss">
#app .control-panel-component {
	height: 2.5rem;
	background-color: black;
	width: 100%;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 1;

	.main-content-block {
		text-align: center;
	}

	.thirty-pct-width {
		width: 30%;
	}

	.flex {
		display: flex;
	}

	.block-display {
		display: block;
	}

	.float-left {
		float: left;
	}

	.float-right {
		float: right;
	}

	.space-between {
		justify-content: space-between;
	}

	.remaining-time-area {
		font-size: 0.9rem;
		border: 1px solid #3298dc;
		border-radius: 1rem;
		margin: 0.25rem;
	}

	.remaining-time {
		display: inline-block;
		color: #3298dc;
		z-index: 2;
		width: 75px;
		text-align: center;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 0.25rem;
	}

	.add-time-btn {
		background-color: transparent;
		color: #3298dc;
		padding: 0 0.5rem 0 0;
		height: inherit;
		vertical-align: middle;
		border-color: transparent;
		border-left: solid 1px;

		&:focus,
		&:hover {
			outline: none;
			border-color: transparent;
			border-left: solid 1px;
		}
	}

	.expand-guac-terminal {
		cursor: pointer;
		font-size: 1.4rem;
		float: right;
		margin-top: 0.15rem;
		margin-right: 0.4rem;
	}

	.help-btn {
		float: left;
		margin: 0.25rem;
		margin-top: 0.5rem;
		cursor: pointer;

		.fa-question-circle {
			font-size: 1.2rem;
		}

		&:hover {
			svg {
				color: white;
			}
		}
	}

	.help-email {
		text-decoration: underline;
	}

	.control-panel-buttons {
		padding-right: 0;
	}

	.control-panel-collapse {
		position: relative;
	}

	.control-panel-table {
		position: absolute;
		top: 4rem;
		right: -0.5rem;

		.table {
			color: #000000;
		}

		thead {
			font-size: 0.9rem;
			th {
				color: #ffffff;
				background-color: #1479bf;
			}

			th:first-of-type {
				//machine column
				width: 1rem;
			}

			th:last-of-type {
				//reset column
				width: 1rem;
			}

			th {
				width: 1rem;
			}
		}

		button {
			padding-left: 0.5em;
			padding-right: 0.5em;
			font-size: 0.8rem;
			width: 4.2rem;
		}

		.machine-name {
			font-weight: bold;
			font-size: 1rem;
		}

		.machine-ipaddress {
			color: gray;
			font-style: italic;
			font-size: 0.8rem;
		}

		.machine-status {
			font-size: 0.9rem;
			font-weight: bold;
		}

		.reset-machine {
			svg {
				font-size: 0.8rem;
			}

			button {
				width: 1.9rem;
			}
		}

		table td {
			vertical-align: middle !important;
			padding: 0.5rem 0.5rem;
		}

		.machine-active {
			background-color: #000000;
			color: #fdf9f9;
		}
	}

	.control-panel-footer {
		button {
			float: right;
			width: 13rem;
			padding: 0.25rem;
		}

		.connect-icon {
			height: 1.3rem;
			width: 1.3rem;
			vertical-align: top;
		}

		.connect-guac-text {
			margin-left: 0.8rem;
			bottom: 3rem;
		}
	}

	.modal-body-title {
		font-weight: bold;
		text-decoration: underline;
	}

	.modal-body-list {
		margin-left: 2rem;
	}

	.bold-font-weight {
		font-weight: bold;
	}

	.small-margin-top {
		margin-top: 0.5rem;
	}

	.width-with-menu {
		width: 31vw;
	}

	.align-center {
		align-items: center;
	}

	.double-height {
		height: 2.5rem;
	}

	.justify-center {
		justify-content: center;
	}

	.control-dropdown-icon {
		font-size: 1rem;
		vertical-align: sub;
	}

	.cp-remaining-time {
		color: #3298dc;
		font-size: 0.9rem;
	}

	.cp-add-time {
		color: #3298dc;

		svg {
			font-size: 0.9rem;
		}
	}

	.machine-type-icon {
		height: 2.5rem;
		width: 2.5rem;
		vertical-align: sub;
	}

	.current-machine-name {
		display: inline-block;
		color: #ffffff;
		margin: 0.5rem;
	}

	.reset-modal {
		z-index: 1;

		.animation-content {
			max-width: 33rem !important;
			z-index: 1;
		}

		&-header {
			background-color: #1479bf;

			&-title {
				color: #fff;
				font-size: 1.2rem;
			}
		}

		&-footer {
			display: inline-block;
			width: 100%;
			text-align: center;
			padding: 0.8rem;

			button {
				text-align: center;
			}
		}
	}

	.dark-background {
		/deep/ table.table {
			background-color: #fdf9f9;
			border: none;
			overflow: hidden;
		}
	}

	.small-padding-left {
		padding-left: 0.2rem;
	}

	.machine-button:first-child {
		border-radius: 16px 0 0 16px !important;
	}

	.machine-button:last-child {
		border-radius: 0px 16px 16px 0 !important;
	}

	.center {
		justify-content: center;
		align-items: center;
	}

	.refresh-button-padding {
		padding: 0.25rem 0.3rem;
	}
	.cursor-default.switch {
		cursor: default;
	}
	.side-margins-0-25 {
		margin: 0px 0.25rem;
	}

	.grey-bg {
		background-color: grey;
	}

	.blue-bg {
		background-color: #3298dc;
	}

	.white-color {
		color: white;
	}

	.red-bg {
		background-color: red !important;
	}

	.green-bg {
		background-color: #00b042 !important;
	}

	.red-color {
		color: red;
	}

	.green-color {
		color: green;
	}
}
</style>
