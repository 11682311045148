<template>
    <div :id="`iframe-${number}`" class="markdown iframe" v-html="value"></div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import EventBus from "../event-bus";

export default {
    name: "MarkdownIframe",
    props: ["value", "number"],
    computed: mapGetters(["isIndexCollapsed"]),
    mounted() {
        this.calcDimensions();

        //whenever page is resized, calc dimensions again
        EventBus.$on("resizer-used", () => {
            this.calcDimensions();
        });

        window.addEventListener("resize", this.calcDimensions);
    },
    beforeDestroy() {
        // Unregister the event listener before destroying this Vue instance
        window.removeEventListener("resize", this.calcDimensions);
    },
    watch: {
        isIndexCollapsed: function() {
            var self = this;
            //resizes video after the animation to resize window ends (300ms)
            setTimeout(function() {
                self.calcDimensions();
            }, 300);
        }
    },
    methods: {
        calcDimensions() {
            //sets height based on width an a 9:16 ratio
            let elementStyle = window.getComputedStyle(
                document.getElementById(`iframe-${this.number}`)
            );
            let el = document.getElementById(`iframe-${this.number}`);

            var paddingX =
                parseFloat(elementStyle.paddingLeft) +
                parseFloat(elementStyle.paddingRight);

            //use width minus padding
            let elementWidth = el.offsetWidth - paddingX;

            el.style.height = (elementWidth * 9) / 16 + "px";
        }
    }
};
</script>

<style lang="scss" scoped>
#app .markdown.iframe {
    padding-left: 1rem;
    padding-right: 1rem;
}
</style>
