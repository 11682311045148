<template>
    <div v-if="loadedQuestion" class="open-response-quiz-question-component" :key="questionId">
        <span>
            <!-- question text -->
            <p class="question-margin-bottom">{{this.loadedQuestion.es_text}}</p>

            <!-- text box -->
            <div class="choices-block">
                <b-field>
                    <template slot="label">
                        <div class="flex">Answer</div>
                    </template>
                    <b-input
                        v-model="answer"
                        placeholder="Type your answer here"
                        @input="handleChange(answer)"
                    ></b-input>
                </b-field>
            </div>
        </span>
    </div>
    <Loader v-else />
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import EventBus from "../event-bus";
import Loader from "./Loader";

export default {
    name: "OpenResponseQuizQuestion",
    props: ["questionId"],
    components: { Loader },
    computed: {
        ...mapGetters([
            "questionChoices",
            "currentCohort",
            "currentQuestion",
            "currentQuestionIndex",
            "previousSelections",
            "continuingQuiz"
        ])
    },
    data() {
        return {
            loadedQuestion: null,
            correctAnswer: false,
            answer: null
        };
    },
    mounted() {
        this.getQuestion();
        this.checkPrevious();
    },
    watch: {
        currentQuestion: function() {
            this.getQuestion();
        }
    },
    methods: {
        ...mapActions(["answerQuizQuestion"]),
        checkPrevious() {
            if (this.previousSelections && this.continuingQuiz) {
                //find selection for this question

                let selection = this.findSelection();

                //save choice
                let choice = selection.es_choice_text;

                //set answer to choice
                if (choice && choice != "No answer selected") {
                    this.answer = choice;
                    this.handleChange(this.answer);
                }
            }
        },
        findSelection() {
            if (this.currentQuestion) {
                return this.previousSelections.find(selection => {
                    return (
                        this.currentQuestion.es_question_id ==
                        selection.es_question_id
                    );
                });
            } else if (this.questionId) {
                return this.previousSelections.find(selection => {
                    return this.questionId == selection.es_question_id;
                });
            } else {
                return null;
            }
        },
        getQuestion() {
            //find an answer choice from this topic with the same questionid
            let questionChoice = this.questionChoices.find(choice => {
                return (
                    choice.es_topicquestion.es_topicquestionid ==
                    this.questionId
                );
            });

            //set question in data equal to that choices question (it is expanded)
            this.loadedQuestion = questionChoice.es_topicquestion;

            //submit an unanswered question (placeholder selection in case student chooses no answer)
            this.submitUnansweredQuestion();
        },
        submitUnansweredQuestion() {
            //sends a "no choice selected/answered" default selection to quizbutton component

            // EventBus.$emit("answered-quiz-question", {
            //     es_topicquestionid: this.loadedQuestion.es_topicquestionid,
            //     choices: null,
            //     es_text: this.loadedQuestion.es_text,
            //     es_type: this.loadedQuestion.es_type,
            //     es_sort: this.currentQuestionIndex
            // });

            this.answerQuizQuestion({
                es_topicquestionid: this.loadedQuestion.es_topicquestionid,
                choices: null,
                es_text: this.loadedQuestion.es_text,
                es_type: this.loadedQuestion.es_type,
                es_sort: this.currentQuestionIndex
            });
        },
        handleChange(choice) {
            //emits to quizbutton and adds object to array this.correctQuestions which is passed to the server
            //when the quiz is submitted
            let choices = [
                {
                    es_topicquestionchoiceid: null,
                    es_topicquestionid: this.loadedQuestion.es_topicquestionid,
                    es_text: choice,
                    file: null
                }
            ];

            // EventBus.$emit("answered-quiz-question", {
            //     es_topicquestionid: this.loadedQuestion.es_topicquestionid,
            //     choices: choices,
            //     es_type: this.loadedQuestion.es_type,
            //     es_text: this.loadedQuestion.es_text,
            //     es_sort: this.currentQuestionIndex
            // });

            this.answerQuizQuestion({
                es_topicquestionid: this.loadedQuestion.es_topicquestionid,
                choices: choices,
                es_type: this.loadedQuestion.es_type,
                es_text: this.loadedQuestion.es_text,
                es_sort: this.currentQuestionIndex
            });
        }
    }
};
</script>

<style lang='scss'>
#app .open-response-quiz-question-component {
    -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    padding: 0.5rem;
    margin-left: 1rem;
    background-color: #dde4fb;
    margin-right: 5rem;
    margin-top: 2rem;
    margin-bottom: 1rem;

    .message-box {
        margin: 1rem;
        border: 1px solid #2ca8df;
        height: 6rem;
        overflow-y: auto;
        padding: 0.5rem 1rem;
        background-color: #ced9fd;
    }

    .flex {
        display: flex;
    }

    .align-center {
        align-items: center;
    }

    .choice-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .validity-icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 24px;
        margin-top: 0px;
        padding-left: 12px;
    }

    .empty {
        padding: 12px;
    }

    .b-radio.radio {
        margin-bottom: 0.5rem;
    }

    .button {
        margin-left: 1rem;
    }

    .choices-block .b-tooltip {
        line-height: 1.5rem;
    }

    .checkbox {
        margin-bottom: 0.5rem;
        line-height: 1.5rem;
    }

    .b-checkbox.checkbox + .checkbox {
        margin-left: 0rem;
    }
}
</style>