<template>
  <div v-show="false" style="background-color: white;overflow-x: hidden;">
    <!-- <b-button @click="logHtml">Print</b-button> -->

    <!---normal markdown rendering-->
    <div v-if="loaded && contentItemList">
      <div id="pdf-markdown">
        <!--loop through contentItemList-->
        <div v-for="(item, index) in contentItemList" :key="index">
          <pdf-image v-if="item.type === 'image'" :image="item" />
          <pdf-question
            v-else-if="item.type === 'question'"
            :choices="item.questionChoices"
          />
          <div
            v-else-if="item.type === 'snippet'"
            class="snippet"
            style="margin: 1rem;border-left: none; page-break-inside: avoid; background-color: black;padding: 0.5rem 1rem;-webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);font-family: 'Ubuntu Mono', monospace;color: greenyellow;"
            v-html="compiledSnippet(item.value)"
          ></div>

          <markdown-iframe
            v-else-if="item.type === 'iframe'"
            :value="item.value"
            :number="index"
          />
          <div
            v-else
            class="markdown"
            v-html="compiledMarkdown(item.value)"
          ></div>
        </div>
      </div>

      <!-- logo watermark-->
      <img id="watermark" src="../assets/evolve-logo-transparent.png" />

      <!-- do not copy watermark -->
      <div
        id="copyright-watermark"
        style="position:relative; display:flex; justify-content:space-between; flex-direction:row; height:300px; width:800px; border:2px solid rgba(255, 0, 0, 0.2);"
      >
        <div
          style="width:100%; height:64px; text-align:center; display:flex; align-items:center; align-self:flex-start; color:rgba(255, 0, 0, 0.2); font-size:36px;"
        >
          For Personal Use Only. Do Not Share or Distribute.
        </div>
        <div
          style="height:180px; display:flex;flex-direction:column;align-items:center;justify-content:center;"
        >
          <!-- student info -->
          <div
            style="color:rgba(255, 0, 0, 0.2); font-size:24px; font-weight:bold; padding-top:.5rem;padding-bottom:.5rem;"
            id="student-watermark"
          >
            <p style="margin:.5rem;">
              {{ this.currentEnrollment.es_contact.fullname }}
            </p>
            <p style="margin:.5rem;">
              {{ this.currentEnrollment.es_contact.emailaddress1 }}
            </p>
            <p style="margin:.5rem;">
              {{ this.currentEnrollment.es_contact.mobilephone }}
            </p>
            <p style="margin:.5rem;">
              {{ this.currentEnrollment.es_contact.address1_composite }}
            </p>
          </div>
        </div>
        <div
          style="width:100%; height:36px; display:flex; align-self:flex-end; text-align:center; color:rgba(255, 0, 0, 0.2); font-size:36px; padding-top:.5rem;"
        >
          CONFIDENTIAL
        </div>
      </div>

      <!--header-->
      <div id="pdf-header">
        <h1 style="text-align:center;margin-bottom:3rem;">
          {{ currentEnrollment.es_cohort.es_name }} -
          {{ currentTopic.es_name || currentTopic.es_displayname }}
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
import marked from "marked";
import { sanitize } from "dompurify";
import { mapGetters, mapActions } from "vuex";
import PdfImage from "./PdfImage";
import PdfQuestion from "./PdfQuestion";
import EventBus from "../event-bus";
import MarkdownIframe from "./MarkdownIframe";
import Loader from "./Loader";

export default {
  name: "PdfRenderer",
  components: {
    PdfImage,
    PdfQuestion,
    MarkdownIframe,
    Loader
  },
  computed: {
    ...mapGetters([
      "currentContent",
      "currentTopic",
      "images",
      "questionChoices",
      "currentEnrollment",
      "contact",
      "pdfPassword"
    ])
  },
  data() {
    return {
      markdown: null,
      contentItemList: null,
      imageIndex: 1,
      neccessaryData: 0,
      loaded: false,
      totalQuestions: 0
    };
  },
  updated() {
    //makes all links open in new tab
    this.changeAnchorTargets();
  },
  mounted() {
    this.markdown = this.currentTopic.es_content;
    this.parseMarkdown();
    EventBus.$on("print-pdf", this.logHtml);
  },
  watch: {
    questionChoices: function() {
      this.contentItemList = null;
      this.loaded = false;
      this.markdown = this.currentTopic.es_content;
      this.parseMarkdown();
    }
  },
  beforeDestroy() {
    EventBus.$off("print-pdf", this.logHtml);
  },
  methods: {
    ...mapActions([
      "getCurrentQuestionChoices",
      "setTotalQuestions",
      "generatePdf"
    ]),
    parseMarkdown() {
      //parseMarkdown essentially reads several lines of markdown, scanning for special tags e.g. question and image
      //the end result is this.contentItemList full of objects parsed in the template above
      //checks for special item.type, otherwise it is parsed by marked.js
      //reset totalquestions
      this.setNumberOfQuestions(0);
      //reset contentItemList
      this.contentItemList = [];
      //reset loaded
      this.loaded = false;
      if (this.markdown) {
        //split into parts by every new line character
        let parts = this.markdown.split("\n");
        let text = "";
        //calls foreach loop
        text = this.loopThroughParts(parts);
        //and any remaining text
        if (text !== "") {
          this.contentItemList.push({ type: "text", value: text });
        }
      }
      this.loaded = true;
    },
    logHtml() {
      var root = document.querySelector("#markdown");
      let htmlString = document.getElementById("pdf-markdown").outerHTML;
      htmlString = htmlString.replace(/break-inside/g, "page-break-inside");

      htmlString += `
<style>
#pdf-markdown {
    user-select:none;
}

.snippet p, .snippet p strong {
    font-family: 'Ubuntu Mono', monospace;
    padding: 0;
    color: greenyellow;
    width: 100%;
    font-weight: 400;
    page-break-inside: avoid;
}

blockquote {
    border-left: 10px solid #ccc;
    margin: 2rem;
    padding: 1rem;
    page-break-inside: avoid;
}

blockquote p {
    display: inline;
    padding-left: 0rem;
}
table,
th,
td {
    border: 1px solid gray;
}
table {
    width: 100%;
}

table tr:nth-child(2n) {
    background-color: #f6f8fa;
}
code {
    background-color: whitesmoke;
    color: #000;
    font-size: 0.875em;
    font-weight: 800;
    padding: 0.25em 0.5em 0.25em;
}
img {
    max-width: 100%;
}
</style>`;

      this.generatePdf({
        password: this.pdfPassword,
        topicid: this.currentTopic.es_topicid,
        htmlString: htmlString,
        watermark: this.returnWatermarkHtml(),
        background: document.getElementById("watermark").outerHTML,
        header: document.getElementById("pdf-header").outerHTML
      });
    },
    returnWatermarkHtml() {
      return document.getElementById("copyright-watermark").outerHTML;
    },
    loopThroughParts(parts) {
      //snippet is a special code box looking for ```\ncode\ncode....\n```\n
      let snippet = {
        snippetStarted: false,
        localSnippet: ""
      };
      let text = "";

      parts.forEach(part => {
        //check the part
        let type = part.substring(1, part.indexOf(":"));
        snippet.isCodeSnippet = part.indexOf("```") === 0;

        //check for questions
        if (type === "question") {
          if (this.handleQuestionPart(part, text)) {
            //reset text
            text = "";
            //early return
            return;
          }
        }

        //check for images
        if (type === "image") {
          //make sure there's a matching image with id
          if (this.handleImagePart(part, text)) {
            text = "";
            return;
          }
        }

        //check for code snippets
        //code snippets are special because they span multiple lines, everything else is line by line
        //this conditional sets variables that are used in future iterations until a closing ``` is found
        if (snippet.isCodeSnippet || snippet.snippetStarted) {
          snippet = this.handleSnippertPart(part, text, snippet);
          if (snippet.isCodeSnippet) {
            //resets text on opening and closing snippet tag
            text = "";
          }
          return;
        }

        //check for iframe
        if (part.indexOf("<iframe") === 0) {
          this.handleIFramePart(part, text);
          text = "";
          return;
        }

        //add part to current text variable if made it this far
        //it will be handled as normal markdown
        text = `${text}\n${part}`;
      });
      return text;
    },
    handleQuestionPart(part, text) {
      //filters the store for this question's answer choices
      let questionChoices = this.getQuestionChoices(part);

      if (questionChoices) {
        this.addTextToItemList(text);
        //adds a question type (used in template above) and corresponding data
        this.contentItemList.push({
          type: "question",
          questionChoices: questionChoices,
          questionId: questionChoices[0].es_topicquestion.es_topicquestionid
        });
        this.increaseNumberOfQuestions();
        return true;
      } else {
        return false;
      }
    },
    handleImagePart(part, text) {
      let image = this.getFile(part);
      let localImageDescription = part.substring(
        part.indexOf(" (") + 2,
        part.indexOf(")]")
      );
      if (image) {
        this.addTextToItemList(text);
        this.contentItemList.push({
          type: "image",
          description: localImageDescription,
          annotationid: image.annotationid,
          index: this.imageIndex
        });
        this.imageIndex++;
        return true;
      } else {
        return false;
      }
    },
    handleSnippertPart(part, text, data) {
      if (text) {
        this.addTextToItemList(text);
      }
      if (!data.snippetStarted) {
        data.snippetStarted = true;
      } else {
        if (data.isCodeSnippet) {
          //ending snippet, push the item
          this.contentItemList.push({
            type: "snippet",
            value: data.localSnippet
          });
          data.snippetStarted = false;
          data.localSnippet = "";
        } else {
          //add to localsnippet
          if (data.localSnippet) {
            data.localSnippet += "<br>" + part;
          } else {
            data.localSnippet = part;
          }
        }
      }
      return data;
    },
    handleIFramePart(part, text) {
      this.addTextToItemList(text);
      //used in template to pass the iframe number for setting id
      //necessary for getting iframe by id and setting iframe height
      this.contentItemList.push({
        type: "iframe",
        value: part
      });
    },
    getFile(part) {
      let startIndex = part.indexOf("image:id-");
      let endIndex = part.indexOf(" (");
      let imageObject = this.images.find(
        i =>
          parseInt(part.substring(startIndex + 9, endIndex)) ===
          i.importsequencenumber
      );
      if (imageObject) {
        return {
          description: imageObject.notetext,
          annotationid: imageObject.annotationid
        };
      } else {
        return "";
      }
    },
    getQuestionChoices(part) {
      if (!this.questionChoices) {
        return "";
      }

      //parse the part to get the question id
      let startIndex = part.indexOf("question:id-");
      let endIndex = part.indexOf("]");
      let idToFind = parseInt(part.substring(startIndex + 12, endIndex));

      //filter question choices to get the ones with same questionid
      let questionChoicesArray = this.questionChoices.filter(
        choice => idToFind == choice.es_topicquestion.es_question_id
      );

      if (questionChoicesArray.length > 0) {
        return questionChoicesArray;
      } else {
        return "";
      }
    },
    changeAnchorTargets() {
      let anchors = document.querySelectorAll(".markdown-renderer a");
      anchors.forEach(anchor => {
        anchor.target = "_blank";
      });
    },
    increaseNumberOfQuestions() {
      this.totalQuestions = this.totalQuestions + 1;
      //set in store
      this.setTotalQuestions(this.totalQuestions);
    },
    setNumberOfQuestions(num) {
      this.totalQuestions = num;
      //set in store
      this.setTotalQuestions(this.totalQuestions);
    },
    addTextToItemList(text) {
      if (text !== "") {
        this.contentItemList.push({ type: "text", value: text });
      }
      return "";
    },
    compiledSnippet(input) {
      return sanitize(marked(input), {
        ALLOWED_TAGS: [
          "b",
          "p",
          "i",
          "em",
          "strong",
          "blockquote",
          "br",
          "code",
          "sup"
        ]
      });
    },
    compiledMarkdown(input) {
      return sanitize(marked(input), {
        ALLOWED_TAGS: [
          "kbd",
          "a",
          "b",
          "p",
          "i",
          "em",
          "strong",
          "blockquote",
          "big",
          "small",
          "div",
          "br",
          "hr",
          "li",
          "ol",
          "ul",
          "table",
          "tbody",
          "thead",
          "td",
          "th",
          "tr",
          "caption",
          "span",
          "h1",
          "h2",
          "h3",
          "h4",
          "h5",
          "h6",
          "code",
          "iframe",
          "sup"
        ]
      });
    }
  }
};
</script>

<style lang="scss">
#app .pdf-renderer-component {
  .margin-2 {
    margin: 2rem;
  }
}
</style>
