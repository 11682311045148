<template>
	<div
		class="markdown-renderer-component"
		:class="
			takingQuiz && this.currentTopic.es_type != 717580004 && !this.markdownProp
				? 'quiz-height'
				: this.currentTopic.es_type == 717580004 && !this.markdownProp
				? 'quiz-lab'
				: null
		"
	>
		<!---normal markdown rendering-->
		<div
			v-if="loaded && contentItemList"
			id="markdown"
			style="padding-top:1rem"
			class="markdown has-padding max-width hundo-width"
			:class="additionalClasses()"
		>
			<!--loop over contentitemlist-->
			<div v-for="(item, index) in contentItemList" :key="index">
				<div v-if="item.type === 'image'" class="has-center-text-align image">
					<content-image :image="item" />
				</div>
				<div v-else-if="item.type === 'question'" class="content-question-container">
					<multiple-select-quiz-question
						v-if="
							[717580002, 717580004, 717580005].includes(currentTopic.es_type) &&
								item.questionChoices[0].es_topicquestion.es_type == 717580004
						"
						class="quiz-question"
						:questionId="item.questionId"
						:key="item.questionId"
					/>
					<open-response-quiz-question
						v-else-if="
							[717580002, 717580004, 717580005].includes(currentTopic.es_type) &&
								item.questionChoices[0].es_topicquestion.es_type == 717580006
						"
						class="quiz-question"
						:questionId="item.questionId"
						:key="item.questionId"
					/>
					<upload-quiz-question
						v-else-if="
							[717580002, 717580004, 717580005].includes(currentTopic.es_type) &&
								item.questionChoices[0].es_topicquestion.es_type == 717580007
						"
						class="quiz-question"
						:questionId="item.questionId"
						:key="item.questionId"
					/>
					<quiz-question
						v-else-if="[717580002, 717580004, 717580005].includes(currentTopic.es_type)"
						class="quiz-question"
						:questionId="item.questionId"
						:key="item.questionId"
					/>
					<content-question v-else class="content-question" :choices="item.questionChoices" />
				</div>
				<div v-else-if="item.type === 'snippet'">
					<div class="markdown snippet" v-html="compiledSnippet(item.value)"></div>
				</div>
				<div v-else-if="item.type === 'quiz'">
					<quiz :number="item.value" :key="currentAttempt + 'quiz'" />
				</div>
				<div v-else-if="item.type === 'iframe'">
					<markdown-iframe :value="item.value" :number="index" />
				</div>
				<span v-else-if="item.type === 'callout'">
					<p style="color:red">
						{{ item.value }}
					</p>
				</span>
				<div v-else class="test" style="margin-left:1rem;">
					<inline-handler :inlineText="compiledMarkdown(item.value)" />
					<!-- <div class="markdown" v-html="handleMarkdown(item.value)"></div> -->
				</div>
			</div>
		</div>

		<!--loader-->
		<Loader v-else />
	</div>
</template>

<script>
import marked from "marked"
import { sanitize } from "dompurify"
import { mapGetters, mapActions } from "vuex"
import ContentImage from "./ContentImage"
import ContentQuestion from "./ContentQuestion"
import QuizQuestion from "./QuizQuestion"
import UploadQuizQuestion from "./UploadQuizQuestion"
import MultipleSelectQuizQuestion from "./MultipleSelectQuizQuestion"
import QuizHistory from "./QuizHistory"
import Timer from "./Timer"
import Quiz from "./Quiz"
import EventBus from "../event-bus"
import moment from "moment"
import timezone from "moment-timezone"
import router from "../router/router.js"
import MarkdownIframe from "./MarkdownIframe"
import OpenResponseQuizQuestion from "./OpenResponseQuizQuestion"
import Loader from "./Loader"
import PdfRenderer from "./PdfRenderer"
import InlineHandler from "./InlineHandler"

export default {
	name: "MarkdownRenderer",
	components: {
		ContentImage,
		ContentQuestion,
		QuizQuestion,
		QuizHistory,
		Timer,
		Quiz,
		MarkdownIframe,
		Loader,
		MultipleSelectQuizQuestion,
		OpenResponseQuizQuestion,
		PdfRenderer,
		UploadQuizQuestion,
		InlineHandler
	},
	props: ["markdownProp"],
	computed: {
		...mapGetters([
			"currentContent",
			"currentTopic",
			"images",
			"questionChoices",
			"attempts",
			"currentAttempt",
			"isIndexCollapsed",
			"attemptSaving",
			"rootTimer",
			"timeZone",
			"pdfDownloading",
			"takingQuiz"
		])
	},
	data() {
		return {
			markdown: null,
			contentItemList: null,
			imageIndex: 1,
			neccessaryData: 0,
			loaded: false,
			totalQuestions: 0
		}
	},
	updated() {
		//makes all links open in new tab
		this.changeAnchorTargets()
	},
	mounted() {
		this.markdown = this.markdownProp || this.currentTopic.es_content
		if (this.markdown) {
			//template will show spinner while loaded == false
			this.loaded = false
		}

		if ([717580002].includes(this.currentTopic.es_type) && !this.markdownProp) {
			//quiz
			//markdown will be parsed by the watch method when questionchoices updates
			this.getCurrentQuestionChoices() //store method
		} else {
			this.parseMarkdown()
		}
	},
	watch: {
		questionChoices: function() {
			this.contentItemList = null
			this.loaded = false
			this.markdown = this.markdownProp || this.currentTopic.es_content
			this.parseMarkdown()
		},
		currentTopic: function() {
			this.markdown = this.markdownProp || this.currentTopic.es_content
		}
	},
	beforeDestroy() {},
	methods: {
		...mapActions(["getCurrentQuestionChoices", "setTotalQuestions", "generatePdf", "updatePdfDownloading"]),
		parseMarkdown() {
			//parseMarkdown essentially reads several lines of markdown, scanning for special tags e.g. question and image
			//the end result is this.contentItemList full of objects parsed in the template above
			//checks for special item.type, otherwise it is parsed by marked.js
			//reset totalquestions
			this.setNumberOfQuestions(0)
			//reset contentItemList
			this.contentItemList = []
			//reset loaded
			this.loaded = false
			if (this.markdown) {
				//split into parts by every new line character
				let parts = this.markdown.split("\n")
				let text = ""
				//calls foreach loop
				text = this.loopThroughParts(parts)
				//and any remaining text
				if (text !== "") {
					this.contentItemList.push({
						type: "text",
						value: text
					})
				}
			}
			this.loaded = true
		},
		printPdf() {
			EventBus.$emit("print-pdf")
			this.updatePdfDownloading(true)
		},
		loopThroughParts(parts) {
			//snippet is a special code box looking for ```\ncode\ncode....\n```\n
			let snippet = {
				snippetStarted: false,
				localSnippet: ""
			}
			let text = ""

			parts.forEach(part => {
				//check the part
				let type = part.substring(1, part.indexOf(":"))
				snippet.isCodeSnippet = part.indexOf("```") === 0

				//check for questions
				if (type === "question") {
					if (this.handleQuestionPart(part, text)) {
						//reset text
						text = ""
						//early return
						return
					}
				}

				//check for images
				if (type === "image") {
					//make sure there's a matching image with id
					if (this.handleImagePart(part, text)) {
						text = ""
						return
					}
				}

				//check for code snippets
				//code snippets are special because they span multiple lines, everything else is line by line
				//this conditional sets variables that are used in future iterations until a closing ``` is found
				if (snippet.isCodeSnippet || snippet.snippetStarted) {
					snippet = this.handleSnippertPart(part, text, snippet)
					if (snippet.isCodeSnippet) {
						//resets text on opening and closing snippet tag
						text = ""
					}
					return
				}

				//check for a quiz
				if (type === "quiz") {
					this.handleQuizPart(part, text)
					text = ""
					return
				}

				//check for iframe
				if (part.indexOf("<iframe") === 0) {
					this.handleIFramePart(part, text)
					text = ""
					return
				}

				//add part to current text variable if made it this far
				//it will be handled as normal markdown
				text = `${text}\n${part}`
			})
			return text
		},
		handleQuestionPart(part, text) {
			//filters the store for this question's answer choices
			let questionChoices = this.getQuestionChoices(part)

			if (questionChoices) {
				this.addTextToItemList(text)
				//adds a question type (used in template above) and corresponding data
				this.contentItemList.push({
					type: "question",
					questionChoices: questionChoices,
					questionId: questionChoices[0].es_topicquestion.es_topicquestionid
				})
				this.increaseNumberOfQuestions()
				return true
			} else {
				return false
			}
		},
		handleImagePart(part, text) {
			let image = this.getFile(part)
			let localImageDescription = part.substring(part.indexOf(" (") + 2, part.indexOf(")]"))
			if (image) {
				this.addTextToItemList(text)
				this.contentItemList.push({
					type: "image",
					description: localImageDescription,
					annotationid: image.annotationid,
					index: this.imageIndex
				})
				this.imageIndex++
				return true
			} else {
				return false
			}
		},
		handleSnippertPart(part, text, data) {
			if (text) {
				this.addTextToItemList(text)
			}
			if (!data.snippetStarted) {
				data.snippetStarted = true
			} else {
				if (data.isCodeSnippet) {
					//ending snippet, push the item
					this.contentItemList.push({
						type: "snippet",
						value: data.localSnippet
					})
					data.snippetStarted = false
					data.localSnippet = ""
				} else {
					//add to localsnippet
					if (data.localSnippet) {
						data.localSnippet += "<br>" + part
					} else {
						data.localSnippet = part
					}
				}
			}
			return data
		},
		handleQuizPart(part, text) {
			this.addTextToItemList(text)

			//given [quiz:####], parses for ####
			let numberOfQuestions = this.parseQuizMarkdown(part)

			//quiz component will randomly choose questions == numberOfQuestions
			this.contentItemList.push({
				type: "quiz",
				value: numberOfQuestions
			})
			this.setNumberOfQuestions(parseInt(numberOfQuestions))
		},
		handleIFramePart(part, text) {
			this.addTextToItemList(text)
			//used in template to pass the iframe number for setting id
			//necessary for getting iframe by id and setting iframe height
			this.contentItemList.push({
				type: "iframe",
				value: part
			})
		},
		parseQuizMarkdown(part) {
			//parses the quiz markdown to find number of questions
			let startIndex = part.indexOf("quiz:")
			let endIndex = part.indexOf("]")
			let numberOfQuestions = part.substring(startIndex + 5, endIndex)
			return numberOfQuestions ? numberOfQuestions : ""
		},
		getFile(part) {
			let startIndex = part.indexOf("image:id-")
			let endIndex = part.indexOf(" (")
			let imageObject = this.images.find(i => parseInt(part.substring(startIndex + 9, endIndex)) === i.importsequencenumber)
			if (imageObject) {
				return {
					description: imageObject.notetext,
					annotationid: imageObject.annotationid
				}
			} else {
				return ""
			}
		},
		getQuestionChoices(part) {
			if (!this.questionChoices) {
				return ""
			}

			//parse the part to get the question id
			let startIndex = part.indexOf("question:id-")
			let endIndex = part.indexOf("]")
			let idToFind = parseInt(part.substring(startIndex + 12, endIndex))

			//filter question choices to get the ones with same questionid
			let questionChoicesArray = this.questionChoices.filter(choice => idToFind == choice.es_topicquestion.es_question_id)

			if (questionChoicesArray.length > 0) {
				return questionChoicesArray
			} else {
				return ""
			}
		},
		changeAnchorTargets() {
			let anchors = document.querySelectorAll(".markdown a")
			anchors.forEach(anchor => {
				anchor.target = "_blank"
			})
		},
		increaseNumberOfQuestions() {
			this.totalQuestions = this.totalQuestions + 1
			//set in store
			this.setTotalQuestions(this.totalQuestions)
		},
		setNumberOfQuestions(num) {
			this.totalQuestions = num
			//set in store
			this.setTotalQuestions(this.totalQuestions)
		},
		addTextToItemList(text) {
			if (text !== "") {
				this.contentItemList.push({
					type: "text",
					value: text
				})
			}
			return ""
		},
		additionalClasses() {
			let classes = ""
			if ((this.isIndexCollapsed && this.currentTopic.es_type != 717580001) || this.takingQuiz) {
				classes += "margin-centered "
			}

			// if (this.takingQuiz) {
			//     classes += "hundo-width"
			// }
			return classes
		},
		compiledSnippet(input) {
			return sanitize(marked(input), {
				ALLOWED_TAGS: ["b", "p", "i", "em", "strong", "blockquote", "br", "code", "sup"]
			})
		},
		compiledMarkdown(input) {
			return sanitize(marked(input), {
				ALLOWED_TAGS: [
					"kbd",
					"a",
					"b",
					"p",
					"i",
					"em",
					"strong",
					"blockquote",
					"big",
					"small",
					"div",
					"br",
					"hr",
					"li",
					"ol",
					"ul",
					"table",
					"tbody",
					"thead",
					"td",
					"th",
					"tr",
					"caption",
					"span",
					"h1",
					"h2",
					"h3",
					"h4",
					"h5",
					"h6",
					"code",
					"iframe",
					"sup",
					"style"
				]
			})
		}
	}
}

</script>

<style lang="scss">
#app .markdown-renderer-component {
	margin: 0 !important;
	position: relative;
	background-color: white;
	overflow-x: hidden;
	// height: inherit;

	code {
		color: #000;
		font-weight: 700;
	}

	.relative {
		position: relative;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-weight: bold;
	}

	.has-padding {
		padding: 0rem 2rem;
	}

	.container {
		max-width: 95%;
	}

	.max-width {
		max-width: 1080px;
	}

	.margin-centered {
		margin-left: auto;
		margin-right: auto;
	}

	.markdown {
		user-select: none;

		p {
			display: inline;
			// padding-left: 1rem;
			margin: 1rem 0rem;
			line-height: 1.5rem;
		}

		p + p {
			display: block;
		}

		.button.is-loading::after {
			height: 1.5rem;
			width: 1.5rem;
			left: calc(50% - (1.5rem / 2));
			top: calc(50% - (1.5rem / 2));
		}

		a {
			color: #0000ee;
			text-decoration: underline;
		}

		// Override Bulma minireset.sass
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			font-weight: bold;
			padding-left: -1rem;
			margin: 1rem 0rem;
			line-height: 1rem;
		}

		h1 {
			font-size: 3rem;
			line-height: 3rem;
			margin: 1rem -1rem;
			letter-spacing: -0.04rem;
		}

		h2 {
			font-size: 2em;
			line-height: 3rem;
			margin: 1rem -1rem;
			margin-top: 3rem;
		}

		h3 {
			font-size: 1.5rem;
			line-height: 1.5rem;
			margin: 1rem -1rem;
		}

		h4 {
			font-size: 1rem;
			line-height: 1rem;
			margin: 1rem 0rem;
		}

		h5 {
			font-size: 0.75rem;
			line-height: 0.75rem;
			margin: 1rem 0rem;
		}

		h6 {
			font-size: 0.5rem;
			line-height: 0.5rem;
			margin: 1rem 0rem;
		}

		.snippet {
			margin: 1rem;
			border-left: none;
			background-color: black;
			padding: 0.5rem 1rem;
			-webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
			box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
			font-family: "Ubuntu Mono", monospace;
			color: greenyellow;
			user-select: text;
			word-break: break-word;
		}

		.snippet p,
		.snippet p strong {
			font-family: "Ubuntu Mono", monospace;
			padding: 0;
			color: greenyellow;
			width: 100%;
			font-weight: 400;
			user-select: text;
		}

		ul {
			list-style: disc inside none;
			padding-left: 1rem;
			margin: 1rem 0rem;
			line-height: 1.5rem;
		}

		ol {
			list-style: default inside none;
			padding-left: 1rem;
			line-height: 1.5rem;
		}

		ol :last-child,
		ul :last-child {
			margin-bottom: 1rem;
		}

		ol :first-child,
		ul :first-child {
			margin-top: 1rem;
		}

		li {
			margin: 0.5rem 0rem;
			line-height: 1.5rem;
		}

		p.image-caption {
			display: inline-block;
			width: 100%;
		}

		.markdown.snippet p {
			margin-top: 1rem;
			margin-bottom: 1rem;
			display: inline-block;
		}

		/* nested paragraph tag in list*/
		li > p {
			display: inline;
		}
		/* nested list in list*/
		li > ul {
			margin-top: 0px;
			padding-left: 2rem;
		}

		ol > li {
			margin: 0.5rem 0rem;
			line-height: 1.5rem;
		}

		h2 + ul li {
			margin-top: 0rem;
		}

		h2 + ol li {
			margin-top: 0rem;
		}

		ol ol {
			padding-left: 2rem;
		}

		ul li {
			padding-left: 1rem;
		}

		ol li {
			padding-left: 1rem;
		}

		ol li p {
			padding-left: 0rem;
		}

		ul li p {
			padding-left: 0rem;
		}

		img {
			padding-left: 1rem;
			width: auto;
			height: auto;
			max-width: 100%;
			margin-left: auto;
			margin-right: auto;
			cursor: zoom-in;
		}

		.image-modal img {
			cursor: zoom-out;
		}

		.image {
			margin-left: auto;
			margin-right: auto;
			max-width: 700px;
			margin-top: 3rem;
			margin-bottom: 3rem;
		}

		.image-caption {
			padding-left: 0;
			margin-left: 1rem;
			font-style: italic;
			font-size: 0.9rem;
			text-align: center;
			margin-top: 0.5rem;
			color: #7e7e7e;
			font-weight: 500;
		}

		.blue-border {
			border: 1px solid #2ca8df;
		}
		.gray-background {
			background-color: #dde4fb;
		}
		.margin-left {
			margin-left: 1rem;
		}

		.margin-right {
			margin-right: 1rem;
		}

		.question-margin-bottom {
			margin-bottom: 10px;
		}

		p.question-margin-bottom {
			display: inline-block;
			padding-left: 1rem;
		}

		.choices-block {
			display: flex;
			flex-direction: column;
			padding: 1rem;
			justify-content: space-between;
		}

		.b-radio.radio {
			margin-left: 0;
			line-height: 1.5rem;
		}

		div + div {
			margin-top: 1rem;
		}

		.tooltip-trigger {
			margin-top: 0rem;
		}

		a {
			color: #0000ee;
			text-decoration: underline;
		}
		a:visited {
			color: purple;
		}

		.validity-icon {
			margin-top: 0px;
		}

		table,
		th,
		td {
			border: 1px solid gray;
		}
		table {
			width: 100%;
			margin: 1rem -1rem;
			// margin-bottom: 1rem;
			// margin-left: -1rem;
		}

		table tr:nth-child(2n) {
			background-color: #f6f8fa;
		}

		blockquote {
			background-color: #f1f1f1;
			border-left: 10px solid #ccc;
			margin: 2rem;
			margin-left: 1rem;
			padding: 1rem;
			word-break: break-word;
		}

		blockquote p {
			display: inline;
			padding-left: 0rem;
			// padding: 0.5rem;
		}

		iframe {
			width: 100%;
			height: 100%;
		}

		td,
		th {
			padding: 0.5rem;
		}

		//https://www.rgagnon.com/jsdetails/js-nice-effect-the-KBD-tag.html
		kbd {
			margin: 0px 0.1em;
			padding: 0.1em 0.6em;
			border-radius: 3px;
			border: 1px solid rgb(204, 204, 204);
			color: rgb(51, 51, 51);
			line-height: 1.4;
			font-family: Arial, Helvetica, sans-serif;
			font-size: 10px;
			display: inline-block;
			box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.2), inset 0px 0px 0px 2px #ffffff;
			background-color: rgb(247, 247, 247);
			-moz-box-shadow: 0 1px 0px rgba(0, 0, 0, 0.2), 0 0 0 2px #ffffff inset;
			-webkit-box-shadow: 0 1px 0px rgba(0, 0, 0, 0.2), 0 0 0 2px #ffffff inset;
			-moz-border-radius: 3px;
			-webkit-border-radius: 3px;
			text-shadow: 0 1px 0 #fff;
		}

		//https://developer.mozilla.org/en-US/docs/Web/HTML/Element/kbd
		// kbd {
		//     background-color: #eee;
		//     border-radius: 3px;
		//     border: 1px solid #b4b4b4;
		//     box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2),
		//         0 2px 0 0 rgba(255, 255, 255, 0.7) inset;
		//     color: #333;
		//     display: inline-block;
		//     font-size: 0.85em;
		//     font-weight: 700;
		//     line-height: 1;
		//     padding: 2px 4px;
		//     white-space: nowrap;
		// }
	}
}

#app .quiz-lab {
	overflow-y: hidden;
	height: auto;
}

#app .quiz-height {
	overflow-y: scroll;
	height: calc(100% - 4rem);
}
</style>
