<template>
	<div class="gradebook-page">
		<table v-if="!generating && (modules && modules[0])">
			<thead>
				<!-- jump to chapter -->
				<tr>
					<th style="vertical-align:middle; background-color:#555;height:3rem;padding:1rem; ">
						<div class="flex align-center">
							<div
								style="min-width:14rem;color:lightgray;text-transform:uppercase;text-align:right;padding-right:1rem;margin-right:1rem;"
							>
								Jump to Module:
							</div>
						</div>
					</th>
					<th :colspan="modules[0].span + modules[1].span" style="background-color:#555;height:3rem;padding:1rem;">
						<b-select
							placeholder="Select a module"
							:loading="generating"
							v-model="selectedModule"
							@input="setModuleIndex()"
						>
							<option v-for="(module, j) in sortedModules" :value="j" :key="module.es_moduleid">
								{{ `${j + 1}. ${module.es_name}` }}
							</option>
						</b-select>
					</th>
				</tr>

				<!-- student, module names, and nav buttons -->
				<tr>
					<th class="module-title">Student</th>
					<th class="module-title module-title-border" v-for="(mod, i) in modules" :key="i + mod.field" :colspan="mod.span">
						<div v-if="i === 0" class="flex align-center">
							<b-button size="is-small" type="is-dark" @click="backTwoModules" :disabled="currentIdx === 0">
								<vue-fontawesome :icon="['fas', 'arrow-left']" size="sm" />
							</b-button>

							<span class="ml-md">{{ currentIdx + 1 + ". " + mod.label }}</span>
						</div>
						<div v-else class="flex space-between align-center">
							<span class="ml-md">{{ currentIdx + 2 + ". " + mod.label }}</span>

							<b-button
								class="ml-md"
								size="is-small"
								type="is-dark"
								@click="forwardTwoModules"
								:disabled="currentIdx >= allModules.length - 2"
							>
								<vue-fontawesome :icon="['fas', 'arrow-right']" size="sm" />
							</b-button>
						</div>
					</th>
				</tr>
				<!-- topic numbers   -->
				<tr>
					<th
						v-for="(col, i) in columns"
						:key="'col' + i"
						scope="col"
						:class="col.count === 1 ? 'left-space px-md sticky-topic-num' : 'px-md sticky-topic-num'"
					>
						<div v-if="col.label != 'Student Name'" class="topic-num">
							<!-- <span>{{ col.label.substring(0, 15) + "..." }}</span> -->
							<b-tooltip :label="col.label" position="is-bottom">
								<kbd>{{ col.count }}</kbd>
							</b-tooltip>
						</div>
					</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(entry, i) in data" :key="entry.enrollment + '-' + i">
					<!-- names -->
					<td class="student-name">{{ entry.fullname }}</td>
					<!-- grades -->
					<td
						v-for="(topic, i) in topics"
						:key="entry.enrollment + topic.topicid + i"
						:class="topic.count === 1 ? 'left-space px-md grades' : 'px-md grades'"
					>
						<!-- content, lab  -->
						<div
							v-if="['Content', 'Lab'].includes(topic.type)"
							class="flex justify-center"
							@click="openTopicModal(entry, topic)"
						>
							<img
								v-if="entry[topic.topicid] && entry[topic.topicid].some(attempt => attempt['score'] === 1)"
								:src="check"
								class="topic-icon pointer"
							/>
							<div v-else class="grade-square empty pointer">-</div>
						</div>
						<!-- assessment  -->
						<div v-else-if="topic.type === 'Assessment'" class="centered-flex">
							<div
								v-if="entry[topic.topicid]"
								class="clickable-grade flex align-center pointer"
								@click="openAssessmentModal(entry, topic)"
							>
								<div class="w-3" :class="hasEdit ? 'pr-sm' : null">
									{{
										entry[topic.topicid]
											? Math.floor(entry[topic.topicid][0].score * 100) + "%"
											: null
									}}
								</div>

								<vue-fontawesome class="hover" icon="edit" v-if="hasEdit" />
							</div>

							<div v-else class=" grade-square empty pointer" @click="openAssessmentModal(entry, topic)">
								-
							</div>
						</div>
						<!-- quiz  -->
						<div v-else-if="topic.type === 'Quiz'" class="centered-flex">
							<div
								v-if="entry[topic.topicid]"
								class="clickable-grade flex align-center pointer"
								@click="openQuizModal(entry, topic)"
							>
								<div class="w-3" :class="hasEdit ? 'pr-sm' : null">
									{{
										entry[topic.topicid]
											? Math.floor(entry[topic.topicid][0].score * 100) + "%"
											: null
									}}
								</div>

								<vue-fontawesome v-if="hasEdit" class="hover" icon="edit" />
							</div>

							<div v-else class=" grade-square empty pointer" @click="openQuizModal(entry, topic)">
								-
							</div>
						</div>
						<!-- final lab  -->
						<div v-else-if="topic.type === 'Final Lab'" class="centered-flex">
							<!-- attempts and submitted  -->
							<div
								v-if="entry[topic.topicid] && entry[topic.topicid].find(a => a.issubmitted)"
								class="clickable-grade flex align-center pointer"
								@click="openFinalLabModal(entry, topic)"
							>
								<div class="w-3" :class="hasEdit ? 'pr-sm' : null">
									{{
										entry[topic.topicid]
											? Math.floor(entry[topic.topicid][0].score * 100) + "%"
											: null
									}}
								</div>
								<vue-fontawesome v-if="hasEdit" class="hover" icon="edit" />
							</div>

							<!-- no attempts  -->
							<!-- <div v-if="!entry[topic.topicid]" class="grade-square empty "> -->
							<div v-else class="grade-square empty ">
								-
							</div>

							<!-- attempts and downloadables  -->
						</div>

						<!-- other  -->
						<div v-else class="centered-flex">
							<div class="grade-square empty pointer">-</div>
						</div>
					</td>
				</tr>
				<!-- pagination -->
				<tr>
					<td colspan="100%" style="padding:.5rem;border-right:0;">
						<div style="display:flex;align-items:center;justify-content:flex-end;">
							<b-button @click="prevPage" icon-left="chevron-left" icon-pack="fa" :disabled="currentRosterPage === 0">
								Prev
							</b-button>
							<div class="px-md" style="width:14.25rem;">
								{{ currentRosterPage * 50 + 1 }} -
								{{ currentRosterPage * 50 + 50 > rosterCount ? rosterCount : currentRosterPage * 50 + 50 }} of
								{{ rosterCount }} enrollments
							</div>

							<b-button
								@click="nextPage"
								icon-right="chevron-right"
								icon-pack="fa"
								:disabled="currentRosterPage * 50 + 50 > rosterCount"
							>
								Next
							</b-button>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
		<Loader v-else />

		<b-modal v-model="quizModalActive">
			<ResetQuizModal v-on:closeModal="closeModal" />
		</b-modal>
		<b-modal v-model="assessmentModalActive">
			<AssessmentModal v-on:closeModal="closeModal" />
		</b-modal>
		<b-modal v-model="topicModalActive">
			<TopicModal v-on:closeModal="closeModal" />
		</b-modal>
		<b-modal v-model="finalLabModalActive">
			<FinalLabModal v-on:closeModal="closeModal" />
		</b-modal>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import Loader from "../components/Loader.vue"
import router from "../router/router.js"
import moment from "moment"
import check from "../assets/check.png"
import ResetQuizModal from "./ResetQuizModal.vue"
import AssessmentModal from "./AssessmentModal.vue"
import TopicModal from "./TopicModal.vue"
import FinalLabModal from "./FinalLabModal.vue"

export default {
	name: "Gradebook",
	components: {
		Loader,
		ResetQuizModal,
		AssessmentModal,
		TopicModal,
		FinalLabModal
	},
	computed: {
		...mapGetters([
			"enrollmentRoles",
			"currentCohort",
			"cohortGradesLoading",
			"cohortGrades",
			"allModules",
			"allTopics",
			"gradebookCurrentAttempts",
			"gradebookModalMode",
			"allModules",
			"allClassmates",
			"nextRosterPage",
			"currentRosterPage",
			"rosterCount"
		]),
		hasEdit: function() {
			return this.enrollmentRoles[0]["es_role1.es_name"] == "Teaching Assistant"
		},
		data: function() {
			if (this.cohortGrades && this.allClassmates) {
				this.makeColumnsFromCurrentCohort()
				return this.makeChartData()
			} else if (this.allClassmates) {
				return this.makeChartData()
			}
		}
	},
	data() {
		return {
			// data: null,
			check: check,
			columns: null,
			modalMode: null,
			currentIdx: 0,
			newData: null,
			topics: null,
			generating: false,
			modules: null,
			assessmentModalActive: false,
			quizModalActive: false,
			topicModalActive: false,
			finalLabModalActive: false,
			currentAttempt: null,
			sortedModules: null,
			selectedModule: null
		}
	},
	watch: {
		cohortGrades: {
			deep: true,
			handler() {
				// this.makeChartData()
				this.makeColumnsFromCurrentCohort()
				this.updateCurrentAttempts()
				this.generating = false
			}
		},
		enrollmentRoles: function() {},
		currentCohort: function() {
			if (!this.cohortGrades && this.currentCohort) {
				this.cohortGradesCall()
			}
		},
		currentIdx: function() {
			this.selectedModule = this.currentIdx
		},
		allClassmates: function() {
			this.makeChartData()
		}
	},
	mounted() {
		this.changeCurrentPage("Gradebook")
		this.generating = true
		this.getRoster(this.$route.params.cohortid)

		//load cohort if missing
		if (this.$route.params.cohortid && (!this.currentCohort || this.$route.params.cohortid != this.currentCohort.es_cohortid)) {
			this.fetchCohortData({
				es_cohortid: this.$route.params.cohortid,
				preventNav: true
			})
		}

		if (!this.cohortGrades && this.currentCohort) {
			this.cohortGradesCall()
		} else if (this.cohortGrades && this.cohortGrades[0] && this.cohortGrades[0].es_cohortid != this.currentCohort.es_cohortid) {
			this.cohortGradesCall()
		}
	},
	methods: {
		...mapActions([
			"getCohortGrades",
			"changeCurrentPage",
			"fetchCohortData",
			"setGradebookModalActive",
			"setAssessmentModalActive",
			"setFinalLabModalLoading",
			"setGradebookCurrentAttempts",
			"getRoster",
			"getNextRosterPage",
			"getPreviousRosterPage",
			"checkForAttemptFiles"
		]),
		formatDate(date) {
			if (!date) {
				return null
			}
			let formattedDate = moment(date)
				.startOf("day")
				.format("MMM/D/YYYY")
			if (formattedDate) {
				return formattedDate
			}
			return null
		},
		makeColumnsFromCurrentCohort() {
			let columns = []
			let modules = []
			let topics = []
			let sortedMods = []

			columns.push({
				field: "fullname",
				label: "Student Name",
				type: "name",
				sticky: true
			})

			this.currentCohort.modules.forEach((mdl, idx) => {
				sortedMods.push(mdl)
				if (this.currentIdx === idx || this.currentIdx + 1 === idx) {
					modules.push({
						field: `${mdl.es_moduleid}`,
						label: `${mdl.es_name}`,
						type: "module",
						span: mdl.topics.length
					})
					let count = 0

					mdl.topics.forEach(topic => {
						count++
						topics.push({
							topicid: topic.es_topicid,
							count,
							type: this.convertType(topic.es_type),
							name: topic.es_name,
							module: mdl.es_name
						})
						columns.push({
							field: `${topic.es_topicid}`,
							label: `${topic.es_name}`,
							type: this.convertType(topic.es_type),
							count
						})
					})
				}
			})

			this.modules = modules
			this.columns = columns
			this.topics = topics
			this.sortedModules = sortedMods
		},
		convertType(int) {
			let typeDict = {
				"717580000": "Content",
				"717580001": "Lab",
				"717580002": "Quiz",
				"717580003": "Assessment",
				"717580004": "Final Lab",
				"717580005": "Final Quiz"
			}
			return typeDict[int]
		},
		makeChartData() {
			let topics = []
			let attempts = []

			let contactRows = {}

			// make contactRows
			this.allClassmates.forEach(mate => {
				if (!mate.es_isstaff) {
					contactRows[mate.enrollmentid] = {
						fullname: mate.fullname,
						enrollment: mate.enrollmentid
					}
				}
			})

			if (this.cohortGrades) {
				this.cohortGrades.forEach(attempt => {
					let currentContact = contactRows[attempt.es_enrollmentid]

					// if contact not already in rows, add the contact
					if (currentContact) {
						if (!currentContact[attempt.es_topicid]) {
							// contact already has a row but row doesn't have topic attempt, set it
							currentContact[attempt.es_topicid] = [
								{
									id: attempt.es_attemptid,
									score: attempt.es_score,
									enrollment: attempt.es_enrollmentid,
									topic: attempt.es_topicid,
									status: attempt.es_attemptstatus,
									issubmitted: attempt.es_issubmitted,
									timeend: attempt.es_timeend
								}
							]
						} else if (currentContact[attempt.es_topicid]) {
							// add an attempt to the contact's topic attempt array
							currentContact[attempt.es_topicid].push({
								id: attempt.es_attemptid,
								score: attempt.es_score,
								enrollment: attempt.es_enrollmentid,
								topic: attempt.es_topicid,
								status: attempt.es_attemptstatus,
								issubmitted: attempt.es_issubmitted,
								timeend: attempt.es_timeend
							})
							// sort the array
							currentContact[attempt.es_topicid].sort((a, b) => b.score - a.score)
						}
					}
				})
			}

			// convert to array
			let enrollmentKeys = Object.keys(contactRows)
			let chartRows = []

			enrollmentKeys.forEach(key => {
				chartRows.push(contactRows[key])
			})

			// save data to chart
			this.generating = false
			return chartRows
		},
		backTwoModules() {
			this.generating = true
			if (this.currentIdx >= 2) {
				this.currentIdx -= 2
			} else if (this.currentIdx === 1) {
				this.currentIdx = 0
			}

			this.cohortGradesCall()
		},
		forwardTwoModules() {
			this.generating = true
			if (this.currentIdx < this.allModules.length - 3) {
				this.currentIdx += 2
			} else if (this.currentIdx < this.allModules.length - 2) {
				this.currentIdx += 1
			}

			this.cohortGradesCall()
		},
		setModuleIndex() {
			let i = this.selectedModule
			if (i === this.allModules.length - 1) {
				this.currentIdx = i - 1
			} else {
				this.currentIdx = i
			}
			this.cohortGradesCall()
		},
		cohortGradesCall() {
			this.generating = true
			this.getCohortGrades({
				cohortid: this.currentCohort.es_cohortid,
				moduleids: [
					this.currentCohort.modules[this.currentIdx].es_moduleid,
					this.currentCohort.modules[this.currentIdx + 1].es_moduleid
				]
			})
		},
		openAssessmentModal(entry, topic) {
			if (this.hasEdit) {
				this.setGradebookCurrentAttempts({
					attempt: entry[topic.topicid],
					topic,
					fullname: entry.fullname,
					enrollment: entry.enrollment
				})
				this.assessmentModalActive = true
			} else {
				this.openTopicModal(entry, topic)
			}
		},
		openFinalLabModal(entry, topic) {
			if (this.hasEdit && entry[topic.topicid] && entry[topic.topicid].length > 0) {
				this.setGradebookCurrentAttempts({
					attempt: entry[topic.topicid],
					topic,
					fullname: entry.fullname,
					enrollment: entry.enrollment
				})
				this.setFinalLabModalLoading(true)
				this.checkForAttemptFiles({ enrollmentid: entry.enrollment, topicid: topic.topicid })
				this.finalLabModalActive = true
			} else {
				this.openTopicModal(entry, topic)
			}
		},
		openQuizModal(entry, topic) {
			if (this.hasEdit && entry[topic.topicid] && entry[topic.topicid].length > 0) {
				this.setGradebookCurrentAttempts({
					attempt: entry[topic.topicid],
					topic,
					fullname: entry.fullname,
					enrollment: entry.enrollment
				})
				this.quizModalActive = true
			} else {
				this.openTopicModal(entry, topic)
			}
		},
		openTopicModal(entry, topic) {
			this.setGradebookCurrentAttempts({
				attempt: entry[topic.topicid],
				topic,
				fullname: entry.fullname,
				enrollment: entry.enrollment
			})
			this.topicModalActive = true
		},
		updateCurrentAttempts() {
			if (this.assessmentModalActive) {
				this.updateAssessment()
			}
		},
		updateAssessment() {
			if (this.gradebookCurrentAttempts.attempt) {
				let updatedVal = this.cohortGrades.find(grade => {
					return grade.es_attemptid === this.gradebookCurrentAttempts.attempt[0].id
				})
				let newStoreVal = this.gradebookCurrentAttempts
				newStoreVal.attempt[0].score = updatedVal.es_score
				this.setGradebookCurrentAttempts(newStoreVal)
			} else {
				let updatedVal = this.cohortGrades.find(grade => {
					return (
						grade.es_topicid === this.gradebookCurrentAttempts.topic.topicid &&
						grade.es_enrollmentid === this.gradebookCurrentAttempts.enrollment
					)
				})

				let newStoreVal = this.gradebookCurrentAttempts
				let newAttempt = {
					enrollment: updatedVal.es_enrollmentid,
					id: updatedVal.es_attemptid,
					score: updatedVal.es_score,
					topic: updatedVal.es_topicid
				}

				newStoreVal.attempt = [newAttempt]
				this.setGradebookCurrentAttempts(newStoreVal)
			}
		},
		closeModal() {
			this.quizModalActive = false
			this.assessmentModalActive = false
			this.topicModalActive = false
			this.finalLabModalActive = false
		},
		nextPage() {
			this.getNextRosterPage(this.nextRosterPage)
		},
		prevPage() {
			this.getPreviousRosterPage()
		}
	}
}
</script>

<style lang="scss">
#app .gradebook-page {
	overflow-y: auto;

	.button .icon.is-small {
		height: 1rem;
		width: 1rem;
	}

	table {
		margin: 1rem 2rem;
		height: 100%;
		border-collapse: separate;
		border: 1px solid gray;
	}

	table td:not([align]) {
		// border: 1px solid #686868;
		text-align: center;
	}

	th .table td {
		padding: 1rem;
		position: relative;
		vertical-align: middle;

		div {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.quiz-cell-container,
	.assessment-container {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 3rem;
	}

	.module-title {
		height: 3rem;
		vertical-align: middle;
		font-weight: bold;
		padding: 1rem;
		text-transform: uppercase;
		background-color: darkgray;
		text-shadow: 0px 1px 2px white;
	}

	th.module-title-border {
		border: 1px solid #666666;
		border-style: none none none solid;
	}

	tr:nth-child(even) {
		background-color: #f2f2f2;
	}

	tr:nth-child(odd) {
		background-color: white;
	}

	td:first-child {
		background-color: inherit;
	}

	// td.grade {
	// 	width: 2rem;
	// }

	th .topic-num {
		text-align: center;
		font-weight: bold;
		min-width: 2.5rem;
	}

	th.sticky-topic-num {
		height: 3rem;
		vertical-align: middle;
	}

	.px-md {
		padding: 0rem 1rem;
	}

	.left-space {
		padding-left: 3rem;
	}

	td.left-space,
	th.left-space {
		padding-left: 3.5rem;
	}

	.left-space:first-child {
		padding-left: 1rem;
	}

	// th.left-space {
	// 	border-left: solid 1px darkgray;
	// }

	th:first-child {
		border-right: solid 1px #666666;
	}

	th:nth-child(2) {
		border-left: none;
	}

	.sticky-topic-num {
		top: 56px;
		background-color: lightgray;
		z-index: 3;
	}

	.ml-md {
		margin-left: 1rem;
	}

	.flex {
		display: flex;
	}

	.space-between {
		justify-content: space-between;
	}

	.align-center {
		align-items: center;
	}

	.justify-center {
		justify-content: center;
	}

	td:last-child,
	th:last-child {
		padding-right: 4rem;
	}

	th:last-child.module-title {
		padding-right: 1rem;
	}

	td:first-child {
		border-right: 1px solid #666666;
	}

	table td:not([align]).student-name {
		min-width: 15rem;
		text-align: left;
		padding: 1rem 0 1rem 1rem;
	}

	.grades {
		vertical-align: middle;
	}

	.grade-square {
		height: 1.5rem;
		width: 1.6rem;
		border-radius: 0.25rem;
	}

	.grade-square.full {
		background-color: #417eab;
	}

	.grade-square.empty {
		background-color: #e1e1e1;
	}

	.module-title span.ml-md {
		margin-left: 3rem;
	}

	.clickable-grade {
		min-height: 1.5rem;
		min-width: 1.6rem;
	}

	.pointer {
		cursor: pointer;
	}

	.centered-flex {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.pr-sm {
		padding-right: 0.5rem;
	}

	.w-3 {
		width: 3rem;
	}

	.modal .modal-content {
		width: unset;
	}

	.px-md {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	// sticky stuff
	thead {
		position: sticky;
		top: 0;
		z-index: 40;
	}

	th:first-child {
		position: sticky;
		left: 0;
		z-index: 1000;
	}

	td:first-child {
		position: sticky;
		left: 0;
		z-index: 1;
	}

	//kbd
	kbd {
		background-color: #eee;
		border-radius: 3px;
		border: 1px solid #b4b4b4;
		box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2), 0 2px 0 0 rgba(255, 255, 255, 0.7) inset;
		color: #333;
		display: inline-block;
		font-size: 0.85em;
		font-weight: 700;
		line-height: 1;
		padding: 2px 4px;
		white-space: nowrap;
		cursor: default;
	}

	//tooltips
	.b-tooltip.is-bottom.is-primary .tooltip-content::before {
		border-right: 10px solid transparent;
		border-bottom: 10px solid #3273dc;
		border-left: 10px solid transparent;
	}

	.b-tooltip.is-bottom .tooltip-content {
		top: calc(100% + 10px + 2px);
	}
}
</style>
