<template>
    <div v-if="mondays" class="chart-two columns is-mobile" id="scrollable-chart">
        <div class="for-scroll column hundo-height">
            <!---table heading-->
            <div class="schedule-heading white z-20 sticky-top border-top border-bottom">
                <div class="row">
                    <div
                        class="row-heading empty-row border-right border-left sticky-left white z-20"
                    ></div>
                    <div
                        v-for="(monday,index) in mondays"
                        :key="'week'+index"
                        class="row-column row second-column-margin white"
                    >
                        <div
                            class="condensed week-column border-right underline-text font-size-four-fifths semi-bold flex-center justify-center"
                        >{{monday}}</div>
                    </div>
                </div>

                <div class="row">
                    <div
                        class="row-heading module-cell border-right border-left sticky-left white z-20"
                    >
                        <b-button
                            size="is-small"
                            type="is-info"
                            class="expand-icon margin-left-auto margin-right-2"
                            @click="handleClick()"
                        >
                            <span class="flex-center flex-row">
                                <vue-fontawesome
                                    icon="arrows-alt-v"
                                    class="margin-right-one-third"
                                />
                                <p>{{collapsed? 'Expand all' : 'Collapse all'}}</p>
                            </span>
                        </b-button>
                        <!-- <b-button
                            size="is-small"
                            type="is-info"
                            class="module-name margin-left-auto margin-right-auto"
                            @click="handleTodayClick()"
                        >Today</b-button>
                        <p
                            class="progress-fraction underline-text align-center font-size-four-fifths semi-bold"
                        >Progress</p>-->
                    </div>
                    <div
                        v-for="(monday,index) in mondays"
                        :key="'weekdays'+index"
                        class="row-column row border-right white"
                    >
                        <div class="day-column day font-size-four-fifths semi-bold">M</div>
                        <div class="day-column day font-size-four-fifths semi-bold">T</div>
                        <div class="day-column day font-size-four-fifths semi-bold">W</div>
                        <div class="day-column day font-size-four-fifths semi-bold">Th</div>
                        <div class="day-column day font-size-four-fifths semi-bold">F</div>
                        <div class="day-column day font-size-four-fifths semi-bold">Sa</div>
                        <div class="day-column day font-size-four-fifths semi-bold">Su</div>
                    </div>
                </div>

                <!-- <div>
                    <div class="row empty-row">
                        <div class="row-heading empty-row border-right border-bottom"></div>
                        <div
                            v-for="(monday,index) in mondays"
                            :key="'empty'+index"
                            class="row-column row border-right border-bottom"
                        >
                            <div class="day-column"></div>
                            <div class="day-column"></div>
                            <div class="day-column"></div>
                            <div class="day-column"></div>
                            <div class="day-column"></div>
                            <div class="day-column"></div>
                            <div class="day-column"></div>
                        </div>
                    </div>
                </div>-->
            </div>

            <!---table body-->

            <div class="schedule-body" v-if="dates">
                <sessions-row :mondays="mondays" :dates="dates" />
                <module-rows
                    v-for="(module,index) in currentCohort.modules"
                    :key="module.es_moduleid+'rows'+index"
                    :mondays="mondays"
                    :dates="dates"
                    :row="index"
                    :moduleName="module.es_name"
                    :module="module"
                    :completedAttempts="completedAttempts"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import SessionsRow from "../components/SessionsRow.vue";
import EmptyRow from "../components/EmptyRow.vue";
import ModuleRows from "../components/ModuleRows.vue";
import Loader from "./Loader.vue";
import EventBus from "../event-bus";

export default {
    name: "GanttChart",
    components: { SessionsRow, EmptyRow, ModuleRows, Loader },
    data() {
        return {
            isVisible: false,
            mondays: null,
            moment: moment,
            dates: null,
            rowsBuilt: false,
            rows: 0,
            collapsed: true
        };
    },
    computed: {
        ...mapGetters([
            "attempts",
            "currentSessions",
            "currentCohort",
            "allTopics"
        ]),
        numberOfTopics: function() {
            return this.allTopics.filter(
                topic =>
                    topic.es_module._es_cohort_value ==
                    this.currentCohort.es_cohortid
            ).length;
        },
        completedAttempts: function() {
            let contactAttempts = this.attempts;

            let completedAttempts = contactAttempts.filter(attempt => {
                return attempt.es_issubmitted;
            });
            return completedAttempts;
        }
    },
    created() {
        this.generateMondays();
        EventBus.$on("rows-built", data => {
            this.rows++;
            if (this.rows === this.numberOfTopics) {
                this.rowsBuilt = true;
            }
        });
    },
    watch: {
        currentCohort: function() {
            this.generateMondays();
        },
        mondays: function() {
            this.dates = this.makeDateArray();
        }
    },
    methods: {
        makeArrayOfDates() {
            let sessionDates = [];
            let topicDates = [];

            this.currentSessions.forEach(session => {
                if (
                    session._es_cohort_value != this.currentCohort.es_cohortid
                ) {
                    //wrong cohort
                } else {
                    //add sessions that are from this cohort
                    if (session.es_startdatetime) {
                        sessionDates.push(session.es_startdatetime);
                    }
                    if (session.es_enddatetime) {
                        sessionDates.push(session.es_enddatetime);
                    }
                }
            });

            this.currentCohort.modules.forEach(module => {
                module.topics.forEach(topic => {
                    if (topic.es_duedate) {
                        topicDates.push(topic.es_duedate);
                    }
                    if (topic.es_enddate) {
                        topicDates.push(topic.es_enddate);
                    }
                    if (topic.es_startdate) {
                        topicDates.push(topic.es_startdate);
                    }
                });
            });
            let allDates = topicDates.concat(sessionDates);
            let moments = allDates.map(d => moment(d));
            return moments;
        },
        findMaxDate(dates) {
            return moment.max(dates);
        },
        findMinDate(dates) {
            return moment.min(dates);
        },
        findNumberOfWeeks(min, max) {
            return max.diff(min, "week");
        },
        findStartMonday(minDate) {
            let startDow = minDate.day();
            return minDate.subtract(startDow - 1, "d").format("MMM D, YYYY");
        },
        findEndMonday(maxDate) {
            let endDow = maxDate.day();
            return maxDate.subtract(endDow - 1, "d").format("MMM D, YYYY");
        },
        generateMondays(max, min) {
            let moments = this.makeArrayOfDates();
            let maxDate = this.findMaxDate(moments);
            let minDate = this.findMinDate(moments);
            let numberOfWeeks = this.findNumberOfWeeks(minDate, maxDate);
            let startMonday = this.findStartMonday(minDate);
            // let endMonday = this.findEndMonday(maxDate);
            let mondays = [startMonday];

            for (let i = 0; i <= numberOfWeeks; i++) {
                mondays.push(
                    moment(mondays[i], "MMM D, YYYY")
                        .add(7, "d")
                        .format("MMM D, YYYY")
                );
            }
            this.mondays = mondays;
        },
        handleClick() {
            if (this.collapsed) {
                EventBus.$emit("expand-chart");
            } else {
                EventBus.$emit("collapse-chart");
            }

            this.collapsed = !this.collapsed;
        },
        handleTodayClick() {
            let el = document.querySelector(
                `[data-date='${moment().format("MMM D, YYYY")}']`
            );
            el.scrollIntoView();
        },
        makeDateArray() {
            let dates = [];

            let startMonday = this.mondays[0];
            let endMonday = this.mondays[this.mondays.length - 1];

            var currDate = moment(startMonday, "MMM D, YYYY")
                .add(-1, "d")
                .startOf("day");
            var lastDate = moment(endMonday, "MMM D, YYYY")
                .add(7, "d")
                .startOf("day");

            while (currDate.add(1, "days").diff(lastDate) < 0) {
                dates.push(currDate.clone().toDate());
            }
            return dates;
        }
    }
};
</script>

<style lang="scss">
.chart-two.columns {
    margin: 1.5rem !important;
    max-width: calc(100% - 3rem);
    max-height: calc(100vh - 3rem - 58px);

    div {
        z-index: 0;
    }

    .sticky-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 10;
    }
    .sticky-left {
        position: -webkit-sticky;
        position: sticky;
        left: 0;
        z-index: 10;
    }
    .z-20 {
        z-index: 20;
    }
}

#app .chart-two {
    box-sizing: border-box !important;
    overflow-x: scroll;
    line-height: 2.5rem;

    .margin-left-auto {
        margin-left: auto !important;
    }

    .margin-right-auto {
        margin-right: auto !important;
    }

    .column {
        padding: 0px !important;
    }

    .underline-text {
        text-decoration: underline;
    }

    .has-borders {
        border-width: 1px;
        border-color: #b7b7b7;
        border-style: solid none none solid;
    }

    div {
        flex: 1 1 1;
    }

    .row {
        display: flex;
    }

    .row-heading {
        width: 370px;
    }

    .row-heading.columns {
        margin: 0 !important;
    }

    .light-gray {
        background-color: #d8d8d8;
    }

    .white {
        background-color: white;
    }

    .day-column {
        width: 20px;
        height: 2.5rem;
        text-align: center;
    }

    .day-column.has-due {
        display: flex;
        justify-content: center;
    }

    .full {
        background-color: #447ab1;
        margin: 4px 0px;
        z-index: 10;
        width: 22px;
        height: 32px;
    }

    .round-start {
        border-radius: 5px 0px 0px 5px !important;
    }

    .round-end {
        border-radius: 0px 5px 5px 0px !important;
    }

    .round {
        border-radius: 5px 5px 5px 5px !important;
        width: 20px;
    }

    .due-dot {
        background-color: #c01010;
        height: 10px;
        width: 10px;
        border-radius: 5px;
        margin: auto;
        margin-top: 11px;
        z-index: 10;
    }

    .due-dot.just-due {
        margin-top: 15px;
        margin-left: 5px;
    }

    .week-column {
        width: 141px;
        text-align: center;
    }

    .topic-title {
        background-color: white;
        font-weight: 400 !important;
        // height: 29px;
    }

    .empty {
        width: 100%;
    }

    .empty-row {
        height: 2.5rem;
        background-color: white;
    }

    .module-cell {
        display: flex;
        justify-content: space-between;
    }

    .white {
        background-color: white;
    }

    .day {
        text-decoration: underline;
    }

    .border-right {
        border-right: 1px solid #b7b7b7;
    }

    .border-bottom {
        border-bottom: 1px solid #b7b7b7;
    }
    .border-left {
        border-left: 1px solid #b7b7b7;
    }
    .border-top {
        border-top: 1px solid #b7b7b7;
    }
    .progress-fraction {
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important;
    }

    .align-center {
        align-self: center;
    }

    .flex-center {
        display: flex;
        align-items: center;
    }

    .flex-row {
        flex-direction: row;
    }

    .margin-right-2 {
        margin-right: auto;
    }

    .margin-right-one-third {
        margin-right: 0.5rem;
    }

    .font-size-four-fifths {
        font-size: 0.8rem;
    }

    .condensed {
        font-family: "Roboto Condensed", sans-serif;
    }

    .font-size-nine-tenths {
        font-size: 0.9rem;
    }

    .semi-bold {
        font-weight: 500;
    }

    .padding-left-one-third {
        padding-left: 0.5rem !important;
    }

    .padding-right-one-third {
        padding-right: 0.5rem !important;
    }

    .space-between {
        justify-content: space-between;
    }

    .justify-center {
        justify-content: center;
    }

    .relative {
        position: relative;
    }

    .absolute {
        position: absolute;
    }

    .overflow-ellipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .display-none {
        // display: none;
        // visibility: hidden !important;
        display: block;
        height: 0;
        width: 0;
        overflow: hidden;
    }

    .cursor-pointer {
        cursor: pointer;
    }

    .hidden {
        display: none;
    }

    .expand-icon {
        width: 102px;
    }

    .hundo-height {
        height: 100%;
    }
}
</style>
 
 