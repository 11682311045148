import axios from 'axios';
import {
    baseCyberLabUrlApi
} from "./../config/index";

const service = axios.create({
    baseURL: baseCyberLabUrlApi + "api/", // url = base url + request url
})

// Request interceptors
service.interceptors.request.use(
    config => {
        // Add X-Access-Token header to every request, you can add other custom headers here
        // if (getToken()) {
        //     config.headers["Authorization"] = "Bearer " + getToken();
        // }
        let decodedCookie = decodeURIComponent(document.cookie);
        let parts = decodedCookie.split(";");
        let cyberLabToken = parts.find(c => c.trim().startsWith("cyberlab="));
        config.headers["Authorization"] = "Bearer " + cyberLabToken.split("=")[1];
        return config;
    },
    error => {
        Promise.reject(error);
    }
);

export default service
