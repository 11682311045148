<template>
    <span class="assessment-page">
        <div class="padding-left padding-top max-width-850">
            <b-table v-if="data" :data="data" :columns="columns"></b-table>

            <!-- button when incomplete -->
            <span v-if="this.filteredAttempts && this.filteredAttempts.length <= 0">
                <b-tooltip
                    label="Topic will be completed by Instructor"
                    position="is-right"
                    type="is-info"
                    active
                >
                    <b-button class="margin-top-2" type="is-info" disabled>Mark Done</b-button>
                </b-tooltip>
            </span>

            <!-- button when completed -->
            <span v-else>
                <b-button class="margin-top-2" type="is-success" disabled>Done!</b-button>
            </span>
        </div>
    </span>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import QuizHistory from "./QuizHistory";
import EventBus from "../event-bus";
import moment from "moment";
import timezone from "moment-timezone";

export default {
    name: "AssessmentPage",
    components: { QuizHistory },
    props: [],
    computed: {
        ...mapGetters(["attempts", "currentTopic", "timeZone", "contact"]),
        filteredAttempts: function() {
            return this.attempts.filter(attempt => {
                return attempt._es_topic_value == this.currentTopic.es_topicid;
            });
        }
    },
    data() {
        return {
            data: null,
            columns: [
                {
                    field: "enrollment",
                    label: "Enrollment",
                    width: "300",
                    numeric: false
                },
                {
                    field: "date",
                    label: "Date",
                    width: "350"
                },
                {
                    field: "result",
                    label: "Result",
                    width: "200"
                }
            ]
        };
    },
    mounted() {
        this.data = this.dataBuilder();
    },
    watch: {
        attempts: function() {
            this.data = this.dataBuilder();
        }
    },
    methods: {
        dataBuilder() {
            let attempts = this.filteredAttempts;
            let data = [];

            attempts.forEach(attempt => {
                let date;
                if (attempt.es_timeend) {
                    date = attempt.es_timeend;
                } else if (attempt.es_timestart) {
                    //no enddate; ie submission wasn't finished
                    date = attempt.es_timestart;
                } else {
                    date = attempt.createdon;
                }

                //convert to timezone and format
                date = moment(date)
                    .tz(this.timeZone)
                    .format("MMMM Do YYYY, [at] h:mm a z");

                data.push({
                    enrollment: this.contact.emailaddress1,
                    date: date,
                    result:
                        attempt.es_score >= 0.7
                            ? "On Track"
                            : "Needs Improvement"
                });
            });
            if (data.length > 0) {
                data = this.sortTopicAttempts(data);
            } else {
                data.push({
                    enrollment: this.contact.emailaddress1,
                    date: "N/A",
                    score: "N/A"
                });
            }

            return data;
        },
        sortTopicAttempts(data) {
            return data.sort((a, b) => {
                return (
                    moment(b.date, "MMMM Do YYYY, [at] h:mm a z") -
                    moment(a.date, "MMMM Do YYYY, [at] h:mm a z")
                );
            });
        }
    }
};
</script>

<style scroped lang="scss">
#app .assessment-page {
    .max-width-850 {
        max-width: 850px;
    }

    .padding-left {
        padding-left: 2rem;
    }

    .padding-top {
        padding-top: 2rem;
    }

    #app button.margin-top-2 {
        margin-top: 2rem;
    }
}
</style>
