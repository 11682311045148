import request from "./request"

export const getFinalGrade = data =>
	request({
		url: `/finalgrade?enrollmentid=${data}`,
		method: "get"
	})

export const getCohortGrades = data =>
	request({
		url: `/gradebook?cohortid=${data.cohortid}&module1=${data.moduleids[0]}&module2=${data.moduleids[1]}`,
		method: "get"
	})
