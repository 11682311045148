<template>
  <div class="add-cohort-row-card">
    <div class="flex-row min-height-75-percent">
      <div class="width-28-percent min-width-15">
        <add-cohort-card-image :cohort="cohort" />
      </div>

      <div
        class="flex-column justify-center padding-left-3 v-padding-2 width-72-percent"
      >
        <div class="media" style="width:100%;">
          <p class="title is-4">{{ cohort.es_name }}</p>
        </div>

        <div class="buttons margin-top-2">
          <!-- add course button  -->
          <b-button
            v-if="
              cohort.es_maxenrollments === null ||
                cohort.enrollmentsCount < cohort.es_maxenrollments
            "
            :loading="enrollingCohort && enrollingCohort == cohort.es_cohortid"
            @click="addCourse(cohort)"
            class="width-10"
            type="is-info"
          >
            Add Course
          </b-button>

          <!-- disabled add course button  -->
          <b-button v-else disabled class="width-10" type="is-info">
            Add Course
          </b-button>

          <!-- waitlist button  -->
          <div
            v-if="
              cohort.es_maxenrollments &&
                cohort.enrollmentsCount >= cohort.es_maxenrollments
            "
          >
            <b-button
              v-if="!waitlistedCohorts.includes(cohort.es_cohortid)"
              :disabled="
                waitlistingCohort && waitlistingCohort == cohort.es_cohortid
              "
              :loading="
                waitlistingCohort && waitlistingCohort == cohort.es_cohortid
              "
              style="margin-left:1rem"
              class="width-10"
              @click="addWaitlist(cohort)"
              type="is-warning"
            >
              Join Waitlist
            </b-button>

            <b-tooltip v-else type="is-info" label="You are waitlisted">
              <b-button
                :disabled="true"
                :loading="
                  waitlistingCohort && waitlistingCohort == cohort.es_cohortid
                "
                class="width-10"
                style="margin-left:1rem"
                type="is-success"
              >
                <vue-fontawesome
                  :icon="['fas', 'check']"
                  size="sm"
                  color="green"
                />
              </b-button>
            </b-tooltip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import router from "../router/router.js"
import AddCohortCardImage from "../components/AddCohortCardImage.vue"

export default {
  name: "AddCohortRowCard",
  components: {
    AddCohortCardImage
  },
  props: ["cohort"],
  data() {
    return {}
  },
  computed: {
    ...mapGetters([
      "baseUrl",
      "currentTopic",
      "currentModule",
      "contact",
      "cohortsArray",
      "currentCohort",
      "freeCohorts",
      "contact",
      "enrollingCohort",
      "waitlistingCohort",
      "waitlistedCohorts"
    ])
  },
  created() {
    this.getWaitlistedCohorts()
  },
  methods: {
    ...mapActions([
      "changeCurrentPage",
      "changeCurrentCohort",
      "getFreeCohorts",
      "autoEnroll",
      "waitlist",
      "getWaitlistedCohorts"
    ]),
    addCourse(cohort) {
      if (
        cohort.es_maxenrollments === null ||
        cohort.enrollmentsCount < cohort.es_maxenrollments
      ) {
        this.autoEnroll({
          contact: this.contact,
          es_cohortid: cohort.es_cohortid,
          es_name: cohort.es_name
        })
      }
    },
    addWaitlist(cohort) {
      this.waitlist({
        contact: this.contact,
        cohortid: cohort.es_cohortid,
        es_name: cohort.es_name
      })
    }
  }
}
</script>

<style lang="scss">
#app .add-cohort-row-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .full-width {
    width: 100%;
    max-width: 90rem;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .margin-2 {
    margin: 2rem;
  }

  .flex-row {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
  }

  .width-25-percent {
    width: 25%;
  }

  .width-75-percent {
    width: 75%;
  }

  .min-width-15 {
    min-width: 15rem;
    max-width: 20rem;
  }

  .padding-1 {
    padding: 1rem;
  }

  .has-border {
    border: 1px solid lightgray;
    border-radius: 0.25rem;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  .shadow {
    box-shadow: 0rem 0.5rem 0.5rem rgba(0, 0, 0, 0.8);
    transition: box-shadow 0.15s ease-out;
  }

  .shadow:hover {
    box-shadow: 0rem 0.6rem 0.6rem rgba(0, 0, 0, 0.7);
    transition: box-shadow 0.15s ease-in; //, margin 150ms ease-in;
  }

  .white-background {
    background-color: white;
  }

  .flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .height-75-percent {
    height: 75%;
    min-height: 11.25rem;
    max-height: 15rem;
  }

  .width-10 {
    width: 10rem;
  }

  .centered {
    margin-top: auto;
    margin-bottom: auto;
  }

  .margin-top-2 {
    margin-top: 2rem;
  }

  .height-180 {
    height: 180px;
  }

  .height-75-hack {
    border-right: 1px solid lightgray;
    position: relative;
    width: 28%;
    padding-top: 21.28%;
    box-sizing: border-box;
  }

  .height-75-hack div {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .min-height-75-percent {
    min-height: 11.25rem;
    max-height: 15rem;
  }
}
</style>
