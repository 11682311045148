<template>
    <span>
        <span class="row">
            <div
                class="row-heading columns is-mobile border-right border-bottom border-left light-gray cursor-pointer sticky-left"
                @click="handleClick()"
            >
                <div class="column is-2 padding-left-one-third">
                    <vue-fontawesome icon="chevron-down" size="lg" color="#727272" />
                </div>
                <div class="column is-8 semi-bold">
                    <p class="semi-bold overflow-ellipsis">{{moduleName}}</p>
                </div>
                <div class="column is-2">
                    <p
                        class="semi-bold font-size-nine-tenths justify-center flex-center"
                    >{{topicsCompleted(module['topics'])}}/{{module['topics'].length}}</p>
                </div>
            </div>
            <div
                v-for="(monday,index) in mondays"
                :key="'emptyrow'+row+index"
                class="row-column row border-right border-bottom"
            >
                <div class="empty-week-column light-gray"></div>
            </div>
        </span>
        <span v-if="isVisible && dates">
            <topic-row
                v-for="topic in module['topics']"
                :key="topic.es_topicid+'row'"
                :topic="topic"
                :mondays="mondays"
                :dates="dates"
                :topicDueStatus="topicDueStatus(topic)"
                :module="module"
            />
        </span>
        <div v-if="!isVisible"></div>
    </span>
</template>

<script>
import { mapGetters } from "vuex";
import TopicRow from "../components/TopicRow.vue";
import EventBus from "../event-bus";
import moment from "moment";

export default {
    name: "ModuleRows",
    components: { TopicRow },
    props: [
        "mondays",
        "row",
        "moduleName",
        "module",
        "dates",
        "completedAttempts"
    ],
    data() {
        return {
            isVisible: false
        };
    },
    computed: {
        ...mapGetters(["attempts"])
    },
    created() {
        EventBus.$on("collapse-chart", () => {
            this.isVisible = false;
        });

        EventBus.$on("expand-chart", () => {
            this.isVisible = true;
        });
    },
    methods: {
        handleClick() {
            this.isVisible = !this.isVisible;
        },
        topicsCompleted(topics) {
            let submitted = 0;
            let contactAttempts = this.attempts;

            let attemptedTopics = topics.filter(topic =>
                contactAttempts.some(
                    attempt =>
                        topic.es_topicid === attempt._es_topic_value &&
                        attempt.es_issubmitted
                )
            );

            return attemptedTopics.length;
        },
        topicDueStatus(topic) {
            let contactAttempts = this.completedAttempts;

            let completedTopicAttempts = contactAttempts.filter(attempt => {
                return (
                    attempt._es_topic_value === topic.es_topicid &&
                    attempt.es_issubmitted
                );
            });

            if (
                completedTopicAttempts.length > 0 &&
                topic.es_type == 717580002
            ) {
                //completed quiz
                let score = 0;
                //return highest grade
                completedTopicAttempts.forEach(attempt => {
                    if (attempt.es_score > score) {
                        score = attempt.es_score;
                    }
                });
                let isPassing;
                //find out if fail
                if (score < 0.7) {
                    isPassing = false;
                } else {
                    isPassing = true;
                }
                return { status: "quiz", score: score, isPassing: isPassing };
            }

            if (completedTopicAttempts.length > 0) {
                return "done";
            } else if (!topic.es_duedate && topic.es_enddate) {
                //no due date, use end date as end date
                let today = new Date();
                let daysBeforeDue = null;
                var a = moment().startOf("day");
                var b = moment(topic.es_enddate).startOf("day");
                var diff = b.diff(a, "days"); // =1
                return diff;
            } else {
                let today = new Date();
                let daysBeforeDue = null;
                var a = moment().startOf("day");
                var b = moment(topic.es_duedate).startOf("day");
                var diff = b.diff(a, "days");
                return diff;
            }
        }
    }
};
</script>

<style>
.empty-week-column {
    width: 140px;
}
</style>
