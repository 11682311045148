import request from './request';

export const getCohortFeedback = (data) =>
    request({
        url: `cohortfeedbacks?enrollmentid=${data.enrollmentid}&cohortid=${data.cohortid}`,
        method: 'get',
    })


export const getTopicFeedback = (data) =>
    request({
        url: `topicfeedbacks?enrollmentid=${data.enrollmentid}&topicid=${data.topicid}`,
        method: 'get',
    })


export const getSessionFeedback = (data) =>
    request({
        url: `sessionfeedbacks?enrollmentid=${data.enrollmentid}&sessionid=${data.sessionid}`,
        method: 'get',
    })