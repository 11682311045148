<template>
	<div key="quiz-container-component" class="quiz-container-component hundo-height overflow-x-hidden" :class="!takingQuiz ? 'overflow-y-scroll' : null">
		<!--pre-quiz page-->
		<div v-if="!agreedToAttempt" class="padding-left-2 padding-top-2">
			<div v-if="topicAttempts">
				<div v-if="currentTopic.es_type != '717580005' && currentCohort.es_cohortid != '75a23a22-9a5c-ea11-a811-000d3a1b1d16'">
					<p>
						Timer will start when you click start. If you leave the exam, you can continue if still within the allowed time.
						Once the topic is submitted, or time expires, your choices are final and the attempt will be graded.
					</p>
				</div>

				<div
					v-else-if="
						currentTopic.es_displayname == 'Course Assessment' &&
							currentCohort.es_cohortid == '75a23a22-9a5c-ea11-a811-000d3a1b1d16'
					"
					class="p-mb-md"
				>
					<p>
						This is an assessment of the concepts discussed in the Cybersecurity Fundamentals course.
					</p>

					<p>
						You have
						{{ currentTopic.es_allowedtime ? `${currentTopic.es_allowedtime} minutes` : `time` }}
						to complete
						{{ currentTopic.es_totalquizquestions ? `${currentTopic.es_totalquizquestions} ` : `the` }}
						questions. You must complete the assessment in one sitting and cannot save and continue the test later.
					</p>
					<p>
						Once you have selected the answer(s) for a question, you will click “Continue” to proceed to the next question.
						When you click “Submit”, your answer is final. You cannot navigate to a previous question.
					</p>
					<p>
						The timer will begin when you click “Start”. Once the assessment is submitted or time expires, the assessment is
						over and it will be graded.
					</p>
					<p>
						The passing requirement is
						{{ currentTopic.es_requiredscore ? `${currentTopic.es_requiredscore}%` : `not listed` }}. You are allowed
						{{ currentTopic.es_allowedattempts ? `${currentTopic.es_allowedattempts} ` : `any number of ` }}
						attempts to complete the assessment.
					</p>
					<p>
						If you have any questions about the assessment or enrolling in the Cybersecurity Bootcamp please contact us at
						admissions@evolvesecurity.com
					</p>
				</div>

				<div
					v-else-if="currentTopic.es_type != '717580005' && currentCohort.es_cohortid == '75a23a22-9a5c-ea11-a811-000d3a1b1d16'"
					class="p-mb-md"
				>
					<p>
						Timer will start when you click start. If you leave the exam, you can continue if still within the allowed time.
						Once the topic is submitted, or time expires, your choices are final and the attempt will be graded.
					</p>
				</div>

				<div v-else>
					<p>
						The final exam is to be completed in one sitting, you will not be allowed to continue if you leave. You will not
						be able to go back to any questions.
					</p>

					<br />

					<p>
						Timer will start when you click start. Once the topic is submitted, or time expires, your choices are final and
						the attempt will be graded.
					</p>
				</div>

				<quiz-history :topicAttempts="topicAttempts" />
				<span class="flex align-center padding-top-1" v-if="topicAttempts">
					<b-button v-if="currentTopic.es_allowedattempts - topicAttempts.length <= 0" disabled>Start</b-button>
					<b-button v-else @click="postAttempt()">Start</b-button>
					<div v-if="submitted.status" class="flex-col-center padding-left-1">
						<span class="small">
							{{ `Last attempted on ${submitted.date}` }}
						</span>

						<a @click="handleFeedbackClick" class="small link-blue underline">Leave feedback</a>
					</div>
				</span>
				<div v-if="!takingQuiz" class="end-white-space"></div>
			</div>
			<Loader v-else />
		</div>

		<!--once start button pressed, renders quiz-->

		<!-- question markdown -->
		<div style="height:100%;" v-else-if="markdownQuiz">
			<quiz-renderer :key="currentAttempt + 'markdownrenderer'" />
			<quiz-button :key="currentAttempt + 'quizbutton'" />
		</div>
		<!-- quiz markdown -->
		<div style="height:100%;" v-else>
			<markdown-renderer :key="currentAttempt + 'markdownrenderer'" />
			<quiz-button :key="currentAttempt + 'quizbutton'" />
		</div>
	</div>
</template>

<script>
import EventBus from "../event-bus"
import { mapGetters, mapActions } from "vuex"
import MarkdownRenderer from "./MarkdownRenderer"
import QuizHistory from "./QuizHistory"
import moment from "moment"
import timezone from "moment-timezone"
import Timer from "./Timer"
import QuizButton from "./QuizButton"
import Loader from "./Loader"
import QuizRenderer from "./QuizRenderer"

export default {
	name: "QuizContainer",
	components: {
		MarkdownRenderer,
		QuizHistory,
		Timer,
		QuizButton,
		Loader,
		QuizRenderer
	},
	data() {
		return {
			agreedToAttempt: false,
			submitted: { status: false },
			intervalVar: null,
			topicAttempts: null
		}
	},
	mounted() {
		this.checkAttempts()

		EventBus.$on("agree-to-attempt", data => {
			this.agreedToAttempt = data
		})
	},
	updated() {},
	computed: {
		...mapGetters(["currentTopic", "currentCohort", "questionChoices", "attempts", "timeZone", "currentAttempt", "takingQuiz"]),
		markdownQuiz: {
			// getter
			get: function() {
				return this.checkMarkdownType()
			}
		}
	},
	watch: {
		currentAttempt: function() {
			if (!this.currentAttempt) {
				this.agreedToAttempt = false
			}
		},
		attempts: function() {
			this.checkAttempts()
		},
		currentTopic: function() {
			this.checkAttempts()
		}
	},
	beforeDestroy() {
		//stops the timer
		// clearInterval(this.intervalVar);
		this.stopTimer()
	},
	methods: {
		...mapActions([
			"setRootTimer",
			"getCurrentQuestionChoices",
			"createAttempt",
			"decrementRootTimer",
			"updateTakingQuiz",
			"stopTimer",
			"startTimer"
		]),
		handleFeedbackClick(e) {
			e.preventDefault()
			EventBus.$emit("feedback-button-clicked")
		},
		checkMarkdownType() {
			if (this.currentTopic.es_content.includes("[question:id")) {
				return true
			}
			return false
		},
		checkAttempts() {
			//filters through attempts on the topic
			//sometimes quizzes aren't submitted (e.g. start and exit browser)
			let contactAttempts = this.attempts
			let topicAttempts = contactAttempts.filter(attempt => {
				return attempt._es_topic_value === this.currentTopic.es_topicid
			})

			this.topicAttempts = topicAttempts

			this.setAttempts()
		},
		setAttempts() {
			if (this.topicAttempts.length > 0) {
				this.submitted = {
					status: true,
					date: moment(this.topicAttempts[0].es_timeend || this.topicAttempts[0].es_timestart)
						.tz(this.timeZone)
						.format("MMMM Do YYYY, [at] h:mm a z")
				}
			}
		},
		postAttempt() {
			if (!this.currentAttempt) {
				//if its a quiz, make an attempt when opened
				// this.markdownQuiz = this.checkMarkdownType();
				this.startTimer()

				this.updateTakingQuiz(true)
				this.createAttempt()
				//start the interval for the timer
				// this.intervalVar = setInterval(this.decrementRootTimer, 1000);

				this.agreedToAttempt = true
			}
		}
	}
}
</script>

<style lang="scss">
#app .quiz-container-component {
	// position: relative;
	// height: calc(100vh - 7.5rem);
	position: relative;

	.absolute {
		position: fixed;
	}

	.right-1 {
		right: 1rem;
	}

	.background-white {
		background-color: white;
	}

	.rounded {
		border-radius: 5px;
	}

	.z-index-2 {
		z-index: 2;
	}
	.overflow-scroll {
		overflow-y: scroll;
	}

	.overflow-x-hidden {
		overflow-x: hidden;
	}

	.hundo-height {
		height: 100%;
	}

	.small {
		font-size: 0.8rem;
	}

	.flex-col-center {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.padding-left-1 {
		padding-left: 1rem;
	}

	.padding-top-1 {
		padding-top: 1rem;
	}

	.padding-left-2 {
		padding-left: 2rem;
	}

	.padding-top-2 {
		padding-top: 2rem;
	}

	.flex {
		display: flex;
	}

	.align-center {
		align-items: center;
	}

	.end-white-space {
		height: 5rem;
	}

	.link-blue {
		color: #0000ee;
	}

	.underline {
		text-decoration: underline;
	}

	.p-mb-md p {
		margin-bottom: 1rem;
	}
}

#app .overflow-y-scroll {
	overflow-y: scroll;
}
</style>
