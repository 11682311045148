import request from "./request"

export const postAttempt = data =>
	request({
		url: "/attempts",
		method: "post",
		data: data,
		headers: { "Content-Type": "application/json" }
	})

export const patchAttempt = data =>
	request({
		url: "/attempts",
		method: "patch",
		data: data,
		headers: { "Content-Type": "application/json" }
	})

export const patchQuizAttempt = data =>
	request({
		url: "/quizattempts",
		method: "patch",
		data: data,
		headers: { "Content-Type": "application/json" }
	})

export const getAttempts = data =>
	request({
		url: `/attempts?contactid=${data}`,
		method: "get"
	})

export const postAttemptSelection = data =>
	request({
		url: "/selections",
		method: "post",
		data: data,
		headers: { "Content-Type": "application/json" }
	})

export const resetQuiz = data =>
	request({
		url: `reset-quiz?cohortid=${data.es_cohortid}&attemptid=${data.es_attemptid}`,
		method: "delete"
	})

export const postAssessment = data =>
	request({
		url: "/assessment",
		method: "post",
		data: data,
		headers: { "Content-Type": "application/json" }
	})

export const patchAssessment = data =>
	request({
		url: "/assessment",
		method: "patch",
		data: data,
		headers: { "Content-Type": "application/json" }
	})

export const getAttemptFiles = data =>
	request({
		url: `/attemptfiles?attemptid=${data}`,
		method: "get",
		headers: {
			"Content-Type": "application/json; charset=utf-8",
			Accept: "application/pdf"
		},
		responseType: "arraybuffer"
	})

export const checkForAttemptFiles = data =>
	request({
		url: `/checkfiles?enrollmentid=${data.enrollmentid}&topicid=${data.topicid}`,
		method: "get"
	})
