<template>
	<section class="cohort-tabs-component" v-if="!loadingCohorts && !loadingCohort && !enrollingCohort">
		<b-tabs v-model="activeTab" position="is-centered" expanded>
			<b-tab-item label="Free Courses">
				<add-row-cards v-if="!loadingCohorts && freeCohorts && freeCohorts.length > 0" />

				<h2 v-else style="color:white;">
					Please check for upcoming cohorts on
					<a href="https://evolvesecurity.com/academy/home/">our website</a>.
				</h2>
			</b-tab-item>

			<b-tab-item label="My Cohorts">
				<cohort-row-cards v-if="!loadingCohorts && cohortCards && cohortCards.length > 0" />
				<h1 v-else>You are not enrolled in any cohorts.</h1>
			</b-tab-item>

			<b-tab-item label="Archived">
				<archived-cohort-row-cards v-if="!loadingCohorts && archivedCards && archivedCards.length > 0" />
				<h1 v-else>You have no archived cohorts at this time.</h1>
			</b-tab-item>
		</b-tabs>
	</section>
	<Loader v-else />
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import CohortRowCards from "../components/CohortRowCards.vue"
import Loader from "../components/Loader.vue"
import ArchivedCohortRowCards from "../components/ArchivedCohortRowCards.vue"
import AddRowCards from "../components/AddRowCards.vue"

export default {
	name: "CohortTabs",
	components: { CohortRowCards, AddRowCards, ArchivedCohortRowCards, Loader },
	computed: {
		...mapGetters([
			"currentTopic",
			"currentModule",
			"freeCohorts",
			"cohortCards",
			"loadingCohorts",
			"archivedCards",
			"loadingCohort",
			"enrollingCohort"
		]),
		activeTab: {
			get() {
				if (!this.cohortCards || this.cohortCards.length == 0 || this.cohortCards == "none") {
					//start on free tab
					return 0
				}
				//start on mycourses tab
				return 1
			},
			set(value) {
				value ? (this.value = value) : (this.value = 0)
			}
		}
	},
	watch: {
		cohortCards: function() {
			// this.getFreeCohorts();
		},
		freeCohorts: function() {}
	},
	data() {
		return {
			test: 0
		}
	},
	methods: {
		...mapActions(["getFreeCohorts"])
	}
}
</script>

<style lang="scss">
#app .cohort-tabs-component {
	//border-radius of tabs
	$tab-radius: 1rem;
	height: 100%;
	width: 100%;
	overflow-x: hidden;

	nav {
		display: flex;
		justify-content: center;
	}

	.tab-content {
		height: calc(100vh - 97px);
	}

	//enclosed tabs
	// .tabs ul li a {
	//     font-size: 1.25rem;
	//     line-height: 1rem;
	//     border: 1px solid #1273b9;
	//     //     border: 1px solid #1479bf;
	//     //     border: 1px solid #2aa8df;
	//     background-color: black;
	// }
	// .tabs ul li:nth-child(1) a {
	//     border-right: 0px solid #1273b9;
	// }

	// .tabs ul li:nth-child(3) a {
	//     border-left: 0px solid #1273b9;
	// }

	// li {
	//     background-color: white;
	//     font-weight: bold;
	//     color: black;
	// }

	// li:nth-child(1),
	// li:nth-child(1) a {
	//     border-radius: $tab-radius 0px 0px $tab-radius;
	// }

	// li:nth-child(3),
	// li:nth-child(3) a {
	//     border-radius: 0px $tab-radius $tab-radius 0px;
	// }

	// .tabs ul {
	//     margin: 2rem 4rem 0rem;
	//     max-width: 90rem;
	//     border-radius: $tab-radius;
	// }

	// .tabs li.is-active a {
	//     color: white;
	//     color: black;
	//     background-color: white;
	// }

	// li.is-active {
	//     background-color: #1273b9;
	//     // background-color: #2aa8df;
	//     font-weight: bold;
	//     color: white;
	// }

	//floating word tabs
	.tabs ul li a {
		color: white;
		font-size: 1.5rem;
		font-weight: bold;
		letter-spacing: 0.05rem;
	}

	.tabs ul li a span {
		margin-bottom: 1px;
	}

	.tabs li.is-active a {
		border-bottom: 2px solid white;
	}
	.b-tabs .tabs li a:focus {
		border-color: white;
	}

	.tabs li.is-active a span {
		margin-bottom: 0px;
	}

	.tabs ul {
		margin: 2rem 4rem 0rem;
		max-width: 90rem;
		border: none;
	}

	li {
		color: black;
	}

	// .shadow {
	//     box-shadow: 0.25rem 0.1rem 0.75rem 0.5rem rgba(0, 0, 0, 0.5);
	//     transition: box-shadow 150ms ease-out; //, margin 150ms ease-out;
	// }

	// .shadow:hover {
	//     box-shadow: 0.25rem 0.1rem 0.85rem 0.55rem rgba(0, 0, 0, 0.5);
	//     transition: box-shadow 150ms ease-in; //, margin 150ms ease-in;
	// }

	.shadow {
		box-shadow: 0rem 0.5rem 0.5rem rgba(0, 0, 0, 0.8);
		transition: box-shadow 0.15s ease-out;
	}

	.shadow:hover {
		box-shadow: 0rem 0.6rem 0.6rem rgba(0, 0, 0, 0.7);
		transition: box-shadow 0.15s ease-in; //, margin 150ms ease-in;
	}

	.white-link {
		color: white;
	}

	.white-link:hover {
		color: #515252;
	}
}
</style>
