<template>
    <!-- <img :src="thumbnail" alt="Placeholder image" /> -->
    <div class="archived-cohort-image-component" :class="isArchived?'no-click':null">
        <div
            v-if="isArchived"
            style="
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.89);
    z-index: 10;
    "
        >
            <div
                style="
    width: 100%;
    height: 104%;
    align-items: center;
    display: flex;
    justify-content: center;
    font-weight: 600;
    font-size: 2rem;
    color: red;
"
            >Archived</div>
        </div>

        <figure v-if="image && image != 'none'" class="image is-4by3">
            <img
                class="cohort-image"
                :src="`data:${cohort.image.mimetype};base64,${cohort.image.documentbody}`"
            />
        </figure>

        <figure v-else-if="image && image == 'none'" class="image is-4by3">
            <img class="cohort-image default" :src="thumbnail" />
        </figure>
        <figure v-else class="image margin-92-5">
            <Loader />
        </figure>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Loader from "./Loader";
import thumbnail from "../assets/academy-image-min.jpg";

export default {
    name: "ArchivedCohortImage",
    components: { Loader },
    props: ["cohort", "isArchived"],
    computed: {
        ...mapGetters([
            "allCohorts",
            "currentCohort",
            "cohortCards",
            "archivedCards"
        ])
    },
    data() {
        return {
            thumbnail: thumbnail,
            image: null
        };
    },
    watch: {
        //if cohorts changed, it is because the backend loaded a cohort
        allCohorts: function() {
            this.checkCohortImage();
        },
        //if cohorts changed, it is because the backend loaded a cohort
        archivedCards: function() {
            this.checkCohortImage();
        }
    },
    mounted() {
        //get cohorts if don't have
        if (this.cohort && !this.cohort.image) {
            let data = {
                cohortid: this.cohort.es_cohortid,
                cardType: "archivedCards"
            };
            this.getCohortImage(data);
        } else {
            this.image = this.cohort.image;
        }
    },
    methods: {
        ...mapActions(["getCohortImage"]),
        checkCohortImage() {
            //Check if the localCohort's image is the same as VUEX's state. Change it if needed.
            let localCohort = this.archivedCards.find(cohort => {
                return cohort.es_cohortid == this.cohort.es_cohortid;
            });
            if (this.image != localCohort.image) {
                this.image = localCohort.image;
            }
        }
    }
};
</script>

<style lang="scss">
#app .archived-cohort-image-component {
    position: relative;
    .margin-92-5 {
        margin: 92.5px 0;
    }
}

#app .archived-cohort-image-component.no-click {
    cursor: not-allowed;
}
</style>