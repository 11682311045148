<template>
    <span class="guac-auth-controls-component">
        <div
            class="absolute bottom black-background inherit-width two-height flex center guac-auth-controls-footer"
            @click="emitFocusGuac()"
        >
            <!---authenticate guac-->
            <b-button
                size="is-small"
                :type="guacamoleAuthenticated?'is-light':'is-warning'"
                rounded
                tag="a"
                @click="saveCookie()"
                class="side-margins-0-25"
            >
                Authenticate
                <vue-fontawesome
                    class="margin-left-small"
                    :icon="['fas', 'external-link-alt']"
                    color="black"
                />
            </b-button>
        </div>
    </span>
</template>

<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import EventBus from "../event-bus";

export default {
    name: "GuacAuthControls",
    computed: {
        ...mapGetters(["currentTopic", "frameUrl", "baseUrl"])
    },
    data() {
        return {
            active: true,
            guacamoleAuthenticated: false
        };
    },
    mounted() {
        this.compareCookie();
    },
    methods: {
        saveCookie() {
            var expireTime = new Date(
                moment()
                    .add(4, "hours")
                    .toDate()
            );
            document.cookie = `guacamoleSession=${expireTime}`;
            this.compareCookie();
        },
        compareCookie() {
            var guacamoleSession = this.getCookie("guacamoleSession");

            if (guacamoleSession) {
                var timeDiff = moment().diff(
                    moment(guacamoleSession),
                    "minutes"
                );
                if (timeDiff >= -5) {
                    this.guacamoleAuthenticated = false;
                } else {
                    this.guacamoleAuthenticated = true;
                }
            } else {
                this.guacamoleAuthenticated = false;
            }
        },
        getCookie(cname) {
            var name = cname + "=";
            var decodedCookie = decodeURIComponent(document.cookie);
            var ca = decodedCookie.split(";");
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == " ") {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        },
        emitFocusGuac() {
            EventBus.$emit("focus-guac");
        }
    }
};
</script>

<style lang="scss">
#app .guac-auth-controls-component {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .absolute {
        position: absolute;
    }

    .bottom {
        bottom: 0;
    }

    .two-height {
        height: 2.5rem;
    }

    .center {
        justify-content: center;
        align-items: center;
    }

    .side-margins-0-25 {
        margin: 0px 0.25rem;
    }

    .guac-auth-controls-footer {
        z-index: 1;
    }

    .inherit-width {
        width: inherit;
    }
}

.margin-left-small {
    margin-left: 0.25rem;
}
</style>
