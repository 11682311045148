import request from './request';

export const waitlistInCohort = (data) =>
    request({
        url: '/waitlist',
        method: 'post',
        data: data,
        headers: { 'Content-Type': 'application/json' },
    })

