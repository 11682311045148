import axios from 'axios';
import { cacheAdapterEnhancer } from 'axios-extensions';
import request from './request';

export const getTopicContent = (data) =>
    request({
        url: `topiccontent?topicid=${data}`,
        method: 'get',
        headers: { 'Cache-Control': 'no-cache' },
        adapter: cacheAdapterEnhancer(axios.defaults.adapter)
    })


export const getTopicAnnotations = (data) =>
    request({
        url: `annotations?topicid=${data}`,
        method: 'get',
        headers: { 'Cache-Control': 'no-cache' },
        adapter: cacheAdapterEnhancer(axios.defaults.adapter)
    })


export const getTopicQuestionChoices = (data) =>
    request({
        url: `questionchoices?topicid=${data}`,
        method: 'get',
        headers: { 'Cache-Control': 'no-cache' },
        adapter: cacheAdapterEnhancer(axios.defaults.adapter)
    })
