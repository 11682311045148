import axios from 'axios';
import EventBus from "../../event-bus";
import {
    getInstanceConnections
} from "./../../api/studentInstance";
import { terminaldesktop_machines, onlydesktop_machines, onlyterminal_machines, devbox_machines } from "../../config/index";

const state = {
    baseGuacUrl: "https://guacamole.cyberlab.evolvesecurity.com/#/client/",
    frameUrl: "",
    esaLinuxTerminalConnId: "",
    esaLinuxDesktopConnId: "",
    devBoxTerminalConnId: "",
    devBoxDesktopConnId: "",
    windowsConnId: "",
    ubuntuConnId: "",
    allMachines: "",
    esaLinuxMachine: "",
    windowsMachine: "",
    ubuntuMachine: "",
    devBoxMachine: "",
    controlAuthenticated: false,
    currentMachine: null,
    currentShutdownTime: "",
    displayStartLaunch: true
};

const getters = {
    frameUrl: (state) => state.frameUrl,
    controlAuthenticated: (state) => state.controlAuthenticated,
    baseGuacUrl: (state) => state.baseGuacUrl,
    esaLinuxTerminalConnId: (state) => state.esaLinuxTerminalConnId,
    esaLinuxDesktopConnId: (state) => state.esaLinuxDesktopConnId,
    devBoxTerminalConnId: (state) => state.devBoxTerminalConnId,
    devBoxDesktopConnId: (state) => state.devBoxDesktopConnId,
    windowsConnId: (state) => state.windowsConnId,
    ubuntuConnId: (state) => state.ubuntuConnId,
    allMachines: (state) => state.allMachines,
    esaLinuxMachine: (state) => state.esaLinuxMachine,
    windowsMachine: (state) => state.windowsMachine,
    ubuntuMachine: (state) => state.ubuntuMachine,
    devBoxMachine: (state) => state.devBoxMachine,
    currentMachine: (state) => state.currentMachine,
    currentShutdownTime: (state) => state.currentShutdownTime,
    displayStartLaunch: (state) => state.displayStartLaunch
};

const actions = {
    loadConnectionIds({
        commit,
        getters,
        dispatch
    }) {
        if (getters.currentEnrollment) {
            let enrollmentId = getters.currentEnrollment.es_enrollmentid;
            getInstanceConnections({
                enrollmentId: enrollmentId
            }).then(response => {
                let data = response.data;
                commit("setAllMachines", data.filter(m => m !== null));
                EventBus.$emit("load-connections-complete");
            }, error => {
                console.log("Fail to get machine connection ids", error);
            })
        }
    },
    setMachine({commit, dispatch, getters}, payload) {
        let studentInstance = payload;
        let boxName = studentInstance.es_box.es_boxname;
        let targetMachine = getters.allMachines.find(m => m.es_box.es_name === boxName);
        targetMachine.es_status = studentInstance.es_status;
        targetMachine.es_guacterminalid = studentInstance.es_guacterminalid;
        targetMachine.es_guacdesktopid = studentInstance.es_guacdesktopid;
        targetMachine.es_privateipaddress = studentInstance.es_privateipaddress;
        targetMachine.es_timetoshutdown = studentInstance.es_timetoshutdown;

        if (studentInstance.es_status === "Inactive") {
            dispatch("setCurrentFrameUrl");
            dispatch("refreshGuacLink");
        }
    },
    setCurrentFrameUrl({commit, getters}) {
        if (getters.currentMachine) {
            switch (getters.currentMachine.es_box.es_name) {
                case "esa_linux":
                case "devbox":
                case "ubuntu":
                    commit('changeFrameUrl', `${getters.baseGuacUrl}${getters.currentMachine.es_guacterminalid}`);
                    break;
                case "Window":
                    commit('changeFrameUrl', `${getters.baseGuacUrl}${getters.currentMachine.es_guacdesktopid}`);
                    break;
            }
        }
    },
    setCurrentShutdownTime({commit, getters}) {
        if (getters.currentMachine) {
            commit("setCurrentShutdownTime", getters.currentMachine.es_timetoshutdown);
        }
    },
    updateCurrentMachineAction({commit}, machine) {
        commit('setCurrentMachine', machine);
    },
    switchDisplay({commit, getters, dispatch}, boxName) {
        let currentMachine = getters.allMachines.find(m => m.es_box.es_name === boxName)
        dispatch("updateCurrentMachineAction", currentMachine)
        if (boxName === 'windows_server') {
            commit('changeFrameUrl', `${getters.baseGuacUrl}${currentMachine.es_guacdesktopid}`);
        } else {
            commit('changeFrameUrl', `${getters.baseGuacUrl}${currentMachine.es_guacterminalid}`);
        }
        commit("setCurrentShutdownTime", currentMachine.es_timetoshutdown);
    },
    checkControlAuthenticated({ commit }) {
        axios.get(`https://control.cyberlab.evolvesecurity.com/api/session/claims`)
            .then(response => {
                commit("setControlAuthenticated", true)
            }).catch(err => {
                commit("setControlAuthenticated", false)
            })
    },
    refreshGuacLink({
        getters
    }) {
        document.getElementById("guac").src = getters.frameUrl;
    },
    setStartLaunchDisplay({commit}, payload) {
        commit("setStartLaunch", payload);
    }
};

const mutations = {
    changeFrameUrl: (state, url) => (state.frameUrl = url),
    setControlAuthenticated: (state, val) => (state.controlAuthenticated = val),
    setESALinuxTerminalConnId: (state, connId) => (state.esaLinuxTerminalConnId = `${state.baseGuacUrl}${connId}`),
    setESALinuxDesktopConnId: (state, connId) => (state.esaLinuxDesktopConnId = `${state.baseGuacUrl}${connId}`),
    setDevBoxTerminalConnId: (state, connId) => (state.devBoxTerminalConnId = `${state.baseGuacUrl}${connId}`),
    setDevBoxDesktopConnId: (state, connId) => (state.devBoxDesktopConnId = `${state.baseGuacUrl}${connId}`),
    setWindowsConnId: (state, connId) => (state.windowsConnId = `${state.baseGuacUrl}${connId}`),
    setUbuntuConnId: (state, connId) => (state.ubuntuConnId = `${state.baseGuacUrl}${connId}`),
    setAllMachines: (state, machines) => (state.allMachines = machines),
    setEsaLinuxMachine: (state, esaLinuxMachine) => (state.esaLinuxMachine = esaLinuxMachine),
    setWindowsMachine: (state, windowsMachine) => (state.windowsMachine = windowsMachine),
    setUbuntuMachine: (state, ubuntuMachine) => (state.ubuntuMachine = ubuntuMachine),
    setDevboxMachine: (state, devBoxMachine) => (state.devBoxMachine = devBoxMachine),
    setCurrentMachine: (state, machine) => (state.currentMachine = machine),
    setCurrentShutdownTime: (state, shutdownTime) => (state.currentShutdownTime = shutdownTime),
    setStartLaunch: (state, displayStartLaunch) => (state.displayStartLaunch = displayStartLaunch)
};

export default {
    state,
    getters,
    actions,
    mutations
}
