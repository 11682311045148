<template>
	<div class="session-attendance">
		<!-- names -->
		<div class="es-my-lg" v-if="!sessionAttendanceLoading && sessionAttendance">
			<!-- if edit permission  -->
			<div v-if="hasEditPermission">
				<div class="flex-row columns" v-for="attendance in sessionAttendance" :key="attendance['es_attendanceid']">
					<div class="column is-one-third">{{ attendance["fullname"] }}</div>

					<b-button
						class="column"
						@click="handleClick(attendance, 717580001)"
						:type="attendance.es_status === 717580001 ? 'is-danger' : null"
						>Absent</b-button
					>
					<b-button
						class="column"
						@click="handleClick(attendance, 717580002)"
						:type="attendance.es_status === 717580002 ? 'is-warning' : null"
						>Excused</b-button
					>
					<b-button
						class="column"
						@click="handleClick(attendance, 717580000)"
						:type="attendance.es_status === 717580000 ? 'is-info' : null"
						>Present</b-button
					>
				</div>
			</div>
			<!-- no edit permission  -->
			<div v-else>
				<div class="flex-row columns" v-for="attendance in sessionAttendance" :key="attendance['es_attendanceid']">
					<div class="column is-one-third">{{ attendance["fullname"] }}</div>

					<div class="column no-edit" :class="attendance.es_status === 717580001 ? 'danger-red' : null">
						Absent
					</div>
					<div class="column no-edit" :class="attendance.es_status === 717580002 ? 'warning-yellow' : null">
						Excused
					</div>
					<div class="column no-edit" :class="attendance.es_status === 717580000 ? 'info-blue' : null">
						Present
					</div>
				</div>
			</div>
		</div>
		<Loader v-else />
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import Loader from "../components/Loader.vue"
import router from "../router/router.js"
import moment from "moment"

export default {
	name: "AdminAttendance",
	components: { Loader },
	computed: {
		...mapGetters(["currentCohort", "allSessions", "currentSessions", "sessionAttendanceLoading", "sessionAttendance", "enrollmentRoles"]),
		hasEditPermission: {
			get() {
				if (this.enrollmentRoles[0]["es_role1.es_name"] === "Teaching Assistant") {
					return true
				}
				return false
			}
			// set(value) {
			// 	value ? (this.value = value) : (this.value = 0)
			// }
		}
	},
	data() {
		return {
			attendanceDates: null
		}
	},
	mounted() {
		if (!this.sessionAttendanceLoading && !this.sessionAttendance) {
			this.getSessionAttendance({
				sessionId: this.$route.params.sessionid,
				cohortId: this.$route.params.cohortid
			})
		}
	},
	methods: {
		...mapActions(["changeCurrentPage", "makeCurrentSessions", "getSessions", "getSessionAttendance", "patchAttendance"]),
		handleSelect(selection) {
			this.getSessionAttendance({
				sessionId: selection.es_sessionid,
				cohortId: this.currentCohort.es_cohortid
			})
		},
		handleClick(attendance, status) {
			if (attendance.es_status === status) {
				return
			}
			this.patchAttendance({
				attendance: attendance,
				es_status: status
			})
		},
		formatDate(date) {
			if (!date) {
				return null
			}
			let formattedDate = moment(date)
				.startOf("day")
				.format("ddd MMM D, YYYY")
			if (formattedDate) {
				return formattedDate
			}
			return null
		},
		makeAttendanceDates() {
			let sessionDates = []

			this.currentSessions.forEach(session => {
				if (session._es_cohort_value != this.currentCohort.es_cohortid) {
					//wrong cohort
				} else {
					//add sessions that are from this cohort
					if (session.es_startdatetime && session.es_enddatetime) {
						sessionDates.push(session)
					}
				}
			})

			this.attendanceDates = sessionDates
		}
	}
}
</script>

<style lang="scss">
#app .attendance-page {
	background-color: #26a0da;
	background-image: linear-gradient(to bottom right, #2aa8df, #1479bf);
	display: flex;
	// align-items: center;
	justify-content: center;
	overflow-y: auto;
	padding-top: 1rem;

	.flex-row {
		align-items: center;
	}

	.attendance-container {
		background-color: white;
		width: 800px;
		border-radius: 5px;
		height: fit-content;
		padding: 2rem;

		.es-my-lg {
			margin-top: 2rem;
			margin-bottom: 2rem;
		}

		.columns > div {
			margin-left: 0.5rem;
			margin-right: 0.5rem;
		}

		.columns > button {
			margin-left: 0.5rem;
			margin-right: 0.5rem;
		}

		button {
			padding-bottom: calc(0.5em - 1px);
			padding-left: 1em;
			padding-right: 1em;
			padding-top: calc(0.5em - 1px);
		}
	}

	.no-edit {
		text-align: center;
		border-radius: 4px;
		border: 1px solid rgb(219, 219, 219);
		height: 2.5rem;
		display: flex;
		justify-content: center;
		align-items: center;
		padding-top: 0.75rem;
	}

	.danger-red {
		background-color: rgb(241, 70, 104);
		color: white;
	}

	.warning-yellow {
		background-color: rgb(255, 224, 138);
	}

	.info-blue {
		background-color: rgb(62, 142, 208);
		color: white;
	}
}
</style>
