<template>
	<div class="grades-component" :key="currentCohort.es_cohortid + 'grades-page'">
		<grades-name />
		<grades-percentages />
		<grades-chart />
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import GradesPercentages from "./GradesPercentages.vue"
import GradesChart from "./GradesChart.vue"
import GradesName from "./GradesName.vue"
import Loader from "./Loader"

export default {
	name: "Grades",
	components: { Loader, GradesPercentages, GradesChart, GradesName },
	computed: mapGetters(["currentPage", "currentCohort", "currentEnrollment"]),
	created() {
		this.getAttendanceForCohort({
			cohortid: this.currentCohort.es_cohortid,
			enrollmentid: this.currentEnrollment.es_enrollmentid
		})
	},
	data() {
		return {
			currentInstructors: null
		}
	},
	mounted() {},
	watch: {},
	methods: {
		...mapActions(["getInstructors", "getAttendanceForCohort"])
	}
}
</script>

<style lang="scss">
#app .grades-component {
}
</style>
