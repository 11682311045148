<template>
  <span class="menu-tab-component">
    <section class="menu-container menu-tab">
      <div
        v-for="(item, index) of menuItems"
        class="card header-shadow"
        :key="index"
      >
        <!-- <router-link @click="handleClick('Schedule')" :to="item.url"> -->
        <span @click="handleClick(item)" :label="item.label">
          <div
            class="card-header open"
            :style="{ cursor: 'pointer' }"
            :class="index == 0 ? 'first-card' : null"
          >
            <div class="columns is-mobile">
              <div class="column is-1">
                <p class="card-header-title"></p>
              </div>
              <div class="column">
                <p class="card-header-title">
                  {{ item.label }}
                  <span
                    v-if="
                      item.label === 'Grades' &&
                        currentEnrollment &&
                        currentEnrollment.es_grademessage
                    "
                    class="color-red"
                    >*</span
                  >
                </p>
              </div>
              <div class="column is-1">
                <a class="card-header-icon">
                  <b-icon icon="chevron-right"></b-icon>
                </a>
              </div>
            </div>
          </div>
        </span>
        <!-- </router-link> -->
      </div>
    </section>
  </span>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import EventBus from "../event-bus"
import router from "../router/router.js"

export default {
  name: "MenuTab",
  computed: {
    ...mapGetters([
      "currentTopic",
      "currentModule",
      "currentCohort",
      "currentEnrollment"
    ]),
    menuItems: function() {
      return [
        {
          url: `/cohort/${this.currentCohort.es_cohortid}/module/${this.currentModule.es_moduleid}/topic/${this.currentTopic.es_topicid}`,
          label: "Course Content"
        },
        {
          url: `/schedule/${this.currentCohort.es_cohortid}`,
          label: "Schedule"
        },
        {
          url: `/instructors/${this.currentCohort.es_cohortid}`,
          label: "Instructors"
        },
        {
          url: `/classmates/${this.currentCohort.es_cohortid}`,
          label: "Classmates"
        },
        {
          url: `/grades/${this.currentCohort.es_cohortid}`,
          label: "Grades"
        }
      ]
    }
  },
  methods: {
    ...mapActions(["changeCurrentPage"]),
    handleClick(item) {
      if (item.label == "Course Content") {
        EventBus.$emit("change-menu-tab")
      }
      this.changeCurrentPage(item.label)
      router.push({ path: item.url })
    }
  }
}
</script>

<style lang="scss">
#app .menu-tab-component {
  .card-header-title {
    display: -webkit-box;
  }

  .menu-container {
    height: 100%;
  }

  .content {
    color: #4a4a4a;
  }

  .icon-name {
    display: flex;
  }

  .tab-content {
    box-sizing: content-box;
    // overflow-y: scroll !important;
  }

  .tab-item {
    height: 100%;
  }

  .type-icon {
    display: block !important;
    height: unset !important;
    width: unset !important;
    max-width: unset !important;
    max-height: unset !important;
    height: 19px !important;
    width: 19px !important;
    margin-right: 5px;
  }

  .card-header {
    margin-top: 0px;
    transition: margin-top 0.3s ease-in;
  }

  .card-header.open {
    margin-top: 1rem;
    transition: margin-top 0.3s ease-out;
  }

  .card-header .columns {
    width: 100%;
  }

  .card-header.open .card-header-title {
    color: black !important;
  }

  .card-header .columns {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    align-items: center;
    height: 4rem;
  }

  .card-header:hover {
    background-color: #eaf5ff !important;
  }

  .card.header-shadow {
    box-shadow: 0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.8) !important;
  }

  .color-red {
    color: red;
  }
}
</style>
