<template>
	<div v-if="data" class="quiz-history-component">
		<br />
		<p>
			Allowed Time:
			{{ currentTopic.es_allowedtime ? `${currentTopic.es_allowedtime} minutes` : `Not set` }}
		</p>
		<p>
			Allowed Attempts:
			{{ currentTopic.es_allowedattempts ? `${currentTopic.es_allowedattempts}` : `Not set` }}
		</p>
		<p>Attempts Remaining: {{ currentTopic.es_allowedattempts - data.length }}</p>
		<br />
		<b-table :data="data">
			<!-- start time  -->
			<b-table-column label="Start time" width="380" v-slot="props">{{ props.row.timeStart }}</b-table-column>

			<!-- end time  -->
			<b-table-column label="End time" width="380" v-slot="props">{{ props.row.timeEnd }}</b-table-column>

			<!-- score  -->
			<b-table-column label="Score" width="200" centered v-slot="props">
				<!-- <b-button
					v-if="
						(props.row.pending || props.row.continue) &&
							currentTopic.es_topicid !== 'cf31576e-d823-eb11-a813-000d3a8febd2' &&
							currentTopic.es_duedate &&
							!props.row.pastDue
					"
					type="is-light"
					size="is-small"
					disabled
					rounded
				>
					Pending
				</b-button>

				<span v-else>{{ props.row.score }}</span> -->
				<span v-if="props.row.score && !props.row.continue">
					{{ props.row.score }}
				</span>

				<b-button v-else type="is-light" size="is-small" disabled rounded>
					Pending
				</b-button>
			</b-table-column>

			<!-- results  -->
			<b-table-column label="Results" width="150" centered v-slot="props">
				<span v-if="props.row.selections && props.row.selections.length > 0">
					<!-- cybersecurity fundamentals pass/fail  -->
					<span v-if="props.row.results">
						{{ props.row.results }}
					</span>

					<!--avail on-->
					<span v-else-if="!props.row.pastDue"> Avail {{ props.row.dueDate }} </span>

					<!-- quiz not finished  -->
					<span v-else-if="props.row.continue && props.row.continue !== 'pending'"> </span>

					<!-- processing -->
					<b-tooltip
						v-else-if="props.row.pending || props.row.continue"
						label="Your selections are still being processed. This may take several minutes. Please check back later or refresh the page."
						multilined
						position="is-top"
					>
						<b-button type="is-light" size="is-small" disabled rounded>
							Processing
						</b-button>
					</b-tooltip>
					<!-- graded -->
					<b-button v-else type="is-success" size="is-small" rounded @click="handleClick(props.row.selections)">
						View
					</b-button>
				</span>

				<!-- pending, missing selections  -->
				<b-tooltip
					v-else
					label="Your selections are still being processed. This may take several minutes. Please check back later or refresh the page."
					multilined
					position="is-top"
				>
					<b-button type="is-light" size="is-small" disabled rounded>
						Processing
					</b-button>
				</b-tooltip>
			</b-table-column>

			<!-- submitted  -->
			<b-table-column label="Submitted?" centered v-slot="props">
				<span v-if="props.row.continue == 'pending'">
					<!-- pending -->
					<!-- <b-button type="is-light" size="is-small" disabled rounded>Pending</b-button> -->
				</span>

				<span v-else-if="props.row.continue">
					<!-- pending -->
					<b-button type="is-info" size="is-small" rounded @click="handleContinue(props.row)">Continue</b-button>
				</span>

				<span v-else>
					<vue-fontawesome :icon="['fas', 'check']" color="black" />
				</span>
			</b-table-column>
		</b-table>

		<!--selections-modal-->
		<b-modal v-if="!isEnded" :active.sync="isSelectionsModalActive">
			<quiz-selections v-if="currentAttemptSelections" :selections="currentAttemptSelections" />
		</b-modal>
	</div>
	<Loader v-else />
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import QuizSelections from "./QuizSelections"
import moment from "moment"
import Loader from "./Loader"
import timezone from "moment-timezone"
import EventBus from "../event-bus"

export default {
	name: "QuizHistory",
	props: ["topicAttempts"],
	components: {
		QuizSelections,
		Loader
	},
	computed: {
		...mapGetters([
			"currentTopic",
			"attempts",
			"contact",
			"currentSelections",
			"currentEnrollment",
			"currentAttempt",
			"timeZone",
			"studentQuizSelections",
			"previousSelections"
		])
	},
	data() {
		return {
			data: null,
			isEnded: false,
			columns: [
				// {
				//     field: "enrollment",
				//     label: "Enrollment",
				//     width: "350",
				//     numeric: false
				// },
				{
					field: "timeStart",
					label: "Start time",
					width: "380"
				},
				{
					field: "timeEnd",
					label: "End time",
					width: "380"
				},
				{
					field: "score",
					label: "Score",
					width: "100"
				},
				{
					field: "selections",
					label: "Results",
					width: "100"
				},
				{
					field: "continue",
					label: "Submitted?",
					width: "100"
				}
			],
			currentAttemptSelections: null,
			isSelectionsModalActive: false
		}
	},
	mounted() {
		let data = {
			topicid: this.currentTopic.es_topicid,
			enrollmentid: this.currentEnrollment.es_enrollmentid
		}

		this.getSelections(data)
	},
	updated() {
		this.isEnded = false
	},
	watch: {
		topicAttempts: function() {
			//this component gets reused, so watch prop to know when to build new data object
			this.data = this.dataBuilder()
		},
		currentSelections: function() {
			this.data = this.dataBuilder()
		},
		currentTopic: function() {
			//component get reused, need to get the selections for the new topic
			let data = {
				topicid: this.currentTopic.es_topicid,
				enrollmentid: this.currentEnrollment.es_enrollmentid
			}
			this.getSelections(data)
		}
	},
	methods: {
		...mapActions([
			"getSelections",
			"updateTakingQuiz",
			"setCurrentAttempt",
			"updateContinuingQuiz",
			"setQuizQuestionsArr",
			"updateCurrentQuestionIndex",
			"setRootTimer",
			"setPreviousSelections",
			"startTimer",
			"setAttemptStartTime",
			"setAttemptTimeEnd",
			"addSelection"
		]),
		dataBuilder() {
			//uses filtered array of attempts from prop
			let attempts = this.topicAttempts
			let data = []

			//build a data object for each attempt and combine in an array
			attempts.forEach(attempt => {
				//set the end date or start date if no end date
				let date
				if (attempt.es_timeend) {
					date = moment(attempt.es_timeend).format("MMMM Do YYYY, [at] h:mm a")
				} else {
					//use starttime
					date = moment(attempt.es_timestart).format("MMMM Do YYYY, [at] h:mm a")
				}

				//filter topic selections by attempt
				let selections = null

				if (this.currentSelections) {
					selections = this.currentSelections.filter(selection => {
						return selection.es_attempt.es_attemptid == attempt.es_attemptid
					})
				}

				//check for pending
				let pending = this.checkForPending(attempts, attempt, selections)

				//check if topic past due
				let pastDue

				if (this.currentTopic.es_duedate) {
					var diff = moment(this.currentTopic.es_duedate)
						.startOf("day")
						.diff(moment(), "days") // =1
					if (diff < 0) {
						pastDue = true
					}
				} else {
					pastDue = true
				}

				//set due date
				let dueDate = moment(this.currentTopic.es_duedate)
					.add(1, "d")
					.tz(this.timeZone)
					.format("MMM D")

				// set result for cybersecurity fundamentals
				let results
				if (this.currentTopic.es_topicid === "cf31576e-d823-eb11-a813-000d3a8febd2") {
					if (attempt.es_score >= 0.7) {
						results = "Passed"
					} else {
						results = "Failed"
					}
				}

				//push data object
				data.push({
					date: date,
					score: (attempt.es_score * 100).toFixed(1) + "%",
					results: results,
					selections: selections,
					pending: pending,
					dueDate: dueDate,
					continue: this.checkForContinue(attempt, selections),
					attempt: attempt,
					pastDue: pastDue,
					timeStart: moment(attempt.es_timestart).format("MMMM Do YYYY, [at] h:mm a") || "",
					timeEnd:
						moment(attempt.es_timeend).format("MMMM Do YYYY, [at] h:mm a") == "Invalid date"
							? null
							: moment(attempt.es_timeend).format("MMMM Do YYYY, [at] h:mm a")
				})
			})

			//sort the data by date
			data = this.sortTopicAttempts(data)
			this.checkForExisting()
			return data
		},
		checkForPending(attempts, attempt, selections) {
			//check for attempt pending
			let pending = attempt.es_attemptstatus == 717580000

			//check for selections not posted
			let notPosted

			if (this.currentTopic.es_totalquizquestions) {
				if (selections && selections.length < this.currentTopic.es_totalquizquestions) {
					notPosted = true
				}
			}

			//check for selections pending
			let selectionsPending = selections.some(selection => {
				return ![717580001, 717580003].includes(selection.es_selectionstatus)
			})

			return pending || notPosted || selectionsPending
		},
		checkForContinue(attempt, selections) {
			let now = moment()

			var duration = moment.duration(now.diff(attempt.es_timestart)).asMinutes()
			let hasTime = duration < this.currentTopic.es_allowedtime && attempt.es_issubmitted == false

			let hasAllSelections = selections && selections.length >= this.currentTopic.es_totalquizquestions

			if (hasTime && hasAllSelections) {
				return attempt
			} else if (hasTime) {
				return "pending"
			}

			return false
		},
		checkForExisting() {
			let unsubmitted = this.topicAttempts.filter(attempt => {
				return attempt.es_issubmitted == false
			})

			let now = moment()

			let openAttempts = []

			unsubmitted.forEach(attempt => {
				var duration = moment.duration(now.diff(attempt.es_timestart)).asMinutes()

				if (duration < this.currentTopic.es_allowedtime) {
					openAttempts.push(attempt)
				}
			})
		},
		checkTopicDueDate() {
			//handle null due date
			if (!this.currentTopic.es_duedate) {
				return true
			}

			//if it's not a final exam, can view submission before due date
			if (![717580004, 717580005].includes(this.currentTopic.es_type)) {
				return true
			}

			let dueDate = moment(this.currentTopic.es_duedate)

			if (dueDate._isValid) {
				var a = moment().startOf("day")
				var b = dueDate.startOf("day")
				var diff = b.diff(a, "days") // =1
				if (diff > 1) {
					return false
				} else {
					return true
				}
			} else {
				return false
			}
		},
		sortTopicAttempts(data) {
			return data.sort((a, b) => {
				return moment(b.date, "MMMM Do YYYY, [at] h:mm a") - moment(a.date, "MMMM Do YYYY, [at] h:mm a")
			})
		},
		handleClick(selections) {
			//takes filtered selections from the data object
			//opens a b-modal and renders a quiz-selections component inside it
			this.currentAttemptSelections = selections
			this.isSelectionsModalActive = true
		},
		handleContinue(row) {
			this.continueAttempt(row)
		},
		continueAttempt(row) {
			if (!this.currentAttempt) {
				//if its a quiz, make an attempt when opened
				this.setCurrentAttempt(row.attempt.es_attemptid)
				this.updateContinuingQuiz(true)
				this.updateTakingQuiz(true)
				let currentIndex
				let continueArr

				this.setPreviousSelections(row.selections)

				if (this.currentTopic.es_type != 717580004) {
					continueArr = this.makeContinueArr(row)

					this.setQuizQuestionsArr(continueArr)

					currentIndex = this.findLastQuestion(continueArr)

					this.updateCurrentQuestionIndex(currentIndex)
				}
				this.startTimer()

				this.pushPreviousStudentQuizSelections(currentIndex, continueArr)

				EventBus.$emit("agree-to-attempt", true)
			}
		},
		pushPreviousStudentQuizSelections(currentIndex, continueArr) {
			let typeDictionary = {
				"Fill in the blank": 717580000,
				"Multiple Choice": 717580003,
				"Multiple Select": 717580004,
				"Open Response": 717580006,
				Upload: 717580007
			}

			for (let i = 0; i < currentIndex; i++) {
				let question = continueArr[i]

				let choices = this.previousSelections.filter(choice => choice.es_question_id === question.es_topicquestionid)
				choices = this.makeChoicesArr(choices)

				let studentQuizSelection = {
					es_topicquestionid: question.es_topicquestionid,
					choices: choices,
					es_text: question.es_name,
					es_type: typeDictionary[question.es_type],
					es_sort: question.es_sort,
					es_selectionstatus: question.es_selectionstatus
				}
				this.addSelection(studentQuizSelection)
			}
		},
		makeChoicesArr(choices) {
			let result = []

			choices.forEach(choice => {
				result.push({
					es_topicquestionchoiceid: choice.es_choice_id,
					es_topicquestionid: choice.es_question_id,
					es_text: choice.es_choice_text,
					file: null
				})
			})

			return result
		},
		makeContinueArr(row) {
			let arr = []

			row.selections.forEach(selection => {
				arr.push({
					es_name: selection.es_question_text,
					es_question_id: selection.es_question_id,
					es_text: selection.es_choice_text,
					es_topicquestionid: selection.es_question_id,
					es_type: selection.es_question_type,
					_es_topic_value: selection.es_attempt._es_topic_value,
					es_selectionstatus: selection.es_selectionstatus,
					es_sort: selection.es_sort
				})
			})

			arr.sort((a, b) => a.es_sort - b.es_sort)

			return arr || null
		},
		findLastQuestion(continueArr) {
			let answered = continueArr.filter(selection => {
				return selection.es_selectionstatus != 717580002
			})

			return answered.length > 0 ? answered[answered.length - 1].es_sort + 1 : 0
		}
	}
}
</script>

<style lang="scss">
#app .quiz-history-component {
	max-width: 1100px;
	margin-right: 1rem;
	.text-align-center {
		text-align: center;
	}

	.modal-content.animation-content {
		background-color: white;
		width: 500px;
		min-height: 80vh;
		margin: auto;
		overflow-y: scroll;
		border-radius: 5px 0px 0px 5px;
	}

	.table td {
		vertical-align: middle;
	}
}
</style>
