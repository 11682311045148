import request from './request';

export const getSelections = (data) =>
    request({
        url: `selections?topicid=${data.topicid}&enrollmentid=${data.enrollmentid}`,
        method: 'get',
    })


export const submitSelections = (data) =>
    request({
        url: '/batchselections',
        method: 'post',
        data: data,
        headers: { 'Content-Type': 'application/json' },
    })


export const skipSelection = (data) =>
    request({
        url: '/skipselection',
        method: 'post',
        data: data,
        headers: { 'Content-Type': 'application/json' },
    })

export const getCorrectChoices = (data) =>
    request({
        url: `/correctchoices?questionid=${data.questionids.join("&questionid=")}&enrollmentid=${data.enrollmentid}&topicid=${data.topicid}`,
        method: 'get',
        headers: { 'Content-Type': 'application/json' },
    })