<template>
	<div class="enroll-view">
		<p v-if="!$route.params.email" class="text">Preparing your new cohort...</p>
		<p v-else class="text">Redirecting to our identity providers...</p>

		<Loader />
	</div>
</template>

<script>
import router from "../router/router.js"
import { mapGetters, mapActions } from "vuex"
import axios from "axios"
import Loader from "../components/Loader"

export default {
	name: "Enroll",
	components: { Loader },
	computed: mapGetters(["errorMessage", "baseUrl", "contact", "cohorts", "cohortsArray", "currentModule", "currentTopic", "currentCohort"]),
	created() {
		if (this.$route.params.email) {
			let url = `/api/enroll?cohortid=${this.$route.params.cohortid}&email=${this.$route.params.email}`
			window.location.href = url
		} else if (!this.$route.params.email && this.$route.params.cohortid) {
			//enroll me, i'm logged in
			this.addCourse(this.$route.params.cohortid)
		}
	},
	mounted() {
		// this.changeCurrentPage("Error");
	},
	watch: {
		currentCohort: function() {
			// let newCohort = this.cohortsArray.find(cohort => {
			//     return cohort.es_cohortid == this.$route.params.cohortid;
			// });

			// this.changeCurrentCohort(newCohort).then(res => {
			//     // redirect to first topic
			router.push({
				path: `/cohort/${this.currentCohort.es_cohortid}/module/${this.currentModule.es_moduleid}/topic/${this.currentTopic.es_topicid}`
			})
			// });
		}
	},
	methods: {
		...mapActions(["changeCurrentPage", "autoEnroll", "changeCurrentCohort"]),
		addCourse(cohortid) {
			this.autoEnroll({
				contact: this.contact || null,
				es_cohortid: cohortid,
				redirect: true
			})
		}
	}
}
</script>

<style lang="scss">
#app .enroll-view {
	// background-color: #26a0da;
	// background-image: linear-gradient(to bottom right, #2aa8df, #1479bf);
	background-color: #0f172a;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 3rem;
	flex-direction: column;

	.text {
		color: black;
		font-size: 2rem;
		font-weight: bold;
		letter-spacing: 0.05rem;
		background-color: transparent;
		margin-bottom: 2rem;
	}
}
</style>
