<template>
	<div
		class="flex hundo-height current-lesson-component"
		id="current-lesson"
		:class="
			currentTopic.es_type === 717580000 && currentTopic.es_video != null && !currentTopic.es_content
				? 'flex justify-center flex-column black-background'
				: 'white-background'
		"
	>
		<section
			v-if="topicContentPiecesLoaded >= 3 || [717580002, 717580005].includes(currentTopic.es_type)"
			class="current-lesson-container hundo-width overflow-y-hidden"
			id="current-lesson-view"
			:class="
				currentTopic.es_type === 717580000 && currentTopic.es_video != null && currentTopic.es_content == null
					? 'black-background'
					: null
			"
		>
			<!-- lab -->
			<lab v-if="currentTopic.es_type === 717580001 && (currentTopic.es_labscreen == 717580001 || currentTopic.es_labscreen == 717580002)" />

			<!-- video -->
			<single-col-video v-else-if="currentTopic.es_type === 717580000 && currentTopic.es_video != null && currentTopic.es_content == null" />

			<!--content + video-->
			<video-with-content v-else-if="currentTopic.es_type === 717580000 && currentTopic.es_video != null" />

			<!--assessment-->
			<assessment-page v-else-if="currentTopic.es_type === 717580003" />

			<!--quiz-->
			<quiz-container
				v-else-if="this.currentTopic.es_type == 717580002 || this.currentTopic.es_type == 717580005"
				:key="currentTopic.es_topicid + 'currentlesson'"
			/>

			<!--final lab-->
			<final-lab-container v-else-if="this.currentTopic.es_type == 717580004" :key="currentTopic.es_topicid" />

			<!--final quiz-->
			<!-- <final-quiz
                v-else-if="this.currentTopic.es_type == 717580005"
                :key="currentTopic.es_topicid"
            />-->

			<!--content -->
			<content-topic v-else />

			<!--feedback modal-->
			<topic-feedback-modal />
		</section>
		<Loader v-else />
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import Loader from "./Loader.vue"
import MarkdownRenderer from "./MarkdownRenderer.vue"
import SingleColVideo from "./SingleColVideo.vue"
import VideoWithContent from "./VideoWithContent.vue"
import TopicFeedbackModal from "./TopicFeedbackModal"
import EventBus from "../event-bus"
import AssessmentPage from "./AssessmentPage.vue"
import QuizContainer from "./QuizContainer.vue"
import Lab from "./Lab.vue"
import ContentTopic from "./ContentTopic.vue"
import FinalLabContainer from "./FinalLabContainer.vue"

export default {
	name: "CurrentLesson",
	computed: {
		...mapGetters(["currentContent", "currentTopic", "topicContentPiecesLoaded", "frameUrl", "allMachines", "currentAttempt"])
	},
	data() {
		return {
			iframe: null,
			calcWidth: 0
		}
	},
	components: {
		Loader,
		MarkdownRenderer,
		SingleColVideo,
		TopicFeedbackModal,
		AssessmentPage,
		Lab,
		VideoWithContent,
		QuizContainer,
		ContentTopic,
		FinalLabContainer
	},
	mounted() {
		// this.changeCurrentContent();
		this.adjustPadding()
		// if (this.currentTopic) {
		//     if (
		//         this.currentTopic.es_type === 717580000 &&
		//         this.currentTopic.es_video != null &&
		//         this.currentTopic.es_content == null
		//     ) {
		//         this.calculateWidth();
		//     }
		// }

		window.addEventListener("resize", this.onResize)

		if (this.allMachines && this.allMachines.length === 0) {
			this.loadConnectionIds()
		}

		EventBus.$on("adjust-padding", data => {
			this.adjustPadding()
		})
	},
	beforeDestroy() {
		// Unregister the event listener before destroying this Vue instance
		window.removeEventListener("resize", this.onResize)
	},
	watch: {
		currentTopic: function() {
			this.adjustPadding()
		},
		currentAttempt: function() {
			//reset height for ongoing quizzes
			if ([717580002, 717580005].includes(this.currentTopic.es_type)) {
				document.getElementById("current-lesson").style.height = "100%"
			}
		}
	},
	methods: {
		...mapActions(["changeCurrentContent", "loadConnectionIds"]),
		adjustPadding() {
			//function is needed to set the height of the content div as 100% minus the height of the content-nav (which is variable)
			let currentNav = document.getElementById("content-nav") || document.getElementById("quiz-header")
			let navHeight = currentNav.offsetHeight

			let availHeight = document.getElementById("course-content-container").offsetHeight

			let newHeight = availHeight - navHeight

			document.getElementById("current-lesson").style.height = `${newHeight}px`
		},
		onResize(event) {
			this.adjustPadding()
		}
	}
}
</script>

<style lang="scss">
#app .current-lesson-component {
	height: 100%;
	.flex {
		display: flex;
	}

	.hundo-width {
		width: 100%;
	}

	.hundo-height {
		height: 100%;
	}

	.no-bottom-padding {
		padding-bottom: 0px !important;
	}

	.relative {
		position: relative;
	}

	.top-padding-2-5 {
		padding-top: 2.5rem !important;
	}

	.bottom-padding-2-5 {
		padding-bottom: 2.5rem !important;
	}

	.bottom-margin-2-5 {
		margin-bottom: 2.5rem !important;
	}

	.top-margin-2-5 {
		margin-top: 2.5rem !important;
	}

	.hundo-minus-80-height {
		height: calc(100% - 5rem);
		// height: 100%;
	}

	.black-background {
		background-color: black;
	}

	.align-center {
		align-items: center;
	}

	.flex-column {
		flex-direction: column;
	}

	.justify-center {
		justify-content: center;
	}

	.white-background {
		background-color: white;
	}

	#current-lesson-view {
		height: inherit;
	}

	.hidden {
		display: none;
	}

	.overflow-y-hidden {
		overflow-y: hidden;
	}
}
</style>
