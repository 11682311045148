<template>
  <span class="topic-feedback-modal-component">
    <b-modal
      :active.sync="feedbackModal"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
      id="feedback-modal"
      class="feedback-modal"
    >
      <div class="feedback-modal cognito">
        <h2 class="date-title">{{ currentTopic.es_name }}</h2>

        <!--  show form once feedback is checked and iframe loaded-->
        <div :style="feedbackLoaded && iframeLoaded ? null : 'display:none;'">
          <iframe
            :src="
              `https://services.cognitoforms.com/f/cWWlgBg3P0iegMgpV3diNA?id=2`
            "
            style="position:relative;border-radius:5px;height:550px"
            frameborder="0"
            scrolling="yes"
            seamless="seamless"
            @load="iframeLoaded = true"
          ></iframe>

          <script
            type="application/javascript"
            src="https://services.cognitoforms.com/scripts/embed.js"
          ></script>

          <!-- topic prefill -->
          <script type="application/javascript">
            Cognito.prefill({
              TopicId: "{{this.currentTopic.es_topicid}}",
              EnrollmentId: "{{this.currentEnrollment.es_enrollmentid}}"
            })
          </script>

          <!--cognito css-->
          <script type="application/javascript">
            Cognito.setCss(
              ".cognito:not(.c-forms-actionBar) {margin:0px} .cognito * {font-weight:400 !important;}.cognito .c-forms-form .c-forms-form-title h2 {font-size:1.5rem;font-weight:400;} .cognito .c-forms-heading .c-forms-form-title {padding:0px}"
            )
          </script>

          <!--  show the submitted date if feedback submitted before-->
          <div
            v-if="
              feedbackLoaded &&
                currentTopicFeedbacks &&
                currentTopicFeedbacks.length > 0
            "
            class="submitted-date"
          >
            <p>Feedback last submitted on {{ topicSubmittedDate }}.</p>
          </div>
        </div>
        <!-- show the loader while iframe and feedback are loading  -->
        <Loader v-if="!iframeLoaded || !feedbackLoaded" />
      </div>
    </b-modal>
  </span>
</template>

<script>
import EventBus from "../event-bus"
import Loader from "./Loader"
import moment from "moment"
import timezone from "moment-timezone"
import { mapGetters, mapActions } from "vuex"

export default {
  name: "TopicFeedbackModal",
  components: { Loader },
  computed: {
    ...mapGetters([
      "currentTopic",
      "currentEnrollment",
      "feedbackLoaded",
      "currentTopicFeedbacks",
      "timeZone",
      "currentCohort"
    ]),
    topicSubmittedDate: function() {
      return moment(this.currentTopicFeedbacks[0].createdon)
        .tz(this.timeZone)
        .format("MMMM Do YYYY, [at] h:mm a z")
    }
  },
  data() {
    return {
      feedbackModal: false,
      iframeLoaded: false
    }
  },
  mounted() {
    this.getFeedbacksForTopic({
      topicid: this.currentTopic.es_topicid,
      enrollmentid: this.currentEnrollment.es_enrollmentid
    })

    //activate feedback modal when topic submitted
    EventBus.$on("topic-submitted", () => {
      // dont show if it's async enterprise and not a lab
      let dontShow =
        717580007 === this.currentCohort.es_type &&
        this.currentTopic.es_type !== 717580001
      if (!dontShow) {
        this.feedbackModal = true
      }
    })

    //activate feedback modal when feedback clicked
    EventBus.$on("feedback-button-clicked", () => {
      this.feedbackModal = true
    })

    EventBus.$on("topic-feedback-submitted", () => {
      this.feedbackModal = false
    })
  },
  beforeDestroy() {
    this.clearCurrentFeedbacks()
  },
  methods: {
    ...mapActions(["getFeedbacksForTopic", "clearCurrentFeedbacks"])
  }
}
</script>

<style lang="scss">
#app .topic-feedback-modal-component {
  #feedback-modal .cognito {
    height: unset;
  }

  #feedback-modal .animation-content {
    background-color: white !important;
    z-index: 5;
    display: block;
    padding: 1rem;
    border-radius: 5px;
    max-width: 350px !important;
    max-height: 90%;
    overflow-y: auto;
    overflow-x: hidden;
    min-width: 344px !important;
    min-height: 200px !important;
  }

  .cognito {
    .margin-bottom-1 {
      margin-bottom: 1rem !important;
    }

    h2 {
      text-transform: uppercase;
      padding: 1rem 0.5rem !important;
      font-weight: bold !important;
    }

    $radio-size: 1rem;

    .align-items-center {
      align-items: center;
    }

    .padding-bottom-1 {
      padding-bottom: 1rem;
    }

    .padding-top-1 {
      padding-top: 1rem;
    }

    .margin-left-half {
      margin-left: 0.5rem;
    }

    .submitted-date {
      padding: 1rem 0rem;
      font-size: 0.8rem;
      font-style: italic;
    }
  }
}
</style>
