<template>
    <div class="classmate-image-component">
        <div class="classmate-image-container center flex padding-top-1">
            <img
                v-if="image && image != 'none'"
                class="classmate-image"
                :src="`data:${classmate.image.mimetype};base64,${classmate.image.documentbody}`"
            />
            <img v-else-if="image && image == 'none'" class="classmate-image" :src="hackerHeadshot" />
            <Loader v-else />
        </div>

        <div class="classmate-name-container flex justify-center">
            <p>{{classmate.fullname}}</p>
            <div v-if="classmate.es_linkedin" class="classmate-linkedin">
                <a class="flex" :href="classmate.es_linkedin" target="_blank">
                    <vue-fontawesome :icon="['fab', 'linkedin']" size="xs" color="#0077b5" />
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Loader from "./Loader";
import hackerHeadshot from "../assets/hacker-headshot.jpg";

export default {
    name: "ClassmateImage",
    components: { Loader },
    props: ["classmate"],
    computed: {
        ...mapGetters(["contact", "allClassmates", "currentCohort"])
    },
    data() {
        return {
            hackerHeadshot: hackerHeadshot,
            image: null
        };
    },
    watch: {
        allClassmates: function() {
            this.checkClassmateImage();
        }
    },
    mounted() {
        //get classmates if don't have
        if (this.classmate && !this.classmate.image) {
            let data = {
                contactid: this.classmate.contactid,
                cohortid: this.classmate.cohortid
            };
            this.getClassmateImage(data);
        } else {
            this.image = this.classmate.image;
        }
    },
    methods: {
        ...mapActions(["getClassmateImage"]),
        checkClassmateImage() {
            let localClassmate = this.allClassmates.find(classmate => {
                return classmate.contactid == this.classmate.contactid;
            });
            if (this.image != localClassmate.image) {
                this.image = localClassmate.image;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
#app .classmate-image-component {
    width: 125px;
    margin-right: 1rem;

    .flex {
        display: flex;
    }

    .row {
        flex-direction: row;
    }

    .font-size-1-2 {
        font-size: 1.2rem;
    }

    .font-bold {
        font-weight: bold;
    }

    .classmate-image-container {
        height: 125px;
        overflow: hidden;
    }

    .classmate-image {
        object-fit: cover;
        object-position: 50% 50%;
    }

    .justify-center {
        justify-content: center;
    }

    .classmate-name-container {
        padding-top: 0.25rem !important;
    }

    .classmate-name-container p {
        font-size: 0.6rem;
    }

    .classmate-linkedin {
        display: flex;
        padding-left: 0.25rem;
        cursor: pointer;
    }

    .fa-linkedin:hover {
        color: #028bd2;
    }

    .padding-top-2 {
        padding-top: 2rem;
    }

    .center {
        justify-content: center;
        align-items: center;
    }

    .wrap {
        flex-wrap: wrap;
    }

    .padding-top-1 {
        margin-top: 1rem;
    }

    .padding-top-1-2 {
        padding-top: 0.5rem;
    }
}
</style>