<template>
	<div class="attendance-percent-component">
		<b-tooltip label="Affects your ESCP exam eligibility" type="is-info">
			<vue-fontawesome :icon="['fas', 'info-circle']" size="sm" color="#0077b5" />
		</b-tooltip>
		<p v-if="percent" class="font-size-2 flex-center">{{ percent }}</p>
		<Loader v-else />
		<p class="text-center">Session Attendance</p>
		<div class="flex-center align-center pt-sm">
			<b-button size="is-small" @click="goToAttendance">View and Print</b-button>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import Loader from "./Loader"
import router from "../router/router.js"

export default {
	name: "AttendancePercent",
	components: { Loader },
	computed: {
		...mapGetters(["attempts", "currentCohort", "cohortAttendance", "currentEnrollment"])
	},
	data() {
		return {
			percent: null
		}
	},
	mounted() {
		if (
			this.cohortAttendance &&
			this.cohortAttendance.length > 0 &&
			this.cohortAttendance[0].es_enrollment.es_enrollmentid == this.currentEnrollment.es_enrollmentid
		) {
			this.calculatePercent()
		} else {
			this.getAttendanceForCohort({
				cohortid: this.currentCohort.es_cohortid,
				enrollmentid: this.currentEnrollment.es_enrollmentid
			})
		}
	},
	watch: {
		cohortAttendance: function() {
			this.calculatePercent()
		}
	},
	methods: {
		...mapActions(["getAttendanceForCohort"]),
		calculatePercent() {
			//make array of attendance statuses
			let arrayOfStatuses = this.buildStatusArray()
			//save how many records
			let total = arrayOfStatuses.length

			if (total == 0) {
				this.percent = "100%"
			} else {
				//filter for absences
				let absences = arrayOfStatuses.filter(status => {
					return status == "Absent"
				})
				//attendance is the number of absences over number of records
				if (absences.length > 0) {
					this.percent = (absences.length / total) * 100 + "%"
				} else {
					this.percent = "100%"
				}
			}
		},
		buildStatusArray() {
			let arr = []
			this.cohortAttendance.forEach(attendance => {
				//set status to human readable
				let status = this.makeStatusReadable(attendance.es_status)

				//apend attendance to arrayOfTopics
				if (status) {
					arr.push(status)
				}
			})
			return arr
		},
		makeStatusReadable(status) {
			switch (status) {
				case 717580000:
					return "Attended"
					break
				case 717580002:
					return "Excused Absence"
					break
				case 717580003:
					return "Late"
					break
				case 717580001:
					return "Absent"
					break
				default:
					return null //no attendance
			}
		},
		goToAttendance() {
			router.push({
				path: `/grades/${this.currentCohort.es_cohortid}/attendance`
			})
		}
	}
}
</script>

<style lang="scss">
#app .attendance-percent-component {
	position: relative;

	.flex-center {
		display: flex;
		flex-direction: row;
		justify-content: center;
	}

	.align-center {
		align-items: center;
	}

	.font-size-2 {
		font-size: 2rem;
	}

	.text-center {
		text-align: center;
	}

	svg {
		cursor: help;
	}

	.b-tooltip {
		position: absolute;
		top: 4px;
		right: 4px;
	}

	.pt-sm {
		padding-top: 0.5rem;
	}
}
</style>
