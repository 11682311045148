<template>
    <div v-if="!loadingCohorts" class="cohort-row-cards">
        <div
            v-for="cohort in freeCohorts"
            class="full-width margin-2 flex-row overflow-hidden border-radius-pt25 shadow white-background"
            :key="cohort.es_cohortid+'-enroll-card'"
            :class="cohort.enrollmentsCount<cohort.es_maxenrollments ? 'cursor-pointer' : null"
        >
            <AddCohortRowCard :cohort="cohort" />
        </div>
    </div>
    <Loader v-else />
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import router from "../router/router.js";
import CohortImage from "../components/CohortImage.vue";
import thumbnail from "../assets/academy-image-min.jpg";
import AddCohortRowCard from "../components/AddCohortRowCard";
import Loader from "../components/Loader";

export default {
    name: "AddRowCards",
    components: { CohortImage, AddCohortRowCard, Loader },
    props: ["tab"],
    data() {
        return {
            thumbnail: thumbnail
        };
    },
    computed: {
        ...mapGetters([
            "baseUrl",
            "currentTopic",
            "currentModule",
            "contact",
            "cohortsArray",
            "currentCohort",
            "freeCohorts",
            "currentEnrollment",
            "contact",
            "loadingCohorts"
        ])
    },
    mounted() {
        // this.getFreeCohorts();
    },
    watch: {
        cohortsArray: function() {
            // this.getFreeCohorts();
        }
    },
    methods: {
        ...mapActions([
            "changeCurrentPage",
            "changeCurrentCohort",
            "getFreeCohorts"
        ]),
        goToCourse() {
            router.push({
                path: `/cohort/${this.currentCohort.es_cohortid}/module/${this.currentModule.es_moduleid}/topic/${this.currentTopic.es_topicid}`
            });
        },
        ...mapActions(["changeCurrentPage", "changeCurrentCohort"]),
        changeCohort(cohort) {
            this.changeCurrentCohort(cohort);
            this.goToCourse();
        }
    }
};
</script>

<style lang="scss">
#app .add-row-cards {
    display: flex;
    flex-direction: column;
    align-items: center;

    .full-width {
        width: 100%;
        max-width: 90rem;
    }

    .cursor-pointer {
        cursor: pointer;
    }

    .margin-2 {
        margin: 1rem 2rem;
    }

    .flex-row {
        display: flex;
        flex-direction: row;
    }

    .flex-column {
        display: flex;
        flex-direction: column;
    }

    .width-25-percent {
        width: 25%;
    }

    .width-75-percent {
        width: 75%;
    }

    .min-width-15 {
        min-width: 15rem;
        max-width: 20rem;
    }

    .padding-1 {
        padding: 1rem;
    }

    .has-border {
        border: 1px solid lightgray;
        border-radius: 0.25rem;
        overflow-x: hidden;
        overflow-y: hidden;
    }

    // .shadow {
    //     box-shadow: 0rem 0.5rem 0.5rem rgba(0, 0, 0, 0.6);
    //     transition: box-shadow 150ms ease-out;
    // }

    // .shadow:hover {
    //     box-shadow: 0rem 0.7rem 0.7rem rgba(0, 0, 0, 0.7);
    //     transition: box-shadow 150ms ease-in;
    // }

    .white-background {
        background-color: white;
    }

    .justify-center {
        justify-content: center;
    }

    .v-padding-2 {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    .padding-left-3 {
        padding-left: 3rem;
    }
}
</style>