<template>
	<div class="submitted-chart-component" v-if="data">
		<!-- Checkboxes -->
		<div class="sticky-header">
			<div class="block padding-right-1 padding-top-half">
				<b-checkbox v-model="checkboxGroup" type="is-info" native-value="Topic">Lectures</b-checkbox>
				<b-checkbox v-model="checkboxGroup" type="is-info" native-value="Lab">Labs</b-checkbox>
				<b-checkbox v-model="checkboxGroup" type="is-info" native-value="Quiz">Quizzes</b-checkbox>
				<b-checkbox v-model="checkboxGroup" type="is-info" native-value="Assessment">Assessments</b-checkbox>
			</div>
		</div>

		<!-- Table -->
		<b-table :data="isEmpty ? [] : data" sticky-header :row-class="applyClasses" striped>
			<!-- Icon column -->
			<b-table-column field="type" label="Type" centered style="width:10%; min-width:4rem" :sortable="true" v-slot="props">
				<vue-fontawesome v-if="props.row.type === 'Lab'" icon="flask" color="black" size="lg" />

				<vue-fontawesome v-else-if="props.row.type === 'Topic'" :icon="['fas', 'file-video']" size="lg" color="black" />

				<vue-fontawesome v-else-if="props.row.type === 'Assessment'" :icon="['fas', 'clipboard-check']" size="lg" color="black" />

				<vue-fontawesome v-else-if="props.row.type === 'Quiz'" :icon="['fas', 'question-circle']" size="lg" color="black" />
			</b-table-column>

			<!-- topic name column -->
			<b-table-column field="name" label="Name" :sortable="true" style="width:50%; min-width:28.5rem;" class="truncate" v-slot="props">
				<div class="flex flex-row">
					<div
						class="truncate pointer color-blue bold vertical-margin-auto"
						@click="handleClick(props.row.topic, props.row.module)"
					>
						{{ props.row.name }}
					</div>

					<!-- view quiz button-->
					<!-- graded -->
					<b-button
						v-if="
							props.row.type == 'Quiz' &&
								props.row.attempts.es_attemptstatus != 717580000 &&
								isPastDue(props.row.topic)
						"
						type="is-success"
						size="is-small"
						rounded
						class="margin-left-1"
						@click="handleViewClick(props.row.topic, props.row.attempts)"
						>View</b-button
					>

					<!-- pending -->
					<b-button
						v-else-if="props.row.type == 'Quiz' && props.row.attempts"
						type="is-light"
						size="is-small"
						class="margin-left-1"
						disabled
						rounded
						>Pending</b-button
					>
				</div>
			</b-table-column>

			<!-- grade column -->
			<b-table-column
				:sortable="true"
				field="score"
				centered
				:custom-sort="sortGrades"
				label="Grade"
				class="text-center"
				style="width:15%; min-width:6rem"
				v-slot="props"
			>
				<span v-if="props.row.attempts && props.row.attempts.es_attemptstatus != 717580000">{{
					props.row.attempts ? Math.round(props.row.attempts.es_score * 100) + "%" : ""
				}}</span>

				<span v-else-if="props.row.attempts">Pending</span>
			</b-table-column>

			<!-- date column -->
			<b-table-column
				:sortable="true"
				field="date"
				:custom-sort="sortDates"
				centered
				label="Date"
				class="text-center"
				style="width:15%; min-width:12rem"
				v-slot="props"
			>
				<span v-if="props.row.attempts">{{ props.row.attempts.es_timeend }}</span>
				<span v-else-if="props.row.es_timeend">{{ props.row.es_timeend }}</span>
				<span v-else>N/A</span>
			</b-table-column>

			<!-- status column -->
			<b-table-column sortable field="status" label="Status" class="text-center" style="width:25%; min-width:12rem" v-slot="props">
				<!-- pending quiz status -->
				<span v-if="props.row.type == 'Quiz' && (props.row.attempts.es_attemptstatus == 717580000 || !isPastDue(props.row.topic))"
					>Pending</span
				>

				<!-- status -->
				<span v-else>{{ props.row.status }}</span>
			</b-table-column>

			<!-- empty -->
			<template slot="empty">
				<div class="content has-text-grey has-text-centered">
					<p>
						<vue-fontawesome :icon="['far', 'frown']" />
					</p>
					<p>Nothing here.</p>
				</div>
			</template>
		</b-table>

		<!-- selections modal -->
		<b-modal :active.sync="isSelectionsModalActive">
			<grades-quiz-selections :topic="selectionsTopic" :attempt="selectionsAttempt" />
		</b-modal>
	</div>
	<!-- loader -->
	<loader v-else />
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import EventBus from "../event-bus"
import Loader from "./Loader"
import router from "../router/router.js"
import SessionPage from "./SessionPage"
import GradesQuizSelections from "./GradesQuizSelections"
import moment from "moment"

export default {
	name: "SubmittedChart",
	components: { Loader, SessionPage, GradesQuizSelections },
	computed: {
		...mapGetters(["currentCohort", "attempts", "currentEnrollment", "currentSelections"])
	},
	data() {
		return {
			data: null,
			checkboxGroup: ["Topic", "Lab", "Quiz", "Assessment"],
			isSelectionsModalActive: false,
			selectionsTopic: null,
			selectionsAttempt: null,
			averageScore: null,
			isEmpty: false
		}
	},
	mounted() {
		this.buildGradeData()
	},
	watch: {
		checkboxGroup: function() {
			if (this.checkboxGroup.length != 0) {
				if (this.isNoneVisible()) {
					this.isEmpty = true
					// this.averageScore = "0%";
				} else {
					this.isEmpty = false
					// this.averageScore = this.calculateAverageScore();
				}
			} else {
				// this.averageScore = "0%";
				this.isEmpty = true
			}
		},
		data: function() {
			if (this.data.length == 0) {
				// document.querySelector(".is-empty td").colSpan = "4";
				// this.averageScore = "0%";
				let emptyRow = document.querySelector(".is-empty td")
				if (emptyRow) {
					emptyRow.colSpan = "4"
				}
			} else {
				// this.averageScore = this.calculateAverageScore();
			}
		}
	},
	methods: {
		...mapActions(["updateCurrentModule", "navigateToTopic", "updateCurrentTopic", "changeCurrentPage", "getSelections"]),
		buildGradeData() {
			//filter topics from currentCohort
			let arrayOfTopics = this.buildArrayOfCurrentTopics()

			//filter attempts for submitted attempts
			let submittedAttempts = this.filterSubmittedAttempts()

			//append attempts to each element
			arrayOfTopics = this.appendAttempts(arrayOfTopics, submittedAttempts)

			//filter out topics with no attempt
			arrayOfTopics = this.filterUnsubmittedTopics(arrayOfTopics)

			//change dates to readable format
			arrayOfTopics = this.formatDates(arrayOfTopics)

			this.data = arrayOfTopics
		},
		buildArrayOfCurrentTopics() {
			let arr = []
			this.currentCohort.modules.forEach(module => {
				module.topics.forEach(topic => {
					arr.push({
						topicid: topic.es_topicid,
						topic: topic,
						module: module,
						name: topic.es_name || topic.es_displayname,
						status: null,
						duedate: topic.es_duedate || null,
						type: this.makeTypeReadable(topic.es_type),
						moduleid: topic._es_module_value
					})
				})
			})
			return arr
		},
		filterSubmittedAttempts() {
			return this.attempts.filter(attempt => {
				return attempt.es_issubmitted && attempt.es_attemptstatus == 717580001
			})
		},
		appendAttempts(arrayOfTopics, submittedAttempts) {
			arrayOfTopics = arrayOfTopics.map(topic => {
				let topicAttempts = submittedAttempts.filter(attempt => topic.topicid === attempt._es_topic_value)

				//append attempts to the topic if there are any for the topic
				//return the new topic
				if (topicAttempts.length > 0) {
					//filter for the attempt with the highest score
					topicAttempts = this.returnHighestScore(topicAttempts)
					topic.attempts = topicAttempts
					topic.status = this.returnChartStatus(topicAttempts, topic.type)
				} else {
					//set the topic to null since there are no attempts i.e. it hasn't been submitted
					topic.attempts = null
				}

				return topic
			})
			return arrayOfTopics
		},
		formatDates(arrayOfTopics) {
			return arrayOfTopics.map(topic => {
				//format date

				if (moment(topic.attempts.es_timeend).format("MMMM Do YYYY") !== "Invalid date") {
					topic.attempts.es_timeend = moment(topic.attempts.es_timeend).format("MMMM Do YYYY")
				}

				return topic
			})
		},

		filterUnsubmittedTopics(arrayOfTopics) {
			//return topics that have attempt data
			return arrayOfTopics.filter(topic => {
				return topic.attempts != null
			})
		},
		applyClasses(row, index) {
			let filter = this.checkboxGroup.includes(row.type)
			if (!filter) {
				return "hidden-row"
			}
			return "visible-row"
		},
		isNoneVisible() {
			let visibleData = []

			this.data.forEach(row => {
				if (this.checkboxGroup.includes(row.type)) {
					visibleData.push(row)
				}
			})

			if (visibleData.length == 0) {
				return true
			}
			return false
		},
		calculateAverageScore() {
			if (this.data) {
				//filter grades by filters
				let visibleScores = this.filterScoresByFilters()

				//average grades
				if (visibleScores.length > 0) {
					let averageScore =
						visibleScores.reduce((total, next) => {
							return total + next
						}) / visibleScores.length

					//stringify
					averageScore = Math.round(averageScore * 100) + "%"
					//return
					return averageScore
				}
			}

			return null
		},
		sortGrades(a, b, c) {
			//custom sort function for b-table, no grade counts as 0 for sorting
			a = a.attempts ? a.attempts.es_score : 0
			b = b.attempts ? b.attempts.es_score : 0

			if (c) {
				return b - a
			} else {
				return a - b
			}
		},
		sortDates(a, b, c) {
			// find and assign date
			if (a.es_timeend) {
				a = a.es_timeend
			} else if (a.attempts.es_timeend) {
				a = a.attempts.es_timeend
			}

			if (b.es_timeend) {
				b = b.es_timeend
			} else if (b.attempts.es_timeend) {
				b = b.attempts.es_timeend
			}

			//calculate diff and order
			if (c) {
				return moment(a, "MMMM Do YYYY").diff(moment(b, "MMMM Do YYYY"))
			}
			return moment(b, "MMMM Do YYYY").diff(moment(a, "MMMM Do YYYY"))
		},
		handleClick(topic, module) {
			this.updateCurrentModule(module)
			this.updateCurrentTopic(topic)
			this.navigateToTopic(topic)
		},
		handleViewClick(topic, attempts) {
			this.selectionsTopic = topic
			this.selectionsAttempt = attempts
			this.isSelectionsModalActive = true
		},
		returnChartStatus(topicAttempts, type) {
			//sets the status for topics
			switch (type) {
				case "Topic":
				case "Lab":
					return "Submitted"
					break
				case "Quiz":
					return topicAttempts.es_score >= 0.7 ? "Pass" : "Fail"
					break
				case "Assessment":
					return topicAttempts.es_score >= 0.7 ? "On Track" : "Needs Improvement"
					break
				default:
					return null //no attendance
			}
		},
		returnHighestScore(topicAttempts) {
			let reducedAttempt = topicAttempts.reduce((prev, current) => {
				return prev.es_score > current.es_score ? prev : current
			})

			return reducedAttempt
		},
		filterScoresByFilters() {
			let visibleScores = []

			this.data.forEach(row => {
				if (row.attempts && this.checkboxGroup.includes(row.type)) {
					visibleScores.push(row.attempts.es_score)
				}
			})

			return visibleScores
		},
		isPastDue(topic) {
			let pastDue

			if (topic.es_duedate) {
				var diff = moment(topic.es_duedate)
					.startOf("day")
					.diff(moment(), "days") // =1
				if (diff < 0) {
					pastDue = true
				}
			}

			return pastDue
		},
		//TODO: Write code to appropriately assign "Exam", "Practical", and "Nonprofit" types
		makeTypeReadable(type) {
			switch (type) {
				case 717580000:
					return "Topic"
					break
				case 717580001:
					return "Lab"
					break
				case 717580003:
					return "Assessment"
					break
				case 717580002:
				case 717580004:
				case 717580005:
					return "Quiz"
					break
				default:
					return null //no type
			}
		}
	}
}
</script>

<style lang="scss">
#app .submitted-chart-component {
	.sticky-header {
		position: sticky;
		top: 41px;
		background-color: white;
		z-index: 10;
		height: 3rem;
		display: flex;
		align-items: center;
		border-bottom: 2px solid #d4d2d2;
		justify-content: flex-end;
	}

	.sticky-header p {
		padding-left: 2rem;
		font-size: 1.1rem;
	}

	.b-table .table {
		border-top: 0px;
	}

	.b-table .table-wrapper.has-sticky-header {
		height: 23rem;
	}

	.hidden-row {
		display: none;
	}

	.truncate {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 28rem;
	}

	.padding-right-1 {
		padding-right: 2.5rem;
	}

	.padding-top-half {
		padding-top: 0.5rem;
	}

	label {
		padding-right: 0.5rem;
	}

	.width-28 {
		width: 28rem;
		max-width: 28rem;
		padding-right: 1rem;
	}

	.width-2 {
		width: 2.5rem;
	}

	.width-4 {
		width: 4rem;
		max-width: 4rem;
		min-width: 4rem;
	}

	.width-6 {
		width: 6rem;
		max-width: 12rem;
		min-width: 6rem;
	}

	.padding-right-1 {
		padding-right: 3rem;
	}

	.b-table .table {
		border-collapse: separate;
		border-bottom: solid 1px #dbdbdb;
	}

	.width-3 {
		width: 3rem;
		max-width: 3rem;
		min-width: 3rem;
	}

	.padding-left-4 {
		padding-left: 4rem;
	}

	.pointer {
		cursor: pointer;
	}

	.text-center {
		text-align: center;
	}

	.color-blue {
		color: #1472b8;
	}

	.color-blue:hover {
		color: #2494d1;
	}

	.bold {
		font-weight: bold;
	}

	/*styles the modal*/
	.animation-content {
		background-color: white;
		z-index: 5;
		display: block;
		padding: 1rem;
		min-width: 526px;
	}

	/*styles the modal*/
	.animation-content {
		overflow-y: scroll;
		max-height: 90%;
		border-radius: 10px;
	}

	.flex {
		display: flex;
	}

	.direction-row {
		flex-direction: row;
	}

	.margin-left-1 {
		margin-left: 1rem;
	}

	.vertical-margin-auto {
		margin-top: auto;
		margin-bottom: auto;
	}

	.button {
		height: 1.5rem;
		padding: 0.25rem;
		line-height: 1rem;
	}

	.button span {
		line-height: 1rem;
	}

	.th-wrap .icon svg {
		width: 1rem;
		height: 1rem;
	}

	.justify-center {
		justify-content: center;
	}

	.justify-end {
		justify-content: flex-end;
	}

	th:last-of-type .th-wrap {
		justify-content: center;
	}

	th {
		position: sticky;
		top: 89px;
		z-index: 5;
		background-color: white;
	}

	.table-wrapper {
		overflow-x: visible;
	}
}
</style>
