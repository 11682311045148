<template>
	<div
		style="margin: 2rem; padding: 1rem 2rem; border: 1px solid rgba(234, 241, 247, 1); display:block; page-break-inside: avoid; box-sizing:border-box;"
		v-if="loadedQuestion"
	>
		<div v-if="loadedQuestion.questionType == 717580003">
			<p style="font-weight:bold;margin-bottom:1rem;">{{ this.loadedQuestion.questionText }}</p>
			<div>
				<ol type="A">
					<li v-for="choice in mixedChoices" :key="choice.es_topicquestionchoice_id" style="margin-left:1rem;">
						{{ choice.es_text }}
					</li>
				</ol>
			</div>
		</div>

		<div v-else>
			<p>{{ this.loadedQuestion.questionText }}</p>
			<div class="choices-block">
				<p>Answer</p>
				<b-input v-model="answer" placeholder="Type your answer here"></b-input>
			</div>
		</div>
	</div>
	<div v-else>Question loading..</div>
</template>

<script>
import { mapGetters } from "vuex"
import axios from "axios"
import EventBus from "../event-bus"

export default {
	name: "PdfQuestion",
	props: ["choices"],
	computed: {
		...mapGetters(["questionChoices", "baseUrl"]),
		mixedChoices: function() {
			let arr = this.choices
			if (arr.length == 2) {
				//probably true/false, so don't mix answers
				return arr
			}
			var currentIndex = arr.length,
				temporaryValue,
				randomIndex
			// While there remain elements to shuffle...
			while (0 !== currentIndex) {
				// Pick a remaining element...
				randomIndex = Math.floor(Math.random() * currentIndex)
				currentIndex -= 1
				// And swap it with the current element.
				temporaryValue = arr[currentIndex]
				arr[currentIndex] = arr[randomIndex]
				arr[randomIndex] = temporaryValue
			}
			return arr
		}
	},
	data() {
		return {
			loadedQuestion: null,
			radio: null,
			checked: null,
			correctAnswer: false,
			answer: null,
			message: null,
			active: false,
			loading: false
		}
	},
	mounted() {
		this.getQuestion()
	},
	methods: {
		getTopicQuestionChoice(topicquestionchoiceid, topicid) {
			axios.get(this.baseUrl + `api/questionchoice?topicquestionchoiceid=${topicquestionchoiceid}&topicid=${topicid}`)
				.then(response => {
					if (response.data.value[0].es_iscorrect) {
						this.handleResponseChecking(true, response.data.value[0].es_message)
					} else {
						this.handleResponseChecking(false, response.data.value[0].es_message)
					}
				})
				.catch(err => {
					console.log("api/questionchoice error", err)
					this.handleResponseChecking(false, err)
				})
		},
		getFitbTopicQuestionChoice(topicquestionchoiceid, topicid) {
			axios.get(this.baseUrl + `api/fitbquestionchoice?topicquestionchoiceid=${topicquestionchoiceid}&topicid=${topicid}&text=${this.answer}`)
				.then(response => {
					if (response.data.value[0].es_iscorrect) {
						this.handleResponseChecking(true, response.data.value[0].es_message)
					} else {
						this.handleResponseChecking(false, "That's incorrect")
					}
				})
				.catch(err => {
					console.log("api/questionchoice error", err)
					this.handleResponseChecking(false, err)
				})
		},
		getQuestion() {
			//get question info out of one of the choices (they're nested objects are the same)
			let filteredObject = {}
			filteredObject.questionText = this.choices[0]["es_topicquestion"]["es_text"]
			filteredObject.questionType = this.choices[0]["es_topicquestion"]["es_type"]
			this.loadedQuestion = filteredObject
		}
	}
}
</script>

<style lang="scss">
#app .pdf-question-component {
	margin: 2rem;
	padding: 2rem;
	background-color: #dde4fb;

	.bold {
		font-weight: bold;
	}

	.margin-bot-2 {
		margin-bottom: 2rem;
	}

	.margin-left-1 {
		margin-left: 1rem;
	}

	.flex {
		display: flex;
	}

	.flex-row {
		flex-direction: row;
	}

	.align-center {
		align-items: center;
	}

	.choice-text {
		width: calc(100% - 24px);
		height: auto;
	}
}
</style>
