import router from '../../router/router.js';

const state = {
    currentPage: null,
    isIndexCollapsed: false
};

const getters = {
    currentPage: (state) => state.currentPage,
    isIndexCollapsed: (state) => state.isIndexCollapsed
};

const actions = {
    changeCurrentPage({ commit, rootState }, data) {
        let currentPage = data
        // if (!rootState.auth.authentication) {
        //     commit('setAuthentication', false)
        //     commit('setContact', null)
        //     commit('setErrorMessage', "Authentication failed. Please login again.")
        //     router.push({ path: '/error' })
        // } else {
        commit('setCurrentPage', currentPage)
        // }
    },
    navigateToTopic({ commit, rootState }, data) {
        let newValue

        if (data === "next") {
            newValue = rootState.topics.nextTopic
        } else if (data === "prev") {
            newValue = rootState.topics.previousTopic
        } else {
            newValue = data
        }

        router.push({
            path: `/cohort/${rootState.cohorts.currentCohort.es_cohortid}/module/${rootState.modules.currentModule.es_moduleid}/topic/${newValue.es_topicid}`
        });
    },
    toggleCollapse({ commit, rootState }) {
        commit('changeIndexCollapsed', !rootState.nav.isIndexCollapsed)
    },
};

const mutations = {
    setCurrentPage: (state, currentPage) => (state.currentPage = currentPage),
    changeIndexCollapsed: (state, isCollapsed) => (state.isIndexCollapsed = isCollapsed)
};

export default {
    state,
    getters,
    actions,
    mutations
}

