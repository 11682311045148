export const openSnackBar = (context, message) => {
    context.$buefy.snackbar.open({
        message: message,
        type: 'is-success',
        position: 'is-top',
        duration: 5000,
    });
}

export const closeableSnackBar = (context, message) => {
    context.$buefy.snackbar.open({
        message: message,
        type: 'is-success',
        position: 'is-top',
        indefinite: true,
        queue: false
    });
}