<template>
	<div class="timer-component">
		<span class="time" :class="minutes <= 1 ? 'red' : null">{{ hoursString }}:{{ minutesString }}:{{ secondsString }}</span>
	</div>
</template>

<script>
import EventBus from "../event-bus"
import { mapGetters, mapActions } from "vuex"
import moment from "moment"

export default {
	name: "Timer",
	mounted() {},
	computed: {
		//time calculations: https://stackoverflow.com/a/1322798/9753578
		...mapGetters(["rootTimer", "currentTopic", "continuingQuiz", "currentAttempt", "topicAttempts", "attempts"]),
		hours: function() {
			return Math.floor(this.rootTimer / 3600)
		},
		minutes: function() {
			let totalSeconds = this.rootTimer
			totalSeconds %= 3600
			return Math.floor(totalSeconds / 60)
		},
		seconds: function() {
			return this.rootTimer % 60
		},
		hoursString: function() {
			return ("0" + this.hours).slice(-2)
		},
		minutesString: function() {
			return ("0" + this.minutes).slice(-2)
		},
		secondsString: function() {
			return ("0" + this.seconds).slice(-2)
		}
	},
	methods: {
		...mapActions(["setRootTimer", "setAttemptStartTime", "setAttemptTimeEnd"])
	}
}
</script>

<style lang="scss">
#app .timer-component {
	width: 7rem;
	flex-direction: column;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid #1373b9;
	padding: 0.25rem;
	height: 2.5rem;
	margin-right: 2rem;
	border-radius: 0.5rem;

	.time {
		font-size: 1rem;
	}

	.red {
		color: red;
	}
}
</style>
