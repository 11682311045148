import axios from "axios"
import router from "../../router/router.js"
import moment from "moment"
import { getCohorts, getCohortImage, freeCohorts, getCohort } from "./../../api/cohort"

const state = {
	cohorts: null,
	myCohortGroups: null,
	cohortsIdsQuery: null,
	cohortsArray: null,
	currentCohort: null,
	slackUrls: null,
	allCohortGroups: null,
	freeCohorts: null,
	loadingCohorts: false,
	loadingCohort: false
}

const getters = {
	allCohorts: state => state.cohorts,
	allMyCohortGroups: state => state.myCohortGroups,
	cohortsArray: state => state.cohortsArray,
	currentCohort: state => state.currentCohort,
	slackUrls: state => state.slackUrls,
	allCohortGroups: state => state.allCohortGroups,
	freeCohorts: state => state.freeCohorts,
	loadingCohorts: state => state.loadingCohorts,
	loadingCohort: state => state.loadingCohort
}

const actions = {
	fetchCohortData({ commit, dispatch, rootState }, data) {
		//gets cohorts, cohortgroups, enrollments, and contact
		commit("setLoadingCohort", true)

		getCohort(data)
			.then(response => {
				if (response.data.message == "No enrollment, no contact") {
					commit("setAuthentication", true)

					if (router.history.current.params.message && router.history.current.params.message == "waitlist") {
					} else {
						router.push({ path: "/welcome" })
					}
				} else if (response.data.message == "No enrollment, has contact") {
					commit("setContact", response.data.value[0])
					commit("setAuthentication", true)

					//show error
					commit("setLoadingCohorts", false)
					commit("setErrorMessage", "Error loading that cohort. Your enrollment could not be found.")
					router.push({ path: "/error" })
				} else if (response.data.message == "Archived") {
					commit("setContact", response.data.value[0].es_contact)
					commit("setAuthentication", true)

					//show error
					commit("setLoadingCohorts", false)
					commit("setErrorMessage", "Cohort has been archived.")
					router.push({ path: "/error" })
					return
				} else {
					var enrollments = response.data.value
					commit("setEnrollments", enrollments)
					commit("setCurrentEnrollment", enrollments[0])

					//pdf password
					let contact = rootState.enrollments.currentEnrollment.es_contact
					let password = `${contact.firstname || ""}_${contact.lastname || ""}_${contact.mobilephone ||
						""}_${contact.emailaddress1 || ""}_${contact.address1_composite || ""}`

					//remove white space, commas, and any subsequent double underscores
					password = password.replace(/[,\s]/g, "_")
					password = password.replace(/_+/g, "_")
					commit("setPdfPassword", password)

					//test returning val from action

					commit("setAttempts", response.data.attempts.value)
					commit("setUnlockedTopics", response.data.unlockedTopics)
					commit("setSlackUrls", response.data.slackurls.value)
					commit("setAuthentication", true)
					commit("setAllCohortGroups", response.data.cohortGroups.value)

					dispatch("buildCohortsAndGroups", enrollments)
					dispatch("buildTopicsAndModules", response.data.topicsAndModules.value)
					dispatch("buildCohortIdsQuery")

					dispatch("makeCohortsArray")
					dispatch("getEnrollmentRoles")
				}
				commit("setLoadingCohort", false)

				if (!data.preventNav) {
					router.push({
						path: `/cohort/${rootState.cohorts.currentCohort.es_cohortid}/module/${rootState.modules.currentModule.es_moduleid}/topic/${rootState.topics.currentTopic.es_topicid}`
					})
				}
			})
			.catch(error => {
				if (error.message == "Network Error") {
					commit("setErrorMessage", "Network Error loading cohort.")
					console.log("api/cohorts error", error)
					router.push({ path: "/error" })
					commit("setAuthentication", false)
					commit("setLoadingCohorts", false)
				} else {
					commit("setErrorMessage", "Error loading cohort.")
					router.push({ path: "/error" })
				}
			})
	},
	testCookie({}) {
		return "test"
	},
	getFreeCohorts({ commit }) {
		commit("setLoadingCohorts", true)

		freeCohorts()
			.then(response => {
				commit("setFreeCohorts", response.data.value)
				commit("setLoadingCohorts", false)
			})
			.catch(error => {
				commit("setLoadingCohorts", false)
				console.log("api/freecohorts error", error)
				commit("setErrorMessage", "Error getting free cohorts")
				router.push({ path: "/error" })
			})
	},
	buildCohortIdsQuery({ commit, rootState }) {
		//build cohorts query
		let cohortsIdsQuery = ""
		let cohorts = rootState.cohorts.cohorts
		let timesToLoop = cohorts.length - 1
		cohorts.forEach((cohort, index) => {
			cohortsIdsQuery += cohort.es_cohortid
			if (index < timesToLoop) {
				cohortsIdsQuery += "&cohortid="
			}
		})
		commit("setCohortsIdsQuery", cohortsIdsQuery)
	},
	buildTopicsAndModules({ commit }, topicsAndModules) {
		let myModules = []
		let myTopics = []
		topicsAndModules.forEach(value => {
			myTopics.push(value)
			if (!myModules.some(e => e.es_moduleid === value._es_module_value)) {
				myModules.push(value.es_module)
			}
		})
		commit("setModules", myModules)
		commit("setTopics", myTopics)
	},
	buildCohortsAndGroups({ commit }, enrollments) {
		//filter out cohorts and cohortgroups
		let myCohorts = []
		let myCohortGroups = []
		enrollments.forEach(enrollment => {
			myCohorts.push(enrollment.es_cohort)
			if (enrollment.es_cohortgroup) {
				myCohortGroups.push(enrollment.es_cohortgroup)
			}
		})
		commit("setCohorts", myCohorts)
		commit("setMyCohortGroups", myCohortGroups)
	},
	changeCurrentCohort({ commit, dispatch, rootState }, cohort) {
		//update cookie
		document.cookie = `currentCohort=${cohort.es_cohortid}`
		commit("setCurrentCohort", cohort)
		let enrollment = rootState.enrollments.enrollments.find(enrollment => {
			return enrollment.es_cohort.es_cohortid == cohort.es_cohortid
		})

		commit("setCurrentEnrollment", enrollment)

		dispatch("setStartModule")
		dispatch("setStartTopic")
		dispatch("setPreviousTopic")
		dispatch("setNextTopic")
	},
	async getCohortImage({ commit, dispatch, rootState }, data) {
		//the backend will use the cohortid, and check it against allowed cohorts

		//variable
		let image

		//first check browser cache
		const cache = await caches.open("cohort-images")
		let response = await cache.match(`/api/cohortimage?cohortid=${data.cohortid}`)

		// use image from cache, else add to cache
		if (response) {
			image = await response.json().then(res => {
				return res.value[0]
			})
			dispatch("handleCohortImage", { image: image, data: data })
		} else {
			//add url
			cache.add(`/api/cohortimage?cohortid=${data.cohortid}`).then(() => {
				response = cache.match(`/api/cohortimage?cohortid=${data.cohortid}`).then(cacheResult => {
					//get result
					if (cacheResult) {
						cacheResult.json().then(cacheJson => {
							dispatch("handleCohortImage", {
								image: cacheJson.value[0],
								data: data
							})
						})
					} else {
						dispatch("handleCohortImage", {
							image: "none",
							data: data
						})
					}
				})
			})
		}
	},
	handleCohortImage({ commit, rootState }, data) {
		let cohortCards
		let cardType = data.data.cardType

		//updating image for which cards?
		if (cardType == "myCards") {
			cohortCards = rootState.enrollments.cohortCards
		} else if (cardType == "archivedCards") {
			cohortCards = rootState.enrollments.archivedCards
		} else {
			cohortCards = rootState.cohorts.freeCohorts
		}

		//loop through the cohorts and assign image to cohort
		cohortCards.forEach((cohort, index) => {
			//if the cohort ids match
			if (data.data.cohortid == cohort.es_cohortid) {
				//set  cohort.image to the image returned from api
				let newCohort = cohort
				newCohort["image"] = data.image ? data.image : "none"
				cohortCards.splice(index, 1, newCohort)
			}
		})

		//update appropriate cards
		if (cardType == "myCards") {
			commit("setCohortCards", cohortCards)
		} else if (cardType == "archivedCards") {
			commit("setArchivedCards", cohortCards)
		} else {
			commit("setFreeCohorts", cohortCards)
		}
	},
	makeCohortsArray({ commit, dispatch, rootState }) {
		let cohorts = rootState.cohorts.cohorts
		let topics = rootState.topics.topics
		let modules = rootState.modules.modules
		let cohortsArray = []

		if (cohorts.length == 0) {
			commit("setErrorMessage", "The data for this cohort does not exist or is not ready to be released.")
			commit("incompleteCohort", true)
		} else {
			cohorts.forEach(cohort => {
				cohort["modules"] = modules.filter(module => {
					return module._es_cohort_value == cohort.es_cohortid
				})

				// modules are sorted on the server
				// cohort["modules"].sort((a, b) => (a.es_sort > b.es_sort ? 1 : -1))

				cohort["modules"].forEach(module => {
					module["topics"] = topics.filter(topic => {
						return topic._es_module_value == module.es_moduleid
					})

					module["topics"].forEach(topic => {
						let enrollment = rootState.enrollments.enrollments.find(e => {
							return e.es_cohort.es_cohortid == cohort.es_cohortid
						})

						if (enrollment.es_isstaff) {
							topic["isLocked"] = false
						} else if (rootState.cohorts.cohorts[0].es_issequential) {
							if (rootState.topics.unlockedTopics.includes(topic.es_topicid)) {
								topic["isLocked"] = false
							} else {
								topic["isLocked"] = true
							}
						}
						// this is also on backend at LMSGuards.cs
						else if (!topic.es_startdate && !topic.es_releasedate) {
							topic["isLocked"] = true
						} else {
							let topicReleaseDate = moment(topic.es_releasedate).startOf("day")
							let today = moment().startOf("day")

							// this returns "is locked" whereas LMSGuards.cs returns "is released"
							topic["isLocked"] = !topicReleaseDate.isSameOrBefore(today, "day")
						}
					})
				})
				cohortsArray.push(cohort)
			})

			commit("setCohortsArray", cohortsArray)
			//update cookie
			document.cookie = `currentCohort=${rootState.enrollments.currentEnrollment.es_cohort.es_cohortid}`
			commit("setCurrentCohort", rootState.enrollments.currentEnrollment.es_cohort)
			dispatch("setStartModule")
			dispatch("setStartTopic")
			dispatch("setPreviousTopic")
			dispatch("setNextTopic")

			// pushes starting page if route is blank
			if (router.currentRoute.name == "course" && !router.currentRoute.params.topicid) {
				if (rootState.enrollments.enrollments.length > 1) {
					//cohort chooser if more than 1 cohort
					router.push({ path: "/welcome" })
				} else {
					router.push({
						path: `/cohort/${rootState.cohorts.currentCohort.es_cohortid}/module/${rootState.modules.currentModule.es_moduleid}/topic/${rootState.topics.currentTopic.es_topicid}`
					})
				}
			}
		}
	},
	setLoadingCohort({ commit, dispatch, rootState }, data) {
		commit("setLoadingCohort", data)
	}
}

const mutations = {
	setCohorts: (state, cohorts) => (state.cohorts = cohorts),
	setMyCohortGroups: (state, myCohortGroups) => (state.myCohortGroups = myCohortGroups),
	setCohortsIdsQuery: (state, cohortsIdsQuery) => (state.cohortsIdsQuery = cohortsIdsQuery),
	setCohortsArray: (state, arr) => (state.cohortsArray = arr),
	setCurrentCohort: (state, obj) => (state.currentCohort = obj),
	setSlackUrls: (state, slackUrls) => (state.slackUrls = slackUrls),
	setAllCohortGroups: (state, groups) => (state.allCohortGroups = groups),
	setFreeCohorts: (state, cohorts) => (state.freeCohorts = cohorts),
	setLoadingCohorts: (state, status) => (state.loadingCohorts = status),
	setLoadingCohort: (state, status) => (state.loadingCohort = status)
}

export default {
	state,
	getters,
	actions,
	mutations
}
